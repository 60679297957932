import gql from 'graphql-tag';

export const SINGLE_FILE_UPLOAD = gql`
  mutation SingleFileUpload($file: Upload!) {
    singleUpload(file: $file) {
      encoding
      filename
      mimetype
      uri
    }
  }
`;

export const UNSPLASH_FILE_UPLOAD = gql`
  mutation UnsplashFileUpload($filename: String!, $url: String!) {
    unsplashUpload(filename: $filename, url: $url) {
      encoding
      filename
      mimetype
      uri
    }
  }
`;
