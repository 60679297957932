import UrlPattern from 'url-pattern';

const hideHeaderUrlPatterns = ['/login', '/confirms/information', '/plan/:type', '/confirms/plan/:type'];
export const isHideHeader = () => {
  let matched = false;
  hideHeaderUrlPatterns.forEach((pattern) => {
    if (matched) return pattern;
    const pt = new UrlPattern(pattern);
    if (pt.match(window.location.pathname)) {
      matched = true;
    }
    return pattern;
  });
  return matched;
};

const hideLoadingUrlPatterns = ['/preview/form/:formId', '/f(/:id)'];
export const isHideLoading = () => {
  let matched = false;
  hideLoadingUrlPatterns.forEach((pattern) => {
    if (matched) return pattern;
    const pt = new UrlPattern(pattern);
    if (pt.match(window.location.pathname)) {
      matched = true;
    }
    return pattern;
  });
  return matched;
};
