import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

const StyledInput = styled(Input.TextArea)`
  background: transparent;
  resize: none;
  font-size: 22px;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  color: ${(props) => props.theme.form.answers};
  border: none;
  border-bottom: 2px solid;
  border-color: ${(props) => props.theme.form.answers}66;
  box-shadow: 0 0 0 0;
  &:focus,
  &:hover {
    border-color: ${(props) => props.theme.form.answers};
    box-shadow: 0 0 0 0;
  }
  ::placeholder {
    font-size: 22px;
    font-weight: unset;
    color: ${(props) => props.theme.form.answers}66;
    opacity: 0.7;
  }
`;

const Title = ({ value, placeholder = null, containerProps = {} }) => (
  <StyledInput {...containerProps} defaultValue={value} placeholder={'Please enter your response'} rows={5} />
);

export default Title;
