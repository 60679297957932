import { ApolloClient } from 'apollo-boost';
import { ApolloLink } from 'apollo-link';
import { notification } from 'antd';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { getErrorMessage } from './utils/errors';
import { getUserAccessToken } from './utils/storage';

const cache = new InMemoryCache();

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: getUserAccessToken(),
    },
  });
  return forward(operation);
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_SERVER_API_URL,
});

const errorLink = onError(({ response, graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      notification.warning({
        message: getErrorMessage(message),
        placement: 'bottomRight',
      });
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
  response.errors = null;
});

class GlobalApollo {
  GlobalApolloClient = new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, authMiddleware, httpLink]),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
    },
  });
}

export default new GlobalApollo();
