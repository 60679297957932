import * as React from 'react';
import Avatar from 'react-avatar';
import Scroll from 'react-perfect-scrollbar';
import { Dropdown, Icon, Badge, List, Empty } from 'antd';
import { useApolloClient } from 'react-apollo-hooks';
import { NotificationPayloadType } from '@formtory/shared/data/default';
import moment from 'moment';
import {
  GetNotifications,
  MarkNotificationAsRead,
  MarkNotificationAsReadVariables,
} from '@formtory/shared/graphql/types';
import {
  GET_NOTIFICATIONS,
  MARK_NOTIFICATIONS_AS_READ,
  DELETE_NOTIFICATIONS,
} from '@formtory/shared/graphql/notification';
import { StyledCurrentLanguageButton, StyledNotifications } from './StyledHeader';
import NotFoundImage from '../../../../app/src/assets/images/Face-with-Monocle.webp';
import { AppContext } from '@formtory/shared/contexts/AppContext';

const Notifications = (
  notifications: GetNotifications['notifications'],
  totalUnreadNotification,
  handleMarkNotificationAsRead,
  handleDeleteNotifications,
  handleNotificationView,
) => {
  const context = React.useContext(AppContext);
  const {
    intl: { formatMessage },
  } = context;
  return (
    <StyledNotifications>
      <div className="header">
        <h2>{formatMessage({ id: 'notifications' })}</h2>
        {totalUnreadNotification > 0 && <a onClick={handleMarkNotificationAsRead}>{formatMessage({ id: 'markAllRead' })}</a>}
        {notifications.length > 0 && totalUnreadNotification === 0 && (
          <a onClick={handleDeleteNotifications}>{formatMessage({ id: 'clearNotifications' })}</a>
        )}
      </div>
      <div className={'notifications'}>
        <Scroll>
          <List
            itemLayout="vertical"
            locale={{ emptyText: <Empty image={NotFoundImage} description={formatMessage({ id: 'noUnreadNotifications' })} /> }}
            dataSource={notifications}
            renderItem={(item) => (
              <List.Item
                className={`notification-item notification-item--${item.read ? 'read' : 'unread'}`}
                actions={[<time>{moment(item.createdAt).fromNow()}</time>]}
                onClick={() => handleNotificationView(item)}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      round={true}
                      src={item.fromUser.avatar}
                      name={`${item.fromUser.firstName} ${item.fromUser.lastName}`}
                      size={'40'}
                    />
                  }
                  description={<div dangerouslySetInnerHTML={{ __html: item.formattedContent }} />}
                />
              </List.Item>
            )}
          />
        </Scroll>
      </div>
    </StyledNotifications>
  )
};

const HeaderNotifications = () => {
  const client = useApolloClient();
  const [notifications, setNotifications] = React.useState<GetNotifications['notifications']>([]);
  let poolNotificationInterval;

  React.useEffect(() => {
    handleGetNotifications().then((r) => r);
    poolNotificationInterval = setInterval(() => {
      handleGetNotifications().then((r) => r);
    }, 10000);
    return () => clearInterval(poolNotificationInterval);
  }, []);

  const handleGetNotifications = async () => {
    const { data } = await client.query<GetNotifications>({
      query: GET_NOTIFICATIONS,
    });
    setNotifications(data.notifications || []);
  };

  const handleMarkNotificationAsRead = async () => {
    if (notifications.length === 0) return;
    const unreadNotificationIds = notifications.filter((item) => !item.read).map((item) => item._id);
    setNotifications((current) => [...current.map((item) => ({ ...item, read: true }))]);
    await client.mutate<MarkNotificationAsRead, MarkNotificationAsReadVariables>({
      mutation: MARK_NOTIFICATIONS_AS_READ,
      variables: {
        notificationIds: unreadNotificationIds,
      },
    });
  };

  const handleDeleteNotifications = async () => {
    if (notifications.length === 0) return;
    const readNotificationIds = notifications.filter((item) => item.read).map((item) => item._id);
    setNotifications([]);
    await client.mutate<MarkNotificationAsRead, MarkNotificationAsReadVariables>({
      mutation: DELETE_NOTIFICATIONS,
      variables: {
        notificationIds: readNotificationIds,
      },
    });
  };

  const handleNotificationView = async (notification: GetNotifications['notifications'][0]) => {
    await client.mutate<MarkNotificationAsRead, MarkNotificationAsReadVariables>({
      mutation: MARK_NOTIFICATIONS_AS_READ,
      variables: {
        notificationIds: [notification._id],
      },
    });
    switch (notification.type) {
      case NotificationPayloadType.MENTION_ON_A_COMMENT:
        if (notification.teamId) localStorage.setItem('selectedTeamId', notification.teamId);
        localStorage.setItem('viewingCommentByFormItemId', notification.formItemId);
        return (location.href = `/form/${notification.formId}`);
      case NotificationPayloadType.ADDED_FORM:
        if (notification.teamId) localStorage.setItem('selectedTeamId', notification.teamId);
        return (location.href = `/form/${notification.formId}`);
      case NotificationPayloadType.ADDED_YOU_TO_WORKSPACE:
        localStorage.setItem('selectedTeamId', notification.teamId);
        return (location.href = `/workspace/${notification.workspaceId}`);
      case NotificationPayloadType.ADDED_YOU_TO_TEAM:
        localStorage.setItem('selectedTeamId', notification.teamId);
        return (location.href = '/');
      default:
        return null;
    }
  };

  const totalUnreadNotification = notifications?.filter((item) => !item.read).length;

  return (
    <Dropdown
      overlay={Notifications(
        notifications,
        totalUnreadNotification,
        handleMarkNotificationAsRead,
        handleDeleteNotifications,
        handleNotificationView,
      )}
      trigger={['click']}
    >
      <StyledCurrentLanguageButton>
        <Badge count={totalUnreadNotification} offset={[-7, 7]} overflowCount={9}>
          <Icon type={'bell'} theme="filled" />
        </Badge>
      </StyledCurrentLanguageButton>
    </Dropdown>
  );
};

export default HeaderNotifications;
