import React from 'react';
import { Icon, Tooltip } from 'antd';
import BaseComponentWithContext from '@formtory/shared/components/BaseComponentWithContext';
import { FormTypes } from '@formtory/shared/models/form';
import {
  GetWorkspaceFormItems,
  GetWorkspaceFormItemsVariables,
  SubmitResponseMutation,
  SubmitResponseMutationVariables,
  TrackingFormVisitorMutationVariables,
  GetForm,
} from '@formtory/shared/graphql/types';
import ReactGA from 'react-ga';
import ReactFullpage from '@fullpage/react-fullpage';
import { GET_WORKSPACE_FORM_ITEMS } from '@formtory/shared/graphql/form';
import { orderFormItems, hasWelcomeScreen, getTitlePlanText } from '@formtory/shared/utils/form';
import { ChildDataProps, graphql } from 'react-apollo';
import * as FacebookPixel from '@formtory/shared/apps/FacebookPixel';
import Submit from './Submit';
import FilteredFormItem from './FilteredFormItem';
import BottomWidget from './BottomWidget';
import {
  StyledThankAfterSubmit,
  StyledMainFormContent,
  StyledEditResponseButton,
  StyledThankScreenContainer,
} from './Styled';
import { getJumpTo, getNextFormItems } from './Utils/LogicJumps';
import { calculate } from './Utils/Calculations';
import { buildIndexes } from './Utils/Indexing';
import { motion } from "framer-motion"


const normalScrollElements =
  '#BaseSettingsWrapper,#BaseEditorWrapper,.ant-modal,#FormEditor,#LogicJump,#FormNotification,#FormDesign,#FormSettings,#Calculator,#RespondentAccess,.ant-dropdown,.ant-select-dropdown';

interface OriginComponentProps {
  formId: string;
  form: GetForm['form'];
  hideFooter?: boolean;
  onSubmitted?: any;
  locale?: string;
  onSubmitResponse: any;
  previewing: boolean;
  googleAnalyticsEnabled?: boolean;
  focusedToFormItemId?: string;
  onTrackingFormVisitor: any;
  isTestMode: boolean;
  editingFormResponse?: SubmitResponseMutation['submitResponse'];
  device?: string;
}

type ChildProps = ChildDataProps<OriginComponentProps, GetWorkspaceFormItems, GetWorkspaceFormItemsVariables>;
const withFormItemsQuery = graphql<
  OriginComponentProps,
  GetWorkspaceFormItems,
  GetWorkspaceFormItemsVariables,
  ChildProps
>(GET_WORKSPACE_FORM_ITEMS, {
  options: (props) => ({
    variables: {
      formId: props.form._id,
    },
    fetchPolicy: 'cache-first',
  }),
});

const WelcomeScreen = ({
  formId,
  previewing,
  locale,
  formItemTranslations,
  customizedLabels,
  active,
  formItems,
  // focusedFormItem,
  onResult,
  initial,
  animate,
  exit,
  transition,
  device
}) => {
  const formItem = {
    ...formItems.find((item) => item.formType === FormTypes[FormTypes.WELCOME]),
  };
  if (!formItem)
    return (
      <StyledThankAfterSubmit className={'section'}>
        <h2>Hi, to start this form, please press enter.</h2>
      </StyledThankAfterSubmit>
    );
  const formItemTranslation = formItemTranslations.find(
    (item) => item.locale === locale && item.formItemId === formItem._id,
  );
  formItem.title = formItemTranslation?.title || formItem.title;
  formItem.description = formItemTranslation?.description || formItem.description;
  return (
    <motion.div
      className={'section'}
      key={formItem._id}
      initial={initial}
      animate={animate}
      exit={exit}
      transition={transition}
    >
      <FilteredFormItem
        onResult={onResult}
        previewing={previewing}
        submitted={false}
        onSaveActive={() => null}
        index={''}
        prices={0}
        scores={0}
        priceCurrency={null}
        customizedLabels={customizedLabels}
        active={active}
        formId={formId}
        formItem={formItem}
        formType={formItem.formType}
        device={device}
      />
    </motion.div>
  );
};

const ThanksScreen = ({
  formId,
  results,
  prices,
  priceCurrency,
  scores,
  previewing,
  formItemTranslations,
  locale,
  focusedFormItem,
  customizedLabels,
  formItems,
  onResult,
  initial,
  animate,
  exit,
  transition,
  device
}) => {
  // const formItem = formItems.find((item) => item._id === focusedFormItem || item.formType === FormTypes[FormTypes.THANK_YOU]);
  const formItem = formItems.find((item) => item.formType === FormTypes[FormTypes.THANK_YOU]);
  if (!formItem)
    return (
      <StyledThankAfterSubmit className={'section'}>
        <h2>Thank you for your submit. Nice to meet you, Bye!</h2>
      </StyledThankAfterSubmit>
    );
  const formItemTranslation = formItemTranslations.find(
    (item) => item.locale === locale && item.formItemId === formItem._id,
  );
  formItem.title = formItemTranslation?.title || formItem.title;
  formItem.description = formItemTranslation?.description || formItem.description;

  return (
    <motion.div
      className={'section'}
      key={formItem._id}
      initial={initial}
      animate={animate}
      exit={exit}
      transition={transition}
    >
      <FilteredFormItem
        results={results}
        onResult={onResult}
        previewing={previewing}
        submitted={false}
        index={''}
        priceCurrency={priceCurrency}
        prices={prices}
        scores={scores}
        customizedLabels={customizedLabels}
        onSaveActive={() => null}
        active={true}
        formId={formId}
        formItem={formItem}
        formType={formItem.formType}
        device={device}
      />
    </motion.div>
  );
};

let FullpageApi;

class Items extends BaseComponentWithContext<ChildProps, {}> {
  state = {
    submitted: false,
    submitting: false,
    started:
      this.props.previewing
        ? false
        : !this.props.form.allowMultipleSubmissionPerUser &&
        !!localStorage.getItem(`SubmittedResponse_${this.props.form._id}`),
    success:
      this.props.previewing
        ? false
        : !this.props.form.allowMultipleSubmissionPerUser &&
        !!localStorage.getItem(`SubmittedResponse_${this.props.form._id}`),
    activeItemId: null,
    loadingFormItems: false,
    startedAt: 0,
    prices: 0,
    scores: 0,
    displayFormItems: [],
    results: (this.props.form.allowEditResponse && !this.props.form.allowMultipleSubmissionPerUser && !this.props.previewing) ? JSON.parse(localStorage.getItem(`Results_${this.props.form._id}`)) : {},
    resultsIndex: (this.props.form.allowEditResponse && !this.props.form.allowMultipleSubmissionPerUser && !this.props.previewing) ? JSON.parse(localStorage.getItem(`ResultsIndex_${this.props.form._id}`)) : {},
    indexes: {},
    scroll: false,
    isNextSubmit: false,
    formLayout: this.props.form.defaultFormLayout || 'oneQuestion',
  };

  preloadImages(urls, allImagesLoadedCallback) {
    var loadedCounter = 0;
    var toBeLoadedNumber = urls.length;
    urls.forEach(function (url) {
      preloadImage(url, function () {
        loadedCounter++;
        if (loadedCounter == toBeLoadedNumber) {
          allImagesLoadedCallback();
        }
      });
    });
    function preloadImage(url, anImageLoadedCallback) {
      var img = new Image();
      img.onload = anImageLoadedCallback;
      img.src = url;
    }
  }

  componentDidMount() {
    window.location.hash = '';
    if (this.props.data.formItems) {
      this.setState({ displayFormItems: this.getUniqueListBy(this.props.data.formItems, '_id') });
      let images = [];
      this.props.data.formItems.map(formItem => {
        formItem.settings.image && images.push(formItem.settings.image)
      })
      this.preloadImages(images, function () {
        console.log('All images were loaded');
      });
      if (!hasWelcomeScreen(this.props.data.formItems)) {
        const orderedItems = orderFormItems(
          this.props.data.formItems.filter((item) => ![FormTypes[FormTypes.HIDDEN_FIELDS]].includes(item.formType)),
          this.props.form.orders,
          true,
        );
        const nextFormItem = orderedItems[0];
        FacebookPixel.Helpers.track('FormtoryFirstInteraction', this.props.form);
        const nextFormItems = getNextFormItems(nextFormItem, 0, orderedItems);
        if (nextFormItems) {
          this.setState({
            started: true,
            startedAt: new Date().getTime(),
            displayFormItems: this.getUniqueListBy([...[nextFormItem], ...nextFormItems], '_id'),
            activeItemId: nextFormItem._id,
          });
        }
      }
    }
    if (this.props.previewing) return;
    const { userAgent } = navigator as any;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    let visitedFormIds: any = localStorage.getItem('visitedFormIds');
    if (visitedFormIds) {
      visitedFormIds = JSON.parse(visitedFormIds);
    } else {
      visitedFormIds = [];
    }
    const visited = visitedFormIds && visitedFormIds.includes(this.props.formId);
    const variables: TrackingFormVisitorMutationVariables = {
      data: {
        formId: this.props.formId,
        uniqueVisitor: !visited,
        isTestMode: this.props.isTestMode,
        isMobile,
        isTablet: /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent.toLowerCase(),
        ),
        isOther: false,
        isDesktop: !isMobile,
      },
    };
    if (!visited) {
      visitedFormIds.push(this.props.formId);
    }
    localStorage.setItem('visitedFormIds', JSON.stringify(visitedFormIds));
    return this.props.onTrackingFormVisitor({ variables });
  }

  componentDidUpdate(prevProps: ChildProps) {
    this.state.formLayout === 'classic' && this.state.success && document.querySelector('.main-scrollbar').scrollTo(0, 0);
    if (
      this.props.focusedToFormItemId &&
      prevProps.focusedToFormItemId !== this.props.focusedToFormItemId
      // && this.props.previewing
    ) {
      // if (this.props.previewing) {
      const currentItem = this.props.data.formItems.find((item) => item._id === this.props.focusedToFormItemId);

      if (currentItem.formType === FormTypes[FormTypes.WELCOME]) {
        return this.setState({ started: false, activeItemId: null, success: false });
      }
      if (currentItem.formType === FormTypes[FormTypes.THANK_YOU]) {
        return this.setState({
          success: true,
          started: true,
          activeItemId: this.props.focusedToFormItemId,
        });
      }
      // }
      this.setState({ started: true, success: false, activeItemId: this.props.focusedToFormItemId });
      setTimeout(() => {
        this.onActiveItem(this.props.focusedToFormItemId);
      }, 200);
    }
    if (!prevProps.data.formItems && this.props.data.formItems) {
      let images = [];
      this.props.data.formItems.map(formItem => {
        formItem.settings.image && images.push(formItem.settings.image)
      })
      this.preloadImages(images, function () {
        console.log('All images were loaded');
      });
      // No welcome screen
      if (!hasWelcomeScreen(this.props.data.formItems)) {
        const orderedItems = orderFormItems(
          this.props.data.formItems.filter((item) => ![FormTypes[FormTypes.HIDDEN_FIELDS]].includes(item.formType)),
          this.props.form.orders,
          true,
        );
        const nextFormItem = orderedItems[0];
        FacebookPixel.Helpers.track('FormtoryFirstInteraction', this.props.form);
        // if (!this.props.previewing) {
        const nextFormItems = getNextFormItems(nextFormItem, 0, orderedItems);
        this.setState({
          started: true,
          startedAt: new Date().getTime(),
          displayFormItems: this.getUniqueListBy([...[nextFormItem], ...nextFormItems], '_id'),
          activeItemId: nextFormItem._id,
        });
        // } else {
        //   this.setState({
        //     started: true,
        //     startedAt: new Date().getTime(),
        //     displayFormItems: this.getUniqueListBy(orderedItems, '_id'),
        //     activeItemId: nextFormItem._id,
        //   });
        // }
      } else {
        const orderedItems = orderFormItems(
          this.props.data.formItems.filter((item) => ![FormTypes[FormTypes.HIDDEN_FIELDS]].includes(item.formType)),
          this.props.form.orders,
          true,
        );
        const nextFormItem = orderedItems[0];
        FacebookPixel.Helpers.track('FormtoryFirstInteraction', this.props.form);
        // if (!this.props.previewing) {
        const nextFormItems = getNextFormItems(nextFormItem, 0, orderedItems);
        this.setState({
          started: false,
          startedAt: null,
          displayFormItems: this.getUniqueListBy([...[nextFormItem], ...nextFormItems], '_id'),
          // activeItemId: nextFormItem._id,
        });
        // } else {
        //   this.setState({
        //     started: true,
        //     startedAt: new Date().getTime(),
        //     displayFormItems: this.getUniqueListBy(orderedItems, '_id'),
        //     activeItemId: nextFormItem._id,
        //   });
        // }
      }
    }
  }

  onResult = (formItemId, value, valueIndex) => {
    const { formItems } = this.props.data;
    const orderedItems = orderFormItems(
      formItems.filter((item) => ![FormTypes[FormTypes.HIDDEN_FIELDS]].includes(item.formType)),
      this.props.form.orders,
      true,
    );

    const currentItem = orderedItems.find((item) => item._id === formItemId);
    const currentItemIndex = orderedItems.findIndex((item) => item._id === formItemId);

    const nextFormItem = orderedItems[currentItemIndex + 1];

    if (currentItem && currentItem.formType === FormTypes[FormTypes.WELCOME]) {
      // if (!this.props.previewing) {
      this.setState({ started: true, loadingFormItems: true, startedAt: new Date().getTime() });
      const nextFormItems = getNextFormItems(nextFormItem, currentItemIndex, orderedItems);
      setTimeout(() => {
        this.setState({
          displayFormItems: this.getUniqueListBy(
            [...[nextFormItem], ...nextFormItems.slice(1, nextFormItems.length)],
            '_id',
          ),
          activeItemId: nextFormItem._id,
          loadingFormItems: false,
        });
      }, 0);
      // } else {
      //   setTimeout(() => {
      //     this.setState({
      //       displayFormItems: this.getUniqueListBy(orderedItems, '_id'),
      //       activeItemId: nextFormItem._id,
      //       loadingFormItems: false,
      //     });
      //   }, 0);
      // }
      FacebookPixel.Helpers.track('FormtoryFirstInteraction', this.props.form);
      return null;
    }

    // if (!this.props.previewing && this.state.results[formItemId]) {
    if (this.state.results[formItemId]) {
      if (currentItem.logicJumps.length > 0 || currentItem.allCaseJumpTo) {
        const currentDisplayItemIndex = this.state.displayFormItems.findIndex((item) => item._id === formItemId);
        this.state.displayFormItems = this.state.displayFormItems.slice(0, currentDisplayItemIndex + 1);
      }

      const formItem = this.props.data.formItems.find((item) => item._id === formItemId);
      if (formItem) {
        if ([FormTypes[FormTypes.MULTIPLE_CHOICE], FormTypes[FormTypes.PICTURE_CHOICE]].includes(formItem.formType))
          this.onActiveItem(formItemId);
      }
    }

    const formItem = this.props.data.formItems.find((item) => item._id === formItemId);
    // if (![FormTypes[FormTypes.STATEMENT]].includes(formItem.formType) && !this.props.previewing) {
    if (![FormTypes[FormTypes.STATEMENT]].includes(formItem.formType)) {
      ReactGA.event({
        category: 'FormtoryQuestionPassed',
        action: `${this.state.indexes[formItemId]}. ${getTitlePlanText(formItem.title)}`,
        value,
      });
    }

    setTimeout(() => {
      this.state.results[formItemId] = value;
      this.state.resultsIndex[formItemId] = valueIndex;
      // if (!this.props.previewing) {
      const jumpTo = getJumpTo(currentItem, this.state.results);
      if (jumpTo) {
        const jumpToFormItem = orderedItems.find((item) => item._id === jumpTo);
        if (
          this.props.data.formItems.find((item) => item._id === jumpTo) &&
          this.props.data.formItems.find((item) => item._id === jumpTo).formType === FormTypes[FormTypes.THANK_YOU]
        ) {
          return this.onPushToSubmit();
        }
        const jumpToFormItemIndex = orderedItems.findIndex((item) => item._id === jumpTo);
        const nextJumpTpFormItems = getNextFormItems(jumpToFormItem, jumpToFormItemIndex, orderedItems);
        this.state.activeItemId = jumpTo;
        this.setState({
          displayFormItems: this.getUniqueListBy(
            [...this.state.displayFormItems, ...[jumpToFormItem], ...nextJumpTpFormItems],
            '_id',
          ),
        });
        this.onActiveItem(jumpTo);
      }
      // }

      const { prices, scores } = calculate(this.state.displayFormItems, this.state.results);
      this.state['prices'] = prices;
      this.state['scores'] = scores;

      if (this.state.formLayout === 'classic') {
        const formItemIndex = this.state.displayFormItems.findIndex((item) => item._id === formItemId)
        const nextFormItems = this.state.displayFormItems.slice(formItemIndex + 1)
        const itemsRequired = []
        nextFormItems.map(item => {
          if (item.settings.required) {
            itemsRequired.push(item._id);
          }
        })
        if (itemsRequired.length === 0) return this.onPushToSubmit();
      }
      const nextFormItemIndex = this.state.displayFormItems.findIndex((item) => item._id === formItemId) + 1;
      if (typeof this.state.displayFormItems[nextFormItemIndex] === 'undefined') return this.onPushToSubmit();

      if (
        !this.state.displayFormItems[nextFormItemIndex]._id ||
        this.state.displayFormItems[nextFormItemIndex].formType === FormTypes[FormTypes.THANK_YOU]
      ) {
        return this.onPushToSubmit();
      }
      this.setState({ activeItemId: this.state.displayFormItems[nextFormItemIndex]._id });
      this.onActiveItem(this.state.displayFormItems[nextFormItemIndex]._id);
    }, 100);
  };

  onActiveItem = (itemId) => {
    this.state.scroll && FullpageApi?.moveTo(itemId);
    !this.state.scroll && this.setState({ activeItemId: itemId });
    this.state.formLayout !== 'classic' && document.querySelector('.main-scrollbar').scrollTo(0, 0);
    this.handleFocusInput(itemId);
  };

  handleFocusInput(itemId, timeout: number = 500) {
    if (this.props.previewing) return;
    const { userAgent } = navigator as any;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    if (!isMobile) {
      setTimeout(() => {
        document.getElementById(`input-${itemId}`)?.focus();
      }, timeout);
    }
  }

  onEditResponse = () => {
    const itemId = this.onScrollToFirstItem();
    setTimeout(() => {
      this.setState({ activeItemId: itemId, success: false, isNextSubmit: false });
    }, 200);
  };

  onScrollToFirstItem = () => {
    const displayFormItems = this.state.displayFormItems.filter(
      (item) =>
        ![FormTypes[FormTypes.THANK_YOU], FormTypes[FormTypes.WELCOME], FormTypes[FormTypes.HIDDEN_FIELDS]].includes(
          item.formType,
        ),
    );
    this.state.scroll && FullpageApi.moveTo(displayFormItems[0]._id);
    !this.state.scroll && this.setState({ activeItemId: displayFormItems[0]._id, isNextSubmit: false });
    return displayFormItems[0]._id;
  };

  onPushToSubmit = () => {
    this.state.scroll && (this.state['activeItemId'] = null);
    this.state.scroll && FullpageApi.moveTo('submit');
    !this.state.scroll && this.setState({ isNextSubmit: true });
    !this.state.scroll && this.state.formLayout === 'classic' && this.setState({ activeItemId: 'submit' });
    // !this.state.scroll && this.state.formLayout === 'classic' && document.querySelector('#submit--button').scrollIntoView();
    this.forceUpdate();
  };

  onSubmit = async () => {
    const { userAgent } = navigator as any;
    const results = [];
    const fieldErrors = [];
    Object.keys(this.state.results).map((key) => {
      if (!this.state.displayFormItems.find((item) => item._id === key)) return;
      results.push({
        formId: this.props.formId,
        formItemId: key,
        formType: this.props.data.formItems.find((item) => item._id === key).formType,
        result: JSON.stringify(this.state.results[key]),
        resultIndex: JSON.stringify(this.state.resultsIndex[key]),
      });
    });
    this.props.data.formItems.forEach((item) => {
      if (item.formType === FormTypes[FormTypes.HIDDEN_FIELDS]) {
        const result = {};
        const urlParams = new URLSearchParams(window.location.search);
        item.hiddenFields.forEach((field) => {
          result[field] = urlParams.get(field.toLowerCase());
        });
        results.push({
          formId: this.props.formId,
          formItemId: item._id,
          formType: FormTypes[FormTypes.HIDDEN_FIELDS],
          result: JSON.stringify(result),
        });
      }
    });
    this.state.displayFormItems.map((item) => {
      if (item.settings.required && !this.state.results[item._id]) {
        fieldErrors.push(item._id);
      }
    });
    if (fieldErrors.length > 0) {
      setTimeout(() => {
        this.setState({ activeItemId: fieldErrors[0], submitted: true, isNextSubmit: false });
      }, 1000);
      return this.onActiveItem(fieldErrors[0]);
    }
    this.state.submitted = true;
    // if (this.props.previewing) {
    //   return this.setState({ submitted: true, submitting: false, success: true });
    // }
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    const variables: SubmitResponseMutationVariables = {
      data: {
        formId: this.props.formId,
        completedTimes: new Date().getTime() - this.state.startedAt,
        isMobile,
        prices: `${this.state.prices}`,
        scores: `${this.state.scores}`,
        isTablet: /(ipad|tablet|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent.toLowerCase()),
        isOther: false,
        isDesktop: !isMobile,
        isTestMode: this.props.isTestMode,
        results,
      },
    };
    if (this.props.editingFormResponse) {
      variables.data._id = this.props.editingFormResponse._id;
    }
    this.setState({ submitting: true });
    const response = await this.props.onSubmitResponse({ variables });
    setTimeout(() => {
      if (response.data && response.data.submitResponse) {
        localStorage.setItem(`SubmittedResponse_${this.props.form._id}`, 'true');
        localStorage.setItem(`Results_${this.props.form._id}`, JSON.stringify(this.state.results));
        localStorage.setItem(`ResultsIndex_${this.props.form._id}`, JSON.stringify(this.state.resultsIndex));
        FacebookPixel.Helpers.track('FormtorySubmit', this.props.form);
        ReactGA.event({
          category: 'FormtoryRespondentInteractions',
          action: 'FormtorySubmit',
        });
        if (this.props.form.redirectOnComplete) {
          this.setState({ submitted: true, submitting: false, success: true })
          setTimeout(() => {
            location.href = this.props.form.redirectOnComplete;
          }, 1500);
          return;
        }
        // setTimeout(this.onScrollToFirstItem, 1000);
        if (this.props.onSubmitted) this.props.onSubmitted(response.data.submitResponse);
        const jumpTo = getJumpTo(
          this.state.displayFormItems[this.state.displayFormItems.length - 1],
          this.state.results,
        );
        if (jumpTo) {
          return this.setState({ submitted: true, submitting: false, activeItemId: jumpTo, success: true });
        }
        return this.setState({ submitted: true, submitting: false, success: true });
      }
    }, 1500);
  };

  afterLoad = (origin, destination) => {
    this.handleFocusInput(destination.anchor);
  };

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  render() {
    const {
      data: { loading, error, formItems },
      editingFormResponse,
      locale,
      form,
      previewing,
      hideFooter,
      device,
    } = this.props;
    const {
      started,
      submitted,
      activeItemId,
      results,
      resultsIndex,
      submitting,
      success,
      prices,
      scores,
      loadingFormItems,
      scroll,
      isNextSubmit,
      formLayout
    } = this.state;
    let displayFormItems: any[];
    let nonUniqueDisplayFormItems: any[];
    if (previewing && formItems) {
      nonUniqueDisplayFormItems = orderFormItems(
        formItems.filter(
          (item) =>
            ![
              FormTypes[FormTypes.HIDDEN_FIELDS],
              FormTypes[FormTypes.THANK_YOU],
              FormTypes[FormTypes.WELCOME],
            ].includes(item.formType),
        ),
        form.orders,
        true,
      );
      displayFormItems = this.getUniqueListBy(nonUniqueDisplayFormItems, '_id');
    } else {
      nonUniqueDisplayFormItems = this.state.displayFormItems.filter(
        (item) =>
          ![FormTypes[FormTypes.THANK_YOU], FormTypes[FormTypes.WELCOME], FormTypes[FormTypes.HIDDEN_FIELDS]].includes(
            item.formType,
          ),
      );
      displayFormItems = this.getUniqueListBy(nonUniqueDisplayFormItems, '_id');
    }
    if (form.locales.length > 0) {
      displayFormItems = displayFormItems.map((formItem) => {
        const cloneFormItem = { ...formItem };
        const formItemTranslation = form.formItemTranslations.find(
          (item) => item.locale === locale && item.formItemId === cloneFormItem._id,
        );
        const defaultFormItem = { ...formItems.find((item) => item._id === cloneFormItem._id) };
        cloneFormItem.title = formItemTranslation?.title || defaultFormItem.title;
        cloneFormItem.description = formItemTranslation?.description || defaultFormItem.description;

        cloneFormItem.settings.choices = formItemTranslation?.choices || defaultFormItem.settings.defaultChoices;
        cloneFormItem.settings.pictureChoices = formItemTranslation?.pictureChoices || defaultFormItem.settings.defaultPictureChoices;
        return cloneFormItem;
      });
    }
    const indexes = buildIndexes(displayFormItems, false);

    const customizedLabels =
      locale === 'vi-VN'
        ? form.customizedLabels
        : JSON.stringify(JSON.parse(form.labelTranslations || JSON.stringify({}))[locale] || {});

    const defaultFormTransition = form.defaultFormTransition || 'effect1';
    const variants = {
      effect1: {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.5 },
      },
      effect2: {
        initial: { scaleY: 0, opacity: 0 },
        animate: { scaleY: 1, opacity: 1 },
        exit: { scaleY: 0, opacity: 0 },
        transition: { duration: 0.5 },
      },
      effect3: {
        initial: { scale: 0.5, opacity: 0 },
        animate: { scale: 1, opacity: 1 },
        exit: { scale: 0, opacity: 0 },
        transition: { duration: 0.5 },
      },
      effect4: {
        initial: { scaleX: 0, opacity: 0 },
        animate: { scaleX: 1, opacity: 1 },
        exit: { scaleX: 0, opacity: 0 },
        transition: { duration: 0.5 },
      },
    }
    return (
      <React.Fragment>
        {/* <AnimatePresence exitBeforeEnter initial={false}> */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {loading && <p>Loading...</p>}
          {error && <p>Something went wrong!</p>}
        </div>
        {!loading && !error && formItems && (
          <StyledMainFormContent>
            {!started && !success && hasWelcomeScreen(formItems) && (
              <WelcomeScreen
                active={!activeItemId}
                onResult={this.onResult}
                previewing={previewing}
                formItems={formItems}
                locale={locale}
                formItemTranslations={form.formItemTranslations}
                customizedLabels={customizedLabels}
                formId={form._id}
                initial={variants[`${defaultFormTransition}`].initial}
                animate={variants[`${defaultFormTransition}`].animate}
                exit={variants[`${defaultFormTransition}`].exit}
                transition={variants[`${defaultFormTransition}`].transition}
                device={device}
              />
            )}
            {started && !loadingFormItems && !scroll && !success && formLayout === 'oneQuestion' && (
              <>
                {displayFormItems.map(
                  (item) =>
                    item._id === activeItemId && (
                      <motion.div
                        className="section"
                        key={item._id}
                        variants={variants}
                        initial={variants[`${defaultFormTransition}`].initial}
                        animate={variants[`${defaultFormTransition}`].animate}
                        exit={variants[`${defaultFormTransition}`].exit}
                        transition={variants[`${defaultFormTransition}`].transition}
                      >
                        <FilteredFormItem
                          index={indexes[item._id]}
                          results={results}
                          resultsIndex={resultsIndex}
                          prices={prices}
                          scores={scores}
                          priceCurrency={form.priceCurrency}
                          previewing={previewing}
                          submitted={submitted}
                          customizedLabels={customizedLabels}
                          active={true}
                          onResult={this.onResult}
                          formId={form._id}
                          formItem={item}
                          formType={item.formType}
                          isNextSubmit={isNextSubmit}
                          onSubmit={this.onSubmit}
                          submitting={submitting}
                          formLayout={formLayout}
                          theme={form.theme}
                          locale={locale}
                          device={device}
                        />
                      </motion.div>
                    ),
                )}
                {/* {activeItemId === 'submit' && (
                  <motion.div className="section" key={'submit'}>
                    <Submit customizedLabels={customizedLabels} onSubmit={this.onSubmit} submitting={submitting} />
                  </motion.div>
                )} */}
              </>
            )}
            {started && !loadingFormItems && !scroll && !success && formLayout === 'classic' && (
              <>
                {displayFormItems.map(
                  (item) =>
                    // item._id === activeItemId && (
                    <motion.div
                      className="section"
                      style={{ height: 'auto' }}
                      key={item._id}
                      variants={variants}
                      initial={variants[`${defaultFormTransition}`].initial}
                      animate={variants[`${defaultFormTransition}`].animate}
                      exit={variants[`${defaultFormTransition}`].exit}
                      transition={variants[`${defaultFormTransition}`].transition}
                    >
                      <FilteredFormItem
                        index={indexes[item._id]}
                        results={results}
                        resultsIndex={resultsIndex}
                        prices={prices}
                        scores={scores}
                        priceCurrency={form.priceCurrency}
                        previewing={previewing}
                        submitted={submitted}
                        customizedLabels={customizedLabels}
                        active={true}
                        onResult={this.onResult}
                        formId={form._id}
                        formItem={item}
                        formType={item.formType}
                        isNextSubmit={isNextSubmit}
                        onSubmit={this.onSubmit}
                        submitting={submitting}
                        formLayout={formLayout}
                        theme={form.theme}
                        locale={locale}
                        device={device}
                      />
                    </motion.div>
                  // ),
                )}
                {/* {activeItemId === 'submit' && ( */}
                <motion.div className="section" style={{ height: 'auto' }} key={'submit'}>
                  <Submit customizedLabels={customizedLabels} onSubmit={this.onSubmit} submitting={submitting} activeItemId={activeItemId} />
                </motion.div>
                {/* )} */}
              </>
            )}
            {/* {started && !loadingFormItems && !scroll && !success && formLayout === 'classic' && (
              <ReactFullpage
                licenseKey={'4111EAEF-EB4544FC-9548AFEB-3ECF182A'}
                scrollingSpeed={500}
                normalScrollElements={normalScrollElements}
                anchors={[...displayFormItems.map((item) => item._id), 'submit']}
                afterLoad={this.afterLoad}
                render={({ fullpageApi }) => {
                  FullpageApi = fullpageApi;
                  return (
                    <ReactFullpage.Wrapper>
                      {displayFormItems.map((item) => (
                        <div className="section" key={item._id}>
                          <FilteredFormItem
                            index={indexes[item._id]}
                            results={results}
                            prices={prices}
                            scores={scores}
                            priceCurrency={form.priceCurrency}
                            previewing={previewing}
                            submitted={submitted}
                            customizedLabels={customizedLabels}
                            active={true}
                            onResult={this.onResult}
                            formId={form._id}
                            formItem={item}
                            formType={item.formType}
                            isNextSubmit={isNextSubmit}
                            onSubmit={this.onSubmit}
                            submitting={submitting}
                          />
                        </div>
                      ))}
                      <div className="section" key={'submit'}>
                        <Submit customizedLabels={customizedLabels} onSubmit={this.onSubmit} submitting={submitting} />
                      </div>
                    </ReactFullpage.Wrapper>
                  );
                }}
              />
            )} */}
            {success && (
              <StyledMainFormContent>
                <ThanksScreen
                  results={results}
                  prices={prices}
                  scores={scores}
                  priceCurrency={form.priceCurrency}
                  locale={locale}
                  formItemTranslations={form.formItemTranslations}
                  customizedLabels={customizedLabels}
                  onResult={this.onResult}
                  formItems={formItems}
                  focusedFormItem={activeItemId}
                  previewing={previewing}
                  formId={form._id}
                  initial={variants[`${defaultFormTransition}`].initial}
                  animate={variants[`${defaultFormTransition}`].animate}
                  exit={variants[`${defaultFormTransition}`].exit}
                  transition={variants[`${defaultFormTransition}`].transition}
                  device={device}
                />
                {editingFormResponse && !previewing && form.allowEditResponse && (
                  <StyledEditResponseButton onClick={this.onEditResponse}>
                    Edit your response
                  </StyledEditResponseButton>
                )}
              </StyledMainFormContent>
            )}
          </StyledMainFormContent>
        )}
        {!hideFooter && started && !success && !loadingFormItems && !this.props.previewing && (
          <BottomWidget
            onActiveItem={this.onActiveItem}
            activeItemId={activeItemId}
            form={form}
            results={results}
            locale={locale}
            formItems={displayFormItems}
          />
        )}
        {/* </AnimatePresence> */}
      </React.Fragment>
    );
  }
}

export default Object.assign(withFormItemsQuery(Items), { contextType: undefined });
