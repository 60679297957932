import React, { useState } from 'react';
import {
 ConfigProvider,
 CountryPhoneInputValue,
 CountryPhoneInput,
} from 'antd-country-phone-input/dist/lite';
import en from 'world_countries_lists/data/countries/en/world.json';
// If you're writing styles with less, this could be a starter for you.
import 'rc-select/assets/index.less';
import styled from 'styled-components';

const StyledPhoneContainer = styled.div`
 display: flex;
 align-items: center;
 margin: 0;
 font-variant: tabular-nums;
 list-style: none;
 font-feature-settings: 'tnum';
 position: relative;
 display: inline-block;
 height: 44px;
 line-height: 22px;
 border-radius: 3px;
 transition: all 0.3s;
 background: transparent;
 font-size: 22px;
 width: 100%;
 padding: 0;
 color: #643ced;
 border: none;
 border-bottom: 2px solid;
 border-color: #643ced66;
 box-shadow: 0 0 0 0;
 .rc-select-single:not(.rc-select-customize-input) .rc-select-selector {
  border: none;
 }
 .rc-select-single:not(.rc-select-customize-input) .rc-select-selector .rc-select-selection-search-input {
  background: transparent;
 }
 .rc-select-single .rc-select-selector .rc-select-selection-item, .rc-select-single .rc-select-selector .rc-select-selection-placeholder {
  font-size: 22px;
 }
`;

const App = ({ value, placeholder = null, onChange, containerProps = {} }) => {
 const [phoneValue, setPhoneValue] = useState<CountryPhoneInputValue>(value || { short: 'VN' });
 return (
  <ConfigProvider locale={en}>
   <StyledPhoneContainer>
    <CountryPhoneInput
     defaultValue={value}
     placeholder={placeholder}
     style={{ height: 44, background: 'transparent', border: 'none', width: 'calc(100% - 100px)' }}
     selectProps={{ style: { height: 44, background: 'transparent', border: 'none', fontSize: 22 } }}
     value={phoneValue}
     onChange={(v) => {
      setPhoneValue(v);
      onChange(v);
     }}
    />
   </StyledPhoneContainer>
  </ConfigProvider>
 );
};

export default App;