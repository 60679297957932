import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { FormItemSettingsLayout } from '@formtory/shared/graphql/types';
import Image from './Elements/Image';
import Video from './Elements/Video';
import { isMobile } from '@formtory/app/src/utils/helperFunctions';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .buttons {
    height: 100px;
    display: flex;
    align-items: center;
    // justify-content: center;
  }
  .error {
    height: 40px;
    display: flex;
    align-items: center;
  }
`;

const StyledBackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .buttons {
    height: 100px;
    display: flex;
    align-items: center;
    // justify-content: flex-start;
  }
  .ant-row-flex {
    height: 100%;
    width: 100%;
    .ant-col {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  @media (max-width: 768px) {
    .ant-row-flex {
      .ant-col {
        height: auto;
      }
    }
  }
  .background {
    width: 100%;
    height: 100vh;
    margin: 0 -16px;
    background-size: cover;
    background-position: center center;
    ${isMobile() && 'margin-bottom: 22px'}
  }
  .content {
    padding: 0 32px;
  }
  .error {
    height: 40px;
    display: flex;
    align-items: center;
  }
`;

const StyledBackgroundFullContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  .buttons {
    height: 100px;
    display: flex;
    align-items: center;
    // justify-content: center;
  }
  .background {
    width: 100%;
    height: 100vh;
    position: relative;
    background-size: cover;
    background-position: center center;
  }
  .content {
    position: absolute;
    width: 100%;
    max-height: 100%;
    height: auto;
    max-width: 798px;
    padding: 24px;
    overflow: auto;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  .error {
    height: 40px;
    display: flex;
    align-items: center;
  }
`;

const StyledNormalContainer = styled.div`
  height: 100%;
  max-width: 798px;
  display: flex;
  margin: auto;
  padding: 24px;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  .buttons {
    height: 100px;
    display: flex;
    align-items: center;
  }
  .error {
    height: 40px;
    display: flex;
    align-items: center;
  }
`;

interface QuestionLayoutInterface {
  layout: FormItemSettingsLayout;
  videoUrl: string;
  image: string;
  hideImage?: boolean;
  imageBrightness: number;
  children: React.ReactElement | React.ReactElement[];
  formLayout: string;
  device?: string;
}

const QuestionLayout = (props: QuestionLayoutInterface) => {
  if (!props.videoUrl && props.image && props.layout && props.layout !== FormItemSettingsLayout.NORMAL) {
    if (props.layout === FormItemSettingsLayout.ASSET_BACKGROUND_FULL) {
      return (
        <StyledBackgroundFullContainer className={props.formLayout === 'classic' && 'section-list'}>
          <div
            className="background"
            style={{
              backgroundImage: `url(${props.image})`,
              filter: `brightness(${(props.imageBrightness || 100) / 100})`,
            }}
          />
          <div className="content">{props.children}</div>
        </StyledBackgroundFullContainer>
      );
    }
    if (props.layout === FormItemSettingsLayout.ASSET_LEFT) {
      return (
        <StyledContainer className={props.formLayout === 'classic' && 'section-list'}>
          <Row type="flex" justify="center" align="middle" gutter={32}>
            <Col span={!isMobile() && props.device !== 'mobile' ? 12 : 24} style={!isMobile() && props.device !== 'mobile' ? {} : { maxHeight: '40vh', textAlign: 'center', marginBottom: 22 }}>
              <Image
                value={props.image}
                containerProps={{
                  className: 'image--aside',
                  style: props.device !== 'mobile' ? { filter: `brightness(${(props.imageBrightness || 100) / 100})` } : { filter: `brightness(${(props.imageBrightness || 100) / 100})`, maxHeight: '40vh' },
                }}
              />
            </Col>
            {props.device === 'mobile'
              ?
              <Col className="content" span={24} style={{ height: 'auto' }}>
                {props.children}
              </Col>
              :
              <Col className="content" span={!isMobile() ? 12 : 18}>
                {props.children}
              </Col>
            }
          </Row>
        </StyledContainer>
      );
    }
    if (props.layout === FormItemSettingsLayout.ASSET_RIGHT) {
      return (
        <StyledContainer className={props.formLayout === 'classic' && 'section-list'}>
          <Row type="flex" justify="center" align="middle" gutter={32}>
            {props.device === 'mobile'
              ?
              <Col className="content" span={24} style={{ height: 'auto' }}>
                {props.children}
              </Col>
              :
              <Col className="content" span={!isMobile() ? 12 : 18}>
                {props.children}
              </Col>
            }
            <Col span={!isMobile() && props.device !== 'mobile' ? 12 : 24} style={!isMobile() && props.device !== 'mobile' ? {} : { maxHeight: '40vh', textAlign: 'center', marginBottom: 22 }}>
              <Image
                value={props.image}
                containerProps={{
                  className: 'image--aside',
                  style: props.device !== 'mobile' ? { filter: `brightness(${(props.imageBrightness || 100) / 100})` } : { filter: `brightness(${(props.imageBrightness || 100) / 100})`, maxHeight: '40vh' },
                }}
              />
            </Col>
          </Row>
        </StyledContainer>
      );
    }
    if (props.layout === FormItemSettingsLayout.ASSET_BACKGROUND_LEFT) {
      return (
        <StyledBackgroundContainer className={props.formLayout === 'classic' && 'section-list'}>
          <Row type="flex" justify="center" align="middle">
            <Col span={!isMobile() && props.device !== 'mobile' ? 12 : 24} style={!isMobile() && props.device !== 'mobile' ? {} : { height: '40vh' }}>
              <div
                className="background"
                style={{
                  backgroundImage: `url(${props.image})`,
                  filter: `brightness(${(props.imageBrightness || 100) / 100})`,
                }}
              />
            </Col>
            {props.device === 'mobile'
              ?
              <Col className="content" span={24} style={{ height: 'auto' }}>
                {props.children}
              </Col>
              :
              <Col className="content" span={!isMobile() ? 12 : 18}>
                {props.children}
              </Col>
            }

          </Row>
        </StyledBackgroundContainer>
      );
    }
    if (props.layout === FormItemSettingsLayout.ASSET_BACKGROUND_RIGHT) {
      return (
        <StyledBackgroundContainer className={props.formLayout === 'classic' && 'section-list'}>
          <Row type="flex" justify="center" align="middle">
            {props.device === 'mobile'
              ?
              <Col className="content" span={24} style={{ height: 'auto' }}>
                {props.children}
              </Col>
              :
              <Col className="content" span={!isMobile() ? 12 : 18}>
                {props.children}
              </Col>
            }

            <Col span={!isMobile() && props.device !== 'mobile' ? 12 : 24} style={!isMobile() && props.device !== 'mobile' ? {} : { height: '40vh' }}>
              <div
                className="background"
                style={{
                  backgroundImage: `url(${props.image})`,
                  filter: `brightness(${(props.imageBrightness || 100) / 100})`,
                }}
              />
            </Col>
          </Row>
        </StyledBackgroundContainer>
      );
    }
  }
  if (props.videoUrl) {
    return (
      <StyledNormalContainer className={props.formLayout === 'classic' && 'section-list'}>
        {/* {!props.hideImage && props.videoUrl && <Video value={props.videoUrl} />} */}
        {props.children}
      </StyledNormalContainer>
    );
  }
  return (
    <StyledNormalContainer className={props.formLayout === 'classic' && 'section-list'}>
      {/* {!props.hideImage && props.image && (
        <Image
          value={props.image}
          containerProps={{
            className: 'image--normal',
            style: { filter: `brightness(${(props.imageBrightness || 100) / 100})` },
          }}
        />
      )} */}
      {props.children}
    </StyledNormalContainer>
  );
};

export default QuestionLayout;
