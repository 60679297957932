import gql from 'graphql-tag';
import { USER_BASE_FIELDS } from './fragments';

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation MarkNotificationAsRead($notificationIds: [ID!]!) {
    markNotificationsAsRead(notificationIds: $notificationIds)
  }
`;

export const DELETE_NOTIFICATIONS = gql`
  mutation DeleteNotifications($notificationIds: [ID!]!) {
    deleteNotifications(notificationIds: $notificationIds)
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    notifications {
      _id
      fromUser {
        ...UserBaseFields,
      }
      type
      formattedContent
      workspaceId
      teamId
      formId
      formItemId
      commentId
      read
      createdAt
    }
  }
  ${USER_BASE_FIELDS}
`;
