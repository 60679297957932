import { Layout } from 'antd';
import styled from 'styled-components';
import { isMobile } from '@formtory/app/src/utils/helperFunctions';

export const StyledLayout = styled(Layout)`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.form.background} fixed repeat center center;
  color: ${(props) => props.theme.form.questions};
  * {
    font-family: ${(props) => props.theme.form.fontFamily} !important;
  }
  .lazy-load-image-background{
    min-height: 100vh;
    min-width: 100%;
    width: auto;
    height: 100vh;
    overflow: hidden;
    position: relative;
    img {
      // min-height: 100vh;
      // min-width: 100%;
      // width: auto;
      height: ${(props) => props.theme.form.fullHeight ? '100%' : 'auto'} ;
      width: ${(props) => props.theme.form.fullWidth ? '100%' : 'auto'} ;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(${(props) => props.theme.form.backgroundTranslateY - 50 || -50}%) translateX(${(props) => props.theme.form.backgroundTranslateX - 50 || -50}%) scaleX(${(props) => props.theme.form.backgroundScaleX || 1}) scaleY(${(props) => props.theme.form.backgroundScaleY || 1});
    }
  }
`;

export const StyledLayoutContent = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const StyledQuestionContainer = styled.div`
  width: 100%;
  // height: 100%;
  display: flex;
  max-width: 798px;
  margin: auto;
  flex-direction: row;
  // justify-content: center;
  @media (max-width: 480px) {
    justify-content: center;
  }
  .button-group {
    text-align: left;
    width: 720px;
    position: relative;
    @media (max-width: 480px) {
      width: fit-content;
    }
  }
`;

export const StyledQuestionContainerNoMedia = styled.div`
  width: 100%;
  max-width: 798px;
  margin: auto;
  // height: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 480px) {
    justify-content: center;
  }
  .button-group {
    text-align: left;
    width: fit-content;
    position: relative;
  }
`;

export const StyledQuestionContainerContent = styled.div`
  min-width: 480px;
  max-width: 798px;
  @media (max-width: 480px) {
    min-width: 80vw;
  }
`;

export const StyledSubmitContainer = styled.div`
  padding: 24px 0;
  display: flex;
  max-width: 798px;
  // margin: auto;
  // border-top: 1px solid ${(props) => props.theme.form.questions};
  flex-direction: column;
  // justify-content: center;
`;

export const StyledText = styled.p`
  color: ${(props) => props.theme.form.questions};
  text-align: left;
`;

export const StyledItemBaseContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
`;

export const StyledThankAfterSubmit = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.form.questions};
  flex-direction: column;
  h2 {
    text-align: center;
  }
`;

export const StyledBottomWidget = styled.div`
  width: 100%;
  height: 50px;
  // position: ${isMobile() ? 'fixed' : 'absolute'};
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transition: all 0.3s;
`;

export const StyledBottomWidgetContent = styled.div`
  height: 50px;
  padding: 0 15px;
  margin: auto;
  display: flex;
  align-items: center;
`;

export const StyledProgressContainer = styled.div`
  width: 300px;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
  }
  p {
    margin-bottom: -3px;
    font-size: 12px;
    color: ${(props) => props.theme.form.buttons};
  }
  .ant-progress-success-bg,
  .ant-progress-bg {
    background-color: ${(props) => props.theme.form.buttons};
  }
  .ant-progress-inner {
    background-color: ${(props) => props.theme.form.buttons}19;
  }
  .ant-progress-text {
    color: ${(props) => props.theme.form.buttons};
  }
`;

export const StyledProgressContainerMobile = styled.div`
  width: 100vw;
  top: -11px;
  position: fixed;
  width: 100vw;
  left: 0;
  right: 0;
  p {
    margin-bottom: -3px;
    font-size: 12px;
    color: ${(props) => props.theme.form.buttons};
  }
  .ant-progress-success-bg,
  .ant-progress-bg {
    background-color: ${(props) => props.theme.form.buttons};
  }
  .ant-progress-inner {
    background-color: ${(props) => props.theme.form.buttons}19;
  }
  .ant-progress-text {
    color: ${(props) => props.theme.form.buttons};
  }
`;

export const StyledErrorMessage = styled.div`
  color: ${(props) => props.theme.form.buttons};
  text-align: left !important;
  // margin-top: 10px;
`;

export const StyledBottomWidgetButtons = styled.div`
  position: absolute;
  right: 15px;
  bottom: 0px;
  height: 50px;
  display: flex;
  align-items: center;
  button {
    height: 40px;
    width: 40px;
    @media (max-width: 768px) {
      height: 30px;
      width: 36px;
      margin-left: 4px;
    }
    margin-left: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.form.buttons};
    border: 1px solid ${(props) => props.theme.form.buttons}55;
    color: ${(props) => props.theme.form.background};
    outline: 0;
    cursor: pointer;
    i {
      font-size: 18px;
      color: ${(props) => props.theme.form.background};
    }
  }
`;

export const StyledBranding = styled.div`
  width: auto !important;
  padding: 0 6px;
  border-radius: 4px;
  position: relative;
  img {
    position: absolute;
    left: -22px;
    top: -5px;
    width: 25px;
    @media (max-width: 768px) {
      width: 20px;
      left: -15px;
    }
  }
  .made-with {
    font-size: 8px;
    @media (max-width: 768px) {
      font-size: 6px;
    }
    color: ${(props) => props.theme.form.questions};
    position: absolute;
    top: -3px;
    left: 16px;
  }
  .formtory {
    font-size: 18px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    color: ${(props) => props.theme.form.answers};
    font-weight: 600;
  }
`;

export const StyledMainFormContent = styled.div`
  width: 100%;
  margin: auto;
  height: 100%;
  // text-align: center;
  @media (max-width: 768px) {
    // text-align: left;
  }
  position: relative;
  .ant-skeleton {
    margin-bottom: 60px;
    .ant-skeleton-paragraph {
      padding-left: 0;
      li {
        background: ${(props) => props.theme.form.buttons}19;
      }
    }
    .ant-skeleton-title {
      height: 24px;
      background: ${(props) => props.theme.form.buttons}19;
    }
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
    background: linear-gradient(
      90deg,
      ${(props) => props.theme.form.buttons}19 25%,
      ${(props) => props.theme.form.buttons}26 37%,
      ${(props) => props.theme.form.buttons}19 63%
    );
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite;
  }
  .fullpage-wrapper {
    height: 100%;
    min-height: 100%;
  }
  .section {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: auto !important;
    .section-list {
      margin: ${isMobile() ? '10px 10px 0 10px' : '20px auto 0px'};
      background-color: ${(props) => props.theme.form.buttons}80;
      border: 1px solid ${(props) => props.theme.form.buttons}90;
      border-radius: 10px;
    }
  }
  .section:last-child .section-list {
    margin-bottom: ${isMobile() ? '10px' : '20px'};
  }
  // .section > div {
  //   height: auto !important;
  // }
`;

export const StyledReloadButton = styled.div`
  position: absolute;
  z-index: 3;
  top: 10px;
  right: 20px;
  height: 40px;
  width: 40px;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #eee;
  i {
    color: #777;
    font-size: 20px;
  }
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

export const StyledDeviceButton = styled.div`
  position: absolute;
  z-index: 3;
  top: 10px;
  left: 20px;
  height: 40px;
  width: 70px;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  background: #fff;
  border: 1px solid #eee;
  i {
    color: #777;
    font-size: 20px;
  }
  @media only screen and (max-width: 480px) {
    display: none;
  }
  .active {
    color: #643ced;
    font-size: 22px
  }
`;

export const StyledNotFoundContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    max-width: 480px;
    margin-top: 16px;
  }
  h2 {
    font-size: 26px;
    font-weight: 500;
  }
`;

export const StyledLogo = styled.div`
  z-index: 30;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 0px;
  padding: 14px 16px 10px;
  box-sizing: border-box;
  background: transparent;
  pointer-events: none;
  transition: opacity 0.3s ease 0s;
  opacity: 1;
  .lazy-load-image-background {
    position: relative;
    min-height: 40px;
    margin: 0px auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: row;
    img {
      width: auto;
      height: auto;
      position: unset;
      top: unset;
      left: unset;
      transform: unset;
    }
  }
`;

export const StyledLocales = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  display: flex;
  z-index: 99;
  .ant-select-selection {
    background: transparent;
    border: none;
  }
  .ant-select-selection__rendered {
    margin-right: 30px;
    font-size: 16px;
    color: ${(props) => props.theme.form.questions}
  }

  button {
    margin-left: 8px;
    height: 40px;
    width: 40px;
    border-radius: 3px;
    background: #fff;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-color: ${(props) => props.theme.form.buttons};
    font-size: 20px;
  }
`;

export const StyledEditResponseButton = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 16px;
  // height: 40px;
  // width: 40px;
  border-radius: 3px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  i {
    color: #777;
    font-size: 20px;
  }
  background-color: ${(props) => props.theme.form.buttons};
  border-color: ${(props) => props.theme.form.buttons};
  color: ${(props) => props.theme.form.background};
  span,
  i {
    font-weight: 500;
    color: ${(props) => props.theme.form.background};
  }
  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => props.theme.form.buttons};
    border-color: ${(props) => props.theme.form.buttons};
    color: ${(props) => props.theme.form.background};
  }
`;

export const StyledSkeletonContainer = styled.div`
  height: 100%;
  max-width: 798px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledThankScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props) => props.theme.form.background} fixed repeat center center;
  background-image: url(${(props) => props.theme.form.backgroundImage});
  background-size: cover;
`;

export const StyledCloseButton = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  i {
    color: #777;
    font-size: 20px;
  }
`;

export const StyledAlphabetCharacters = styled.span`
  order: 1;
  width: 28px;
  height: 28px;
  background: ${(props) => props.theme.form.background};
  display: flex !important;
  flex-shrink: 0;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 700;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  border-color: ${(props) => props.theme.form.answers};
  margin-right: 10px;
  font-size: 12px;
  &.active {
    background: ${(props) => props.theme.form.answers};
    color: ${(props) => props.theme.form.background}!important;
  }
`;

export const StyledOptionContent = styled.div`
  // padding-right: 60px;
  flex-wrap: wrap;
  order: 2;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`;

export const StyledOptionCheck = styled.div`
  order: 3;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
`;
