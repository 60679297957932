import React from 'react';
import { FormTypes } from '@formtory/shared/models/form';
import Welcome from './Welcome';
import Thanks from './Thanks';
import MultipleChoice from './MultipleChoice';
import ShortText from './ShortText';
import LongText from './LongText';
import Statement from './Statement';
import PictureChoice from './PictureChoice';
import YesNo from './YesNo';
import Email from './Email';
import Date from './Date';
import Number from './Number';
import OpinionScale from './OpinionScale';
import Rating from './Rating';
import Dropdown from './Dropdown';
import Website from './Website';
import FileUpload from './FileUpload';
import QuestionGroup from './QuestionGroup';
import UnKnow from './UnKnow';
import { withBaseFormItem, withSaveFormItem, FormItemBaseWrappedChildProps } from './FormItemBase';

const FilteredFormItem = (props: FormItemBaseWrappedChildProps) => {
  switch (props.formType) {
    case FormTypes[FormTypes.WELCOME]:
      return <Welcome {...props} />;
    case FormTypes[FormTypes.DATE]:
      return <Date {...props} />;
    case FormTypes[FormTypes.DROPDOWN]:
      return <Dropdown {...props} />;
    case FormTypes[FormTypes.EMAIL]:
      return <Email {...props} />;
    case FormTypes[FormTypes.FILE_UPLOAD]:
      return <FileUpload {...props} />;
    case FormTypes[FormTypes.MULTIPLE_CHOICE]:
      return <MultipleChoice {...props} />;
    case FormTypes[FormTypes.OPINION_SCALE]:
      return <OpinionScale {...props} />;
    case FormTypes[FormTypes.PICTURE_CHOICE]:
      return <PictureChoice {...props} />;
    case FormTypes[FormTypes.QUESTION_GROUP]:
      return <QuestionGroup {...props} />;
    case FormTypes[FormTypes.RATING]:
      return <Rating {...props} />;
    case FormTypes[FormTypes.NUMBER]:
      return <Number {...props} />;
    case FormTypes[FormTypes.LONG_TEXT]:
      return <LongText {...props} />;
    case FormTypes[FormTypes.SHORT_TEXT]:
      return <ShortText {...props} />;
    case FormTypes[FormTypes.STATEMENT]:
      return <Statement {...props} />;
    case FormTypes[FormTypes.THANK_YOU]:
      return <Thanks {...props} />;
    case FormTypes[FormTypes.YES_NO]:
      return <YesNo {...props} />;
    case FormTypes[FormTypes.WEBSITE]:
      return <Website {...props} />;
    case FormTypes[FormTypes.UN_KNOW]:
      return <UnKnow {...props} />;
    default:
      return <div />;
  }
};

export default withSaveFormItem(withBaseFormItem(FilteredFormItem));
