import React, { useRef, useEffect, useState } from 'react';
import { Progress, Icon } from 'antd';
import { FormTypes } from '@formtory/shared/models/form';
import {
  StyledBottomWidget,
  StyledBottomWidgetContent,
  StyledProgressContainer,
  StyledProgressContainerMobile,
  StyledBottomWidgetButtons,
  StyledBranding
} from './Styled';
import { isMobile } from '@formtory/app/src/utils/helperFunctions';
import Logo from '../../../app/src/assets/images/favicon.png';
import { Link } from 'react-router-dom';

export const Brand = React.memo(() => (
  <Link to={'/'}>
    <img src={Logo} alt="Formtory" />
    <span className="made-with">Made with</span> <span className="formtory">Formtory</span>
  </Link>
));

const BottomWidget = ({ results, locale, activeItemId, form, formItems, onActiveItem }) => {
  const totalResults = Object.keys(results).length;
  const containerRef = useRef();
  const [containerWidth, setContainerWidth] = useState(0);
  let totalItems = formItems.length;
  const hasWelcome = formItems.find((item) => item.formType === FormTypes[FormTypes.WELCOME]);
  if (hasWelcome) {
    totalItems -= 1;
  }
  const hasThanks = formItems.find((item) => item.formType === FormTypes[FormTypes.THANK_YOU]);
  if (hasThanks) {
    totalItems -= 1;
  }
  const percent = Math.floor(Math.round((totalResults / totalItems) * 100));

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);
    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  });

  useEffect(() => {
    const currentContainerRef = containerRef.current as any;
    if (!currentContainerRef) return;
    setContainerWidth(currentContainerRef?.parentNode?.clientWidth);
  });

  const onNext = () => {
    if (activeItemId === 'submit') return;
    const currentItemIndex = formItems.findIndex((item) => item._id === activeItemId);
    if (typeof formItems[currentItemIndex + 1] === 'undefined') return;
    if (formItems[currentItemIndex].settings.required && !results[activeItemId]) return;
    const nextFormItem = formItems[currentItemIndex + 1];
    if (!nextFormItem._id || nextFormItem.formType === FormTypes[FormTypes.THANK_YOU]) return;
    onActiveItem(formItems[currentItemIndex + 1]._id);
  };

  const onBack = () => {
    if (activeItemId === 'submit') {
      onActiveItem(formItems[formItems.length - 1]._id);
    }
    const currentItemIndex = formItems.findIndex((item) => item._id === activeItemId);
    if (typeof formItems[currentItemIndex - 1] === 'undefined') return;
    const prevFormItem = formItems[currentItemIndex - 1];
    if (!prevFormItem._id || prevFormItem.formType === FormTypes[FormTypes.WELCOME]) return;
    onActiveItem(formItems[currentItemIndex - 1]._id);
  };

  const onKeydown = (e) => {
    if (e.keyCode === 38) {
      onBack();
    }
    if (e.keyCode === 40) {
      onNext();
    }
    return;
  };

  return (
    <StyledBottomWidget ref={containerRef} style={{ width: containerWidth }}>
      <StyledBottomWidgetContent>
        {/* {!isMobile() ? ( */}
        <>
          <StyledProgressContainerMobile>
            <Progress percent={percent} status="active" showInfo={false} />
          </StyledProgressContainerMobile>
        </>
        {/* ) : ( */}
        {/* <StyledProgressContainer>
            {form.progressBarType === 'PROPORTION' && (
              <p>
                {totalResults < 0 ? 0 : totalResults} of {totalItems} answered
              </p>
            )}
            <Progress
              percent={percent}
              status="active"
              showInfo={!form.progressBarType || form.progressBarType === 'PERCENTAGE'}
            />
          </StyledProgressContainer> */}
        {/* )} */}
      </StyledBottomWidgetContent>
      <StyledBottomWidgetButtons>
        {!form.hideBranding && (
          <StyledBranding onClick={onBack}>
            <Brand />
          </StyledBranding>
        )}
        <button onClick={onBack} style={{ borderRadius: 4 }}>
          <Icon type={'up'} />
        </button>
        <button onClick={onNext} style={{ borderRadius: 4 }}>
          <Icon type={'down'} />
        </button>
      </StyledBottomWidgetButtons>
    </StyledBottomWidget>
  );
};

export default BottomWidget;
