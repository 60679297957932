import * as React from 'react';
import { Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { useApolloClient } from 'react-apollo-hooks';
import {
  GetFormVariables,
  GetWorkspaceFormItems,
  GetWorkspaceFormItemsVariables,
  GetForm,
  PublishForm,
  PublishFormVariables,
} from '@formtory/shared/graphql/types';
import { GET_FORM, GET_WORKSPACE_FORM_ITEMS, PUBLISH_FORM } from '@formtory/shared/graphql/form';
import { StyledSingleFormHeaderActions } from './StyledHeader';
import { AppContext } from '../../../../shared/contexts/AppContext';
import { isMobile } from 'utils/helperFunctions';
import swal from '@sweetalert/with-react'
import { RouterHistoryService } from '@formtory/shared/core';
import { publicThemes } from '@formtory/shared/data/default';

export const onCheckPusblish = (data, context) => {
  const {
    intl: { formatMessage },
  } = context;
  const trial = []
  const authPlan = context.auth?.accessKey?.plan && context.auth?.accessKey?.active ? context.auth?.accessKey?.plan : 'FREE'
  data.formItems.map(formItem => {
    if (authPlan === 'FREE') {
      if (formItem.logicJumps.length > 0 || formItem.allCaseJumpTo !== null) {
        trial.push({ 'itemId': formItem._id, 'type': formatMessage({ id: 'logicJumpTab' }) })
      }
      if (formItem.calculations.length > 0) {
        trial.push({ 'itemId': formItem._id, 'type': formatMessage({ id: 'calculatorTab' }) })
      }
      if (formItem.formType === 'HIDDEN_FIELDS') {
        trial.push({ 'itemId': formItem._id, 'type': formatMessage({ id: 'hiddenFields' }) })
      }
      if (formItem.formType === 'THANK_YOU') {
        trial.push({ 'itemId': formItem._id, 'type': formatMessage({ id: 'thankScreen' }) })
      }
      if (formItem.title && Object.keys(JSON.parse(formItem.title)?.entityMap)?.length > 0) {
        trial.push({ 'itemId': formItem._id, 'type': formatMessage({ id: 'Mention' }) })
      }
      if (formItem.settings.isNPS) {
        trial.push({ 'itemId': formItem._id, 'type': formatMessage({ id: 'NPS' }) })
      }
      if (formItem.settings.layout && formItem.settings.layout !== "NORMAL") {
        trial.push({ 'itemId': formItem._id, 'type': formatMessage({ id: 'imageLayout' }) })
      }
    }
    if (authPlan === 'BASIC') {
      if (formItem.formType === 'HIDDEN_FIELDS') {
        trial.push({ 'itemId': formItem._id, 'type': formatMessage({ id: 'hiddenFields' }) })
      }
      if (formItem.settings.isNPS) {
        trial.push({ 'itemId': formItem._id, 'type': formatMessage({ id: 'NPS' }) })
      }
    }
  })
  if (authPlan === 'FREE') {
    if (data.form.locales.length > 0) trial.push({ 'type': formatMessage({ id: 'translationTab' }) })
    if (data.form.hideBranding) trial.push({ 'type': formatMessage({ id: 'hideFormtoryBranding' }) })
    if (data.form.redirectOnComplete) trial.push({ 'type': formatMessage({ id: 'redirectOnSuccess' }) })
    if (data.form.hasProtectedPassword) trial.push({ 'type': formatMessage({ id: 'protectedPassword' }) })
    if (data.form.enableTrackingResponderIp) trial.push({ 'type': formatMessage({ id: 'enableTrackingIp' }) })
    if (data.form.selfNotificationEnable) trial.push({ 'type': formatMessage({ id: 'selfNotifications' }) })
    if (data.form.respondentNotificationEnable) trial.push({ 'type': formatMessage({ id: 'respondentNotifications' }) })
    if (data.form.closeFormCustomEnable) trial.push({ 'type': formatMessage({ id: 'closeFormToNewResponse' }) })
    if (data.form.closeFormFromDateEnable) trial.push({ 'type': formatMessage({ id: 'scheduleCloseDate' }) })
    if (data.form.responseLimitEnable) trial.push({ 'type': formatMessage({ id: 'setResponseLimit' }) })
    if (data.form.themeKey && !publicThemes[data.form.themeKey]) trial.push({ 'type': formatMessage({ id: 'newTheme' }) })
    if (data.form.theme.logoImage) trial.push({ 'type': formatMessage({ id: 'themeLogo' }) })
  }
  if (authPlan === 'BASIC') {
    if (data.form.hideBranding) trial.push({ 'type': formatMessage({ id: 'hideFormtoryBranding' }) })
    if (data.form.enableTrackingResponderIp) trial.push({ 'type': formatMessage({ id: 'enableTrackingIp' }) })
    if (data.form.closeFormFromDateEnable) trial.push({ 'type': formatMessage({ id: 'scheduleCloseDate' }) })
    if (data.form.responseLimitEnable) trial.push({ 'type': formatMessage({ id: 'setResponseLimit' }) })
    if (data.form.theme.logoImage) trial.push({ 'type': formatMessage({ id: 'themeLogo' }) })
  }
  if (authPlan === 'PRO') {
    // if (data.form.theme.logoImage) trial.push({ 'type': formatMessage({ id: 'themeLogo' }) })
  }
  return trial
}

const SingleFormHeaderActions = ({ currentFormId, currentForm, savedSingleFormChanges }) => {
  const context = React.useContext(AppContext);
  const {
    intl: { formatMessage },
  } = context;
  const client = useApolloClient();
  const [publishing, setPublishing] = React.useState(false);
  const [published, setPublished] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setPublished(false);
      return () => clearInterval(interval);
    }, 30000);
  }, []);

  const handlePublishForm = async () => {
    if (context.selectedTeam) {
      const permissions = context.selectedTeam.permissions.find((permission) => permission.memberId === context.auth._id)
      if (!permissions.canPublishForm) return message.warning(`You don't have permission to publish this!.`);
    }
    setPublishing(true);
    const getFormResponse = await client.query<GetForm, GetFormVariables>({
      query: GET_FORM,
      variables: { formId: currentFormId },
      fetchPolicy: 'network-only',
    });
    const getFormItemResponse = await client.query<GetWorkspaceFormItems, GetWorkspaceFormItemsVariables>({
      query: GET_WORKSPACE_FORM_ITEMS,
      variables: { formId: currentFormId },
      fetchPolicy: 'network-only',
    });
    const data = {
      form: getFormResponse.data.form,
      formItems: getFormItemResponse.data.formItems,
    };
    const trial = await onCheckPusblish(data, context)
    const trialType = trial.map(t => t.type)
    let uniqueTrialType = [...new Set(trialType)];
    if (uniqueTrialType.length > 0) {
      swal(
        <div style={{ textAlign: 'left' }}>
          <h3>{formatMessage({ id: 'thisFormUsesFeatures' })}</h3>
          <h5 style={{ color: 'rgb(115, 115, 115)', fontWeight: 400 }}>{formatMessage({ id: 'toPublishItYouCan' })} <a onClick={() => RouterHistoryService.push('/plan/personal')}>{formatMessage({ id: 'upgradeYourPlan' })}</a> {formatMessage({ id: 'orRemoveTheseFeatures' })}</h5>
          <ul>
            {uniqueTrialType.map(item => <li>{item}</li>)}
          </ul>
        </div>
      )
    } else {
      await client.mutate<PublishForm, PublishFormVariables>({
        mutation: PUBLISH_FORM,
        variables: { id: currentFormId, data: JSON.stringify(data) },
      });
    }
    setPublishing(false);
    setPublished(true);
  };

  return (
    <StyledSingleFormHeaderActions>
      <ul>
        {!isMobile() &&
          <li style={{ width: 60 }}>
            <a href="/">{savedSingleFormChanges ? '' : formatMessage({ id: 'saving' })}</a>
          </li>
        }
        <li>
          <Link to={`/preview/form/${currentForm?.hash}`} target="_blank">
            <Button type={'default'}>{formatMessage({ id: 'preview' })}</Button>
          </Link>
        </li>
        {!context.limitResponse ? (<li>
          <Button type={'primary'} loading={publishing} disabled={publishing || published} onClick={handlePublishForm}>
            {published ? formatMessage({ id: 'published' }) : formatMessage({ id: 'publish' })}
          </Button>
        </li>) : (<li>
          <Button type={'primary'} loading={publishing} disabled={true}>
            {formatMessage({ id: 'publish' })}
          </Button>
        </li>)}
      </ul>
    </StyledSingleFormHeaderActions>
  );
};

export default SingleFormHeaderActions;
