import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from 'antd/lib/icon';
import uuid from 'uuid/v4';
import { useApolloClient } from 'react-apollo-hooks';
import { asyncForEach } from '@formtory/shared/utils/helpers';
import { SINGLE_FILE_UPLOAD } from '@formtory/shared/graphql/file';

const StyledContainer = styled.label`
  width: 150px;
  height: 150px;
  border: 1px dashed #ddd;
  display: inline-flex;
  margin-right: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-size: cover;
  background-position: center center;
  position: relative;
  p {
    text-align: center;
    margin-bottom: 0;
  }
  a {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      color: ${props => props.theme.colors.primary};
      margin: 0 !important;
    }
  }
  &:hover {
    cursor: pointer;
    background-color: #f8f8f8;
  }
`;

const StyledUploadIcon = styled(Icon)`
  font-size: 24px !important;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 0 !important;
`;

interface BaseProps {
  initialValue?: any;
  multiple?: boolean;
  onChange: (value: string | string[]) => void;
}

const PhotoUploader = React.memo((props: BaseProps) => {
  const [fileInputId] = useState(uuid());
  const client = useApolloClient();
  const [uploadedFiles, setUploadedFiles] = useState<Array<string>>(props.initialValue?.filter(item => !!item) || []);
  const [uploading, setUploading] = useState(false);

  const onUpload = async e => {
    if (!e.target.files) return;
    const files = [];
    setUploading(true);
    await asyncForEach(e.target.files, async file => {
      const { data } = await client.mutate({ mutation: SINGLE_FILE_UPLOAD, variables: { file } });
      files.push(data?.singleUpload?.uri);
    });
    setUploading(false);
    setUploadedFiles(files);
    if (props.onChange && !props.multiple) return props.onChange(files[0]);
    if (props.onChange && props.multiple) return props.onChange(files);
  };

  const onClear = file => {
    setUploadedFiles(uploadedFiles.filter(f => f !== file));
    if (props.onChange && !props.multiple) return props.onChange(null);
    if (props.onChange && props.multiple) return props.onChange(uploadedFiles.filter(f => f !== file));
  };

  return (
    <React.Fragment>
      {uploadedFiles.map(file => (
        <StyledContainer key={file} style={{ backgroundImage: `url(${file})`, borderStyle: 'solid' }}>
          {file && (
            <a onClick={() => onClear(file)}>
              <Icon type={'close'} />
            </a>
          )}
        </StyledContainer>
      ))}
      {!(uploadedFiles.length > 0 && !props.multiple) && (
        <StyledContainer htmlFor={fileInputId}>
          <StyledUploadIcon type={uploading ? 'loading' : 'camera'} />
          <p>{uploading ? 'Uploading...' : 'Upload logo'}</p>
        </StyledContainer>
      )}
      <input
        style={{ display: 'none' }}
        multiple={props.multiple}
        type="file"
        onChange={onUpload}
        id={fileInputId}
        accept="image/*"
      />
    </React.Fragment>
  );
});

export default PhotoUploader;
