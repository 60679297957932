import * as React from 'react';
import { Layout, Menu, Icon, Button, Progress, Alert, Row } from 'antd';
import styled from 'styled-components';
// import { FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';
import BaseComponentWithContext from '@formtory/shared/components/BaseComponentWithContext';
import { RouterHistoryService } from '@formtory/shared/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import WorkspaceForms from '../../components/Workspaces/WorkspaceForms';
import AddWorkspace from '../../components/Workspaces/AddWorkspace';
import WorkspaceListing from '../../components/Workspaces/WorkspaceListing';
import ActiveWorkspaceName from '../../components/Workspaces/ActiveWorkspaceName';
import AddNewForm from '../../components/Workspaces/AddNewForm';
import { isMobile } from '../../utils/helperFunctions';
import HeaderTeamSelector from 'components/Layouts/HeaderTeamSelector';
import { PLANS } from '../../../../shared/data/pricing';
import moment from 'moment';
import FormTemplatesHome from 'components/Workspaces/FormTemplatesHome';

const { Content, Sider } = Layout;

interface HomepageStates {
  openAddWorkspace: boolean;
  searchValue: string;
  currentWorkspace: any;
  openAddWorkspaceForm: boolean;
  openSideMenu: boolean;
}

interface HomepageProps extends RouteComponentProps<any> { }

class Homepage extends BaseComponentWithContext<HomepageProps, HomepageStates> {
  constructor(props: any) {
    super(props);
    this.state = {
      openAddWorkspace: false,
      searchValue: null,
      currentWorkspace: null,
      openAddWorkspaceForm: false,
      openSideMenu: false,
    };
    this.onSelectWorkspace = debounce(this.onSelectWorkspace.bind(this), 100);
  }

  onMenuClick = (params: any) => {
    if (params.key === 'CREATE_NEW_WORKSPACE') {
      return this.setState({ openAddWorkspace: !this.state.openAddWorkspace });
    }
  };

  onCloseAddWorkspaceForm = () => {
    return this.setState({ openAddWorkspace: false });
  };

  onSearchWorkspaces = (e: any) => {
    this.setState({ searchValue: e.target.value });
  };

  onSelectWorkspace(workspace: any) {
    if (!workspace) return;
    const { workspaceId } = this.props.match.params;
    if (workspace._id === workspaceId && this.state.currentWorkspace) {
      return this.setState({ currentWorkspace: workspace });
    }
    requestAnimationFrame(() => {
      this.setState({ currentWorkspace: workspace });
      RouterHistoryService.push(`/workspace/${workspace._id}`);
    });
  }

  onToggleAddWorkspaceForm = () => {
    this.setState({ openAddWorkspaceForm: !this.state.openAddWorkspaceForm });
  };

  onToggleSideMenu = () => {
    this.setState({ openSideMenu: !this.state.openSideMenu });
  };

  render() {
    const { openAddWorkspace, searchValue, currentWorkspace, openAddWorkspaceForm, openSideMenu } = this.state;
    const { workspaceId } = this.props.match.params;
    const {
      selectedTeam,
      auth,
      limitResponse,
      intl: { formatMessage },
      onCheckCurrentPlan,
    } = this.context;

    let monthlyTotalResponses = 0;
    let monthlyTotalResponsesAvailable = selectedTeam ? parseInt(selectedTeam.accessKey?.responsesNeed) : auth ? parseInt(auth.accessKey?.responsesNeed) : 0;
    (selectedTeam || auth) && (monthlyTotalResponses = selectedTeam ? selectedTeam.monthlyTotalResponses : auth.monthlyTotalResponses);
    if (onCheckCurrentPlan(this.context) === PLANS.FREE) {
      monthlyTotalResponsesAvailable = Infinity;
    }
    // switch (onCheckCurrentPlan(this.context)) {
    //   case PLANS.FREE: {
    //     monthlyTotalResponsesAvailable = Infinity;
    //     break;
    //   }
    //   case PLANS.BASIC: {
    //     monthlyTotalResponsesAvailable = 200;
    //     break;
    //   }
    //   case PLANS.PRO: {
    //     monthlyTotalResponsesAvailable = 2000;
    //     break;
    //   }
    //   case PLANS.BUSINESS: {
    //     monthlyTotalResponsesAvailable = 5000;
    //     break;
    //   }
    //   case PLANS.START: {
    //     monthlyTotalResponsesAvailable = 5000;
    //     break;
    //   }
    //   case PLANS.GROW: {
    //     monthlyTotalResponsesAvailable = 10000;
    //     break;
    //   }
    //   default: {
    //     monthlyTotalResponsesAvailable = 0;
    //     break;
    //   }
    // }
    const startDate = moment()
      .add(1, 'M')
      .startOf('month')
      .format("MMM D");
    const percent = Math.floor(Math.round((monthlyTotalResponses / monthlyTotalResponsesAvailable) * 100));
    return (
      <>
        {isMobile() && (
          <StyledMenuDiv>
            <StyledMenuToggleButton onClick={this.onToggleSideMenu} style={openSideMenu ? {} : {}}>
              <Icon type="menu" /> {formatMessage({ id: 'myWorkspaces' })}
            </StyledMenuToggleButton>
          </StyledMenuDiv>
        )}
        <StyledLayout className={isMobile() ? 'mobile' : ''}>

          <StyledSider
            width={isMobile() && !openSideMenu ? 0 : 240}
            theme={'light'}
            className={isMobile() ? 'mobile sider' : 'sider'}
          >
            <HeaderTeamSelector />
            {/* <StyledSearchInputGroup>
            <input
              onChange={this.onSearchWorkspaces}
              type="text"
              placeholder={`${formatMessage({ id: 'searchWorkspaces' })}...`}
            />
          </StyledSearchInputGroup> */}
            <StyledMenu mode="inline" defaultSelectedKeys={['1']} onClick={this.onMenuClick}>
              {/* <Menu.Item key="CREATE_NEW_WORKSPACE">
              <Icon type="plus" />
              <span className="nav-text">
                <FormattedMessage id={'createWorkspace'} />
              </span>
            </Menu.Item> */}

              <Menu.Item className="create-workspace" key="CREATE_NEW_WORKSPACE">
                <StyledMenuDividerText>{formatMessage({ id: 'myWorkspaces' }).toUpperCase()}</StyledMenuDividerText>
                <button className="btn-create-workspace"><Icon type="plus" /></button>
                {/* <span className="nav-text">
                <FormattedMessage id={'createWorkspace'} />
              </span> */}
              </Menu.Item>
              {openAddWorkspace && (
                <Menu.Item style={{ height: 54 }}>
                  <AddWorkspace selectedTeam={selectedTeam} onAddedWorkspace={this.onCloseAddWorkspaceForm} formatMessage={formatMessage} />
                </Menu.Item>
              )}
              <WorkspaceListing
                selectedTeam={selectedTeam}
                activeWorkspaceId={workspaceId}
                onSelectWorkspace={this.onSelectWorkspace}
                q={searchValue}
              />
            </StyledMenu>
            {(selectedTeam || auth) && (
              <StyledMenuBottom>
                <div className='bottom-header'>
                  <h1>{selectedTeam ? selectedTeam.name : (auth.username || 'guest')}</h1>
                  {this.context.selectedTeam && this.context.selectedTeam.ownerId === this.context.auth._id && (
                    <Button onClick={() => this.context.onUpdateContext({ openTeamSettings: true })}><Icon type="setting" /></Button>
                  )}
                </div>

                <div>
                  <div>{formatMessage({ id: 'responsesCollected' })}</div>
                  <Progress
                    percent={percent}
                    status="active"
                    showInfo={false}
                    strokeColor={percent > 90 ? 'rgb(217, 87, 103)' : ''}
                  />
                  <div className='text'>
                    <span>
                      <b>{monthlyTotalResponses < 0 ? 0 : monthlyTotalResponses}</b> {monthlyTotalResponsesAvailable === Infinity ? '' : `/ ${monthlyTotalResponsesAvailable}`}
                    </span>
                    <span>{formatMessage({ id: 'resetsOn' })} {startDate}</span>
                  </div>
                </div>
                {(!selectedTeam || selectedTeam.ownerId === auth._id) && (
                  <Button
                    onClick={() => RouterHistoryService.push(selectedTeam ? '/plan/personal' : '/plan/personal')}
                  >
                    <span>{formatMessage({ id: 'increaseResponseLimit' })}</span>
                  </Button>
                )}
              </StyledMenuBottom>
            )}
            <Button
              className="gist-open-chat"
            >
              <span>{formatMessage({ id: 'needHelp' })}</span>
            </Button>
          </StyledSider>
          <Layout style={{ padding: isMobile() ? '0px 16px' : 44, backgroundColor: '#FAFAFA' }}>
            {limitResponse && (
              <Alert type={'warning'} showIcon message={formatMessage({ id: 'reachedALimitWarning' })} style={{ marginBottom: 30 }} />
            )}
            <Row><FormTemplatesHome /></Row>
            {currentWorkspace && (
              <StyledContent>
                <ActiveWorkspaceName selectedTeam={selectedTeam} workspace={currentWorkspace} />
                <WorkspaceForms
                  workspaceId={currentWorkspace._id}
                  ableToAddForm={selectedTeam?.ownerId === auth._id || !selectedTeam}
                  onToggleAddWorkspaceForm={this.onToggleAddWorkspaceForm}
                />
              </StyledContent>
            )}
          </Layout>
          {openAddWorkspaceForm && <AddNewForm workspace={currentWorkspace} onCancel={this.onToggleAddWorkspaceForm} />}
        </StyledLayout>
      </>
    );
  }
}

const HomepageWrapper: any = Homepage;
export default Object.assign(withRouter(HomepageWrapper), { contextType: undefined });

const StyledLayout = styled(Layout)`
  // margin-top: 70px;
  // min-height: 96vh;
  height: ${isMobile() ? 'calc(100vh - 124px)' : 'calc(100vh - 70px)'};
  &.mobile {
    .ant-layout-content {
      margin-top: 20px;
    }
  }
`;

const StyledMenuDiv = styled.div`
  position: sticky;
  width: 100%
  background: #fff;
  height: 54px;
  top: 70px;
  left: 0;
  border: 0;
  padding: 5px 15px !important;
  box-shadow: rgb(0 0 0 / 7%) 0px -1px inset;
  z-index: 99;
`;

const StyledMenuToggleButton = styled<any>(Button)`
  border: 0;
  padding: 0;
  box-shadow: none;
  &:active, &:hover, &:focus {
    border: 0;
  }
  span {
    font-size: 12px;
    line-height: 20px;
    // color: rgb(115,115,115);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
`;

const StyledSider = styled<any>(Sider)`
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const StyledMenu = styled(Menu)`
  // height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-x: hidden;
  // border-right: none;
  flex-grow: 1;
  .create-workspace {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      border-radius: 4px;
      position: relative;
      outline: none;
      cursor: pointer;
      transition: all 0.4s ease 0s;
      padding: 0px;
      width: 32px;
      height: 32px;
      background-color: rgb(227, 227, 227);
      i {
        margin: 0;
        color: #737373;
      }
    }
  }
  .workspace {
    position: relative;
    transition: all 0.25s ease 0s;
    padding: 10px 32px;
    cursor: pointer;
  }
  .workspace-active {
    background-color: rgb(240, 240, 240);
    color: #643ced;
    font-weight: 500;
  }
`;

const StyledMenuBottom = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid #e8e8e8;
  padding: 0px 16px 8px 24px;
  height: 20vh;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .bottom-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    button {
      border: 0px;
      box-shadow: none;
    }
  }
  .text {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    span {
      color: rgb(115, 115, 115);
      font-size: 12px;
      font-weight: 200;
      b {
        color: rgb(38, 38, 39);
        font-size: 16px;
      }
    }
  }
  button {
    padding: 0;
  }
`;

const StyledMenuDividerText = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: rgb(115, 115, 115);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

// const StyledSearchInputGroup = styled.div`
//   padding: 0 24px;
//   border-right: 1px solid #eee;
//   input {
//     outline: none;
//     border: none;
//     height: 48px;
//   }
// `;

const StyledContent = styled(Content)``;
