import React, { useState } from 'react';
import { Button, List, Icon } from 'antd';
import styled from 'styled-components';
import Title from './Elements/Title';
import Description from './Elements/Description';
import { FormItemBaseWrappedChildProps } from './FormItemBase';
import {
  StyledQuestionContainer,
  StyledQuestionContainerContent,
  StyledAlphabetCharacters,
  StyledOptionContent,
  StyledOptionCheck,
} from './Styled';
import { getSetting } from './Utils';
import QuestionLayout from './QuestionLayout';
import { FormItemSettingsLayout } from '../../graphql/types';
import Image from './Elements/Image';
import Video from './Elements/Video';
import Index from './Elements/Index';
import Submit from './Submit';

const StyledOption = styled<any>(Button)`
  background: ${(props) => props.theme.form.answers}19;
  border: 1px solid ${(props) => props.theme.form.answers};
  color: ${(props) => props.theme.form.answers};
  text-align: left;
  font-size: 20px;
  min-width: 200px;
  padding: 4px;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  i {
    font-size: 18px;
  }
  // font-weight: 600;
  &:hover,
  &:focus {
    opacity: 1;
    background: transparent;
    border: 1px solid ${(props) => props.theme.form.answers};
    color: ${(props) => props.theme.form.answers};
  }
  span {
    color: ${(props) => props.theme.form.answers} !important;
  }
  i {
    color: ${(props) => props.theme.form.answers} !important;
  }
  &.active {
    border: 1px solid ${(props) => props.theme.form.answers};
    background-color: ${(props) => props.theme.form.answers};
    background: ${(props) => props.theme.form.answers}55;
    color: ${(props) => props.theme.form.answers};
  }
`;

const YesNo = (props: FormItemBaseWrappedChildProps) => {
  const { formItem, index, customizedLabels, prices, scores, priceCurrency, isNextSubmit, onSubmit, submitting, formLayout } = props;
  const { settings } = formItem;
  const [result, setResult] = useState(props.results[formItem._id] || null);

  const onSaveResults = (choice) => {
    setResult(choice.key);
    return setTimeout(() => {
      props.onResult(formItem._id, choice.key);
    }, 500);
  };

  return (
    <QuestionLayout
      videoUrl={settings.videoUrl}
      image={settings.image}
      hideImage={true}
      layout={settings.layout}
      imageBrightness={settings.imageBrightness}
      formLayout={formLayout}
    >
      <StyledQuestionContainer style={props.device === 'mobile' ? { justifyContent: 'center' } : {}}>
        <Index renderIndex={index} required={settings.required} />
        <StyledQuestionContainerContent style={props.device === 'mobile' ? { minWidth: '90%' } : {}}>
          <Title
            value={formItem.title}
            results={props.results}
            prices={prices}
            priceCurrency={priceCurrency}
            scores={scores}
            containerProps={{
              style: { textAlign: 'left', marginBottom: settings.descriptionEnabled && formItem.description ? 0 : 22 },
            }}

          />
          {settings.descriptionEnabled && formItem.description && (
            <Description
              prices={prices}
              scores={scores}
              priceCurrency={priceCurrency}
              results={props.results}
              value={formItem.description}
            />
          )}
          {settings.videoUrl && <Video value={settings.videoUrl} />}
          {!settings.videoUrl &&
            settings.image &&
            (!settings.layout || settings.layout === FormItemSettingsLayout.NORMAL) && (
              <Image
                value={settings.image}
                containerProps={{
                  className: 'image--normal',
                  style: { filter: `brightness(${(settings.imageBrightness || 100) / 100})` },
                }}
              />
            )}
          <List
            dataSource={[
              { key: 'YES', label: getSetting(customizedLabels, 'BUTTON_TO_RESPONSE_YES', 'Yes'), index: 'Y' },
              { key: 'NO', label: getSetting(customizedLabels, 'BUTTON_TO_RESPONSE_NO', 'No'), index: 'N' },
            ]}
            grid={{ gutter: 10 }}
            renderItem={(item, index) => (
              <List.Item style={{ border: 'none', marginBottom: 10 }}>
                <StyledOption className={result === item.key ? 'active' : ''} onClick={() => onSaveResults(item)}>
                  <StyledAlphabetCharacters className={result === item.key ? 'active' : ''}>
                    {item.index}
                  </StyledAlphabetCharacters>
                  <StyledOptionContent>{item.label}</StyledOptionContent>
                  <StyledOptionCheck>{result === item.key && <Icon type={'check'} />}</StyledOptionCheck>
                </StyledOption>
              </List.Item>
            )}
          />
          {/* <div className="buttons" /> */}
          {formLayout !== 'classic' &&
            <div className="buttons">
              {isNextSubmit && <Submit customizedLabels={customizedLabels} onSubmit={onSubmit} submitting={submitting} activeItemId={isNextSubmit && 'submit'} />}
            </div>
          }
        </StyledQuestionContainerContent>
      </StyledQuestionContainer>
    </QuestionLayout>
  );
};

export default YesNo;
