import gql from 'graphql-tag';

export const UPGRADE_PLAN = gql`
  mutation UpgradePlan($data: UpgradePlanInput!) {
    upgradePlan(data: $data) {
      _id
    }
  }
`;

export const APPROVE_PLAN = gql`
  mutation ApprovePlan($userId: ID!) {
    approvePlan(userId: $userId) {
      _id
      firstName
      lastName
      username
      avatar
    }
  }
`;

export const CHANGE_USER_PLAN = gql`
  mutation ChangeUserPlan($userId: ID!, $plan: String!) {
    changePlan(userId: $userId, plan: $plan) {
      _id
      firstName
      lastName
      username
      avatar
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers($limit: Int!, $skip: Int!, $search: String) {
    users(limit: $limit, skip: $skip, search: $search) {
      count
      edges {
        _id
        firstName
        lastName
        email
        username
        avatar
        dob
        gender
        createdAt
        updatedAt
        lastLoginAt
        lastLoginIP
      }
    }
  }
`;

export const GET_PUBLIC_USER_BY_EMAIL = gql`
  query GetPublicUserByEmail($email: String) {
    publicUser(email: $email) {
      _id
      firstName
      lastName
      email
      username
      avatar
    }
  }
`;
