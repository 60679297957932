import { User } from '@formtory/shared/models/user';
import { Workspace } from '@formtory/shared/models/workspace';

export enum FormTypes {
  WELCOME,
  THANK_YOU,
  MULTIPLE_CHOICE,
  SHORT_TEXT,
  LONG_TEXT,
  STATEMENT,
  PICTURE_CHOICE,
  YES_NO,
  EMAIL,
  RATING,
  DATE,
  OPINION_SCALE,
  NUMBER,
  DROPDOWN,
  FILE_UPLOAD,
  WEBSITE,
  QUESTION_GROUP,
  UN_KNOW,
  HIDDEN_FIELDS,
}

export interface Form {
  _id: any;
  name: string;
  themeKey: string;
  workspaceId: string;
  createdBy: string;
  totalVisitors: number;
  totalUniqueVisitors: number;
  totalDesktopVisitors: number;
  totalDesktopUniqueVisitors: number;
  totalMobileVisitors: number;
  totalMobileUniqueVisitors: number;
  totalTabletVisitors: number;
  totalTabletUniqueVisitors: number;
  totalOtherVisitors: number;
  totalOtherUniqueVisitors: number;
  createdAt: Date;
  deletedAt?: Date;
  creator: User;
  workspace: Workspace;
  updatedAt?: Date;
  customizedLabels: string;
}

export interface FormItem {
  _id: any;
  title: string;
  description: string;
  formId: string;
  formType: string;
  choices: Array<string>;
  order: number;
  settings: {
    required: boolean;
    descriptionEnabled: boolean;
    otherOptionEnabled: boolean;
    verticalAlignEnabled: boolean;
    randomizeEnabled: boolean;
    multipleSelectionEnabled: boolean;
    pictureChoiceSuperSize: boolean;
    opinionScaleLabelEnabled: boolean;
    opinionScaleLabelLeft: string;
    opinionScaleLabelCenter: string;
    opinionScaleLabelRight: string;
    opinionScaleStartAt: number;
    ratingSteps: number;
    ratingIconName: string;
    image: string;
    maxCharacters: number;
    buttonText: string;
    videoUrl: string;
    variables: [string];
  };
  summaries: any;
  form: Form;
  createdBy: string;
  createdAt: Date;
  deletedAt?: Date;
  updatedAt?: Date;
  creator: User;
}
