import * as React from 'react';
import { graphql, ChildMutateProps } from 'react-apollo';
import { CREATE_NEW_FORM, GET_WORKSPACE_FORMS } from '@formtory/shared/graphql/form';
import {
  CreateWorkspaceFormMutation,
  CreateWorkspaceFormMutationVariables,
  GetWorkspaceForms,
} from '@formtory/shared/graphql/types';
import styled from 'styled-components';
import { Button, Modal, Input, Icon } from 'antd';
import Avatar from 'react-avatar';
import BaseComponentWithContext from '../../../../shared/components/BaseComponentWithContext';
import FormTemplates from './FormTemplates';

interface AddWorkspaceFormProps {
  workspace: any;
  onCancel: any;
}

export const StyledModal = styled<any>(Modal)`
  box-shadow: box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2),0 2px 4px 0 rgba(0, 0, 0, 0.1),inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header{
    border-radius: 8px 8px 0 0;
    .ant-modal-title {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .ant-modal-body {
    padding: 30px 30px 40px 30px;
    @media (max-width: 768px) {
      padding: 15px 15px 20px 15px;
    }
  }
  .ant-input-group-addon:first-child {
    border-width: 3px !important;
    font-size: 22px;
    color: ${(props) => props.theme.colors.primary};
  }
  .ant-input-group-addon:last-child {
    border-width: 3px !important;
    font-size: 22px;
    color: ${(props) => props.theme.colors.primary};
  }
  p.label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 8px;
  }
  .avatar-info {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    .avatar {
      span {
        font-size: 16px;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      padding: 5px 10px;
      h3 {
        margin: 0;
      }
      span {
        padding: 1px 10px;
        background-color: rgb(245, 249, 248);
        color: rgb(2, 80, 65);
        border-radius: 6px;
        font-weight: 500;
      }
    }
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  input.ant-input {
    font-size: 18px;
    border-width: 3px !important;
    outline: none;
    height: 50px;
    padding-left: 16px;
    box-shadow: none;
    color: ${(props) => props.theme.colors.primary};
    display: block;
  }
`;

export const StyledButton = styled<any>(Button)`
  margin-top: 20px;
  width: 100%;
  border-radius: 8px;
`;

const StyledFormItem = styled.div`
  // margin-bottom: 10px;
  flex-grow: 1;
  
`;

type ChildProps = ChildMutateProps<
  AddWorkspaceFormProps,
  CreateWorkspaceFormMutation,
  CreateWorkspaceFormMutationVariables
>;

class AddNewForm extends BaseComponentWithContext<ChildProps> {
  state = {
    submitting: false,
    formName: null,
    template: true
  };

  onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    this.setState({ submitting: true });
    return this.props.mutate({
      variables: {
        workspaceId: this.props.workspace._id,
        name: this.state.formName,
      },
      update: (store, { data: { createForm } }) => {
        const previous: GetWorkspaceForms = store.readQuery({
          query: GET_WORKSPACE_FORMS,
          variables: { workspaceId: this.props.workspace._id },
        });
        const data = {
          forms: [...[createForm], ...previous.forms],
        };
        store.writeQuery({ query: GET_WORKSPACE_FORMS, variables: { workspaceId: this.props.workspace._id }, data });
        this.setState({ openInviteForm: false, submitting: false });
        requestAnimationFrame(this.props.onCancel);
      },
    });
  };

  onChangeFormName = (e: any) => {
    this.setState({ formName: e.target.value });
  };

  onSkipTemplate = () => {
    this.setState({ template: false });
  }

  render() {
    const { auth, selectedTeam, intl: { formatMessage } } = this.context;
    const { onCancel } = this.props;
    const { submitting, template } = this.state;
    return (
      <>
        {!template &&
          <StyledModal
            footer={null}
            title={formatMessage({ id: 'bringYourNewForm' })}
            closable={true}
            onCancel={onCancel}
            wrapClassName="theme-modal"
            visible={true}
            centered
          >
            {/* <Divider orientation={'left'} style={{ marginTop: -16 }}>
        </Divider> */}
            <div className='avatar-info'>
              <Avatar
                className="avatar"
                round={true}
                // src={selectedTeam ? selectedTeam.logo : auth.avatar}
                src={auth.avatar}
                size={'46'}
                // name={selectedTeam ? selectedTeam.name : `${auth.firstName} ${auth.lastName}`}
                name={`${auth.firstName} ${auth.lastName}`}
              />
              <div className='info'>
                <h3>{`${auth.firstName} ${auth.lastName}`}</h3>
                <span>{selectedTeam ? selectedTeam.name : `${auth.firstName} ${auth.lastName}`} <Icon type="caret-right" /> {this.props.workspace.name}</span>
              </div>
            </div>
            <h3>{formatMessage({ id: 'giveItAName' })}</h3>
            <StyledForm onSubmit={this.onSubmit}>
              <StyledFormItem>
                <Input autoFocus={true} onChange={this.onChangeFormName} placeholder={formatMessage({ id: 'formNamePlaceholder' })} required />
              </StyledFormItem>
              <div>
                <StyledButton disabled={submitting} loading={submitting} type={'primary'} htmlType={'submit'}>
                  {formatMessage({ id: 'create' })}
                </StyledButton>
              </div>
            </StyledForm>
          </StyledModal>
        }
        {template && <FormTemplates workspaceId={this.props.workspace._id} onSkipTemplate={this.onSkipTemplate} onCancel={onCancel} />}
      </>
    );
  }
}

const withAddWorkspaceFormMutation = graphql<
  AddWorkspaceFormProps,
  CreateWorkspaceFormMutation,
  CreateWorkspaceFormMutationVariables,
  ChildProps
>(CREATE_NEW_FORM);

export default withAddWorkspaceFormMutation(AddNewForm);
