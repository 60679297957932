export const PLANS = {
  FREE: 'FREE',
  BASIC: 'BASIC',
  PRO: 'PRO',
  BUSINESS: 'BUSINESS',
  START: 'START',
  GROW: 'GROW',
  ENTERPRISE: 'ENTERPRISE',
};

export const PAYMENT_METHODS = {
  BANK_TRANSFER: 'BANK_TRANSFER',
  COD: 'COD',
  STRIPE: 'STRIPE',
  NONE: 'NONE',
};

export const ACCESS_KEY_STATUS = {
  WAITING_FOR_COD: 'WAITING_FOR_COD',
  COD_PROCESSING: 'COD_PROCESSING',
  WAITING_FOR_BANK_TRANSFER: 'WAITING_FOR_BANK_TRANSFER',
  PAID: 'PAID',
};


export const getPlanAmount = (plan: string, months: number, responses?: string, user?: string): number => {
  let amount = 0;
  if (responses) {
    if (plan === PLANS.BASIC || plan === PLANS.PRO) {
      if (months >= 12 * 3) {
        amount = PRICES[plan].threeYears[responses] * months;
      }
      if (months >= 12 * 2 && months < 12 * 3) {
        amount = PRICES[plan].twoYears[responses] * months;
      }
      if (months >= 12 && months < 12 * 2) {
        amount = PRICES[plan].yearly[responses] * months;
      }
      if (months >= 6 && months < 12) {
        amount = PRICES[plan].sixMonths[responses] * months;
      }
      if (months >= 3 && months < 6) {
        amount = PRICES[plan].threeMonths[responses] * months;
      }
      if (months < 3) {
        amount = PRICES[plan].monthly[responses] * months;
      }
    }
    if (plan === PLANS.START || plan === PLANS.GROW) {
      if (months >= 12 * 3) {
        amount = PRICES[plan].threeYears[responses] * months;
      }
      if (months >= 12 * 2 && months < 12 * 3) {
        amount = PRICES[plan].twoYears[responses] * months;
      }
      if (months >= 12 && months < 12 * 2) {
        amount = PRICES[plan].yearly[responses] * months;
      }
      if (months < 12) {
        amount = PRICES[plan].sixMonths[responses] * months;
      }
    }
    if (plan === PLANS.BUSINESS) {
      if (months >= 12 && months < 12 * 2) {
        amount = PRICES[plan].yearly[parseInt(responses) < 15000 ? 15000 : responses][user] * months;
      }
      if (months < 12) {
        amount = PRICES[plan].monthly[parseInt(responses) < 15000 ? 15000 : responses][user] * months;
      }
    }
  }
  return amount;
};



// export const PRICES = {
//   FREE: {
//     monthly: 0,
//     yearly: 0,
//   },
//   BASIC: {
//     weekly: 29000,
//     monthly: 79000,
//     threeMonths: 79000,
//     sixMonths: 69000,
//     yearly: 59000,
//     twoYears: 55000,
//     threeYears: 49000,
//   },
//   PRO: {
//     weekly: 399000,
//     monthly: 799000,
//     threeMonths: 799000,
//     sixMonths: 699000,
//     yearly: 599000,
//     twoYears: 499000,
//     threeYears: 399000,
//   },
//   START: {
//     sixMonths: 5500000,
//     yearly: 5000000,
//     twoYears: 4500000,
//     threeYears: 4000000,
//   },
//   GROW: {
//     sixMonths: 9500000,
//     yearly: 8500000,
//     twoYears: 7500000,
//     threeYears: 6500000,
//   },
//   ENTERPRISE: {
//     monthly: 'CONTACT_US',
//     yearly: 'CONTACT_US',
//   },
// };

export const PRICES = {
  FREE: {
    monthly: 0,
    yearly: 0,
  },
  BASIC: {
    monthly: {
      // '200': 3.5,
      // '350': 5.5,
      // '600': 7.5,
      // '850': 9.5,
      '2000': 11.5,
    },
    yearly: {
      // '200': 2.5,
      // '350': 4.5,
      // '600': 6.5,
      // '850': 8.5,
      '2000': 2.5,
    },
  },
  PRO: {
    monthly: {
      '6000': 32,
      '7000': 42,
      '8000': 52,
      '9000': 62,
      '10000': 72,
    },
    yearly: {
      '6000': 17,
      '7000': 18,
      '8000': 19,
      '9000': 20,
      '10000': 21,
    },
  },
  BUSINESS: {
    monthly: {
      '15000': {
        '2': 81,
        '3': 87,
        '5': 93,
      },
      '20000': {
        '2': 128,
        '3': 134,
        '5': 140,
      },
      '30000': {
        '2': 174,
        '3': 180,
        '5': 186,
      },
      '50000': {
        '2': 383,
        '3': 424,
        '5': 464,
      },
      '> 50000': {
        // '2': 383,
        // '3': 424,
        // '5': 464,
      },
    },
    yearly: {
      '15000': {
        '2': 70,
        '3': 75,
        '5': 80,
      },
      '20000': {
        '2': 110,
        '3': 115,
        '5': 120,
      },
      '30000': {
        '2': 150,
        '3': 155,
        '5': 160,
      },
      '50000': {
        '2': 330,
        '3': 365,
        '5': 400,
      },
      '> 50000': {
        // '2': 330,
        // '3': 365,
        // '5': 400,
      },
    },
  }
};

export const FEATURES = {
  FREE_MINIMIZED: [
    'Unlimited responses',
    'Unlimited questions per formtory',
    'Create unlimited formtory',
  ],
  FREE: [
    'Unlimited',
    '1',
    'Unlimited',
    'Unlimited',

    '14',
    'O',
    'X',
    'Basic',
    'O',
    'O',
    'O',
    'O',
    '2MB',
    'O',
    'O',
    'O',
    'O',
    'O',

    'X',
    'O',
    'O',
    'O',
    'X',

    'X',
    'O',

    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',

    'O',
    'O',
    'O',
    'O',

    'O',
    'O',

    'O',
    'O',
    'O',

    'O',
    'O',
    'O',
    'O',

    'O',
    'X',
    'O',
  ],
  BASIC_MINIMIZED: [
    '2000 responses / mo',
    'EVERYTHING IN FREE',
    'Unlimited questions',
    'Unlimited Formtory',
    'Receive file uploads (2MB upload)',
    'Hidden fields',
    '5 Designs available',
  ],
  BASIC: [
    '2000',
    '1',
    'Unlimited',
    'Unlimited',

    '15',
    'X',
    'X',
    'Basic',
    'X',
    'X',
    'X',
    'X',
    '2MB',
    'O',
    'X',
    'O',
    'O',
    'O',

    'X',
    'X',
    'X',
    'O',
    'X',

    'X',
    'O',

    'X',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',

    'X',
    'X',
    'O',
    'O',

    'O',
    'O',

    'O',
    'O',
    'O',

    'O',
    'O',
    'O',
    'O',

    'Standard',
    'X',
    'O',
  ],
  PRO_MINIMIZED: [
    '6,000 - 10,000 responses / mo',
    'EVERYTHING IN BASIC',
    'Self email notifications',
    'Respondent email',
    'Formtory Templates',
    'Change image position',
    'Transition effects',
    'Redirect on completion',
    'Redirect from ending screen',
    'Report',
    'Translate',
    '3 Basic Connects'
  ],
  PRO: [
    '6000 - 10000',
    '1',
    'Unlimited',
    'Unlimited',

    '16',
    'X',
    'X',
    'Basic',
    'X',
    'X',
    'X',
    'X',
    '5MB',
    'X',
    'X',
    'X',
    'X',
    'X',

    'X',
    'X',
    'X',
    'X',
    'X',

    'X',
    'X',

    'X',
    'X',
    'X',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',
    'O',

    'X',
    'X',
    'X',
    'X',

    'O',
    'O',

    'O',
    'O',
    'O',

    'O',
    'O',
    'O',
    'O',

    'Priority',
    'X',
    'O',

  ],
  BUSINESS_MINIMIZED: [
    '15,000 responses / mo',
    '2 users',
    'Unlimited questions',
    'Unlimited Formtory',
    'Multiple Thank You Screens',
    'Calculator',
    'Mention',
    'Logic Jump',
    'Receive file uploads (2MB)',
    'Hidden fields',
    'Embed your form in a web',
    'Self email notifications',
    'Respondent email',
    'Formtory Templates',
    'Change image position',
    'Transition effects',
    'Redirect on completion',
    'Redirect from ending screen',
    'Report',
    // 'Sync with Google Sheets',
    // 'Facebook Pixel Connect',
    // 'Google Analytics',
    'Translate',
    '3 Basic Connects'
  ],
  BUSINESS: [
    '15000',
    '2',
    'Unlimited',
    'Unlimited',

    '16',
    'X',
    'X',
    'Advance',
    'X',
    'X',
    'X',
    'X',
    '10MB',
    'X',
    'X',
    'X',
    'X',
    'X',

    'X',
    'X',
    'X',
    'X',
    'X',

    'X',
    'X',

    'X',
    'X',
    'X',
    'X',
    'X',
    'X',
    'X',
    'X',
    'X',
    'X',
    'X',
    'X',

    'X',
    'X',
    'X',
    'X',

    'X',
    'X',

    'X',
    'X',
    'X',

    'X',
    'X',
    'X',
    'X',

    'Priority+',
    'X',
    'X',
  ],
  START_MINIMIZED: [
    '5,000 responses / mo',
    '2 users',
    'Unlimited questions',
    'Unlimited Formtory',
    'Multiple Thank You Screens',
    'Calculator',
    'Mention',
    'Logic Jump',
    'Receive file uploads (2MB)',
    'Hidden fields',
    'Embed your form in a web',
    'Self email notifications',
    'Respondent email',
    'Formtory Templates',
    'Change image position',
    'Transition effects',
    'Redirect on completion',
    'Redirect from ending screen',
    'Report',
    // 'Sync with Google Sheets',
    // 'Facebook Pixel Connect',
    // 'Google Analytics',
    'Translate',
    '3 Basic Connects'
  ],
  START: [
    '5,000 responses / mo',
    '2 users',
    'Unlimited questions',
    'Unlimited Formtory',
    'Multiple Thank You Screens',
    'Calculator',
    'Mention',
    'Logic Jump',
    'Receive file uploads (2MB uploads)',
    'Hidden fields',
    'Embed your form in a web page',
    'Self email notifications',
    'Respondent email notifications',
    'Formtory Templates',
    'Change image position',
    'Transition effects',
    'Redirect on completion',
    'Redirect from ending screen',
    'Report',
    'Sync with Google Sheets',
    'Facebook Pixel Connect',
    'Google Analytics',
    'Translate',
  ],
  GROW_MINIMIZED: [
    '20,000 responses / mo',
    '6 users',
    'EVERYTHING IN START',
    'Receive file uploads (5MB)',
    'Remove Formtory branding',
    'Advanced embed options',
    'Close embed on submit',
    'Auto-close form to new responses',
    'Schedule closing date',
    'Custom  subdomain',
    'Custom meta description',
    'Advanced Report',
    'Accept payments',
    'Webhooks',
    'Custom link',
    '500+ intergrations',
    'Drop-off rates',
    '8 Advanced Connects'
  ],
  GROW: [
    '20,000 responses / mo',
    '6 users',
    'Unlimited Formtory',
    'Multiple Thank You Screens',
    'Calculator',
    'Mention',
    'Logic Jump',
    'Receive file uploads (5MB uploads)',
    'Hidden fields',
    'Embed your form in a web page',
    'Self email notifications',
    'Respondent email notifications',
    'Formtory Templates',
    'Change image position',
    'Transition effects',
    'Redirect on completion',
    'Redirect from ending screen',
    'Report',
    'Sync with Google Sheets',
    'Facebook Pixel Connect',
    'Google Analytics',
    'Translate',
    'Remove Formtory branding',
    'Advanced embed options',
    'Close embed on submit',
    'Auto-close form to new responses',
    'Schedule closing date',
    'Custom  subdomain',
    'Custom meta description',
    'Advanced Report',
    'Accept payments',
    'Webhooks',
    '500+ intergrations with Zapier',
    'Drop-off rates',
    'Automate.io intergration',
    'HubSpot intergration',
    'Mailchimp intergration',
    'Airtable intergration',
    'Pipedrive intergration',
    'Slack intergration',
    'Custom link',
    'Google Tag Manager',
    'Saleforce intergration',

  ],
  ENTERPRISE_MINIMIZED: [
    '50,000 responses / mo',
    '6 users',
    'EVERYTHING IN GROW',
    'Team permission',
    '360 Degree Feedback',
  ],
  ENTERPRISE: [
    'Monthly response limit',
    'Users',
    'Number of questions',
    'Number of Formtory',

    'Question types',
    'Redirect on completion',
    'Redirect on Thank-you screen',
    'Report',
    'Multiple Thank-you screen',
    'Calculator',
    'Mention',
    'Logic Jump',
    'Receive file upload',
    'Hidden Fields',
    'Translation',
    'Track IP address',
    'NPS',
    'Accept Payment',

    '12 available designs',
    'Customize',
    'Template',
    'Hide Formtory branding',
    'Custom closed message',

    'Share',
    'Advance embed options',

    'Google Sheets connect',
    'Facebook Pixel connect',
    'Google Analytics connect',
    'Mailchimp connect',
    'Hubspot connect',
    'Slack connect',
    'Google Drive connect',
    'Microsoft Teams connect',
    'Salesforce connect',
    'Zoho CRM connect',
    'Trello connect',
    'Intercom connect',

    'Self email Notification',
    'Respondent email notification',
    'Schedule',
    'Set response limit',

    'Google tag Management',
    'Drop-off rates',

    'Team permission',
    'Invite team members',
    'Commenting',

    'Custom link',
    'Custom subdomain',
    'Custom meta description',
    'Webhooks',

    'Live Chat',
    'Email support',
    'Booking support',
  ],
  ENTERPRISE_VN: [
    'Phản hồi mỗi tháng',
    'Số thành viên',
    'Số câu hỏi',
    'Số biểu mẫu',

    'Dạng câu hỏi',
    'Chuyển hướng khi hoàn thành',
    'Chuyển hướng tại Trang kết thúc',
    'Báo cáo',
    'Đa Trang kết thúc',
    'Công cụ tính',
    'Gọi phản hồi',
    'Nhảy câu thông minh',
    'Tải dữ liệu',
    'Trường ẩn',
    'Ngôn ngữ',
    'Theo dõi địa chỉ IP',
    'Khảo sát NPS',
    'Thanh toán nhanh',

    '12 thiết kế có sẵn',
    'Tuỳ biến',
    'Thư viện mẫu',
    'Ẩn logo Formtory',
    'Màn hình đóng tuỳ chỉnh',

    'Chia sẻ',
    'Chức năng nhúng nâng cao',

    'Kết nối Google Trang Tính',
    'Kết nối Facebook Pixel',
    'Kết nối Google Analytics',
    'Kết nối Mailchimp',
    'Kết nối Hubspot',
    'Kết nối Slack',
    'Kết nối Google Drive',
    'Kết nối Microsoft Teams',
    'Kết nối Salesforce',
    'Kết nối Zoho CRM',
    'Kết nối Trello',
    'Kết nối Intercom',

    'Thông báo email cho bạn',
    'Thông báo email cho người trả lời',
    'Hẹn lịch',
    'Đặt giới hạn phản hồi',

    'Google tag Management',
    'Đo tỷ lệ rớt',

    'Phân quyền thành viên',
    'Chức năng mời thành viên mới',
    'Thảo luận nhóm',

    'Tuỳ chỉnh đường dẫn',
    'Tuỳ chỉnh tên miền',
    'Tuỳ chỉnh thẻ meta',
    'Webhooks',

    'Hỗ Trợ trực tuyến',
    'Hỗ trợ email',
    'Hỗ trợ tận nơi',
  ],
};
