import { Me_me } from '@formtory/shared/graphql/types';

export const hasNoPlan = (user: Me_me) => {
  return !user.accessKey;
};

export const hasPendingPlan = (user: Me_me) => {
  return user.accessKey && !user.accessKey.active;
};

export const hasNoTeamPlan = (user: Me_me) => {
  const team = user.teams.find((item) => item._id === localStorage.getItem('selectedTeamId'));
  if (!team) return false;
  return !team.accessKey;
};

export const hasPendingTeamPlan = (user: Me_me) => {
  const team = user.teams.find((item) => item._id === localStorage.getItem('selectedTeamId'));
  if (!team) return false;
  return team.accessKey && !team.accessKey.active;
};
