import notification from 'antd/lib/notification';

notification.config({
  placement: 'bottomRight',
  bottom: 50,
  duration: 3,
});

class NotificationCenter {

  success(messages: Array<string> = []) {
    notification['success']({
      message: messages[0],
      description: '',
    });
  }

  warning(messages: Array<string> = []) {
    notification['warning']({
      message: messages[0],
      description: '',
    });
  }

  info(messages: Array<string>) {
    notification['info']({
      message: messages[0],
      description: '',
    });
  }

  error(messages: Array<string>) {
    notification['error']({
      message: messages[0],
      description: '',
    });
  }

  somethingWentWrong() {
    notification['warning']({
      message: 'Something went wrong!',
      description: '',
    });
  }

  successOnlyTitle(title: string) {
    notification['success']({
      message: title,
      description: '',
    });
  }

  warningOnlyTitle(title: string) {
    notification['warning']({
      message: title,
      description: '',
    });
  }
}

export default new NotificationCenter();
