import React from 'react';
import { graphql, ChildMutateProps } from 'react-apollo';
import { FormTypes } from '@formtory/shared/models/form';
import { SAVE_FORM_ITEM } from '@formtory/shared/graphql/form';
import {
  SaveFormItemMutation,
  SaveFormItemMutationVariables,
  GetWorkspaceFormItems_formItems,
} from '@formtory/shared/graphql/types';
import debounce from 'lodash/debounce';
import { StyledItemBaseContainer } from './Styled';

interface FormItemBaseProps {
  formType: FormTypes | string;
  formId: string;
  index: string;
  results?: any;
  resultsIndex?: any;
  formItem: GetWorkspaceFormItems_formItems;
  onSaved?: () => void;
  onResult?: any;
  onSaveActive?: any;
  active: boolean;
  submitted: boolean;
  previewing: boolean;
  prices: number;
  scores: number;
  priceCurrency: string;
  customizedLabels: string;
  isNextSubmit?: boolean;
  onSubmit?: () => void;
  submitting?: boolean;
  formLayout?: string;
  theme?: any;
  locale?: any;
  device?: string;
}

export type FormItemBaseChildProps = ChildMutateProps<
  FormItemBaseProps,
  SaveFormItemMutation,
  SaveFormItemMutationVariables
>;
export type FormItemBaseWrappedChildProps = ChildMutateProps<
  FormItemBaseProps,
  SaveFormItemMutation,
  SaveFormItemMutationVariables
> & { onSave: any };

export function withBaseFormItem(WrappedComponent: React.FunctionComponent<FormItemBaseChildProps & { onSave: any }>) {
  return class extends React.PureComponent<FormItemBaseChildProps, { marginTop: number }> {
    constructor(props: any) {
      super(props);
      this.state = {
        marginTop: 0,
      };
      this.onSave = debounce(this.onSave.bind(this), 1000);
    }

    onSave() {
      return null;
    }

    render() {
      return (
        <StyledItemBaseContainer className={'active'}>
          <WrappedComponent onSave={this.onSave} {...this.props} />
        </StyledItemBaseContainer>
      );
    }
  };
}

export const withSaveFormItem = graphql<
  FormItemBaseProps,
  SaveFormItemMutation,
  SaveFormItemMutationVariables,
  FormItemBaseChildProps
>(SAVE_FORM_ITEM);
