import React from 'react';
import { FormItemBaseWrappedChildProps } from './FormItemBase';
import Title from './Elements/Title';
import Description from './Elements/Description';
import QuestionLayout from './QuestionLayout';
import { StyledQuestionContainerNoMedia, StyledQuestionContainerContent } from './Styled';
import Video from './Elements/Video';
import Image from './Elements/Image';
import { FormItemSettingsLayout } from '../../graphql/types';

const Thanks = (props: FormItemBaseWrappedChildProps) => {
  const { formItem, prices, scores, priceCurrency } = props;
  const { settings } = formItem;
  return (
    <QuestionLayout
      videoUrl={settings.videoUrl}
      image={settings.image}
      layout={settings.layout}
      imageBrightness={settings.imageBrightness}
      device={props.device}
    >
      <StyledQuestionContainerNoMedia>
        <StyledQuestionContainerContent style={props.device !== 'mobile' ? { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' } : { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', minWidth: '90%' }}>
          {settings.videoUrl && <Video value={settings.videoUrl} />}
          {!settings.videoUrl &&
            settings.image &&
            (!settings.layout || settings.layout === FormItemSettingsLayout.NORMAL) && (
              <Image
                value={settings.image}
                containerProps={{
                  className: 'image--normal',
                  style: { filter: `brightness(${(settings.imageBrightness || 100) / 100})` },
                }}
              />
            )}
          <Title
            prices={prices}
            priceCurrency={priceCurrency}
            scores={scores}
            value={formItem.title}
            results={props.results}

            center={true}
          />
          {settings.descriptionEnabled && formItem.description && (
            <Description
              prices={prices}
              scores={scores}
              priceCurrency={priceCurrency}
              results={props.results}
              containerProps={{ style: { textAlign: 'center' } }}
              value={formItem.description}
            />
          )}
        </StyledQuestionContainerContent>
      </StyledQuestionContainerNoMedia>
    </QuestionLayout>
  );
};

export default Thanks;
