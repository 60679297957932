import io from 'socket.io-client';
import { Auth } from '../auth';

export class Socket {

  private eventListeners: Array<string>;
  private socket: any;

  constructor() {
    this.socket = null;
    this.eventListeners = [];
  }

  connect(callback: any) {
    let secure = false;
    if (typeof window !== 'undefined') {
      const auth = Auth.service.getAuth();
      let query = '';
      if (auth) {
        query += `authId=${auth._id}`;
      }
      if (process.env.NODE_ENV === 'production') {
        secure = true;
      }
      this.socket = io.connect(process.env.SERVER_API_URL, { secure, query, forceNew: true });
      this.socket.on('connect', callback);
      this.socket.on('reconnect', () => null);
    }
  }

  reconnect(callback: any) {
    this.eventListeners = [];
    this.socket.disconnect();
    this.connect(callback);
  }

  listen(event: string, callback: any) {
    if (!this.eventListeners.includes(event)) {
      this.socket.on(event, callback);
      this.eventListeners.push(event);
    }
  }

  removeListener(event: string) {
    this.eventListeners.splice(this.eventListeners.indexOf(event), 1);
    this.socket.removeListener(event);
  }
}

export default new Socket();
