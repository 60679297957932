import * as React from 'react';
import { IntlProvider } from 'react-intl';

interface LanguageProviderProps {
  messages?: Array<any> | any;
}

const locale = localStorage.getItem('locale') || 'vi';

export class LanguageProvider extends React.PureComponent<LanguageProviderProps> {
  render() {
    return (
      <IntlProvider locale={locale} key={locale} messages={this.props.messages[locale]}>
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}

export default LanguageProvider;
