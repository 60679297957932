import React from 'react';
import styled from 'styled-components';
import { GetForm } from '@formtory/shared/graphql/types';

interface BaseProps {
  form: GetForm['form'];
}

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .closed {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${(props) => props.theme.form.questions};
    font-size: 22px;
    img {
      max-width: 80%;
      margin: auto;
    }
    h1 {
      font-weight: 400;
      font-size: 22px;
      margin-top: 20px;
      margin-bottom: 0;
      color: ${(props) => props.theme.form.questions}
    }
    p {
      font-size: 16px;
      color: #777;
      color: ${(props) => props.theme.form.answers}
    }
    &.bordered {
      border: 1px solid #eee;
    }
  }
`;

const FormClosed = React.memo((props: BaseProps) => {
  return (
    <StyledContainer>
      {!props.form.closeFormCustomEnable && <div className="closed">This form is now closed.</div>}
      {props.form.closeFormCustomEnable && (
        <div className="closed">
          {props.form.closeFormImage && <img src={props.form.closeFormImage} alt="" />}
          <h1>{props.form.closeFormTitle}</h1>
          <p>{props.form.closeFormDescription}</p>
        </div>
      )}
    </StyledContainer>
  );
});

export default FormClosed;
