import React from 'react';
import { Steps } from 'antd';
import styled from 'styled-components';
import { AppContext } from '@formtory/shared/contexts/AppContext';

const StyledSteps = styled<any>(Steps)`
  // position: absolute;
  // top: 50px;
  width: 30vw;
  margin-left: 150px;
  // margin-top: 16px;
`;


const { Step } = Steps;

const AuthenticationStep = (props) => {
  const { intl: { formatMessage } } = React.useContext(AppContext);

  return (
    <StyledSteps current={props.current} size="small">
      <Step title={formatMessage({ id: 'email' })} />
      <Step title={formatMessage({ id: 'information' })} />
      <Step title={formatMessage({ id: 'PlanPricing' })} />
    </StyledSteps>
  );
}

export default AuthenticationStep;