import { GetWorkspaceForm } from '@formtory/shared/graphql/types';

export const isAppConnected = (connectedApps: GetWorkspaceForm['form']['connectedApps'], name: string) => {
  return connectedApps && !!connectedApps.find(app => app.connected && app.name === name);
};

export const getApp = (connectedApps: GetWorkspaceForm['form']['connectedApps'], name: string) => {
  try {
    const app = connectedApps.find(app => app.name === name);
    if (!app) return null;
    app.payload = JSON.parse(app.payload || JSON.stringify({}));
    return app;
  } catch (e) {
    return null;
  }
};
