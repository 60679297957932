import React, { Component } from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { PLANS } from '@formtory/shared/data/pricing';
import { Me_me, TeamBaseFields } from '@formtory/shared/graphql/types';

export interface AppContextInterface {
  auth: Me_me;
  currentPlan: string;
  limitResponse: boolean;
  authenticated: boolean;
  authLoaded: boolean;
  isSingleFormPage: boolean;
  isChoosePlanPage: boolean;
  displayUpgradeOverlay: boolean;
  displayUpgradeOverlayPlan: string;
  savedSingleFormChanges: boolean;
  locale: string;
  openCreateNewTeam: boolean;
  openTeamSettings: boolean;
  selectedTeam: Me_me['teams'][0];
  intl?: {
    formatMessage?: (...params: any) => any;
    formatRelative?: (...params: any) => any;
    formatNumber?: (...params: any) => any;
    formatPlural?: (...params: any) => any;
    formatDate?: (...params: any) => any;
    formatTime?: (...params: any) => any;
  };
  onUpdateContext: (...params: any) => void;
  onCheckAuthPlan: (...params: any) => any;
  onCheckCurrentPlan: (...params: any) => any;
  onCheckLimitResponse: (...params: any) => void;
  onTeamCreated: (team: TeamBaseFields) => void;
  onTeamUpdated: (team: TeamBaseFields) => void;
}

export const AppContext = React.createContext<AppContextInterface>({
  auth: null,
  currentPlan: null,
  limitResponse: false,
  authenticated: false,
  isSingleFormPage: false,
  isChoosePlanPage: false,
  displayUpgradeOverlay: false,
  savedSingleFormChanges: true,
  displayUpgradeOverlayPlan: PLANS.PRO,
  authLoaded: false,
  selectedTeam: null,
  openCreateNewTeam: false,
  openTeamSettings: false,
  locale: localStorage.locale,
  onUpdateContext: (context) => context,
  onCheckAuthPlan: (plan) => plan,
  onCheckCurrentPlan: (data) => data,
  onCheckLimitResponse: (context) => context,
  onTeamCreated: (team) => team,
  onTeamUpdated: (team) => team,
});

export class AppProvider extends Component<InjectedIntlProps, AppContextInterface> {
  onUpdateContext = (context: AppContextInterface) => {
    const newContext = { ...this.state, ...context };
    if (context.auth?.teams?.length > 0) {
      newContext.selectedTeam = this.getSelectedTeam(context);
    }
    this.setState(newContext);
  };

  onCheckAuthPlan = (plan: string, displayOverlay = true, context: AppContextInterface) => {
    return context.auth?.accessKey?.plan === plan && context.auth?.accessKey?.active ? plan : null
  };

  onCheckCurrentPlan = (context: AppContextInterface) => {
    if (this.getSelectedTeam(context)) {
      return context.selectedTeam?.accessKey?.plan && context.selectedTeam?.accessKey?.active ? context.selectedTeam?.accessKey?.plan : null
    }
    return context.auth?.accessKey?.plan && context.auth?.accessKey?.active ? context.auth?.accessKey?.plan : 'FREE'
  };

  onCheckLimitResponse = (context: AppContextInterface) => {
    const {
      selectedTeam,
      auth,
    } = context;
    let monthlyTotalResponses = 0;
    let monthlyTotalResponsesAvailable = selectedTeam ? parseInt(selectedTeam.accessKey?.responsesNeed) : parseInt(auth.accessKey?.responsesNeed) || 0;
    monthlyTotalResponses = this.getSelectedTeam(context) ? selectedTeam.monthlyTotalResponses : auth.monthlyTotalResponses;
    if (this.onCheckCurrentPlan(context) === PLANS.FREE) {
      monthlyTotalResponsesAvailable = Infinity;
    }
    (monthlyTotalResponses >= monthlyTotalResponsesAvailable && this.onCheckCurrentPlan(context) !== PLANS.FREE) && (this.setState({
      limitResponse: true
    }))
  };

  onTeamCreated = (team: TeamBaseFields) => {
    localStorage.setItem('selectedTeamId', team._id);
    this.setState({
      auth: { ...this.state.auth, teams: [...this.state.auth.teams, team] },
      selectedTeam: { ...team, accessKey: null },
    });
  };

  onTeamUpdated = (team: TeamBaseFields) => {
    this.setState({
      auth: {
        ...this.state.auth,
        teams: [...this.state.auth.teams.map((item) => (item._id === team._id ? { ...item, ...team } : item))],
      },
      selectedTeam: { ...this.state.selectedTeam, ...team },
    });
  };

  getSelectedTeam = (context: AppContextInterface) => {
    if (!localStorage.getItem('selectedTeamId')) return null;
    return context.auth?.teams?.length > 0
      ? context.auth?.teams.find((team) => team._id === localStorage.getItem('selectedTeamId'))
      : null;
  };

  state = {
    auth: null,
    authenticated: false,
    currentPlan: null,
    limitResponse: false,
    authLoaded: false,
    isSingleFormPage: false,
    openCreateNewTeam: false,
    isChoosePlanPage: false,
    openTeamSettings: false,
    displayUpgradeOverlayPlan: PLANS.PRO,
    displayUpgradeOverlay: false,
    savedSingleFormChanges: true,
    selectedTeam: null,
    locale: localStorage.locale,
    intl: {
      formatMessage: this.props.intl.formatMessage,
      formatRelative: this.props.intl.formatRelative,
      formatNumber: this.props.intl.formatNumber,
      formatPlural: this.props.intl.formatPlural,
      formatDate: this.props.intl.formatDate,
      formatTime: this.props.intl.formatTime,
    },
    onUpdateContext: this.onUpdateContext,
    onCheckAuthPlan: this.onCheckAuthPlan,
    onCheckCurrentPlan: this.onCheckCurrentPlan,
    onCheckLimitResponse: this.onCheckLimitResponse,
    onTeamCreated: this.onTeamCreated,
    onTeamUpdated: this.onTeamUpdated,
  };

  render() {
    return <AppContext.Provider value={this.state}>{this.props.children}</AppContext.Provider>;
  }
}

export default injectIntl<{ intl?: any }>(AppProvider);
