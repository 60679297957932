import gql from 'graphql-tag';
import { USER_BASE_FIELDS, TEAM_BASE_FIELDS, ACCESS_KEY_BASE_FIELDS } from './fragments';

export const SEND_OTP = gql`
  mutation SendOTPMutation($data: SendOTPInput!) {
    sendOTP(data: $data)
  }
`;

export const CONFIRM_OTP = gql`
  mutation ConfirmOTPMutation($data: ConfirmOTPInput!) {
    confirmOTP(data: $data) {
      accessToken
      user {
        ...UserBaseFields
        teams {
          ...TeamBaseFields
          accessKey {
            ...AccessKeyBaseFields
          }
        }
        accessKey {
          ...AccessKeyBaseFields
        }
      }
    }
  }
  ${USER_BASE_FIELDS}
  ${TEAM_BASE_FIELDS}
  ${ACCESS_KEY_BASE_FIELDS}
`;

export const CONFIRM_INFORMATION = gql`
  mutation ConfirmInformationMutation($data: ConfirmInformationInput!) {
    confirmInformation(data: $data) {
      ...UserBaseFields
      teams {
        ...TeamBaseFields
        accessKey {
          ...AccessKeyBaseFields
        }
      }
      accessKey {
        ...AccessKeyBaseFields
      }
    }
  }
  ${USER_BASE_FIELDS}
  ${TEAM_BASE_FIELDS}
  ${ACCESS_KEY_BASE_FIELDS}
`;

export const ME = gql`
  query Me {
    me {
      ...UserBaseFields
      teams {
        ...TeamBaseFields
        accessKey {
          ...AccessKeyBaseFields
        }
        monthlyTotalResponses
      }
      monthlyTotalResponses
      accessKey {
        ...AccessKeyBaseFields
      }
    }
  }
  ${USER_BASE_FIELDS}
  ${TEAM_BASE_FIELDS}
  ${ACCESS_KEY_BASE_FIELDS}
`;
