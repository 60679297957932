import { FormTypes } from '../models/form';
import convertFromRaw from 'draft-js/lib/convertFromRawToDraftState';
import EditorState from 'draft-js/lib/EditorState';

export const getTitlePlanText = title => {
  const rawValue = title ? convertFromRaw(JSON.parse(title)) : null;
  const editorState = rawValue ? EditorState.createWithContent(rawValue) : null;
  if (!editorState) return null;
  let titleAsPlainText = editorState.getCurrentContent().getPlainText();
  const mentions = titleAsPlainText.match(/\[(.*?)\]/g);
  if (mentions && mentions.length > 0) {
    mentions.forEach(mention => {
      return (titleAsPlainText = titleAsPlainText.replace(mention, '___'));
    });
  }
  return titleAsPlainText;
};

export const orderFormItems = (formItems: Array<any>, orders: Array<string>, listAll?: boolean) => {
  const orderedFormItems = [];
  const hiddenFields = [];
  const welcomeScreens = [];
  const thankScreens = [];
  orders.forEach(formItemId => {
    const formItem = formItems.find(item => item._id.toString() === formItemId.toString());
    if (!formItem) return formItemId;
    if (formItem.formType === FormTypes[FormTypes.WELCOME]) return welcomeScreens.push(formItem);
    if (formItem.formType === FormTypes[FormTypes.THANK_YOU]) return thankScreens.push(formItem);
    if (formItem.formType === FormTypes[FormTypes.STATEMENT] && !listAll) return formItemId;
    if (formItem.formType === 'HIDDEN_FIELDS') {
      return hiddenFields.push(formItem);
    }
    orderedFormItems.push(formItem);
    return formItemId;
  });
  if (listAll) {
    return [...hiddenFields, ...welcomeScreens, ...orderedFormItems, ...thankScreens];
  }
  return [...hiddenFields, ...orderedFormItems];
};

export const convertResult = (result, formItem = null) => {
  const decodedResult = JSON.parse(result || JSON.stringify(''));
  if (formItem?.formType === FormTypes[FormTypes.RATING]) return `${decodedResult}/${formItem.settings.ratingSteps || 10}`;
  if (formItem?.formType === FormTypes[FormTypes.OPINION_SCALE])
    return `${decodedResult}/${formItem.settings.opinionScaleSteps || 10}`;
  if (typeof decodedResult === 'string') return decodedResult;
  if (typeof decodedResult === 'object' && decodedResult) {
    if (typeof decodedResult[0] === 'string' && decodedResult[0]) return decodedResult.join(', ');
    if (typeof decodedResult[0] === 'object' && decodedResult[0])
      return decodedResult.map(item => item.label).join(', ');
    const result = Object.keys(decodedResult)
      .map(key => decodedResult[key])
      .join('/');
    if (result.length > 300) {
      return `${result.slice(0, 300)}...`;
    }
    return result;
  }
  return 'No response';
};

export const hasWelcomeScreen = (orderedItems: any) => {
  return orderedItems.find(item => item.formType === FormTypes[FormTypes.WELCOME]);
};
