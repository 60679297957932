import React, { useState, useEffect, useRef } from 'react';
import { Button, List, Icon, Input } from 'antd';
import styled from 'styled-components';
import sampleSize from 'lodash/sampleSize';
import { AlphabetCharacters } from '@formtory/shared/data/default';
import EventHub from '@formtory/shared/core/events/hub';
import Title from './Elements/Title';
import Description from './Elements/Description';
import ButtonGroup from './Elements/ButtonGroup';
import { FormItemBaseWrappedChildProps } from './FormItemBase';
import {
  StyledQuestionContainerNoMedia,
  StyledErrorMessage,
  StyledQuestionContainerContent,
  StyledAlphabetCharacters,
  StyledOptionContent,
  StyledOptionCheck,
} from './Styled';
import { getSetting } from './Utils';
import QuestionLayout from './QuestionLayout';
import Video from './Elements/Video';
import { FormItemSettingsLayout } from '../../graphql/types';
import Image from './Elements/Image';
import Index from './Elements/Index';
import Submit from './Submit';
import { isMobile } from '@formtory/app/src/utils/helperFunctions';

const StyledListWrap = styled.div`
  .vertical > .ant-spin-nested-loading > .ant-spin-container > .ant-row {
    display: inline-flex;
    margin: 0px 0px -8px;
    /* list-style: none; */
    padding: 0px;
    flex-flow: column wrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    max-width: 100%;
    min-width: 168px;
  }
`;

const StyledList = styled<any>(List)`
  text-align: left;
`;

const StyledOption = styled<any>(Button)`
  background: ${(props) => props.theme.form.answers}19;
  border: 1px solid ${(props) => props.theme.form.answers};
  color: ${(props) => props.theme.form.answers};
  text-align: left;
  font-size: ${isMobile ? '16px' : '20px'};
  white-space: normal;
  height: auto;
  width: 100%;
  min-width: 250px;
  max-width: 100%;
  padding: 4px;
  display: flex;
  // justify-content: space-between;
  align-items: flex-start;
  i {
    font-size: 18px;
  }
  // font-weight: 600;
  &:hover,
  &:focus {
    opacity: 1;
    background: ${(props) => props.theme.form.answers}55;
    border: 1px solid ${(props) => props.theme.form.answers};
    color: ${(props) => props.theme.form.answers};
  }
  span {
    color: ${(props) => props.theme.form.answers} !important;
  }
  i {
    color: ${(props) => props.theme.form.answers} !important;
  }
  &.active {
    border: 1px solid ${(props) => props.theme.form.answers};
    background: ${(props) => props.theme.form.answers}55;
    color: ${(props) => props.theme.form.answers};
  }
`;

// const StyledOtherInput = styled<any>(Input)`
// ::placeholder {
//   font-size: 20px;
//   font-weight: unset;
//   font-family: ${(props) => props.theme.form.fontFamily};
//   color: ${(props) => props.theme.form.answers}66;
//   opacity: 0.7;
// }
// `;

const getTextWidth: any = (text: string, font: string) => {
  // re-use canvas object for better performance
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
};

const MultipleChoice = (props: FormItemBaseWrappedChildProps) => {
  const otherInputFocus = useRef(null);
  const { formItem, index, customizedLabels, previewing, prices, scores, priceCurrency, isNextSubmit, onSubmit, submitting, formLayout, locale } = props;
  const { settings } = formItem;
  const containerWidth = document.getElementById(`ChoiceOptionContainers-${formItem._id}`)?.clientWidth || 1024;
  const [otherInputOpen, setOtherInputOpen] = useState(false);
  const [otherInput, setOtherInput] = useState('');
  const [results, setResults] = useState(props.results[formItem._id] || []);
  const [resultsIndex, setResultsIndex] = useState(props.resultsIndex[formItem._id] || []);
  const [hasError, setHasError] = useState(false);
  const [verticalAlign, setVerticalAlign] = useState(null);
  const [choices, setChoices] = useState(
    settings.randomizeEnabled ? sampleSize(settings.choices, settings.choices.length) : settings.choices,
  );
  const resetChoices = (e) => {
    if (!previewing) return;
    const settings = e.detail.settings;
    setChoices(settings.randomizeEnabled ? sampleSize(settings.choices, settings.choices.length) : settings.choices);
  };

  useEffect(() => {
    if (otherInputFocus.current) {
      otherInputFocus.current.focus();
    }
  }, [otherInputOpen]);

  useEffect(() => {
    setChoices(settings.randomizeEnabled ? sampleSize(settings.choices, settings.choices.length) : settings.choices);
  }, [locale])

  useEffect(() => {
    if (!previewing) return;
    setChoices(settings.randomizeEnabled ? sampleSize(settings.choices, settings.choices.length) : settings.choices);
  }, [settings.choices])

  useEffect(() => {
    EventHub.addEventListener(`SAVED_SETTING:${formItem._id}`, resetChoices);
    return () => {
      EventHub.removeEventListener(`SAVED_SETTING:${formItem._id}`, resetChoices);
    };
  }, []);

  useEffect(() => {
    setChoices([
      ...(settings.randomizeEnabled ? sampleSize(settings.choices, settings.choices.length) : settings.choices),
    ]);
  }, [formItem._id]);

  useEffect(() => {
    choices.map((choice) => {
      if (!choice) return;
      if (getTextWidth(choice, 'bold 16px arial') > document.getElementById(encodeURI(choice)).clientWidth - 80) {
        setVerticalAlign('1');
      }
    });
  }, [choices]);

  useEffect(() => {
    if (props.submitted && settings.required && results.length === 0) return setHasError(true);
  });

  const onSelectChoice = (choice) => {
    setHasError(false);
    const choiceIndex = settings.choices.findIndex((c) => c === choice)
    if (choiceIndex === -1) {
      if (!settings.multipleSelectionEnabled) {
        setResults([choice]);
        return setResultsIndex([choiceIndex]);
      }
    }
    if (!settings.multipleSelectionEnabled) {
      setResults([choice]);
      setResultsIndex([choiceIndex]);
      return setTimeout(() => {
        onSaveResults([...[choice]], [...[choiceIndex]]);
      }, 200);
    }
    const index = results.findIndex((result) => result === choice);
    if (index > -1) {
      results.splice(index, 1);
      resultsIndex.splice(index, 1);
      if (formLayout === 'classic') {
        setResults([...results])
        setResultsIndex([...resultsIndex]);
        return setTimeout(() => {
          onSaveResults(results, resultsIndex);
        }, 200);
      }
      setResults([...results]);
      return setResultsIndex([...resultsIndex]);
    }
    if (
      settings.maxSelectionsEnabled &&
      settings.maxSelectionsValue &&
      settings.maxSelectionsValue > 0 &&
      settings.maxSelectionsValue - results.length === 1
    ) {
      setResults([...results, choice]);
      setResultsIndex([...resultsIndex, choiceIndex]);
      return setTimeout(() => {
        onSaveResults([...results, choice], [...resultsIndex, choiceIndex]);
      }, 200);
    }
    // return setResults([...results, choice]);
    setResults([...results, choice]);
    setResultsIndex([...resultsIndex, choiceIndex]);
    return;
    // return setTimeout(() => {
    //   onSaveResults([...results, choice], [...resultsIndex, choiceIndex]);
    // }, 200);
  };

  const onSaveResults = (results, resultsIndex) => {
    if (settings.required && results.length === 0) return setHasError(true);
    return props.onResult(formItem._id, [...results], [...resultsIndex]);
  };
  return (
    <QuestionLayout
      videoUrl={settings.videoUrl}
      image={settings.image}
      layout={settings.layout}
      hideImage
      imageBrightness={settings.imageBrightness}
      formLayout={formLayout}
      device={props.device}
    >
      <StyledQuestionContainerNoMedia style={props.device === 'mobile' ? { justifyContent: 'center' } : {}}>
        <Index renderIndex={index} required={settings.required} />
        <StyledQuestionContainerContent style={props.device === 'mobile' ? { minWidth: '90%' } : {}}>
          <Title
            value={formItem.title}
            prices={prices}
            priceCurrency={priceCurrency}
            scores={scores}
            results={props.results}
            containerProps={{
              style: { textAlign: 'left', marginBottom: settings.descriptionEnabled && formItem.description ? 0 : 22 },
            }}

          />
          {settings.descriptionEnabled && formItem.description && (
            <Description
              prices={prices}
              scores={scores}
              priceCurrency={priceCurrency}
              results={props.results}
              value={formItem.description}
            />
          )}
          {settings.videoUrl && <Video value={settings.videoUrl} />}
          {!settings.videoUrl &&
            settings.image &&
            (!settings.layout || settings.layout === FormItemSettingsLayout.NORMAL) && (
              <Image
                value={settings.image}
                containerProps={{
                  className: 'image--normal',
                  style: { filter: `brightness(${(settings.imageBrightness || 100) / 100})` },
                }}
              />
            )}
          <StyledListWrap id={`ChoiceOptionContainers-${formItem._id}`} style={{ width: '100%' }}>
            <StyledList
              grid={{
                column: containerWidth <= 480 ? 1 : verticalAlign || settings.verticalAlignEnabled || props.device === 'mobile' ? 1 : 2,
                gutter: 10,
              }}
              className={verticalAlign || settings.verticalAlignEnabled ? 'vertical' : ''}
              dataSource={choices}
              renderItem={(item, index) => {
                if (index === choices.length - 1 && settings.otherOptionEnabled) {
                  return (
                    <React.Fragment>
                      <List.Item id={encodeURI(item)} style={{ width: '100%', marginBottom: '10px' }}>
                        <StyledOption
                          htmlType={'button'}
                          className={results.includes(item) ? 'active' : ''}
                          block={!settings.verticalAlignEnabled}
                          onClick={() => onSelectChoice(item)}
                        >
                          <StyledAlphabetCharacters className={results.includes(item) ? 'active' : ''}>
                            {AlphabetCharacters[index]}
                          </StyledAlphabetCharacters>
                          <StyledOptionContent>{item}</StyledOptionContent>
                          <StyledOptionCheck>{results.includes(item) && <Icon type={'check'} />}</StyledOptionCheck>
                        </StyledOption>
                      </List.Item>
                      <List.Item style={{ width: '100%', marginBottom: '10px' }}>
                        <StyledOption
                          htmlType={'button'}
                          block={!settings.verticalAlignEnabled}
                          className={
                            resultsIndex.includes(-1)
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            if (!otherInputOpen) {
                              if (!resultsIndex.includes(-1)) {
                                otherInputFocus.current?.focus({ cursor: 'start' });
                                setOtherInputOpen(true);
                              } else {
                                onSelectChoice(otherInput || getSetting(customizedLabels, 'LABEL_FOR_OTHER_ANSWER_OPTION', 'Other'))
                              }
                            }
                          }
                            // !resultsIndex.includes(-1) && onSelectChoice(getSetting(customizedLabels, 'LABEL_FOR_OTHER_ANSWER_OPTION', 'Other'))
                          }
                        >
                          <StyledAlphabetCharacters
                            className={
                              resultsIndex.includes(-1)
                                ? 'active'
                                : ''
                            }
                          >
                            {AlphabetCharacters[choices.length]}
                          </StyledAlphabetCharacters>
                          <StyledOptionContent>
                            {otherInputOpen
                              ?
                              <>
                                <Input
                                  ref={otherInputFocus}
                                  onChange={(e) => setOtherInput(e.target.value)}
                                  value={otherInput}
                                  style={{ width: 'calc(100% - 40px)', height: '28px', background: 'transparent', fontSize: '20px', color: '#643ced', border: 'none', boxShadow: 'none', padding: 0 }}
                                  placeholder={getSetting(customizedLabels, 'HINT_FOR_ADDING_TEXT', 'Please enter your response')}
                                />
                                <Button
                                  onClick={() => {
                                    setOtherInputOpen(false)
                                    onSelectChoice(otherInput || getSetting(customizedLabels, 'LABEL_FOR_OTHER_ANSWER_OPTION', 'Other'))
                                  }}
                                  style={{ width: '28px', height: '28px', padding: 0, borderRadius: '2px' }}
                                >
                                  <Icon type={'check'} />
                                </Button>
                              </>
                              :
                              `${otherInput && getSetting(customizedLabels, 'LABEL_FOR_OTHER_ANSWER_OPTION', 'Other')}${otherInput && ': '}${otherInput || getSetting(customizedLabels, 'LABEL_FOR_OTHER_ANSWER_OPTION', 'Other')}`
                            }
                          </StyledOptionContent>
                          {!otherInputOpen &&
                            <StyledOptionCheck>
                              {resultsIndex.includes(-1) && <Icon type={'check'} />}
                            </StyledOptionCheck>
                          }
                        </StyledOption>
                      </List.Item>
                    </React.Fragment>
                  );
                }
                return (
                  <List.Item id={encodeURI(item)} style={{ width: '100%', marginBottom: '10px' }}>
                    <StyledOption
                      htmlType={'button'}
                      className={results.includes(item) ? 'active' : ''}
                      block={!settings.verticalAlignEnabled}
                      onClick={() => onSelectChoice(item)}
                    >
                      <StyledAlphabetCharacters className={results.includes(item) ? 'active' : ''}>
                        {AlphabetCharacters[index]}
                      </StyledAlphabetCharacters>
                      {/* <div style={{ paddingRight: 60, flexWrap: 'wrap',  }}>{item}</div> */}
                      <StyledOptionContent>{item}</StyledOptionContent>
                      <StyledOptionCheck>{results.includes(item) && <Icon type={'check'} />}</StyledOptionCheck>
                    </StyledOption>
                  </List.Item>
                );
              }}
            />
          </StyledListWrap>
          <div className="error">
            {hasError && (
              <StyledErrorMessage>
                {getSetting(customizedLabels, 'ERROR_IF_AN_ANSWER_REQUIRES_A_SELECTION', 'Oops, Please make a selection')}
              </StyledErrorMessage>
            )}
          </div>
          {formLayout !== 'classic' &&
            <div className="buttons">
              {props.active && !settings.multipleSelectionEnabled && results.length > 0 && !isNextSubmit && resultsIndex.includes(-1) && (
                <ButtonGroup
                  onClick={() => onSaveResults(results, resultsIndex)}
                  label={getSetting(customizedLabels, 'CONFIRM_ANSWER_BUTTON_TEXT', 'OK')}
                />
              )}
              {props.active && settings.multipleSelectionEnabled && results.length > 0 && !isNextSubmit && (
                <ButtonGroup
                  onClick={() => onSaveResults(results, resultsIndex)}
                  label={getSetting(customizedLabels, 'CONFIRM_ANSWER_BUTTON_TEXT', 'OK')}
                />
              )}
              {isNextSubmit && <Submit customizedLabels={customizedLabels} onSubmit={onSubmit} submitting={submitting} activeItemId={isNextSubmit && 'submit'} />}
            </div>
          }
        </StyledQuestionContainerContent>
      </StyledQuestionContainerNoMedia>
    </QuestionLayout>
  );
};

export default MultipleChoice;
