import * as React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Tabs } from 'antd';
import { withApollo, WithApolloClient } from 'react-apollo';
import UrlPattern from 'url-pattern';
import { RouterHistoryService, EventHub } from '@formtory/shared/core';
import { GET_WORKSPACE_FORM } from '@formtory/shared/graphql/form';
import BaseComponentWithContext from '@formtory/shared/components/BaseComponentWithContext';
import HeaderMember from './HeaderMember';
// import HeaderTeamSelector from './HeaderTeamSelector';
import {
  StyledSingleFormHeaderTab,
  StyledHeader,
  StyledBreadcrumb,
  StyledHeaderIconLoading,
  StyledHeaderLeft,
  StyledHeaderRight,
  StyledBrand,
} from './StyledHeader';
import { isMobile } from '../../utils/helperFunctions';
import Logo from '../../assets/images/formtory-logo.svg';
import SingleFormHeaderActions from './SingleFormHeaderActions';

const Brand = React.memo(() => (
  <StyledBrand to={'/'}>
    <img src={Logo} width={'30px'} alt="Formtory" />
  </StyledBrand>
));

function getLocationPathname() {
  if (process.env.NODE_ENV === 'production') return window.location.pathname;
  return window.location.pathname;
}

const SingleFormBreadcrumb = ({ currentForm }) => (
  <StyledBreadcrumb>
    <ul>
      <li>
        <Link to={`/workspace/${currentForm.workspace._id}`}>{currentForm.workspace.name}</Link>
      </li>
      <li>/</li>
      <li>
        <Link to={`/form/${currentForm._id}`}>{currentForm.name}</Link>
      </li>
    </ul>
  </StyledBreadcrumb>
);

const SingleFormHeaderTab = ({ activePage, onChangeMenu, formatMessage }) => (
  <StyledSingleFormHeaderTab>
    <Tabs tabBarGutter={0} defaultActiveKey={activePage} onChange={onChangeMenu}>
      <Tabs.TabPane tab={formatMessage({ id: 'create' })} key="create" />
      <Tabs.TabPane tab={formatMessage({ id: 'share' })} key="share" />
      <Tabs.TabPane tab={formatMessage({ id: 'connects' })} key="connects" />
      <Tabs.TabPane tab={formatMessage({ id: 'results' })} key="results" />
    </Tabs>
  </StyledSingleFormHeaderTab>
);

// const ChoosePlanHeaderTab = ({ activePage, onChangeMenu }) => (
//   <StyledSingleFormHeaderTab>
//     <Tabs tabBarGutter={0} defaultActiveKey={activePage} onChange={onChangeMenu}>
//       <Tabs.TabPane tab={'Personal'} key="personal" />
//       <Tabs.TabPane tab={'Team'} key="team" />
//     </Tabs>
//   </StyledSingleFormHeaderTab>
// );

const HeaderRightLoading = React.memo(() => (
  <React.Fragment>
    <StyledHeaderIconLoading />
    <StyledHeaderIconLoading />
    <StyledHeaderIconLoading style={{ marginRight: 15 }} />
  </React.Fragment>
));

interface HeaderStates {
  openAuthentication: boolean;
}

export class Header extends BaseComponentWithContext<WithApolloClient<any> & RouteComponentProps, HeaderStates> {
  componentDidMount() {
    EventHub.addEventListener('UPDATE_PAGE_HEADER', () => {
      this.forceUpdate();
    });
  }

  onChangeMenu = (path: string) => {
    let basePath = getLocationPathname().split('?')[0].split('#')[0];
    const page = basePath.split('/')[basePath.split('/').length - 1];
    basePath = basePath.replace(page, path);
    return RouterHistoryService.push(basePath);
  };

  render() {
    const { authenticated, authLoaded, isSingleFormPage, savedSingleFormChanges, intl: { formatMessage } } = this.context;
    const singleDisplayFormPattern = new UrlPattern('/f(/:id)');
    const singlePreviewFormPattern = new UrlPattern('/preview/form(/:id)');
    const singleFormPattern = new UrlPattern('/form(/:id)(/:page)');
    // const planPagePattern = new UrlPattern('/plan(/:type)');
    const { id, page } = singleFormPattern.match(getLocationPathname()) || ({} as any);
    // const { type } = planPagePattern.match(getLocationPathname()) || ({} as any);
    let currentForm = null;
    if (isSingleFormPage) {
      try {
        const data = this.props.client.readQuery({ query: GET_WORKSPACE_FORM, variables: { formId: id } });
        if (data) currentForm = data.form;
      } catch (e) {
        console.log(e.message);
      }
    }
    return (
      <>
        <StyledHeader
          id={'Header'}
          style={
            singleDisplayFormPattern.match(getLocationPathname()) || singlePreviewFormPattern.match(getLocationPathname())
              ? { display: 'none' }
              : {}
          }
        >
          <StyledHeaderLeft>
            <Brand />
            {isSingleFormPage && currentForm && !isMobile() && <SingleFormBreadcrumb currentForm={currentForm} />}
          </StyledHeaderLeft>
          {isSingleFormPage && !isMobile() && <SingleFormHeaderTab activePage={page} onChangeMenu={this.onChangeMenu} formatMessage={formatMessage} />}
          {/* {isChoosePlanPage && <ChoosePlanHeaderTab activePage={type} onChangeMenu={this.onChangeMenu} />} */}
          <StyledHeaderRight>
            {isSingleFormPage && (
              <SingleFormHeaderActions
                currentFormId={id}
                currentForm={currentForm}
                savedSingleFormChanges={savedSingleFormChanges}
              />
            )}
            {!authLoaded && <HeaderRightLoading />}
            {authLoaded && <React.Fragment>{authenticated && <HeaderMember />}</React.Fragment>}
            {/* {authLoaded && <HeaderTeamSelector />} */}
          </StyledHeaderRight>
        </StyledHeader>
        {isSingleFormPage && isMobile() && <SingleFormHeaderTab activePage={page} onChangeMenu={this.onChangeMenu} formatMessage={formatMessage} />}
      </>
    );
  }
}

export default withRouter(withApollo(Header));
