import styled from 'styled-components';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { isMobile } from 'utils/helperFunctions';

export const StyledHeaderMember = styled.a`
  margin-right: 8px;
  margin-left: 8px;
`;

export const StyledCurrentPlanButton = styled<any>(Button)`
  height: 38px;
  width: 38px;
  border-radius: 50%;
  display: flex !important;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0 16px;
  margin: 0 8px;
  font-weight: 500;
  background: ${(props: any) => props.color || '#EFF2F5'};
  color: ${(props: any) => (props.color ? '#fff' : props.theme.colors.primary)};
  border-color: ${(props: any) => props.color || '#eee'};
  &:hover,
  &:focus,
  &:active {
    background: ${(props: any) => props.color || '#fff'};
    color: ${(props: any) => (props.color ? '#fff' : props.theme.colors.primary)};
    border-color: ${(props: any) => props.color || '#eee'};
    opacity: 0.8;
  }
  i {
    color: ${(props: any) => (props.color ? '#fff' : props.theme.colors.primary)};
    font-size: 18px;
  }
`;

export const StyledCurrentLanguageButton = styled<any>(Button)`
  height: 38px;
  width: 38px;
  border-radius: 50%;
  display: flex !important;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0 16px;
  margin-right: 8px;
  background: ${(props: any) => props.color || '#EFF2F5'};
  color: ${(props: any) => (props.color ? '#fff' : 'rgb(115, 115, 115)')};
  border-color: ${(props: any) => props.color || '#eee'};
  &:hover,
  &:focus,
  &:active {
    background: ${(props: any) => props.color || '#fff'};
    color: ${(props: any) => (props.color ? '#fff' : props.theme.colors.blackGrey)};
    border-color: ${(props: any) => props.color || '#eee'};
    opacity: 0.8;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
  
`;

export const StyledNotifications = styled.div`
  width: 420px;
  height: 420px;
  background: #fff;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2),0 2px 4px 0 rgba(0, 0, 0, 0.1),inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  .header {
    padding: 16px 16px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      margin: 0;
    }
    a {
      color: ${(props: any) => props.theme.colors.primary};
    }
  }
  .notifications {
    height: 100%;
    padding-bottom: 54px;
    .notification-item {
      padding-left: 12px;
      padding-right: 12px;
      cursor: pointer;
      .ant-list-item-meta-description {
        color: ${(props: any) => props.theme.colors.blackGrey};
      }
      .ant-list-item-meta {
        margin-bottom: 0;
      }
      .ant-list-item-action {
        margin-top: 0;
      }
      b {
        font-weight: 500;
      }
      time {
        margin-left: 54px;
      }
    }
    .notification-item--unread {
      background: #f8f8f8;
    }
  }
`;

export const StyledSingleFormHeaderTab = styled.div`
  position: ${!isMobile() ? 'absolute' : 'unset'};
  box-shadow: ${!isMobile() ? '' : 'rgb(0 0 0 / 7%) 0px -1px inset'};

  width: 100%;
  text-align: center;
  .ant-tabs-bar {
    border: none;
    margin-bottom: 1px;
    .ant-tabs-nav {
      background: #f1f1f1;
      border-radius: ${!isMobile() ? "8px" : "6px"};
      margin: ${!isMobile() ? '0px!important' : '6px 2px!important'};
    }
    .ant-tabs-tab {
      margin: ${!isMobile() ? '4px 4px!important' : '2px 4px!important'};
      // margin-right: ${!isMobile() ? '16px' : '0'};
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      color: ${(props) => props.theme.colors.grey};
      padding: ${!isMobile() ? '4px 18px!important' : '4px 12px!important'};
    }
    .ant-tabs-tab-active {
      // color: ${(props) => props.theme.colors.primary}
      background: #fff;
      border-radius: ${!isMobile() ? "6px" : "4px"};
      color: #2F2F2F;
      box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px;
    }
    .ant-tabs-ink-bar {
      height: 0;
    }
  }
`;

export const StyledHeader = styled.header`
  width: 100%;
  height: 70px;
  z-index: 100;
  display: flex;
  top: 0;
  box-shadow: rgb(0 0 0 / 7%) 0px -1px inset;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  background-color: #fff;
`;

export const StyledHeaderLeft = styled.div`
  height: 69px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  z-index: 100;
  box-shadow: rgb(0 0 0 / 7%) 0px -1px inset;
`;

export const StyledHeaderRight = styled.div`
  height: 69px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  z-index: 100;
  box-shadow: rgb(0 0 0 / 7%) 0px -1px inset;
`;

export const StyledHeaderIconLoading = styled.div`
  width: 40px;
  height: 40px;
  background-color: #f1f1f1;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 50%;
`;

export const StyledBreadcrumb = styled.div`
  padding: 10px;
  border-left: 1px solid ${(props) => props.theme.colors.lightGrey};
  ul {
    padding: 0;
    margin: 0;
    li {
      margin-right: 6px;
      display: inline-block;
      list-style: none;
      max-width: 200px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      @media (max-width: 768px) {
        max-width: 100px;
      }
      a {
        color: ${(props) => props.theme.colors.blackGrey};
      }
      &:first-child {
        a {
          color: ${(props) => props.theme.colors.grey};
          font-weight: 400;
        }
      }
    }
  }
`;

export const StyledSingleFormHeaderActions = styled.div`
  height: 42px;
  padding-top: 3px;
  border-right: 1px solid ${(props) => props.theme.colors.lightGrey};
  ul {
    padding: 0;
    margin: 0;
    li {
      margin-right: 8px;
      display: inline-block;
      list-style: none;
      a {
        color: ${(props) => props.theme.colors.grey};
        text-decoration: underline;
      }
      button {
        height: 36px;
        padding: ${!isMobile() ? '0 20px' : '0 10px'};
        margin: 0;
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        border-radius: 8px;
      }
    }
  }
`;

export const StyledBrand = styled(Link)`
  background-color: #fff;
  color: #fff;
  cursor: pointer;
  padding: 16px;
`;
