import * as React from 'react';
import { GetWorkspaceForms, GetWorkspaceForms_forms, GetWorkspaceFormsVariables } from '@formtory/shared/graphql/types';
import { ChildDataProps, graphql } from 'react-apollo';
import { GET_WORKSPACE_FORMS } from '@formtory/shared/graphql/form';
import { Card, Icon, List } from 'antd';
import styled from 'styled-components';
import FormMainType from '../FormTypes/FormMainType';
import { useDeleteFormMutation, useDuplicateFormMutation } from './Hooks';
import { AppContext } from '@formtory/shared/contexts/AppContext';


const StyledListForm = styled(List)`
  margin-top: 24px;
`;

const StyledCreateCard = styled(Card)`
  border: none;
  border-radius: 15px;
  // background: rgba(0, 0, 0, 0.05);
  -webkit-animation: glowing 1300ms infinite;
    -moz-animation: glowing 1300ms infinite;
    -o-animation: glowing 1300ms infinite;
    animation: glowing 1300ms infinite;
    @-webkit-keyframes glowing {
      0% {
        background-color: #714DEC;
        -webkit-box-shadow: 0 0 3px #714DEC;
      }
      50% {
        background-color: ${(props) => props.theme.colors.primary};
        -webkit-box-shadow: 0 0 15px ${(props) => props.theme.colors.primary};
      }
      100% {
        background-color: #714DEC;
        -webkit-box-shadow: 0 0 3px #714DEC;
      }
    }
    @keyframes glowing {
      0% {
        background-color: #714DEC;
        box-shadow: 0 0 3px #714DEC;
      }
      50% {
        background-color: ${(props) => props.theme.colors.primary};
        box-shadow: 0 0 15px ${(props) => props.theme.colors.primary};
      }
      100% {
        background-color: #714DEC;
        box-shadow: 0 0 3px #714DEC;
      }
    }
    i, p {
      color: #fff;
    }
  cursor: pointer;
  .ant-card-body {
    border-radius: 10px;
    height: 200px;
    border: none;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    i {
      font-size: 26px;
      margin-bottom: 10px;
    }
    p {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

interface OriginComponentProps {
  q?: string;
  onToggleAddWorkspaceForm: () => void;
  workspaceId: string;
  ableToAddForm: boolean;
}

function reducerForms(forms: Array<GetWorkspaceForms_forms>, q: string): Array<GetWorkspaceForms_forms> {
  if (!q) return forms;
  return forms.filter(form => form.name.indexOf(q) > -1);
}

type ChildProps = ChildDataProps<OriginComponentProps, GetWorkspaceForms, GetWorkspaceFormsVariables>;
const withWorkspaceQuery = graphql<OriginComponentProps, GetWorkspaceForms, GetWorkspaceFormsVariables, ChildProps>(
  GET_WORKSPACE_FORMS,
  {
    options: props => ({
      variables: {
        workspaceId: props.workspaceId,
      },
      fetchPolicy: 'network-only',
    }),
  },
);

export default withWorkspaceQuery(({ q, workspaceId, onToggleAddWorkspaceForm, data: { loading, error, forms } }) => {
  const context = React.useContext(AppContext);
  const {
    intl: { formatMessage },
  } = context;
  const deleteFormMutation = useDeleteFormMutation(workspaceId);
  const duplicateFormMutation = useDuplicateFormMutation(workspaceId);
  const onDelete = async form => {
    if (window.confirm('Are you sure?')) {
      await deleteFormMutation({
        variables: { formId: form._id },
      });
    }
  };
  const onDuplicate = async form => {
    await duplicateFormMutation({
      variables: { formId: form._id, workspaceId: workspaceId },
    });
    window.location.href = '/';
  };
  return (
    <React.Fragment>
      {!loading && !error && forms.length > 0 && context.onCheckCurrentPlan(context) && (
        <StyledListForm
          grid={{
            gutter: 16,
            xs: 2,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 8
          }}
          dataSource={reducerForms(forms, q)}
          renderItem={(item, index) => {
            if (index === 0) {
              return (
                <React.Fragment>
                  <List.Item>
                    <StyledCreateCard onClick={onToggleAddWorkspaceForm}>
                      <Icon type={'plus'} />
                      <p>{formatMessage({ id: 'newForm' })}</p>
                    </StyledCreateCard>
                  </List.Item>
                  <List.Item>
                    <FormMainType onDelete={() => onDelete(item)} onDuplicate={() => onDuplicate(item)} form={item} />
                  </List.Item>
                </React.Fragment>
              );
            }
            return (
              <List.Item>
                <FormMainType onDelete={() => onDelete(item)} onDuplicate={() => onDuplicate(item)} form={item} />
              </List.Item>
            );
          }}
        />
      )}
      {!loading && !error && forms.length === 0 && context.onCheckCurrentPlan(context) && (
        <StyledListForm
          grid={{
            gutter: 16,
            xs: 2,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 8
          }}
          dataSource={[1]}
          renderItem={() => (
            <List.Item>
              <StyledCreateCard onClick={onToggleAddWorkspaceForm}>
                <Icon type={'plus'} />
                <p>{formatMessage({ id: 'newForm' })}</p>
              </StyledCreateCard>
            </List.Item>
          )}
        />
      )}
      {!context.onCheckCurrentPlan(context) && (
        <h1>{formatMessage({ id: 'needAPlan' })}</h1>
      )}
    </React.Fragment>
  );
});
