import React from 'react';
import { FormItemBaseWrappedChildProps } from './FormItemBase';
import ButtonGroup from './Elements/ButtonGroup';
import Title from './Elements/Title';
import Description from './Elements/Description';
import { getSetting } from './Utils';
import QuestionLayout from './QuestionLayout';
import { StyledQuestionContainerNoMedia, StyledQuestionContainerContent } from './Styled';
import Video from './Elements/Video';
import Image from './Elements/Image';
import { FormItemSettingsLayout } from '../../graphql/types';

const Welcome = (props: FormItemBaseWrappedChildProps) => {
  const { formItem, customizedLabels, previewing, prices, scores, priceCurrency } = props;
  const { settings } = formItem;
  return (
    <QuestionLayout
      videoUrl={settings.videoUrl}
      image={settings.image}
      layout={settings.layout}
      imageBrightness={settings.imageBrightness}
      device={props.device}
    >
      <StyledQuestionContainerNoMedia>
        <StyledQuestionContainerContent style={props.device !== 'mobile' ? { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' } : { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', minWidth: '90%' }}>
          {settings.videoUrl && <Video value={settings.videoUrl} />}
          {!settings.videoUrl &&
            settings.image &&
            (!settings.layout || settings.layout === FormItemSettingsLayout.NORMAL) && (
              <Image
                value={settings.image}
                containerProps={{
                  className: 'image--normal',
                  style: { filter: `brightness(${(settings.imageBrightness || 100) / 100})` },
                }}
              />
            )}
          <Title
            prices={prices}
            priceCurrency={priceCurrency}
            scores={scores}
            value={formItem.title}

            center={true}
          />
          {settings.descriptionEnabled && formItem.description && (
            <Description
              prices={prices}
              scores={scores}
              priceCurrency={priceCurrency}
              value={formItem.description}
              containerProps={{ style: { textAlign: 'center' } }}
            />
          )}
          <div className="buttons">
            {props.active && (
              <ButtonGroup
                previewing={previewing}
                onClick={() => props.onResult(formItem._id, true)}
                label={
                  settings.buttonText ||
                  getSetting(customizedLabels, 'CONFIRM_ANSWER_BUTTON_TEXT', 'Start now') ||
                  'Start now'
                }
              />
            )}
          </div>
        </StyledQuestionContainerContent>
      </StyledQuestionContainerNoMedia>
    </QuestionLayout>
  );
};

export default Welcome;
