import { getLocale } from './storage';

const MESSAGES = {
  USERNAME_IS_ALREADY_EXISTED: {
    en: 'Your username is already existed!',
    vi: 'Tên tài khoản của bạn đã tồn tại!',
  },
  TEAM_DOMAIN_IS_ALREADY_EXISTED: {
    en: 'Your team domain is already existed!',
    vi: 'Domain của bạn đã tồn tại!',
  },
  SOMETHING_WENT_WRONG: {
    en: 'Something went wrong!',
    vi: 'Đã có lỗi xảy ra!',
  },
};

export const getErrorMessage = error => {
  if (!MESSAGES[error]) return MESSAGES['SOMETHING_WENT_WRONG'][getLocale()];
  return MESSAGES[error][getLocale()];
};
