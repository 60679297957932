import $ from 'jquery';
import AuthService from './index';
import { FACEBOOK_LOGIN_SERVICE } from '../../constants/auth.constants';

declare var FB: any;

class FacebookAuthService extends AuthService {

  constructor() {
    super();
  }

  /**
   * Initialize
   */
  init(callback?: any) {
    $.getScript(process.env.REACT_APP_FACEBOOK_SDK_URL, () => {
      FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: process.env.REACT_APP_FACEBOOK_SDK_VERSION,
      });
      if (callback) callback();
    });
  }

  /**
   * Get platform name
   */
  static getPlatformName() {
    return FACEBOOK_LOGIN_SERVICE;
  }

  async login(): Promise<{ status: boolean, data: any }> {
    return new Promise((resolve) => {
      FB.login((response) => {
        if (response.authResponse) {
          const token = response.authResponse.accessToken;
          return FB.api('/me?fields=id,first_name,last_name,picture.width(800).height(800),gender,email', (response) => {
            return resolve({ status: true, data: FacebookAuthService.convertUserData({ ...response, token }) });
          });
        }
        throw new Error('User canceled!');
      }, {
        scope: 'email',
        return_scopes: true,
      });
    });
  }

  static convertUserData(responseData: any) {
    const userData: any = {};
    userData.refId = responseData.id;
    userData.name = {
      first: responseData.first_name,
      last: responseData.last_name,
    };
    userData.email = responseData.email;
    userData.gender = (responseData.gender === 'male') ? 1 : 0;
    userData.accessToken = responseData.token;
    userData.avatar = `https://graph.facebook.com/${userData.refId}/picture?type=large`;
    userData.source = FacebookAuthService.getPlatformName();
    return userData;
  }
}

export default new FacebookAuthService();
