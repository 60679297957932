import React from 'react';
import styled from 'styled-components';
import { getYoutubeVideoId } from '@formtory/shared/utils/helpers';

const StyledWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  div {
    position: relative;
    padding-bottom: 55%;
    height: 0;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Video = ({ value, containerProps = {} }) => (
  <StyledWrapper {...containerProps}>
    <div>
      <iframe frameBorder="0" allowFullScreen={true} src={`https://www.youtube.com/embed/${getYoutubeVideoId(value)}`} />
    </div>
  </StyledWrapper>
);

export default Video;
