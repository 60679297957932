import React from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Row, Col, Checkbox, Empty, Dropdown, Menu, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useApolloClient } from 'react-apollo-hooks';
import Avatar from 'react-avatar';
import {
  CreateTeamMutation,
  CreateTeamMutationVariables,
  Me_me_teams,
  GetPublicUserByEmail,
  GetPublicUserByEmailVariables,
  TeamBaseFields,
  UpdateTeamMutation,
  UpdateTeamMutationVariables,
} from '@formtory/shared/graphql/types';
import { CREATE_TEAM, UPDATE_TEAM } from '@formtory/shared/graphql/team';
import { GET_PUBLIC_USER_BY_EMAIL } from '@formtory/shared/graphql/user';
import PhotoUploader from '@formtory/shared/components/Forms/PhotoUploader';
import { TeamPermissions, DefaultTeamPermissions } from '@formtory/shared/data/default';
import NotificationCenter from '@formtory/shared/core/services/notification';
import { AppContext } from '@formtory/shared/contexts/AppContext';
import { StyledForm } from 'components/Workspaces/AddNewForm';
import { FormattedMessage } from 'react-intl';

const StyledSpacing = styled.div`
  height: 16px;
`;

const StyledTeamMember = styled.div`
  margin-top: 16px;
  border-radius: 6px;
  .avatar {
    span {
      font-size: 16px;
    }
  }
  .info {
    background-color: #f8f8f8;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    justify-content: space-between;
    .action {
      min-width: 40px;
      min-height: 40px;
      width: 40px;
      height: 40px;
    }
    .user {
      display: flex;
      // margin-bottom: 16px;
      align-items: center;
      .avatar {
        span {
          font-size: 16px;
        }
      }
      .user-info {
        display: flex;
        flex-direction: column;
        padding: 5px 10px;
        align-items: flex-start;
        h3 {
          margin: 0;
        }
        span {
          // padding: 1px 10px;
          background-color: rgb(245, 249, 248);
          color: rgb(2, 80, 65);
          border-radius: 6px;
          font-weight: 500;
        }
      }
    }
  }
  .permissions {
    background-color: #f1f1f1;
    padding: 16px 24px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .ant-checkbox-group-item {
      margin-top: 4px;
    }
  }
`;
const CardActions = (onRemoveMember, memberId, setPermissionsOpen, permissionsOpen) => (
  <Menu>
    <Menu.Item key="0">
      <a href="#" onClick={() => setPermissionsOpen(!permissionsOpen)}><FormattedMessage id="permission" /></a>
    </Menu.Item>
    <Menu.Item key="1">
      <a href="#" onClick={() => onRemoveMember(memberId)} style={{ color: 'rgb(175, 4, 4)' }}><FormattedMessage id="remove" /></a>
    </Menu.Item>
  </Menu>
);

const TeamMember = ({ member, owner, onChangePermissions, onRemoveMember }) => {
  const [permissionsOpen, setPermissionsOpen] = React.useState(false)
  return (
    <StyledTeamMember>
      <div className="info">
        <div className={'user'}>
          <Avatar
            className="avatar"
            round={true}
            src={member.avatar}
            size={'40'}
            name={`${member.firstName} ${member.lastName}`}
          />
          <div className='user-info'>
            <h3>{`${member.firstName} ${member.lastName}`} {owner && '(Owner)'}</h3>
            <span>{member.email}</span>
          </div>
        </div>
        {owner
          ? <span>Owner</span>
          : (
            <Dropdown overlay={CardActions(onRemoveMember, member._id, setPermissionsOpen, permissionsOpen)} trigger={['click']} placement="bottomRight">
              <Icon type={'ellipsis'} style={{ fontSize: 22 }} />
            </Dropdown>
          )
        }
      </div>
      {permissionsOpen &&
        <div className="permissions">
          <Checkbox.Group
            options={TeamPermissions}
            defaultValue={member.permissions}
            onChange={values => onChangePermissions(member._id, values)}
          />
        </div>
      }
    </StyledTeamMember >
  );
};

interface FormProps extends FormComponentProps {
  initialValues?: Me_me_teams;
  onSubmitted?: (isCreated: boolean, team: TeamBaseFields) => void;
}

const TeamForm = (props: FormProps) => {
  const client = useApolloClient();
  const context = React.useContext(AppContext);
  const { intl: { formatMessage } } = context;
  const addMemberEmailInput = React.useRef<any>(null);
  const [submitting, setSubmitting] = React.useState(false);
  const [members, setMembers] = React.useState(
    props.initialValues?.members?.map(member => {
      const memberPermissions = props.initialValues.permissions.find(item => item.memberId === member._id);
      return {
        _id: member._id,
        firstName: member.firstName,
        lastName: member.lastName,
        email: member.email,
        permissions: Object.keys(memberPermissions).filter(
          key => typeof memberPermissions[key] === 'boolean' && !!memberPermissions[key],
        ),
      };
    }) || [
      {
        _id: context.auth._id,
        firstName: context.auth.firstName,
        lastName: context.auth.lastName,
        email: context.auth.email,
        permissions: ["canViewCreateTab"
          , "canViewShareTab"
          , "canViewConnectTab"
          , "canViewResultsTab"
          , "canEditCreateTab"
          , "canEditShareTab"
          , "canEditConnectTab"
          , "canEditResultsTab"
          , "canPublishForm"],
      }
    ],
  );
  const handleSubmit = async e => {
    e.preventDefault();
    const { validateFields } = props.form;
    validateFields(async (error, values) => {
      if (error) {
        console.log(error);
        return;
      }
      setSubmitting(true);
      let team;
      if (!props.initialValues) {
        team = await handlePostCreateTeam(values);
      } else {
        team = await handlePostUpdateTeam(values);
      }
      setSubmitting(false);
      if (team) {
        await props.onSubmitted(!props.initialValues, values);
      }
    });
  };

  const handlePostCreateTeam = async values => {
    const { data } = await client.mutate<CreateTeamMutation, CreateTeamMutationVariables>({
      mutation: CREATE_TEAM,
      variables: {
        data: {
          name: values.name,
          domain: values.domain,
          logo: values.logo,
          members: members.map(item => ({
            memberId: item._id,
            canViewCreateTab: item.permissions.includes('canViewCreateTab'),
            canViewShareTab: item.permissions.includes('canViewShareTab'),
            canViewConnectTab: item.permissions.includes('canViewConnectTab'),
            canViewResultsTab: item.permissions.includes('canViewResultsTab'),
            canEditCreateTab: item.permissions.includes('canEditCreateTab'),
            canEditShareTab: item.permissions.includes('canEditShareTab'),
            canEditConnectTab: item.permissions.includes('canEditConnectTab'),
            canEditResultsTab: item.permissions.includes('canEditResultsTab'),
            canPublishForm: item.permissions.includes('canPublishForm'),
          })),
        },
      },
    });
    if (data && data.createTeam) {
      context.onTeamCreated(data.createTeam);
    }
    return data.createTeam;
  };

  const handlePostUpdateTeam = async values => {
    const { data } = await client.mutate<UpdateTeamMutation, UpdateTeamMutationVariables>({
      mutation: UPDATE_TEAM,
      variables: {
        teamId: props.initialValues._id,
        data: {
          name: values.name,
          domain: values.domain,
          logo: values.logo,
          members: members.map(item => ({
            memberId: item._id,
            canViewCreateTab: item.permissions.includes('canViewCreateTab'),
            canViewShareTab: item.permissions.includes('canViewShareTab'),
            canViewConnectTab: item.permissions.includes('canViewConnectTab'),
            canViewResultsTab: item.permissions.includes('canViewResultsTab'),
            canEditCreateTab: item.permissions.includes('canEditCreateTab'),
            canEditShareTab: item.permissions.includes('canEditShareTab'),
            canEditConnectTab: item.permissions.includes('canEditConnectTab'),
            canEditResultsTab: item.permissions.includes('canEditResultsTab'),
            canPublishForm: item.permissions.includes('canPublishForm'),
          })),
        },
      },
    });
    context.onTeamUpdated(data.updateTeam);
    return data.updateTeam;
  };

  const handleSearchMemberByEmail = async () => {
    if (members.length >= parseInt(props.initialValues?.accessKey?.teamUser)) return;
    const email = addMemberEmailInput.current?.state?.value;
    if (!email) {
      return;
    }
    if (members.find(member => member.email === email)) {
      NotificationCenter.error([`${formatMessage({ id: 'thisMemberAlreadyInYourTeam' })}`]);
      return;
    }
    const { data } = await client.query<GetPublicUserByEmail, GetPublicUserByEmailVariables>({
      query: GET_PUBLIC_USER_BY_EMAIL,
      variables: { email },
    });
    if (!data?.publicUser) {
      NotificationCenter.error([`${formatMessage({ id: 'thisEmailIsNotRegistered' })}`]);
      return;
    }
    setMembers(current => [...current, { ...data.publicUser, permissions: DefaultTeamPermissions }]);
    addMemberEmailInput.current.setValue('', null, null);
  };

  const handleChangePermissions = async (memberId: string, permissions: string[]) => {
    setMembers(current => [...current.map(member => (member._id === memberId ? { ...member, permissions } : member))]);
  };

  const handleRemoveMember = async (memberId) => {
    setMembers(members.filter(member => member._id !== memberId));
  };

  const { getFieldDecorator, setFieldsValue } = props.form;
  return (
    <StyledForm onSubmit={handleSubmit}>
      {/* <Divider orientation={'left'}>Logo</Divider> */}
      <Form.Item style={{ textAlign: 'center' }}>
        {getFieldDecorator('logo', {
          initialValue: props.initialValues?.logo,
        })(
          <PhotoUploader
            multiple={false}
            initialValue={[props.initialValues?.logo]}
            onChange={value => setFieldsValue({ logo: value })}
          />,
        )}
      </Form.Item>
      <p className={'label'}>{formatMessage({ id: 'teamInformation' })}</p>
      <Form.Item>
        {getFieldDecorator('name', {
          initialValue: props.initialValues?.name,
          rules: [
            {
              required: true,
              message: 'Required!',
            },
          ],
        })(<Input type={'text'} autoFocus placeholder={`${formatMessage({ id: 'teamNamePlace' })}`} />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('domain', {
          initialValue: props.initialValues?.domain,
          rules: [
            {
              required: true,
              message: 'Required!',
            },
          ],
        })(<Input addonBefore="https://" addonAfter=".formtory.com" placeholder={'acme'} />)}
      </Form.Item>
      <StyledSpacing />
      {props.initialValues &&
        <>
          <p className={'label'}>{formatMessage({ id: 'teamMembers' })}: {members.length} {formatMessage({ id: 'of' })} {props.initialValues?.accessKey?.teamUser} {formatMessage({ id: 'seatsUsed' })}</p>
          <Row>
            <Col span={18}>
              <Input ref={addMemberEmailInput} type={'text'} placeholder={`${formatMessage({ id: 'addFormtoryEmailPlace' })}`} style={{ borderRadius: '3px 0 0 3px' }} />
            </Col>
            <Col span={6}>
              <Button disabled={!props.initialValues?.accessKey?.teamUser || members.length >= parseInt(props.initialValues?.accessKey?.teamUser)} onClick={handleSearchMemberByEmail} style={{ background: '#eee', fontSize: '18px', height: 50, borderRadius: '0 3px 3px 0' }}>
                {formatMessage({ id: 'add' })}
              </Button>
            </Col>
          </Row>
          {members.map(member => (
            <TeamMember key={member._id} member={member} owner={props.initialValues?.ownerId === member._id} onChangePermissions={handleChangePermissions} onRemoveMember={handleRemoveMember} />
          ))}
          {members.length === 0 && (
            <>
              <StyledSpacing />
              <Empty description={'Add member to your team'} />
            </>
          )}
          <StyledSpacing />
        </>
      }
      <StyledSpacing />
      <Button type="primary" loading={submitting} disabled={submitting} htmlType={'submit'}>
        {props.initialValues ? 'Update' : 'Create team'}
      </Button>
    </StyledForm>
  );
};

export default Form.create<FormProps>()(TeamForm);
