import gql from 'graphql-tag';

export const USER_BASE_FIELDS = gql`
  fragment UserBaseFields on User {
    _id
    firstName
    lastName
    email
    username
    avatar
    dob
    gender
    createdAt
    updatedAt
    lastLoginAt
    lastLoginIP
  }
`;

export const TEAM_PERMISSION_BASE_FIELDS = gql`
  fragment TeamPermissionBaseFields on TeamPermission {
    _id
    teamId
    memberId
    canViewCreateTab
    canViewShareTab
    canViewConnectTab
    canViewResultsTab
    canEditCreateTab
    canEditShareTab
    canEditConnectTab
    canEditResultsTab
    canPublishForm
  }
`;

export const TEAM_BASE_FIELDS = gql`
  fragment TeamBaseFields on Team {
    _id
    name
    domain
    logo
    ownerId
    memberIds
    members {
      ...UserBaseFields
    }
    permissions {
      ...TeamPermissionBaseFields
    }
    createdAt
    updatedAt
    deletedAt
  }
  ${USER_BASE_FIELDS}
  ${TEAM_PERMISSION_BASE_FIELDS}
`;

export const ACCESS_KEY_BASE_FIELDS = gql`
  fragment AccessKeyBaseFields on AccessKey {
    _id
    orderId
    plan
    userId
    teamId
    expirationDate
    responsesNeed
    teamUser
    active
    status
    CODAddress
    CODPhoneNumber
    CODNotes
    amount
    paymentMethod
    createdAt
    updatedAt
  }
`;

export const WORKSPACE_BASE_FIELDS = gql`
  fragment WorkspaceBaseFields on Workspace {
    _id
    name
    createdBy
    createdAt
    creator {
      _id
      username
      firstName
      lastName
      avatar
    }
    members {
      _id
    }
  }
`;

export const FORM_BASE_FIELDS = gql`
  fragment FormBaseFields on Form {
    _id
    name
    createdAt
    createdBy
    totalResponses
    themeKey
    orders
    theme {
      _id
      answers
      logoImage
      backgroundImage
      backgroundBrightness
      backgroundScaleX
      backgroundScaleY
      backgroundTranslateX
      backgroundTranslateY
      fullWidth
      fullHeight
      background
      buttons
      fontFamily
      questions
      name
    }
    hash
    hideBranding
    embedType
    embedWidth
    embedWidthUnit
    embedHeight
    embedHeightUnit
    embedButtonText
    embedButtonColor
    embedButtonFontSize
    embedButtonBorderRadius
    embedButtonAsText
    embedLaunchPopupAutomatically
    embedHideFooter
    embedCloseOnComplete
    closeFormToNewResponses
    closeFormFromDate
    closeFormFromDateEnable
    responseLimitEnable
    responseLimit
    closeFormTitle
    closeFormDescription
    closeFormCustomEnable
    closeFormImage
    selfNotificationEnable
    selfNotificationSendTo
    selfNotificationReplyTo
    selfNotificationSubject
    selfNotificationMessage
    respondentNotificationEnable
    respondentNotificationSendTo
    respondentNotificationReplyTo
    respondentNotificationSubject
    respondentNotificationMessage
    priceCurrency
    progressBarType
    defaultFormLanguage
    defaultFormTransition
    defaultFormLayout
    redirectOnComplete
    customizedLabels
    totalResponsesInThisMonth
    connectedApps {
      name
      payload
      connected
    }
    locales
    labelTranslations
    shouldRegenerateLocales
    allowEditResponse
    enableTrackingResponderIp
    allowMultipleSubmissionPerUser
    hasProtectedPassword
    publishedAt
    updatedAt
  }
`;

export const COMMENT_BASE_FIELDS = gql`
  fragment CommentBaseFields on Comment {
    _id
    ref
    refId
    content
    photos
    createdAt
    creator {
      ...UserBaseFields
    }
  }
  ${USER_BASE_FIELDS}
`;

export const FORM_ITEM_SETTING_BASE_FIELDS = gql`
  fragment FormItemSettingBaseFields on FormItemSettings {
    required
    descriptionEnabled
    quotationMarks
    alphabeticalOrder
    maxSelectionsEnabled
    maxSelectionsValue
    otherOptionEnabled
    verticalAlignEnabled
    randomizeEnabled
    multipleSelectionEnabled
    maxCharactersEnabled
    pictureChoiceLabelEnabled
    buttonEnabled
    pictureChoiceSuperSize
    opinionScaleLabelEnabled
    opinionScaleLabelLeft
    opinionScaleLabelCenter
    opinionScaleLabelRight
    opinionScaleStartAt
    opinionScaleFrom
    opinionScaleTo
    opinionScaleSteps
    ratingSteps
    choices
    defaultChoices
    pictureChoices {
      image
      label
    }
    defaultPictureChoices {
      image
      label
    }
    maxCharactersValue
    ratingIconName
    image
    maxCharacters
    buttonText
    videoUrl
    imageBrightness
    layout
    isNPS
    isTelephone
    npsSegmentedOptionsEnabled
    npsSegmentedOptions {
      promoters
      passives
      detractors
    }
    variables
  }
`;

export const FORM_ITEM_BASE_FIELDS = gql`
  fragment FormItemBaseFields on FormItem {
    _id
    title
    description
    formType
    order
    hiddenFields
    settings {
      ...FormItemSettingBaseFields
    }
    logicJumps {
      _id
      jumpTo
      conditions {
        _id
        formItemId
        and
        or
        condition
        value
      }
      createdAt
    }
    groupId
    allCaseJumpTo
    calculations {
      _id
      condition
      addTo
      addValue
      addType
      value
    }
    totalComments
    summaries
    createdBy
    updatedAt
    createdAt
    deletedAt
  }
  ${FORM_ITEM_SETTING_BASE_FIELDS}
`;
