import { useMutation } from 'react-apollo-hooks';
import { GET_WORKSPACE_FORMS, DELETE_WORKSPACE_FORM, DUPLICATE_FORM } from '@formtory/shared/graphql/form';
import { GetWorkspaceForms, DeleteWorkspaceForm, DeleteWorkspaceFormVariables, DuplicateForm, DuplicateFormVariables } from '@formtory/shared/graphql/types';

export const useDeleteFormMutation = workspaceId => {
  return useMutation<DeleteWorkspaceForm, DeleteWorkspaceFormVariables>(DELETE_WORKSPACE_FORM, {
    update: (store, { data: { deleteForm } }) => {
      const previous: GetWorkspaceForms = store.readQuery({
        query: GET_WORKSPACE_FORMS,
        variables: { workspaceId },
      });
      const formIndex = previous.forms.findIndex(form => form._id === deleteForm._id);
      store.writeQuery({
        query: GET_WORKSPACE_FORMS,
        variables: { workspaceId },
        data: { ...previous, forms: previous.forms.filter((_, i) => i !== formIndex) },
      });
    },
  });
};

export const useDuplicateFormMutation = workspaceId => {
  return useMutation<DuplicateForm, DuplicateFormVariables>(DUPLICATE_FORM, {
    update: (store, { data: { duplicateForm } }) => {
      const previous: GetWorkspaceForms = store.readQuery({
        query: GET_WORKSPACE_FORMS,
        variables: { workspaceId },
      });
      const form = previous.forms.find(form => form._id === duplicateForm._id);
      store.writeQuery({
        query: GET_WORKSPACE_FORMS,
        variables: { workspaceId },
        data: {
          ...previous, forms: [
            ...previous.forms,
            { ...form },
          ]
        },
      });
    },
  });
};