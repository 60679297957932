import gql from 'graphql-tag';

export const GET_WORKSPACES = gql`
  query GetWorkspaces($teamId: ID) {
    workspaces(teamId: $teamId) {
      _id
      name
      creator {
        _id
        username
        avatar
        firstName
        lastName
      }
      members {
        _id
        username
        avatar
        firstName
        lastName
      }
    }
  }
`;

export const ADD_WORKSPACE = gql`
  mutation AddWorkspaceMutation($data: WorkspaceInput!) {
    createWorkspace(data: $data) {
      _id
      name
      creator {
        _id
        username
        avatar
        firstName
        lastName
      }
      members {
        _id
        username
        avatar
        firstName
        lastName
      }
    }
  }
`;

export const UPDATE_WORKSPACE = gql`
  mutation UpdateWorkspaceMutation($id: ID!, $data: WorkspaceInput!) {
    updateWorkspace(id: $id, data: $data) {
      _id
      name
      creator {
        _id
        username
        avatar
        firstName
        lastName
      }
      members {
        _id
        username
        avatar
        firstName
        lastName
      }
    }
  }
`;
