import React from 'react';
import styled from 'styled-components';
import Title from './Elements/Title';
import Description from './Elements/Description';
import ButtonGroup from './Elements/ButtonGroup';
import { FormItemBaseWrappedChildProps } from './FormItemBase';
import { StyledQuestionContainer, StyledQuestionContainerContent } from './Styled';
import { getSetting } from './Utils';
import QuestionLayout from './QuestionLayout';
import { FormItemSettingsLayout } from '../../graphql/types';
import Image from './Elements/Image';
import Video from './Elements/Video';
import Index from './Elements/Index';
import Submit from './Submit';

const QuotationMarks = styled.div`
  font-size: 24px;
  line-height: 32px;
  quotes: '“' '”';
  color: ${(props) => props.theme.form.answers};
  height: 32px;
  display: inline-block;
  &:before {
    content: open-quote;
    font-size: 60px;
  }
`;

const Statement = (props: FormItemBaseWrappedChildProps) => {
  const { formItem, customizedLabels, previewing, prices, scores, priceCurrency, isNextSubmit, onSubmit, submitting, formLayout } = props;
  const { settings } = formItem;
  return (
    <QuestionLayout
      videoUrl={settings.videoUrl}
      image={settings.image}
      hideImage={true}
      layout={settings.layout}
      imageBrightness={settings.imageBrightness}
      formLayout={formLayout}
    >
      <StyledQuestionContainer style={props.device === 'mobile' ? { justifyContent: 'center' } : {}}>
        <Index renderIndex={settings.quotationMarks ? <QuotationMarks /> : <span />} />
        <StyledQuestionContainerContent style={props.device === 'mobile' ? { minWidth: '90%' } : {}}>
          <Title
            prices={prices}
            priceCurrency={priceCurrency}
            scores={scores}
            value={formItem.title}
            results={props.results}
            containerProps={{ style: { textAlign: 'left' } }}

          />
          {settings.descriptionEnabled && formItem.description && (
            <Description
              prices={prices}
              scores={scores}
              priceCurrency={priceCurrency}
              results={props.results}
              value={formItem.description}
            />
          )}
          {settings.videoUrl && <Video value={settings.videoUrl} />}
          {!settings.videoUrl &&
            settings.image &&
            (!settings.layout || settings.layout === FormItemSettingsLayout.NORMAL) && (
              <Image
                value={settings.image}
                containerProps={{
                  className: 'image--normal',
                  style: { filter: `brightness(${(settings.imageBrightness || 100) / 100})` },
                }}
              />
            )}
          {formLayout !== 'classic' &&
            <div className="buttons">
              {props.active && !isNextSubmit && (
                <ButtonGroup
                  previewing={previewing}
                  onClick={() => props.onResult(formItem._id, true)}
                  label={
                    settings.buttonText ||
                    getSetting(customizedLabels, 'CONFIRM_ANSWER_BUTTON_TEXT', 'CONTINUE') ||
                    'CONTINUE'
                  }
                />
              )}
              {isNextSubmit && <Submit customizedLabels={customizedLabels} onSubmit={onSubmit} submitting={submitting} activeItemId={isNextSubmit && 'submit'} />}
            </div>
          }
        </StyledQuestionContainerContent>
      </StyledQuestionContainer>
    </QuestionLayout>
  );
};

export default Statement;
