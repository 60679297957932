import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Homepage from './containers/HomePage/Homepage';
import { StyledBranding } from '@formtory/shared/components/DisplaySingleForm/Styled';
import { Brand } from '@formtory/shared/components/DisplaySingleForm/BottomWidget';
const ForbiddenPage = React.lazy(() => import('./containers/ErrorPages/ForbiddenPage'));
const SingleForm = React.lazy(() => import('./containers/SingleForm/SingleForm'));
const DisplaySingleForm = React.lazy(() => import('./containers/DisplaySingleForm/DisplaySingleForm'));
const DisplaySingleFormForPreview = React.lazy(() => import('./containers/DisplaySingleForm/DisplaySingleFormForPreview'));
const InternalServerErrorPage = React.lazy(() => import('./containers/ErrorPages/InternalServerErrorPage'));
const NotFoundPage = React.lazy(() => import('./containers/ErrorPages/NotFoundPage'));
const Login = React.lazy(() => import('./containers/Authentication/Login'));
const ConfirmInformation = React.lazy(() => import('./containers/Authentication/ConfirmInformation'));
const ChoosePlan = React.lazy(() => import('./containers/Plan/ChoosePlan'));
const ConfirmPlan = React.lazy(() => import('./containers/Plan/ConfirmPlan'));

const PageLoading = () => (
  <div className="screen-loading">
    <div id="circle3" />
    <StyledBranding>
      <Brand />
    </StyledBranding>
  </div>
);

const FormLoading = () => (<div></div>);

export default function renderRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/workspace/:workspaceId" component={Homepage} />
      <Route exact path="/403">
        <React.Suspense fallback={<PageLoading />}>
          <ForbiddenPage />
        </React.Suspense>
      </Route>
      <Route exact path="/login">
        <React.Suspense fallback={<PageLoading />}>
          <Login />
        </React.Suspense>
      </Route>
      <Route exact path="/confirms/information">
        <React.Suspense fallback={<PageLoading />}>
          <ConfirmInformation />
        </React.Suspense>
      </Route>
      <Route path="/form/:formId">
        <React.Suspense fallback={<PageLoading />}>
          <SingleForm />
        </React.Suspense>
      </Route>
      <Route path="/f/:formId">
        <React.Suspense fallback={<FormLoading />}>
          <DisplaySingleForm />
        </React.Suspense>
      </Route>
      <Route path="/preview/form/:formId">
        <React.Suspense fallback={<FormLoading />}>
          <DisplaySingleFormForPreview />
        </React.Suspense>
      </Route>
      <Route path="/plan/:type">
        <React.Suspense fallback={<PageLoading />}>
          <ChoosePlan />
        </React.Suspense>
      </Route>
      <Route path="/confirms/plan/:type">
        <React.Suspense fallback={<PageLoading />}>
          <ConfirmPlan />
        </React.Suspense>
      </Route>
      <Route exact path="/500">
        <React.Suspense fallback={<PageLoading />}>
          <InternalServerErrorPage />
        </React.Suspense>
      </Route>
      <Route exact path="*">
        <React.Suspense fallback={<PageLoading />}>
          <NotFoundPage />
        </React.Suspense>
      </Route>
    </Switch>
  );
}
