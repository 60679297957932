import React from 'react';
import styled from 'styled-components';
import { isMobile } from '@formtory/app/src/utils/helperFunctions';

const StyledIndex = styled.div`
  color: ${(props) => props.theme.form.answers};
  font-size: ${isMobile ? '20px' : '24px'};
  font-weight: unset;
  margin-right: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  max-height: 100px;
  cursor: default;
`;

const Index = ({ renderIndex = null, required = false }) => {
  return (
    <StyledIndex>
      {required && <b style={{ position: 'absolute', left: '-13px', top: '-3px' }}>*</b>}
      {renderIndex}
    </StyledIndex>
  )
};

export default Index;
