

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendOTPMutation
// ====================================================

export interface SendOTPMutation {
  sendOTP: boolean | null;
}

export interface SendOTPMutationVariables {
  data: SendOTPInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmOTPMutation
// ====================================================

export interface ConfirmOTPMutation_confirmOTP_user_teams_members {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface ConfirmOTPMutation_confirmOTP_user_teams_permissions {
  _id: string;
  teamId: string;
  memberId: string;
  canViewCreateTab: boolean | null;
  canViewShareTab: boolean | null;
  canViewConnectTab: boolean | null;
  canViewResultsTab: boolean | null;
  canEditCreateTab: boolean | null;
  canEditShareTab: boolean | null;
  canEditConnectTab: boolean | null;
  canEditResultsTab: boolean | null;
  canPublishForm: boolean | null;
}

export interface ConfirmOTPMutation_confirmOTP_user_teams_accessKey {
  _id: string;
  orderId: string | null;
  plan: string | null;
  userId: string | null;
  teamId: string | null;
  expirationDate: any | null;
  responsesNeed: string | null;
  teamUser: string | null;
  active: boolean | null;
  status: AccessKeyStatus | null;
  CODAddress: string | null;
  CODPhoneNumber: string | null;
  CODNotes: string | null;
  amount: number | null;
  paymentMethod: AccessKeyPaymentMethod | null;
  createdAt: any | null;
  updatedAt: any | null;
}

export interface ConfirmOTPMutation_confirmOTP_user_teams {
  _id: string;
  name: string;
  domain: string;
  logo: string | null;
  ownerId: string;
  memberIds: string[] | null;
  members: ConfirmOTPMutation_confirmOTP_user_teams_members[] | null;
  permissions: ConfirmOTPMutation_confirmOTP_user_teams_permissions[] | null;
  createdAt: any | null;
  updatedAt: any | null;
  deletedAt: any | null;
  accessKey: ConfirmOTPMutation_confirmOTP_user_teams_accessKey | null;
}

export interface ConfirmOTPMutation_confirmOTP_user_accessKey {
  _id: string;
  orderId: string | null;
  plan: string | null;
  userId: string | null;
  teamId: string | null;
  expirationDate: any | null;
  responsesNeed: string | null;
  teamUser: string | null;
  active: boolean | null;
  status: AccessKeyStatus | null;
  CODAddress: string | null;
  CODPhoneNumber: string | null;
  CODNotes: string | null;
  amount: number | null;
  paymentMethod: AccessKeyPaymentMethod | null;
  createdAt: any | null;
  updatedAt: any | null;
}

export interface ConfirmOTPMutation_confirmOTP_user {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
  teams: ConfirmOTPMutation_confirmOTP_user_teams[] | null;
  accessKey: ConfirmOTPMutation_confirmOTP_user_accessKey | null;
}

export interface ConfirmOTPMutation_confirmOTP {
  accessToken: string | null;
  user: ConfirmOTPMutation_confirmOTP_user | null;
}

export interface ConfirmOTPMutation {
  confirmOTP: ConfirmOTPMutation_confirmOTP | null;
}

export interface ConfirmOTPMutationVariables {
  data: ConfirmOTPInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmInformationMutation
// ====================================================

export interface ConfirmInformationMutation_confirmInformation_teams_members {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface ConfirmInformationMutation_confirmInformation_teams_permissions {
  _id: string;
  teamId: string;
  memberId: string;
  canViewCreateTab: boolean | null;
  canViewShareTab: boolean | null;
  canViewConnectTab: boolean | null;
  canViewResultsTab: boolean | null;
  canEditCreateTab: boolean | null;
  canEditShareTab: boolean | null;
  canEditConnectTab: boolean | null;
  canEditResultsTab: boolean | null;
  canPublishForm: boolean | null;
}

export interface ConfirmInformationMutation_confirmInformation_teams_accessKey {
  _id: string;
  orderId: string | null;
  plan: string | null;
  userId: string | null;
  teamId: string | null;
  expirationDate: any | null;
  responsesNeed: string | null;
  teamUser: string | null;
  active: boolean | null;
  status: AccessKeyStatus | null;
  CODAddress: string | null;
  CODPhoneNumber: string | null;
  CODNotes: string | null;
  amount: number | null;
  paymentMethod: AccessKeyPaymentMethod | null;
  createdAt: any | null;
  updatedAt: any | null;
}

export interface ConfirmInformationMutation_confirmInformation_teams {
  _id: string;
  name: string;
  domain: string;
  logo: string | null;
  ownerId: string;
  memberIds: string[] | null;
  members: ConfirmInformationMutation_confirmInformation_teams_members[] | null;
  permissions: ConfirmInformationMutation_confirmInformation_teams_permissions[] | null;
  createdAt: any | null;
  updatedAt: any | null;
  deletedAt: any | null;
  accessKey: ConfirmInformationMutation_confirmInformation_teams_accessKey | null;
}

export interface ConfirmInformationMutation_confirmInformation_accessKey {
  _id: string;
  orderId: string | null;
  plan: string | null;
  userId: string | null;
  teamId: string | null;
  expirationDate: any | null;
  responsesNeed: string | null;
  teamUser: string | null;
  active: boolean | null;
  status: AccessKeyStatus | null;
  CODAddress: string | null;
  CODPhoneNumber: string | null;
  CODNotes: string | null;
  amount: number | null;
  paymentMethod: AccessKeyPaymentMethod | null;
  createdAt: any | null;
  updatedAt: any | null;
}

export interface ConfirmInformationMutation_confirmInformation {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
  teams: ConfirmInformationMutation_confirmInformation_teams[] | null;
  accessKey: ConfirmInformationMutation_confirmInformation_accessKey | null;
}

export interface ConfirmInformationMutation {
  confirmInformation: ConfirmInformationMutation_confirmInformation | null;
}

export interface ConfirmInformationMutationVariables {
  data: ConfirmInformationInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Me
// ====================================================

export interface Me_me_teams_members {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface Me_me_teams_permissions {
  _id: string;
  teamId: string;
  memberId: string;
  canViewCreateTab: boolean | null;
  canViewShareTab: boolean | null;
  canViewConnectTab: boolean | null;
  canViewResultsTab: boolean | null;
  canEditCreateTab: boolean | null;
  canEditShareTab: boolean | null;
  canEditConnectTab: boolean | null;
  canEditResultsTab: boolean | null;
  canPublishForm: boolean | null;
}

export interface Me_me_teams_accessKey {
  _id: string;
  orderId: string | null;
  plan: string | null;
  userId: string | null;
  teamId: string | null;
  expirationDate: any | null;
  responsesNeed: string | null;
  teamUser: string | null;
  active: boolean | null;
  status: AccessKeyStatus | null;
  CODAddress: string | null;
  CODPhoneNumber: string | null;
  CODNotes: string | null;
  amount: number | null;
  paymentMethod: AccessKeyPaymentMethod | null;
  createdAt: any | null;
  updatedAt: any | null;
}

export interface Me_me_teams {
  _id: string;
  name: string;
  domain: string;
  logo: string | null;
  ownerId: string;
  memberIds: string[] | null;
  members: Me_me_teams_members[] | null;
  permissions: Me_me_teams_permissions[] | null;
  createdAt: any | null;
  updatedAt: any | null;
  deletedAt: any | null;
  accessKey: Me_me_teams_accessKey | null;
  monthlyTotalResponses: number | null;
}

export interface Me_me_accessKey {
  _id: string;
  orderId: string | null;
  plan: string | null;
  userId: string | null;
  teamId: string | null;
  expirationDate: any | null;
  responsesNeed: string | null;
  active: boolean | null;
  status: AccessKeyStatus | null;
  CODAddress: string | null;
  CODPhoneNumber: string | null;
  CODNotes: string | null;
  amount: number | null;
  paymentMethod: AccessKeyPaymentMethod | null;
  createdAt: any | null;
  updatedAt: any | null;
}

export interface Me_me {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
  teams: Me_me_teams[] | null;
  monthlyTotalResponses: number | null;
  accessKey: Me_me_accessKey | null;
}

export interface Me {
  me: Me_me | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveComment
// ====================================================

export interface SaveComment_saveComment_creator {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface SaveComment_saveComment {
  _id: string | null;
  ref: CommentRef | null;
  refId: string;
  content: string | null;
  photos: (string | null)[] | null;
  createdAt: any | null;
  creator: SaveComment_saveComment_creator | null;
}

export interface SaveComment {
  saveComment: SaveComment_saveComment | null;
}

export interface SaveCommentVariables {
  data: CommentInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteComment
// ====================================================

export interface DeleteComment {
  deleteComment: boolean | null;
}

export interface DeleteCommentVariables {
  commentId: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetComments
// ====================================================

export interface GetComments_comments_edges_creator {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface GetComments_comments_edges {
  _id: string | null;
  ref: CommentRef | null;
  refId: string;
  content: string | null;
  photos: (string | null)[] | null;
  createdAt: any | null;
  creator: GetComments_comments_edges_creator | null;
}

export interface GetComments_comments {
  count: number | null;
  edges: GetComments_comments_edges[] | null;
}

export interface GetComments {
  comments: GetComments_comments | null;
}

export interface GetCommentsVariables {
  limit: number;
  skip: number;
  refId: string;
  ref: CommentRef;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CheckDiscount
// ====================================================

export interface CheckDiscount_checkDiscount {
  _id: string;
  code: string;
  discount: number;
  plan: string;
  billedBy: string;
}

export interface CheckDiscount {
  checkDiscount: CheckDiscount_checkDiscount | null;
}

export interface CheckDiscountVariables {
  code: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDiscount
// ====================================================

export interface CreateDiscount_createDiscount {
  _id: string;
  code: string;
  discount: number;
  plan: string;
  billedBy: string;
}

export interface CreateDiscount {
  createDiscount: CreateDiscount_createDiscount | null;
}

export interface CreateDiscountVariables {
  data: CreateDiscountInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDiscount
// ====================================================

export interface DeleteDiscount {
  deleteDiscount: boolean | null;
}

export interface DeleteDiscountVariables {
  id: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDiscounts
// ====================================================

export interface GetDiscounts_discounts {
  _id: string;
  code: string;
  discount: number;
  plan: string[];
  billedBy: string;
}

export interface GetDiscounts {
  discounts: (GetDiscounts_discounts | null)[] | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SingleFileUpload
// ====================================================

export interface SingleFileUpload_singleUpload {
  encoding: string;
  filename: string;
  mimetype: string;
  uri: string;
}

export interface SingleFileUpload {
  singleUpload: SingleFileUpload_singleUpload | null;
}

export interface SingleFileUploadVariables {
  file: any;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnsplashFileUpload
// ====================================================

export interface UnsplashFileUpload_unsplashUpload {
  encoding: string;
  filename: string;
  mimetype: string;
  uri: string;
}

export interface UnsplashFileUpload {
  unsplashUpload: UnsplashFileUpload_unsplashUpload | null;
}

export interface UnsplashFileUploadVariables {
  filename: string;
  url: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaceForms
// ====================================================

export interface GetWorkspaceForms_forms_theme {
  _id: string | null;
  answers: string;
  logoImage: string | null;
  backgroundImage: string | null;
  backgroundBrightness: number | null;
  backgroundScaleX: number | null;
  backgroundScaleY: number | null;
  backgroundTranslateX: number | null;
  backgroundTranslateY: number | null;
  fullWidth: boolean;
  fullHeight: boolean;
  background: string;
  buttons: string;
  fontFamily: string;
  questions: string;
  name: string;
}

export interface GetWorkspaceForms_forms_connectedApps {
  name: string | null;
  payload: string | null;
  connected: boolean | null;
}

export interface GetWorkspaceForms_forms_creator {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface GetWorkspaceForms_forms_workspace_creator {
  _id: string;
  username: string | null;
  firstName: string | null;
  lastName: string | null;
  avatar: string | null;
}

export interface GetWorkspaceForms_forms_workspace_members {
  _id: string;
}

export interface GetWorkspaceForms_forms_workspace {
  _id: string;
  name: string;
  createdBy: string;
  createdAt: string;
  creator: GetWorkspaceForms_forms_workspace_creator;
  members: (GetWorkspaceForms_forms_workspace_members | null)[];
}

export interface GetWorkspaceForms_forms {
  _id: string;
  name: string;
  createdAt: any;
  createdBy: string;
  totalResponses: number | null;
  themeKey: string;
  orders: string[] | null;
  theme: GetWorkspaceForms_forms_theme | null;
  hash: string;
  hideBranding: boolean | null;
  embedType: string | null;
  embedWidth: string | null;
  embedWidthUnit: string | null;
  embedHeight: string | null;
  embedHeightUnit: string | null;
  embedButtonText: string | null;
  embedButtonColor: string | null;
  embedButtonFontSize: string | null;
  embedButtonBorderRadius: string | null;
  embedButtonAsText: boolean | null;
  embedLaunchPopupAutomatically: boolean | null;
  embedHideFooter: boolean | null;
  embedCloseOnComplete: boolean | null;
  closeFormToNewResponses: boolean | null;
  closeFormFromDate: any | null;
  closeFormFromDateEnable: boolean | null;
  responseLimitEnable: boolean | null;
  responseLimit: number | null;
  closeFormTitle: string | null;
  closeFormDescription: string | null;
  closeFormCustomEnable: boolean | null;
  closeFormImage: string | null;
  selfNotificationEnable: boolean | null;
  selfNotificationSendTo: string[] | null;
  selfNotificationReplyTo: string | null;
  selfNotificationSubject: string | null;
  selfNotificationMessage: string | null;
  respondentNotificationEnable: boolean | null;
  respondentNotificationSendTo: string | null;
  respondentNotificationReplyTo: string | null;
  respondentNotificationSubject: string | null;
  respondentNotificationMessage: string | null;
  priceCurrency: string | null;
  progressBarType: string | null;
  defaultFormLanguage: string | null;
  defaultFormTransition: string | null;
  defaultFormLayout: string | null;
  redirectOnComplete: string | null;
  customizedLabels: string | null;
  totalResponsesInThisMonth: number | null;
  connectedApps: (GetWorkspaceForms_forms_connectedApps | null)[] | null;
  locales: string[] | null;
  labelTranslations: string | null;
  shouldRegenerateLocales: string[] | null;
  allowEditResponse: boolean | null;
  enableTrackingResponderIp: boolean | null;
  allowMultipleSubmissionPerUser: boolean | null;
  hasProtectedPassword: boolean | null;
  publishedAt: any | null;
  updatedAt: any | null;
  creator: GetWorkspaceForms_forms_creator;
  workspace: GetWorkspaceForms_forms_workspace;
}

export interface GetWorkspaceForms {
  forms: (GetWorkspaceForms_forms | null)[] | null;
}

export interface GetWorkspaceFormsVariables {
  workspaceId: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTemplateForms
// ====================================================

export interface GetTemplateForms_templateForms_theme {
  _id: string | null;
  answers: string;
  logoImage: string | null;
  backgroundImage: string | null;
  backgroundBrightness: number | null;
  backgroundScaleX: number | null;
  backgroundScaleY: number | null;
  backgroundTranslateX: number | null;
  backgroundTranslateY: number | null;
  fullWidth: boolean;
  fullHeight: boolean;
  background: string;
  buttons: string;
  fontFamily: string;
  questions: string;
  name: string;
}

export interface GetTemplateForms_templateForms_connectedApps {
  name: string | null;
  payload: string | null;
  connected: boolean | null;
}

export interface GetTemplateForms_templateForms_templateInfo {
  name: string | null;
  categories: string[] | null;
  language: string[] | null;
  backgroundImage: string | null;
}

export interface GetTemplateForms_templateForms {
  _id: string;
  name: string;
  createdAt: any;
  createdBy: string;
  totalResponses: number | null;
  themeKey: string;
  orders: string[] | null;
  theme: GetTemplateForms_templateForms_theme | null;
  hash: string;
  hideBranding: boolean | null;
  embedType: string | null;
  embedWidth: string | null;
  embedWidthUnit: string | null;
  embedHeight: string | null;
  embedHeightUnit: string | null;
  embedButtonText: string | null;
  embedButtonColor: string | null;
  embedButtonFontSize: string | null;
  embedButtonBorderRadius: string | null;
  embedButtonAsText: boolean | null;
  embedLaunchPopupAutomatically: boolean | null;
  embedHideFooter: boolean | null;
  embedCloseOnComplete: boolean | null;
  closeFormToNewResponses: boolean | null;
  closeFormFromDate: any | null;
  closeFormFromDateEnable: boolean | null;
  responseLimitEnable: boolean | null;
  responseLimit: number | null;
  closeFormTitle: string | null;
  closeFormDescription: string | null;
  closeFormCustomEnable: boolean | null;
  closeFormImage: string | null;
  selfNotificationEnable: boolean | null;
  selfNotificationSendTo: string[] | null;
  selfNotificationReplyTo: string | null;
  selfNotificationSubject: string | null;
  selfNotificationMessage: string | null;
  respondentNotificationEnable: boolean | null;
  respondentNotificationSendTo: string | null;
  respondentNotificationReplyTo: string | null;
  respondentNotificationSubject: string | null;
  respondentNotificationMessage: string | null;
  priceCurrency: string | null;
  progressBarType: string | null;
  defaultFormLanguage: string | null;
  defaultFormTransition: string | null;
  defaultFormLayout: string | null;
  redirectOnComplete: string | null;
  customizedLabels: string | null;
  totalResponsesInThisMonth: number | null;
  connectedApps: (GetTemplateForms_templateForms_connectedApps | null)[] | null;
  locales: string[] | null;
  labelTranslations: string | null;
  shouldRegenerateLocales: string[] | null;
  allowEditResponse: boolean | null;
  enableTrackingResponderIp: boolean | null;
  allowMultipleSubmissionPerUser: boolean | null;
  hasProtectedPassword: boolean | null;
  publishedAt: any | null;
  updatedAt: any | null;
  isTemplate: boolean | null;
  templateInfo: GetTemplateForms_templateForms_templateInfo | null;
}

export interface GetTemplateForms {
  templateForms: (GetTemplateForms_templateForms | null)[] | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaceForm
// ====================================================

export interface GetWorkspaceForm_form_theme {
  _id: string | null;
  answers: string;
  logoImage: string | null;
  backgroundImage: string | null;
  backgroundBrightness: number | null;
  backgroundScaleX: number | null;
  backgroundScaleY: number | null;
  backgroundTranslateX: number | null;
  backgroundTranslateY: number | null;
  fullWidth: boolean;
  fullHeight: boolean;
  background: string;
  buttons: string;
  fontFamily: string;
  questions: string;
  name: string;
}

export interface GetWorkspaceForm_form_connectedApps {
  name: string | null;
  payload: string | null;
  connected: boolean | null;
}

export interface GetWorkspaceForm_form_creator {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface GetWorkspaceForm_form_workspace_creator {
  _id: string;
  username: string | null;
  firstName: string | null;
  lastName: string | null;
  avatar: string | null;
}

export interface GetWorkspaceForm_form_workspace_members {
  _id: string;
}

export interface GetWorkspaceForm_form_workspace {
  _id: string;
  name: string;
  createdBy: string;
  createdAt: string;
  creator: GetWorkspaceForm_form_workspace_creator;
  members: (GetWorkspaceForm_form_workspace_members | null)[];
}

export interface GetWorkspaceForm_form {
  _id: string;
  name: string;
  createdAt: any;
  createdBy: string;
  totalResponses: number | null;
  themeKey: string;
  orders: string[] | null;
  theme: GetWorkspaceForm_form_theme | null;
  hash: string;
  hideBranding: boolean | null;
  embedType: string | null;
  embedWidth: string | null;
  embedWidthUnit: string | null;
  embedHeight: string | null;
  embedHeightUnit: string | null;
  embedButtonText: string | null;
  embedButtonColor: string | null;
  embedButtonFontSize: string | null;
  embedButtonBorderRadius: string | null;
  embedButtonAsText: boolean | null;
  embedLaunchPopupAutomatically: boolean | null;
  embedHideFooter: boolean | null;
  embedCloseOnComplete: boolean | null;
  closeFormToNewResponses: boolean | null;
  closeFormFromDate: any | null;
  closeFormFromDateEnable: boolean | null;
  responseLimitEnable: boolean | null;
  responseLimit: number | null;
  closeFormTitle: string | null;
  closeFormDescription: string | null;
  closeFormCustomEnable: boolean | null;
  closeFormImage: string | null;
  selfNotificationEnable: boolean | null;
  selfNotificationSendTo: string[] | null;
  selfNotificationReplyTo: string | null;
  selfNotificationSubject: string | null;
  selfNotificationMessage: string | null;
  respondentNotificationEnable: boolean | null;
  respondentNotificationSendTo: string | null;
  respondentNotificationReplyTo: string | null;
  respondentNotificationSubject: string | null;
  respondentNotificationMessage: string | null;
  priceCurrency: string | null;
  progressBarType: string | null;
  defaultFormLanguage: string | null;
  defaultFormTransition: string | null;
  defaultFormLayout: string | null;
  redirectOnComplete: string | null;
  customizedLabels: string | null;
  totalResponsesInThisMonth: number | null;
  connectedApps: (GetWorkspaceForm_form_connectedApps | null)[] | null;
  locales: string[] | null;
  labelTranslations: string | null;
  shouldRegenerateLocales: string[] | null;
  allowEditResponse: boolean | null;
  enableTrackingResponderIp: boolean | null;
  allowMultipleSubmissionPerUser: boolean | null;
  hasProtectedPassword: boolean | null;
  publishedAt: any | null;
  updatedAt: any | null;
  creator: GetWorkspaceForm_form_creator;
  workspace: GetWorkspaceForm_form_workspace;
  protectedPassword: string | null;
}

export interface GetWorkspaceForm {
  form: GetWorkspaceForm_form | null;
}

export interface GetWorkspaceFormVariables {
  formId: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetForm
// ====================================================

export interface GetForm_form_theme {
  _id: string | null;
  answers: string;
  logoImage: string | null;
  backgroundImage: string | null;
  backgroundBrightness: number | null;
  backgroundScaleX: number | null;
  backgroundScaleY: number | null;
  backgroundTranslateX: number | null;
  backgroundTranslateY: number | null;
  fullWidth: boolean;
  fullHeight: boolean;
  background: string;
  buttons: string;
  fontFamily: string;
  questions: string;
  name: string;
}

export interface GetForm_form_connectedApps {
  name: string | null;
  payload: string | null;
  connected: boolean | null;
}

export interface GetForm_form_creator {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface GetForm_form_workspace_creator {
  _id: string;
  username: string | null;
  firstName: string | null;
  lastName: string | null;
  avatar: string | null;
}

export interface GetForm_form_workspace_members {
  _id: string;
}

export interface GetForm_form_workspace_team_members {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface GetForm_form_workspace_team_permissions {
  _id: string;
  teamId: string;
  memberId: string;
  canViewCreateTab: boolean | null;
  canViewShareTab: boolean | null;
  canViewConnectTab: boolean | null;
  canViewResultsTab: boolean | null;
  canEditCreateTab: boolean | null;
  canEditShareTab: boolean | null;
  canEditConnectTab: boolean | null;
  canEditResultsTab: boolean | null;
  canPublishForm: boolean | null;
}

export interface GetForm_form_workspace_team {
  _id: string;
  name: string;
  domain: string;
  logo: string | null;
  ownerId: string;
  memberIds: string[] | null;
  members: GetForm_form_workspace_team_members[] | null;
  permissions: GetForm_form_workspace_team_permissions[] | null;
  createdAt: any | null;
  updatedAt: any | null;
  deletedAt: any | null;
}

export interface GetForm_form_workspace {
  _id: string;
  name: string;
  createdBy: string;
  createdAt: string;
  creator: GetForm_form_workspace_creator;
  members: (GetForm_form_workspace_members | null)[];
  team: GetForm_form_workspace_team | null;
}

export interface GetForm_form_formItemTranslations {
  _id: string;
  title: string | null;
  description: string | null;
  choices: string[] | null;
  pictureChoices: (SaveFormItemMutation_saveFormItem_settings_pictureChoices | null)[] | null;
  locale: string | null;
  formId: string | null;
  formItemId: string | null;
}

export interface GetForm_form {
  _id: string;
  name: string;
  createdAt: any;
  createdBy: string;
  totalResponses: number | null;
  themeKey: string;
  orders: string[] | null;
  theme: GetForm_form_theme | null;
  hash: string;
  hideBranding: boolean | null;
  embedType: string | null;
  embedWidth: string | null;
  embedWidthUnit: string | null;
  embedHeight: string | null;
  embedHeightUnit: string | null;
  embedButtonText: string | null;
  embedButtonColor: string | null;
  embedButtonFontSize: string | null;
  embedButtonBorderRadius: string | null;
  embedButtonAsText: boolean | null;
  embedLaunchPopupAutomatically: boolean | null;
  embedHideFooter: boolean | null;
  embedCloseOnComplete: boolean | null;
  closeFormToNewResponses: boolean | null;
  closeFormFromDate: any | null;
  closeFormFromDateEnable: boolean | null;
  responseLimitEnable: boolean | null;
  responseLimit: number | null;
  closeFormTitle: string | null;
  closeFormDescription: string | null;
  closeFormCustomEnable: boolean | null;
  closeFormImage: string | null;
  selfNotificationEnable: boolean | null;
  selfNotificationSendTo: string[] | null;
  selfNotificationReplyTo: string | null;
  selfNotificationSubject: string | null;
  selfNotificationMessage: string | null;
  respondentNotificationEnable: boolean | null;
  respondentNotificationSendTo: string | null;
  respondentNotificationReplyTo: string | null;
  respondentNotificationSubject: string | null;
  respondentNotificationMessage: string | null;
  priceCurrency: string | null;
  progressBarType: string | null;
  defaultFormLanguage: string | null;
  defaultFormTransition: string | null;
  defaultFormLayout: string | null;
  redirectOnComplete: string | null;
  customizedLabels: string | null;
  totalResponsesInThisMonth: number | null;
  connectedApps: (GetForm_form_connectedApps | null)[] | null;
  locales: string[] | null;
  labelTranslations: string | null;
  shouldRegenerateLocales: string[] | null;
  allowEditResponse: boolean | null;
  enableTrackingResponderIp: boolean | null;
  allowMultipleSubmissionPerUser: boolean | null;
  hasProtectedPassword: boolean | null;
  publishedAt: any | null;
  updatedAt: any | null;
  creator: GetForm_form_creator;
  workspace: GetForm_form_workspace;
  formItemTranslations: (GetForm_form_formItemTranslations | null)[] | null;
}

export interface GetForm {
  form: GetForm_form | null;
}

export interface GetFormVariables {
  formId: string;
  live?: boolean | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PublishForm
// ====================================================

export interface PublishForm_publishForm_theme {
  _id: string | null;
  answers: string;
  logoImage: string | null;
  backgroundImage: string | null;
  backgroundBrightness: number | null;
  backgroundScaleX: number | null;
  backgroundScaleY: number | null;
  backgroundTranslateX: number | null;
  backgroundTranslateY: number | null;
  fullWidth: boolean;
  fullHeight: boolean;
  background: string;
  buttons: string;
  fontFamily: string;
  questions: string;
  name: string;
}

export interface PublishForm_publishForm_connectedApps {
  name: string | null;
  payload: string | null;
  connected: boolean | null;
}

export interface PublishForm_publishForm {
  _id: string;
  name: string;
  createdAt: any;
  createdBy: string;
  totalResponses: number | null;
  themeKey: string;
  orders: string[] | null;
  theme: PublishForm_publishForm_theme | null;
  hash: string;
  hideBranding: boolean | null;
  embedType: string | null;
  embedWidth: string | null;
  embedWidthUnit: string | null;
  embedHeight: string | null;
  embedHeightUnit: string | null;
  embedButtonText: string | null;
  embedButtonColor: string | null;
  embedButtonFontSize: string | null;
  embedButtonBorderRadius: string | null;
  embedButtonAsText: boolean | null;
  embedLaunchPopupAutomatically: boolean | null;
  embedHideFooter: boolean | null;
  embedCloseOnComplete: boolean | null;
  closeFormToNewResponses: boolean | null;
  closeFormFromDate: any | null;
  closeFormFromDateEnable: boolean | null;
  responseLimitEnable: boolean | null;
  responseLimit: number | null;
  closeFormTitle: string | null;
  closeFormDescription: string | null;
  closeFormCustomEnable: boolean | null;
  closeFormImage: string | null;
  selfNotificationEnable: boolean | null;
  selfNotificationSendTo: string[] | null;
  selfNotificationReplyTo: string | null;
  selfNotificationSubject: string | null;
  selfNotificationMessage: string | null;
  respondentNotificationEnable: boolean | null;
  respondentNotificationSendTo: string | null;
  respondentNotificationReplyTo: string | null;
  respondentNotificationSubject: string | null;
  respondentNotificationMessage: string | null;
  priceCurrency: string | null;
  progressBarType: string | null;
  defaultFormLanguage: string | null;
  defaultFormTransition: string | null;
  defaultFormLayout: string | null;
  redirectOnComplete: string | null;
  customizedLabels: string | null;
  totalResponsesInThisMonth: number | null;
  connectedApps: (PublishForm_publishForm_connectedApps | null)[] | null;
  locales: string[] | null;
  labelTranslations: string | null;
  shouldRegenerateLocales: string[] | null;
  allowEditResponse: boolean | null;
  enableTrackingResponderIp: boolean | null;
  allowMultipleSubmissionPerUser: boolean | null;
  hasProtectedPassword: boolean | null;
  publishedAt: any | null;
  updatedAt: any | null;
}

export interface PublishForm {
  publishForm: PublishForm_publishForm | null;
}

export interface PublishFormVariables {
  id: string;
  data: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveFormItemMutation
// ====================================================

export interface SaveFormItemMutation_saveFormItem_settings_pictureChoices {
  image: string;
  label: string | null;
}

export interface SaveFormItemMutation_saveFormItem_settings_npsSegmentedOptions {
  promoters: string | null;
  passives: string | null;
  detractors: string | null;
}

export interface SaveFormItemMutation_saveFormItem_settings {
  required: boolean | null;
  descriptionEnabled: boolean | null;
  quotationMarks: boolean | null;
  alphabeticalOrder: boolean | null;
  maxSelectionsEnabled: boolean | null;
  maxSelectionsValue: number | null;
  otherOptionEnabled: boolean | null;
  verticalAlignEnabled: boolean | null;
  randomizeEnabled: boolean | null;
  multipleSelectionEnabled: boolean | null;
  maxCharactersEnabled: boolean | null;
  pictureChoiceLabelEnabled: boolean | null;
  buttonEnabled: boolean | null;
  pictureChoiceSuperSize: boolean | null;
  opinionScaleLabelEnabled: boolean | null;
  opinionScaleLabelLeft: string | null;
  opinionScaleLabelCenter: string | null;
  opinionScaleLabelRight: string | null;
  opinionScaleStartAt: number | null;
  opinionScaleFrom: number | null;
  opinionScaleTo: number | null;
  opinionScaleSteps: number | null;
  ratingSteps: number | null;
  choices: (string | null)[] | null;
  defaultChoices: (string | null)[] | null;
  pictureChoices: (SaveFormItemMutation_saveFormItem_settings_pictureChoices | null)[] | null;
  defaultPictureChoices: (SaveFormItemMutation_saveFormItem_settings_pictureChoices | null)[] | null;
  maxCharactersValue: number | null;
  ratingIconName: string | null;
  image: string | null;
  maxCharacters: number | null;
  buttonText: string | null;
  videoUrl: string | null;
  imageBrightness: number | null;
  layout: FormItemSettingsLayout | null;
  isNPS: boolean | null;
  isTelephone: boolean | null;
  npsSegmentedOptionsEnabled: boolean | null;
  npsSegmentedOptions: SaveFormItemMutation_saveFormItem_settings_npsSegmentedOptions | null;
  variables: (string | null)[] | null;
}

export interface SaveFormItemMutation_saveFormItem_logicJumps_conditions {
  _id: string | null;
  formItemId: string | null;
  and: boolean | null;
  or: boolean | null;
  condition: string | null;
  value: string | null;
}

export interface SaveFormItemMutation_saveFormItem_logicJumps {
  _id: string | null;
  jumpTo: string | null;
  conditions: SaveFormItemMutation_saveFormItem_logicJumps_conditions[] | null;
  createdAt: any | null;
}

export interface SaveFormItemMutation_saveFormItem_calculations {
  _id: string | null;
  condition: CalculationCondition;
  addTo: string | null;
  addValue: number | null;
  addType: string | null;
  value: string | null;
}

export interface SaveFormItemMutation_saveFormItem {
  _id: string;
  title: string | null;
  description: string | null;
  formType: string;
  order: number | null;
  hiddenFields: string[] | null;
  settings: SaveFormItemMutation_saveFormItem_settings;
  logicJumps: SaveFormItemMutation_saveFormItem_logicJumps[] | null;
  groupId: string | null;
  allCaseJumpTo: string | null;
  calculations: SaveFormItemMutation_saveFormItem_calculations[] | null;
  totalComments: number | null;
  summaries: string | null;
  createdBy: string;
  updatedAt: any | null;
  createdAt: any;
  deletedAt: any | null;
}

export interface SaveFormItemMutation {
  saveFormItem: SaveFormItemMutation_saveFormItem | null;
}

export interface SaveFormItemMutationVariables {
  data: FormItemInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteWorkspaceForm
// ====================================================

export interface DeleteWorkspaceForm_deleteForm {
  _id: string;
}

export interface DeleteWorkspaceForm {
  deleteForm: DeleteWorkspaceForm_deleteForm | null;
}

export interface DeleteWorkspaceFormVariables {
  formId: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveFormItemSettingsMutation
// ====================================================

export interface SaveFormItemSettingsMutation_saveFormItemSettings_pictureChoices {
  image: string;
  label: string | null;
}

export interface SaveFormItemSettingsMutation_saveFormItemSettings_npsSegmentedOptions {
  promoters: string | null;
  passives: string | null;
  detractors: string | null;
}

export interface SaveFormItemSettingsMutation_saveFormItemSettings {
  required: boolean | null;
  descriptionEnabled: boolean | null;
  quotationMarks: boolean | null;
  alphabeticalOrder: boolean | null;
  maxSelectionsEnabled: boolean | null;
  maxSelectionsValue: number | null;
  otherOptionEnabled: boolean | null;
  verticalAlignEnabled: boolean | null;
  randomizeEnabled: boolean | null;
  multipleSelectionEnabled: boolean | null;
  maxCharactersEnabled: boolean | null;
  pictureChoiceLabelEnabled: boolean | null;
  buttonEnabled: boolean | null;
  pictureChoiceSuperSize: boolean | null;
  opinionScaleLabelEnabled: boolean | null;
  opinionScaleLabelLeft: string | null;
  opinionScaleLabelCenter: string | null;
  opinionScaleLabelRight: string | null;
  opinionScaleStartAt: number | null;
  opinionScaleFrom: number | null;
  opinionScaleTo: number | null;
  opinionScaleSteps: number | null;
  ratingSteps: number | null;
  choices: (string | null)[] | null;
  defaultChoices: (string | null)[] | null;
  pictureChoices: (SaveFormItemSettingsMutation_saveFormItemSettings_pictureChoices | null)[] | null;
  defaultPictureChoices: (SaveFormItemSettingsMutation_saveFormItemSettings_pictureChoices | null)[] | null;
  maxCharactersValue: number | null;
  ratingIconName: string | null;
  image: string | null;
  maxCharacters: number | null;
  buttonText: string | null;
  videoUrl: string | null;
  imageBrightness: number | null;
  layout: FormItemSettingsLayout | null;
  isNPS: boolean | null;
  isTelephone: boolean | null;
  npsSegmentedOptionsEnabled: boolean | null;
  npsSegmentedOptions: SaveFormItemSettingsMutation_saveFormItemSettings_npsSegmentedOptions | null;
  variables: (string | null)[] | null;
}

export interface SaveFormItemSettingsMutation {
  saveFormItemSettings: SaveFormItemSettingsMutation_saveFormItemSettings | null;
}

export interface SaveFormItemSettingsMutationVariables {
  data: FormItemSettingsInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaceFormItems
// ====================================================

export interface GetWorkspaceFormItems_formItems_settings_pictureChoices {
  image: string;
  label: string | null;
}

export interface GetWorkspaceFormItems_formItems_settings_npsSegmentedOptions {
  promoters: string | null;
  passives: string | null;
  detractors: string | null;
}

export interface GetWorkspaceFormItems_formItems_settings {
  required: boolean | null;
  descriptionEnabled: boolean | null;
  quotationMarks: boolean | null;
  alphabeticalOrder: boolean | null;
  maxSelectionsEnabled: boolean | null;
  maxSelectionsValue: number | null;
  otherOptionEnabled: boolean | null;
  verticalAlignEnabled: boolean | null;
  randomizeEnabled: boolean | null;
  multipleSelectionEnabled: boolean | null;
  maxCharactersEnabled: boolean | null;
  pictureChoiceLabelEnabled: boolean | null;
  buttonEnabled: boolean | null;
  pictureChoiceSuperSize: boolean | null;
  opinionScaleLabelEnabled: boolean | null;
  opinionScaleLabelLeft: string | null;
  opinionScaleLabelCenter: string | null;
  opinionScaleLabelRight: string | null;
  opinionScaleStartAt: number | null;
  opinionScaleFrom: number | null;
  opinionScaleTo: number | null;
  opinionScaleSteps: number | null;
  ratingSteps: number | null;
  choices: (string | null)[] | null;
  defaultChoices: (string | null)[] | null;
  pictureChoices: (GetWorkspaceFormItems_formItems_settings_pictureChoices | null)[] | null;
  defaultPictureChoices: (GetWorkspaceFormItems_formItems_settings_pictureChoices | null)[] | null;
  maxCharactersValue: number | null;
  ratingIconName: string | null;
  image: string | null;
  maxCharacters: number | null;
  buttonText: string | null;
  videoUrl: string | null;
  imageBrightness: number | null;
  layout: FormItemSettingsLayout | null;
  isNPS: boolean | null;
  isTelephone: boolean | null;
  npsSegmentedOptionsEnabled: boolean | null;
  npsSegmentedOptions: GetWorkspaceFormItems_formItems_settings_npsSegmentedOptions | null;
  variables: (string | null)[] | null;
}

export interface GetWorkspaceFormItems_formItems_logicJumps_conditions {
  _id: string | null;
  formItemId: string | null;
  and: boolean | null;
  or: boolean | null;
  condition: string | null;
  value: string | null;
}

export interface GetWorkspaceFormItems_formItems_logicJumps {
  _id: string | null;
  jumpTo: string | null;
  conditions: GetWorkspaceFormItems_formItems_logicJumps_conditions[] | null;
  createdAt: any | null;
}

export interface GetWorkspaceFormItems_formItems_calculations {
  _id: string | null;
  condition: CalculationCondition;
  addTo: string | null;
  addValue: number | null;
  addType: string | null;
  value: string | null;
}

export interface GetWorkspaceFormItems_formItems {
  _id: string;
  title: string | null;
  description: string | null;
  formType: string;
  order: number | null;
  hiddenFields: string[] | null;
  settings: GetWorkspaceFormItems_formItems_settings;
  logicJumps: GetWorkspaceFormItems_formItems_logicJumps[] | null;
  groupId: string | null;
  allCaseJumpTo: string | null;
  calculations: GetWorkspaceFormItems_formItems_calculations[] | null;
  totalComments: number | null;
  summaries: string | null;
  createdBy: string;
  updatedAt: any | null;
  createdAt: any;
  deletedAt: any | null;
}

export interface GetWorkspaceFormItems {
  formItems: (GetWorkspaceFormItems_formItems | null)[] | null;
}

export interface GetWorkspaceFormItemsVariables {
  formId: string;
  live?: boolean | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWorkspaceFormMutation
// ====================================================

export interface CreateWorkspaceFormMutation_createForm_theme {
  _id: string | null;
  answers: string;
  logoImage: string | null;
  backgroundImage: string | null;
  backgroundBrightness: number | null;
  backgroundScaleX: number | null;
  backgroundScaleY: number | null;
  backgroundTranslateX: number | null;
  backgroundTranslateY: number | null;
  fullWidth: boolean;
  fullHeight: boolean;
  background: string;
  buttons: string;
  fontFamily: string;
  questions: string;
  name: string;
}

export interface CreateWorkspaceFormMutation_createForm_connectedApps {
  name: string | null;
  payload: string | null;
  connected: boolean | null;
}

export interface CreateWorkspaceFormMutation_createForm_creator {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface CreateWorkspaceFormMutation_createForm_workspace_creator {
  _id: string;
  username: string | null;
  firstName: string | null;
  lastName: string | null;
  avatar: string | null;
}

export interface CreateWorkspaceFormMutation_createForm_workspace_members {
  _id: string;
}

export interface CreateWorkspaceFormMutation_createForm_workspace {
  _id: string;
  name: string;
  createdBy: string;
  createdAt: string;
  creator: CreateWorkspaceFormMutation_createForm_workspace_creator;
  members: (CreateWorkspaceFormMutation_createForm_workspace_members | null)[];
}

export interface CreateWorkspaceFormMutation_createForm {
  _id: string;
  name: string;
  createdAt: any;
  createdBy: string;
  totalResponses: number | null;
  themeKey: string;
  orders: string[] | null;
  theme: CreateWorkspaceFormMutation_createForm_theme | null;
  hash: string;
  hideBranding: boolean | null;
  embedType: string | null;
  embedWidth: string | null;
  embedWidthUnit: string | null;
  embedHeight: string | null;
  embedHeightUnit: string | null;
  embedButtonText: string | null;
  embedButtonColor: string | null;
  embedButtonFontSize: string | null;
  embedButtonBorderRadius: string | null;
  embedButtonAsText: boolean | null;
  embedLaunchPopupAutomatically: boolean | null;
  embedHideFooter: boolean | null;
  embedCloseOnComplete: boolean | null;
  closeFormToNewResponses: boolean | null;
  closeFormFromDate: any | null;
  closeFormFromDateEnable: boolean | null;
  responseLimitEnable: boolean | null;
  responseLimit: number | null;
  closeFormTitle: string | null;
  closeFormDescription: string | null;
  closeFormCustomEnable: boolean | null;
  closeFormImage: string | null;
  selfNotificationEnable: boolean | null;
  selfNotificationSendTo: string[] | null;
  selfNotificationReplyTo: string | null;
  selfNotificationSubject: string | null;
  selfNotificationMessage: string | null;
  respondentNotificationEnable: boolean | null;
  respondentNotificationSendTo: string | null;
  respondentNotificationReplyTo: string | null;
  respondentNotificationSubject: string | null;
  respondentNotificationMessage: string | null;
  priceCurrency: string | null;
  progressBarType: string | null;
  defaultFormLanguage: string | null;
  defaultFormTransition: string | null;
  defaultFormLayout: string | null;
  redirectOnComplete: string | null;
  customizedLabels: string | null;
  totalResponsesInThisMonth: number | null;
  connectedApps: (CreateWorkspaceFormMutation_createForm_connectedApps | null)[] | null;
  locales: string[] | null;
  labelTranslations: string | null;
  shouldRegenerateLocales: string[] | null;
  allowEditResponse: boolean | null;
  enableTrackingResponderIp: boolean | null;
  allowMultipleSubmissionPerUser: boolean | null;
  hasProtectedPassword: boolean | null;
  publishedAt: any | null;
  updatedAt: any | null;
  creator: CreateWorkspaceFormMutation_createForm_creator;
  workspace: CreateWorkspaceFormMutation_createForm_workspace;
}

export interface CreateWorkspaceFormMutation {
  createForm: CreateWorkspaceFormMutation_createForm | null;
}

export interface CreateWorkspaceFormMutationVariables {
  workspaceId: string;
  name: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitResponseMutation
// ====================================================

export interface SubmitResponseMutation_submitResponse {
  _id: string;
  formId: string;
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isOther: boolean;
  completedTimes: number;
  createdAt: any | null;
  deletedAt: any | null;
  prices: string;
  scores: string;
}

export interface SubmitResponseMutation {
  submitResponse: SubmitResponseMutation_submitResponse | null;
}

export interface SubmitResponseMutationVariables {
  data: SubmitResponseInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TrackingFormVisitorMutation
// ====================================================

export interface TrackingFormVisitorMutation_trackingFormVisitor {
  _id: string;
}

export interface TrackingFormVisitorMutation {
  trackingFormVisitor: TrackingFormVisitorMutation_trackingFormVisitor | null;
}

export interface TrackingFormVisitorMutationVariables {
  data: trackingFormVisitorInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormThemes
// ====================================================

export interface GetFormThemes_themes {
  _id: string | null;
  answers: string;
  logoImage: string | null;
  backgroundImage: string | null;
  backgroundBrightness: number | null;
  backgroundScaleX: number | null;
  backgroundScaleY: number | null;
  backgroundTranslateX: number | null;
  backgroundTranslateY: number | null;
  fullWidth: boolean;
  fullHeight: boolean;
  background: string;
  buttons: string;
  fontFamily: string;
  questions: string;
  name: string;
}

export interface GetFormThemes {
  themes: (GetFormThemes_themes | null)[] | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFormThemeMutation
// ====================================================

export interface CreateFormThemeMutation_createFormTheme {
  _id: string | null;
  answers: string;
  logoImage: string | null;
  backgroundImage: string | null;
  backgroundBrightness: number | null;
  backgroundScaleX: number | null;
  backgroundScaleY: number | null;
  backgroundTranslateX: number | null;
  backgroundTranslateY: number | null;
  fullWidth: boolean;
  fullHeight: boolean;
  background: string;
  buttons: string;
  fontFamily: string;
  questions: string;
  name: string;
}

export interface CreateFormThemeMutation {
  createFormTheme: CreateFormThemeMutation_createFormTheme | null;
}

export interface CreateFormThemeMutationVariables {
  data: CreateThemeInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateFormTranslationsMutation
// ====================================================

export interface CreateFormTranslationsMutation_createFormTranslation {
  _id: string;
  locales: string[] | null;
  labelTranslations: string | null;
}

export interface CreateFormTranslationsMutation {
  createFormTranslation: CreateFormTranslationsMutation_createFormTranslation | null;
}

export interface CreateFormTranslationsMutationVariables {
  id: string;
  locale: string;
  usingGoogleTranslate?: boolean | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFormItemTranslationMutation
// ====================================================

export interface UpdateFormItemTranslationMutation {
  updateFormItemTranslation: boolean | null;
}

export interface UpdateFormItemTranslationMutationVariables {
  id: string;
  formItemId: string;
  locale: string;
  title?: string | null;
  description?: string | null;
  choices?: string[] | null;
  pictureChoices?: (SaveFormItemMutation_saveFormItem_settings_pictureChoices | null)[] | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFormItemLabelTranslationMutation
// ====================================================

export interface UpdateFormItemLabelTranslationMutation_updateFormLabelTranslation {
  _id: string;
  labelTranslations: string | null;
}

export interface UpdateFormItemLabelTranslationMutation {
  updateFormLabelTranslation: UpdateFormItemLabelTranslationMutation_updateFormLabelTranslation | null;
}

export interface UpdateFormItemLabelTranslationMutationVariables {
  formId: string;
  locale: string;
  value: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFormTranslationMutation
// ====================================================

export interface DeleteFormTranslationMutation_deleteFormTranslation {
  _id: string;
  locales: string[] | null;
  labelTranslations: string | null;
}

export interface DeleteFormTranslationMutation {
  deleteFormTranslation: DeleteFormTranslationMutation_deleteFormTranslation | null;
}

export interface DeleteFormTranslationMutationVariables {
  id: string;
  locale: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RegenerateFormTranslationMutation
// ====================================================

export interface RegenerateFormTranslationMutation_createFormTranslation {
  _id: string;
  locales: string[] | null;
  labelTranslations: string | null;
}

export interface RegenerateFormTranslationMutation {
  createFormTranslation: RegenerateFormTranslationMutation_createFormTranslation | null;
}

export interface RegenerateFormTranslationMutationVariables {
  id: string;
  locale: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFormItemTranslations
// ====================================================

export interface GetFormItemTranslations_getFormItemTranslations {
  _id: string;
  title: string | null;
  description: string | null;
  choices: string[] | null;
  pictureChoices: (SaveFormItemMutation_saveFormItem_settings_pictureChoices | null)[] | null;
  locale: string | null;
  formId: string | null;
  formItemId: string | null;
}

export interface GetFormItemTranslations {
  getFormItemTranslations: (GetFormItemTranslations_getFormItemTranslations | null)[] | null;
}

export interface GetFormItemTranslationsVariables {
  formId: string;
  locale: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFormThemeMutation
// ====================================================

export interface UpdateFormThemeMutation_updateFormTheme {
  _id: string | null;
  answers: string;
  logoImage: string | null;
  backgroundImage: string | null;
  backgroundBrightness: number | null;
  backgroundScaleX: number | null;
  backgroundScaleY: number | null;
  backgroundTranslateX: number | null;
  backgroundTranslateY: number | null;
  fullWidth: boolean;
  fullHeight: boolean;
  background: string;
  buttons: string;
  fontFamily: string;
  questions: string;
  name: string;
}

export interface UpdateFormThemeMutation {
  updateFormTheme: UpdateFormThemeMutation_updateFormTheme | null;
}

export interface UpdateFormThemeMutationVariables {
  id: string;
  data: CreateThemeInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteThemeMutation
// ====================================================

export interface DeleteThemeMutation_deleteFormTheme_theme {
  _id: string | null;
  answers: string;
  logoImage: string | null;
  backgroundImage: string | null;
  backgroundBrightness: number | null;
  backgroundScaleX: number | null;
  backgroundScaleY: number | null;
  backgroundTranslateX: number | null;
  backgroundTranslateY: number | null;
  fullWidth: boolean;
  fullHeight: boolean;
  background: string;
  buttons: string;
  fontFamily: string;
  questions: string;
  name: string;
}

export interface DeleteThemeMutation_deleteFormTheme {
  _id: string;
  themeKey: string;
  theme: DeleteThemeMutation_deleteFormTheme_theme | null;
}

export interface DeleteThemeMutation {
  deleteFormTheme: DeleteThemeMutation_deleteFormTheme | null;
}

export interface DeleteThemeMutationVariables {
  id: string;
  formId: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateFormMutation
// ====================================================

export interface UpdateFormMutation_updateForm_theme {
  _id: string | null;
  answers: string;
  logoImage: string | null;
  backgroundImage: string | null;
  backgroundBrightness: number | null;
  backgroundScaleX: number | null;
  backgroundScaleY: number | null;
  backgroundTranslateX: number | null;
  backgroundTranslateY: number | null;
  fullWidth: boolean;
  fullHeight: boolean;
  background: string;
  buttons: string;
  fontFamily: string;
  questions: string;
  name: string;
}

export interface UpdateFormMutation_updateForm_connectedApps {
  name: string | null;
  payload: string | null;
  connected: boolean | null;
}

export interface UpdateFormMutation_updateForm {
  _id: string;
  name: string;
  createdAt: any;
  createdBy: string;
  totalResponses: number | null;
  themeKey: string;
  orders: string[] | null;
  theme: UpdateFormMutation_updateForm_theme | null;
  hash: string;
  hideBranding: boolean | null;
  embedType: string | null;
  embedWidth: string | null;
  embedWidthUnit: string | null;
  embedHeight: string | null;
  embedHeightUnit: string | null;
  embedButtonText: string | null;
  embedButtonColor: string | null;
  embedButtonFontSize: string | null;
  embedButtonBorderRadius: string | null;
  embedButtonAsText: boolean | null;
  embedLaunchPopupAutomatically: boolean | null;
  embedHideFooter: boolean | null;
  embedCloseOnComplete: boolean | null;
  closeFormToNewResponses: boolean | null;
  closeFormFromDate: any | null;
  closeFormFromDateEnable: boolean | null;
  responseLimitEnable: boolean | null;
  responseLimit: number | null;
  closeFormTitle: string | null;
  closeFormDescription: string | null;
  closeFormCustomEnable: boolean | null;
  closeFormImage: string | null;
  selfNotificationEnable: boolean | null;
  selfNotificationSendTo: string[] | null;
  selfNotificationReplyTo: string | null;
  selfNotificationSubject: string | null;
  selfNotificationMessage: string | null;
  respondentNotificationEnable: boolean | null;
  respondentNotificationSendTo: string | null;
  respondentNotificationReplyTo: string | null;
  respondentNotificationSubject: string | null;
  respondentNotificationMessage: string | null;
  priceCurrency: string | null;
  progressBarType: string | null;
  defaultFormLanguage: string | null;
  defaultFormTransition: string | null;
  defaultFormLayout: string | null;
  redirectOnComplete: string | null;
  customizedLabels: string | null;
  totalResponsesInThisMonth: number | null;
  connectedApps: (UpdateFormMutation_updateForm_connectedApps | null)[] | null;
  locales: string[] | null;
  labelTranslations: string | null;
  shouldRegenerateLocales: string[] | null;
  allowEditResponse: boolean | null;
  enableTrackingResponderIp: boolean | null;
  allowMultipleSubmissionPerUser: boolean | null;
  hasProtectedPassword: boolean | null;
  publishedAt: any | null;
  updatedAt: any | null;
}

export interface UpdateFormMutation {
  updateForm: UpdateFormMutation_updateForm | null;
}

export interface UpdateFormMutationVariables {
  id: string;
  data: UpdateFormInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DuplicateForm
// ====================================================

export interface DuplicateForm_duplicateForm {
  _id: string;
}

export interface DuplicateForm {
  duplicateForm: DuplicateForm_duplicateForm | null;
}

export interface DuplicateFormVariables {
  formId: string;
  workspaceId: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DuplicateFormItemMutation
// ====================================================

export interface DuplicateFormItemMutation_duplicateFormItem {
  _id: string;
  order: number | null;
}

export interface DuplicateFormItemMutation {
  duplicateFormItem: DuplicateFormItemMutation_duplicateFormItem | null;
}

export interface DuplicateFormItemMutationVariables {
  formItemId: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteFormItemMutation
// ====================================================

export interface DeleteFormItemMutation_deleteFormItem {
  _id: string;
}

export interface DeleteFormItemMutation {
  deleteFormItem: DeleteFormItemMutation_deleteFormItem | null;
}

export interface DeleteFormItemMutationVariables {
  id: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormStats
// ====================================================

export interface FormStats_formStats {
  averageResponseCompleteTime: number;
  completionRates: string;
  totalResponses: number;
  totalUniqueVisits: number;
  totalVisits: number;
}

export interface FormStats {
  formStats: FormStats_formStats | null;
}

export interface FormStatsVariables {
  id: string;
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isOther: boolean;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormResultsByFormItemId
// ====================================================

export interface FormResultsByFormItemId_formResponseResultsByFormItemId_edges {
  result: string;
  createdAt: any;
}

export interface FormResultsByFormItemId_formResponseResultsByFormItemId {
  count: number | null;
  edges: (FormResultsByFormItemId_formResponseResultsByFormItemId_edges | null)[] | null;
}

export interface FormResultsByFormItemId {
  formResponseResultsByFormItemId: FormResultsByFormItemId_formResponseResultsByFormItemId | null;
}

export interface FormResultsByFormItemIdVariables {
  formItemId: string;
  limit: number;
  skip: number;
  live?: boolean | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormResponseByFormId
// ====================================================

export interface FormResponseByFormId_formResponsesByFormId_edges_resultByFormItem {
  result: string;
}

export interface FormResponseByFormId_formResponsesByFormId_edges_results {
  _id: string;
  formItemId: string;
  createdAt: any;
  result: string;
}

export interface FormResponseByFormId_formResponsesByFormId_edges {
  _id: string;
  completedTimes: number;
  prices: string;
  scores: string;
  createdAt: any | null;
  resultByFormItem: FormResponseByFormId_formResponsesByFormId_edges_resultByFormItem | null;
  results: (FormResponseByFormId_formResponsesByFormId_edges_results | null)[] | null;
}

export interface FormResponseByFormId_formResponsesByFormId {
  count: number | null;
  edges: (FormResponseByFormId_formResponsesByFormId_edges | null)[] | null;
}

export interface FormResponseByFormId {
  formResponsesByFormId: FormResponseByFormId_formResponsesByFormId | null;
}

export interface FormResponseByFormIdVariables {
  formId: string;
  startDate?: any | null;
  endDate?: any | null;
  limit: number;
  skip: number;
  formItemId?: string | null;
  searchValue?: string | null;
  live?: boolean | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteResponseMutation
// ====================================================

export interface DeleteResponseMutation_deleteFormResponse_resultByFormItem {
  result: string;
}

export interface DeleteResponseMutation_deleteFormResponse_results {
  _id: string;
  formItemId: string;
  createdAt: any;
  result: string;
}

export interface DeleteResponseMutation_deleteFormResponse {
  _id: string;
  completedTimes: number;
  prices: string;
  scores: string;
  createdAt: any | null;
  resultByFormItem: DeleteResponseMutation_deleteFormResponse_resultByFormItem | null;
  results: (DeleteResponseMutation_deleteFormResponse_results | null)[] | null;
}

export interface DeleteResponseMutation {
  deleteFormResponse: DeleteResponseMutation_deleteFormResponse | null;
}

export interface DeleteResponseMutationVariables {
  id: string;
  formItemId: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnlockFormMutation
// ====================================================

export interface UnlockFormMutation {
  unlockForm: boolean | null;
}

export interface UnlockFormMutationVariables {
  formId: string;
  password: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormItemNPSReports
// ====================================================

export interface FormItemNPSReports_formItemNPSReports_stats {
  day: number | null;
  month: number | null;
  year: number | null;
  detractors: number | null;
  passives: number | null;
  promoters: number | null;
  score: number | null;
}

export interface FormItemNPSReports_formItemNPSReports {
  formItemId: string;
  score: number | null;
  detractors: number | null;
  passives: number | null;
  promoters: number | null;
  stats: FormItemNPSReports_formItemNPSReports_stats[] | null;
}

export interface FormItemNPSReports {
  formItemNPSReports: FormItemNPSReports_formItemNPSReports | null;
}

export interface FormItemNPSReportsVariables {
  formItemId: string;
  startDate?: string | null;
  endDate?: string | null;
  live?: boolean | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MarkNotificationAsRead
// ====================================================

export interface MarkNotificationAsRead {
  markNotificationsAsRead: boolean | null;
}

export interface MarkNotificationAsReadVariables {
  notificationIds: string[];
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteNotifications
// ====================================================

export interface DeleteNotifications {
  deleteNotifications: boolean | null;
}

export interface DeleteNotificationsVariables {
  notificationIds: string[];
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNotifications
// ====================================================

export interface GetNotifications_notifications_fromUser {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface GetNotifications_notifications {
  _id: string;
  fromUser: GetNotifications_notifications_fromUser;
  type: string;
  formattedContent: string;
  workspaceId: string | null;
  teamId: string | null;
  formId: string | null;
  formItemId: string | null;
  commentId: string | null;
  read: boolean | null;
  createdAt: any | null;
}

export interface GetNotifications {
  notifications: GetNotifications_notifications[] | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChooseUserPlan
// ====================================================

export interface ChooseUserPlan_chooseUserPlan {
  _id: string;
  orderId: string | null;
  plan: string | null;
  userId: string | null;
  teamId: string | null;
  expirationDate: any | null;
  responsesNeed: string | null;
  active: boolean | null;
  status: AccessKeyStatus | null;
  CODAddress: string | null;
  CODPhoneNumber: string | null;
  CODNotes: string | null;
  amount: number | null;
  paymentMethod: AccessKeyPaymentMethod | null;
  createdAt: any | null;
  updatedAt: any | null;
}

export interface ChooseUserPlan {
  chooseUserPlan: ChooseUserPlan_chooseUserPlan | null;
}

export interface ChooseUserPlanVariables {
  data: ChooseUserPlanInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmUserPlan
// ====================================================

export interface ConfirmUserPlan_confirmUserPlan {
  _id: string;
  orderId: string | null;
  plan: string | null;
  userId: string | null;
  teamId: string | null;
  expirationDate: any | null;
  responsesNeed: string | null;
  active: boolean | null;
  status: AccessKeyStatus | null;
  CODAddress: string | null;
  CODPhoneNumber: string | null;
  CODNotes: string | null;
  amount: number | null;
  paymentMethod: AccessKeyPaymentMethod | null;
  createdAt: any | null;
  updatedAt: any | null;
}

export interface ConfirmUserPlan {
  confirmUserPlan: ConfirmUserPlan_confirmUserPlan | null;
}

export interface ConfirmUserPlanVariables {
  accessKey: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChooseTeamPlan
// ====================================================

export interface ChooseTeamPlan_chooseTeamPlan {
  _id: string;
  orderId: string | null;
  plan: string | null;
  userId: string | null;
  teamId: string | null;
  expirationDate: any | null;
  responsesNeed: string | null;
  teamUser: string | null;
  active: boolean | null;
  status: AccessKeyStatus | null;
  CODAddress: string | null;
  CODPhoneNumber: string | null;
  CODNotes: string | null;
  amount: number | null;
  paymentMethod: AccessKeyPaymentMethod | null;
  createdAt: any | null;
  updatedAt: any | null;
}

export interface ChooseTeamPlan {
  chooseTeamPlan: ChooseTeamPlan_chooseTeamPlan | null;
}

export interface ChooseTeamPlanVariables {
  data: ChooseTeamPlanInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmTeamPlan
// ====================================================

export interface ConfirmTeamPlan_confirmTeamPlan {
  _id: string;
  orderId: string | null;
  plan: string | null;
  userId: string | null;
  teamId: string | null;
  expirationDate: any | null;
  responsesNeed: string | null;
  teamUser: string | null;
  active: boolean | null;
  status: AccessKeyStatus | null;
  CODAddress: string | null;
  CODPhoneNumber: string | null;
  CODNotes: string | null;
  amount: number | null;
  paymentMethod: AccessKeyPaymentMethod | null;
  createdAt: any | null;
  updatedAt: any | null;
}

export interface ConfirmTeamPlan {
  confirmTeamPlan: ConfirmTeamPlan_confirmTeamPlan | null;
}

export interface ConfirmTeamPlanVariables {
  teamId: string;
  accessKey: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTeamMutation
// ====================================================

export interface CreateTeamMutation_createTeam_members {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface CreateTeamMutation_createTeam_permissions {
  _id: string;
  teamId: string;
  memberId: string;
  canViewCreateTab: boolean | null;
  canViewShareTab: boolean | null;
  canViewConnectTab: boolean | null;
  canViewResultsTab: boolean | null;
  canEditCreateTab: boolean | null;
  canEditShareTab: boolean | null;
  canEditConnectTab: boolean | null;
  canEditResultsTab: boolean | null;
  canPublishForm: boolean | null;
}

export interface CreateTeamMutation_createTeam {
  _id: string;
  name: string;
  domain: string;
  logo: string | null;
  ownerId: string;
  memberIds: string[] | null;
  members: CreateTeamMutation_createTeam_members[] | null;
  permissions: CreateTeamMutation_createTeam_permissions[] | null;
  createdAt: any | null;
  updatedAt: any | null;
  deletedAt: any | null;
}

export interface CreateTeamMutation {
  createTeam: CreateTeamMutation_createTeam | null;
}

export interface CreateTeamMutationVariables {
  data: TeamInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTeamMutation
// ====================================================

export interface UpdateTeamMutation_updateTeam_members {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface UpdateTeamMutation_updateTeam_permissions {
  _id: string;
  teamId: string;
  memberId: string;
  canViewCreateTab: boolean | null;
  canViewShareTab: boolean | null;
  canViewConnectTab: boolean | null;
  canViewResultsTab: boolean | null;
  canEditCreateTab: boolean | null;
  canEditShareTab: boolean | null;
  canEditConnectTab: boolean | null;
  canEditResultsTab: boolean | null;
  canPublishForm: boolean | null;
}

export interface UpdateTeamMutation_updateTeam {
  _id: string;
  name: string;
  domain: string;
  logo: string | null;
  ownerId: string;
  memberIds: string[] | null;
  members: UpdateTeamMutation_updateTeam_members[] | null;
  permissions: UpdateTeamMutation_updateTeam_permissions[] | null;
  createdAt: any | null;
  updatedAt: any | null;
  deletedAt: any | null;
}

export interface UpdateTeamMutation {
  updateTeam: UpdateTeamMutation_updateTeam | null;
}

export interface UpdateTeamMutationVariables {
  teamId: string;
  data: TeamInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpgradePlan
// ====================================================

export interface UpgradePlan_upgradePlan {
  _id: string;
}

export interface UpgradePlan {
  upgradePlan: UpgradePlan_upgradePlan | null;
}

export interface UpgradePlanVariables {
  data: UpgradePlanInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApprovePlan
// ====================================================

export interface ApprovePlan_approvePlan {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  username: string | null;
  avatar: string | null;
}

export interface ApprovePlan {
  approvePlan: ApprovePlan_approvePlan | null;
}

export interface ApprovePlanVariables {
  userId: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeUserPlan
// ====================================================

export interface ChangeUserPlan_changePlan {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  username: string | null;
  avatar: string | null;
}

export interface ChangeUserPlan {
  changePlan: ChangeUserPlan_changePlan | null;
}

export interface ChangeUserPlanVariables {
  userId: string;
  plan: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsers
// ====================================================

export interface GetUsers_users_edges {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface GetUsers_users {
  count: number | null;
  edges: (GetUsers_users_edges | null)[] | null;
}

export interface GetUsers {
  users: GetUsers_users | null;
}

export interface GetUsersVariables {
  limit: number;
  skip: number;
  search?: string | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPublicUserByEmail
// ====================================================

export interface GetPublicUserByEmail_publicUser {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
}

export interface GetPublicUserByEmail {
  publicUser: GetPublicUserByEmail_publicUser | null;
}

export interface GetPublicUserByEmailVariables {
  email?: string | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetWorkspaces
// ====================================================

export interface GetWorkspaces_workspaces_creator {
  _id: string;
  username: string | null;
  avatar: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface GetWorkspaces_workspaces_members {
  _id: string;
  username: string | null;
  avatar: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface GetWorkspaces_workspaces {
  _id: string;
  name: string;
  creator: GetWorkspaces_workspaces_creator;
  members: (GetWorkspaces_workspaces_members | null)[];
}

export interface GetWorkspaces {
  workspaces: (GetWorkspaces_workspaces | null)[] | null;
}

export interface GetWorkspacesVariables {
  teamId?: string | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddWorkspaceMutation
// ====================================================

export interface AddWorkspaceMutation_createWorkspace_creator {
  _id: string;
  username: string | null;
  avatar: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface AddWorkspaceMutation_createWorkspace_members {
  _id: string;
  username: string | null;
  avatar: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface AddWorkspaceMutation_createWorkspace {
  _id: string;
  name: string;
  creator: AddWorkspaceMutation_createWorkspace_creator;
  members: (AddWorkspaceMutation_createWorkspace_members | null)[];
}

export interface AddWorkspaceMutation {
  createWorkspace: AddWorkspaceMutation_createWorkspace | null;
}

export interface AddWorkspaceMutationVariables {
  data: WorkspaceInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateWorkspaceMutation
// ====================================================

export interface UpdateWorkspaceMutation_updateWorkspace_creator {
  _id: string;
  username: string | null;
  avatar: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface UpdateWorkspaceMutation_updateWorkspace_members {
  _id: string;
  username: string | null;
  avatar: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface UpdateWorkspaceMutation_updateWorkspace {
  _id: string;
  name: string;
  creator: UpdateWorkspaceMutation_updateWorkspace_creator;
  members: (UpdateWorkspaceMutation_updateWorkspace_members | null)[];
}

export interface UpdateWorkspaceMutation {
  updateWorkspace: UpdateWorkspaceMutation_updateWorkspace | null;
}

export interface UpdateWorkspaceMutationVariables {
  id: string;
  data: WorkspaceInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserBaseFields
// ====================================================

export interface UserBaseFields {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TeamPermissionBaseFields
// ====================================================

export interface TeamPermissionBaseFields {
  _id: string;
  teamId: string;
  memberId: string;
  canViewCreateTab: boolean | null;
  canViewShareTab: boolean | null;
  canViewConnectTab: boolean | null;
  canViewResultsTab: boolean | null;
  canEditCreateTab: boolean | null;
  canEditShareTab: boolean | null;
  canEditConnectTab: boolean | null;
  canEditResultsTab: boolean | null;
  canPublishForm: boolean | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TeamBaseFields
// ====================================================

export interface TeamBaseFields_members {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface TeamBaseFields_permissions {
  _id: string;
  teamId: string;
  memberId: string;
  canViewCreateTab: boolean | null;
  canViewShareTab: boolean | null;
  canViewConnectTab: boolean | null;
  canViewResultsTab: boolean | null;
  canEditCreateTab: boolean | null;
  canEditShareTab: boolean | null;
  canEditConnectTab: boolean | null;
  canEditResultsTab: boolean | null;
  canPublishForm: boolean | null;
}

export interface TeamBaseFields {
  _id: string;
  name: string;
  domain: string;
  logo: string | null;
  ownerId: string;
  memberIds: string[] | null;
  members: TeamBaseFields_members[] | null;
  permissions: TeamBaseFields_permissions[] | null;
  createdAt: any | null;
  updatedAt: any | null;
  deletedAt: any | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AccessKeyBaseFields
// ====================================================

export interface AccessKeyBaseFields {
  _id: string;
  orderId: string | null;
  plan: string | null;
  userId: string | null;
  teamId: string | null;
  expirationDate: any | null;
  responsesNeed: string | null;
  teamUser: string | null;
  active: boolean | null;
  status: AccessKeyStatus | null;
  CODAddress: string | null;
  CODPhoneNumber: string | null;
  CODNotes: string | null;
  amount: number | null;
  paymentMethod: AccessKeyPaymentMethod | null;
  createdAt: any | null;
  updatedAt: any | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WorkspaceBaseFields
// ====================================================

export interface WorkspaceBaseFields_creator {
  _id: string;
  username: string | null;
  firstName: string | null;
  lastName: string | null;
  avatar: string | null;
}

export interface WorkspaceBaseFields_members {
  _id: string;
}

export interface WorkspaceBaseFields {
  _id: string;
  name: string;
  createdBy: string;
  createdAt: string;
  creator: WorkspaceBaseFields_creator;
  members: (WorkspaceBaseFields_members | null)[];
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormBaseFields
// ====================================================

export interface FormBaseFields_theme {
  _id: string | null;
  answers: string;
  logoImage: string | null;
  backgroundImage: string | null;
  backgroundBrightness: number | null;
  backgroundScaleX: number | null;
  backgroundScaleY: number | null;
  backgroundTranslateX: number | null;
  backgroundTranslateY: number | null;
  fullWidth: boolean;
  fullHeight: boolean;
  background: string;
  buttons: string;
  fontFamily: string;
  questions: string;
  name: string;
}

export interface FormBaseFields_connectedApps {
  name: string | null;
  payload: string | null;
  connected: boolean | null;
}

export interface FormBaseFields {
  _id: string;
  name: string;
  createdAt: any;
  createdBy: string;
  totalResponses: number | null;
  themeKey: string;
  orders: string[] | null;
  theme: FormBaseFields_theme | null;
  hash: string;
  hideBranding: boolean | null;
  embedType: string | null;
  embedWidth: string | null;
  embedWidthUnit: string | null;
  embedHeight: string | null;
  embedHeightUnit: string | null;
  embedButtonText: string | null;
  embedButtonColor: string | null;
  embedButtonFontSize: string | null;
  embedButtonBorderRadius: string | null;
  embedButtonAsText: boolean | null;
  embedLaunchPopupAutomatically: boolean | null;
  embedHideFooter: boolean | null;
  embedCloseOnComplete: boolean | null;
  closeFormToNewResponses: boolean | null;
  closeFormFromDate: any | null;
  closeFormFromDateEnable: boolean | null;
  responseLimitEnable: boolean | null;
  responseLimit: number | null;
  closeFormTitle: string | null;
  closeFormDescription: string | null;
  closeFormCustomEnable: boolean | null;
  closeFormImage: string | null;
  selfNotificationEnable: boolean | null;
  selfNotificationSendTo: string[] | null;
  selfNotificationReplyTo: string | null;
  selfNotificationSubject: string | null;
  selfNotificationMessage: string | null;
  respondentNotificationEnable: boolean | null;
  respondentNotificationSendTo: string | null;
  respondentNotificationReplyTo: string | null;
  respondentNotificationSubject: string | null;
  respondentNotificationMessage: string | null;
  priceCurrency: string | null;
  progressBarType: string | null;
  defaultFormLanguage: string | null;
  defaultFormTransition: string | null;
  defaultFormLayout: string | null;
  redirectOnComplete: string | null;
  customizedLabels: string | null;
  totalResponsesInThisMonth: number | null;
  connectedApps: (FormBaseFields_connectedApps | null)[] | null;
  locales: string[] | null;
  labelTranslations: string | null;
  shouldRegenerateLocales: string[] | null;
  allowEditResponse: boolean | null;
  enableTrackingResponderIp: boolean | null;
  allowMultipleSubmissionPerUser: boolean | null;
  hasProtectedPassword: boolean | null;
  publishedAt: any | null;
  updatedAt: any | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CommentBaseFields
// ====================================================

export interface CommentBaseFields_creator {
  _id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  username: string | null;
  avatar: string | null;
  dob: any | null;
  gender: Gender | null;
  createdAt: any | null;
  updatedAt: any | null;
  lastLoginAt: any | null;
  lastLoginIP: string | null;
}

export interface CommentBaseFields {
  _id: string | null;
  ref: CommentRef | null;
  refId: string;
  content: string | null;
  photos: (string | null)[] | null;
  createdAt: any | null;
  creator: CommentBaseFields_creator | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormItemSettingBaseFields
// ====================================================

export interface FormItemSettingBaseFields_pictureChoices {
  image: string;
  label: string | null;
}

export interface FormItemSettingBaseFields_npsSegmentedOptions {
  promoters: string | null;
  passives: string | null;
  detractors: string | null;
}

export interface FormItemSettingBaseFields {
  required: boolean | null;
  descriptionEnabled: boolean | null;
  quotationMarks: boolean | null;
  alphabeticalOrder: boolean | null;
  maxSelectionsEnabled: boolean | null;
  maxSelectionsValue: number | null;
  otherOptionEnabled: boolean | null;
  verticalAlignEnabled: boolean | null;
  randomizeEnabled: boolean | null;
  multipleSelectionEnabled: boolean | null;
  maxCharactersEnabled: boolean | null;
  pictureChoiceLabelEnabled: boolean | null;
  buttonEnabled: boolean | null;
  pictureChoiceSuperSize: boolean | null;
  opinionScaleLabelEnabled: boolean | null;
  opinionScaleLabelLeft: string | null;
  opinionScaleLabelCenter: string | null;
  opinionScaleLabelRight: string | null;
  opinionScaleStartAt: number | null;
  opinionScaleFrom: number | null;
  opinionScaleTo: number | null;
  opinionScaleSteps: number | null;
  ratingSteps: number | null;
  choices: (string | null)[] | null;
  defaultChoices: (string | null)[] | null;
  pictureChoices: (FormItemSettingBaseFields_pictureChoices | null)[] | null;
  defaultPictureChoices: (FormItemSettingBaseFields_pictureChoices | null)[] | null;
  maxCharactersValue: number | null;
  ratingIconName: string | null;
  image: string | null;
  maxCharacters: number | null;
  buttonText: string | null;
  videoUrl: string | null;
  imageBrightness: number | null;
  layout: FormItemSettingsLayout | null;
  isNPS: boolean | null;
  isTelephone: boolean | null;
  npsSegmentedOptionsEnabled: boolean | null;
  npsSegmentedOptions: FormItemSettingBaseFields_npsSegmentedOptions | null;
  variables: (string | null)[] | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FormItemBaseFields
// ====================================================

export interface FormItemBaseFields_settings_pictureChoices {
  image: string;
  label: string | null;
}

export interface FormItemBaseFields_settings_npsSegmentedOptions {
  promoters: string | null;
  passives: string | null;
  detractors: string | null;
}

export interface FormItemBaseFields_settings {
  required: boolean | null;
  descriptionEnabled: boolean | null;
  quotationMarks: boolean | null;
  alphabeticalOrder: boolean | null;
  maxSelectionsEnabled: boolean | null;
  maxSelectionsValue: number | null;
  otherOptionEnabled: boolean | null;
  verticalAlignEnabled: boolean | null;
  randomizeEnabled: boolean | null;
  multipleSelectionEnabled: boolean | null;
  maxCharactersEnabled: boolean | null;
  pictureChoiceLabelEnabled: boolean | null;
  buttonEnabled: boolean | null;
  pictureChoiceSuperSize: boolean | null;
  opinionScaleLabelEnabled: boolean | null;
  opinionScaleLabelLeft: string | null;
  opinionScaleLabelCenter: string | null;
  opinionScaleLabelRight: string | null;
  opinionScaleStartAt: number | null;
  opinionScaleFrom: number | null;
  opinionScaleTo: number | null;
  opinionScaleSteps: number | null;
  ratingSteps: number | null;
  choices: (string | null)[] | null;
  defaultChoices: (string | null)[] | null;
  pictureChoices: (FormItemBaseFields_settings_pictureChoices | null)[] | null;
  defaultPictureChoices: (FormItemBaseFields_settings_pictureChoices | null)[] | null;
  maxCharactersValue: number | null;
  ratingIconName: string | null;
  image: string | null;
  maxCharacters: number | null;
  buttonText: string | null;
  videoUrl: string | null;
  imageBrightness: number | null;
  layout: FormItemSettingsLayout | null;
  isNPS: boolean | null;
  isTelephone: boolean | null;
  npsSegmentedOptionsEnabled: boolean | null;
  npsSegmentedOptions: FormItemBaseFields_settings_npsSegmentedOptions | null;
  variables: (string | null)[] | null;
}

export interface FormItemBaseFields_logicJumps_conditions {
  _id: string | null;
  formItemId: string | null;
  and: boolean | null;
  or: boolean | null;
  condition: string | null;
  value: string | null;
}

export interface FormItemBaseFields_logicJumps {
  _id: string | null;
  jumpTo: string | null;
  conditions: FormItemBaseFields_logicJumps_conditions[] | null;
  createdAt: any | null;
}

export interface FormItemBaseFields_calculations {
  _id: string | null;
  condition: CalculationCondition;
  addTo: string | null;
  addValue: number | null;
  addType: string | null;
  value: string | null;
}

export interface FormItemBaseFields {
  _id: string;
  title: string | null;
  description: string | null;
  formType: string;
  order: number | null;
  hiddenFields: string[] | null;
  settings: FormItemBaseFields_settings;
  logicJumps: FormItemBaseFields_logicJumps[] | null;
  groupId: string | null;
  allCaseJumpTo: string | null;
  calculations: FormItemBaseFields_calculations[] | null;
  totalComments: number | null;
  summaries: string | null;
  createdBy: string;
  updatedAt: any | null;
  createdAt: any;
  deletedAt: any | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHERS = "OTHERS",
}

export enum CommentRef {
  FORM_ITEM = "FORM_ITEM",
}

export enum CalculationCondition {
  ALWAYS = "ALWAYS",
  IF = "IF",
}

export enum FormItemSettingsLayout {
  ASSET_BACKGROUND_FULL = "ASSET_BACKGROUND_FULL",
  ASSET_BACKGROUND_LEFT = "ASSET_BACKGROUND_LEFT",
  ASSET_BACKGROUND_RIGHT = "ASSET_BACKGROUND_RIGHT",
  ASSET_LEFT = "ASSET_LEFT",
  ASSET_RIGHT = "ASSET_RIGHT",
  NORMAL = "NORMAL",
}

export enum AccessKeyStatus {
  COD_PROCESSING = "COD_PROCESSING",
  PAID = "PAID",
  WAITING_FOR_BANK_TRANSFER = "WAITING_FOR_BANK_TRANSFER",
  WAITING_FOR_COD = "WAITING_FOR_COD",
}

export enum AccessKeyPaymentMethod {
  BANK_TRANSFER = "BANK_TRANSFER",
  COD = "COD",
  NONE = "NONE",
  STRIPE = "STRIPE",
}

// 
export interface SendOTPInput {
  email: string;
}

// 
export interface ConfirmOTPInput {
  email: string;
  otpCode: string;
}

// 
export interface ConfirmInformationInput {
  firstName: string;
  lastName: string;
  gender: Gender;
  username: string;
}

// 
export interface CommentInput {
  _id?: string | null;
  refId: string;
  ref: CommentRef;
  content?: string | null;
  photos?: string[] | null;
  mentionedUserIds?: string[] | null;
}

// 
export interface CreateDiscountInput {
  code: string;
  discount: number;
  plan: string;
  billedBy: string;
}

// 
export interface FormItemInput {
  id?: string | null;
  formId: string;
  createFrom?: string | null;
  groupId?: string | null;
  title?: string | null;
  description?: string | null;
  formType: string;
  hiddenFields?: string[] | null;
  logicJumps?: (LogicJumpInput | null)[] | null;
  calculations?: CalculationInput[] | null;
  allCaseJumpTo?: string | null;
}

// 
export interface LogicJumpInput {
  _id?: string | null;
  jumpTo?: string | null;
  conditions?: (LogicJumpConditionInput | null)[] | null;
}

// 
export interface LogicJumpConditionInput {
  _id?: string | null;
  formItemId?: string | null;
  and?: boolean | null;
  or?: boolean | null;
  condition?: string | null;
  value?: string | null;
}

// 
export interface CalculationInput {
  _id?: string | null;
  condition: CalculationCondition;
  value?: string | null;
  addType?: string | null;
  addValue?: number | null;
  addTo?: string | null;
}

// 
export interface FormItemSettingsInput {
  formItemId: string;
  required?: boolean | null;
  descriptionEnabled?: boolean | null;
  quotationMarks?: boolean | null;
  alphabeticalOrder?: boolean | null;
  maxSelectionsEnabled?: boolean | null;
  maxSelectionsValue?: number | null;
  otherOptionEnabled?: boolean | null;
  verticalAlignEnabled?: boolean | null;
  maxCharactersEnabled?: boolean | null;
  pictureChoiceLabelEnabled?: boolean | null;
  randomizeEnabled?: boolean | null;
  buttonEnabled?: boolean | null;
  multipleSelectionEnabled?: boolean | null;
  pictureChoiceSuperSize?: boolean | null;
  opinionScaleLabelEnabled?: boolean | null;
  opinionScaleLabelLeft?: string | null;
  opinionScaleLabelCenter?: string | null;
  opinionScaleLabelRight?: string | null;
  opinionScaleStartAt?: number | null;
  opinionScaleFrom?: number | null;
  opinionScaleTo?: number | null;
  ratingSteps?: number | null;
  ratingIconName?: string | null;
  image?: string | null;
  imageBrightness?: number | null;
  layout?: FormItemSettingsLayout | null;
  maxCharactersValue?: number | null;
  opinionScaleSteps?: number | null;
  choices?: (string | null)[] | null;
  defaultChoices?: (string | null)[] | null;
  pictureChoices?: (PictureChoiceSettingInput | null)[] | null;
  defaultPictureChoices?: (PictureChoiceSettingInput | null)[] | null;
  maxCharacters?: number | null;
  buttonText?: string | null;
  videoUrl?: string | null;
  isNPS?: boolean | null;
  isTelephone?: boolean | null;
  npsSegmentedOptionsEnabled?: boolean | null;
  npsSegmentedOptions?: NPSSegmentedOptionsInput | null;
  variables?: (string | null)[] | null;
}

// 
export interface PictureChoiceSettingInput {
  image: string;
  label?: string | null;
}

// 
export interface NPSSegmentedOptionsInput {
  promoters?: string | null;
  passives?: string | null;
  detractors?: string | null;
}

// 
export interface SubmitResponseInput {
  _id?: string | null;
  formId: string;
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isOther: boolean;
  completedTimes: number;
  isTestMode: boolean;
  prices: string;
  scores: string;
  results: SubmitResponseResultInput[];
}

// 
export interface SubmitResponseResultInput {
  formId: string;
  formItemId: string;
  formType: string;
  result?: string | null;
  resultIndex?: string | null;
}

// 
export interface trackingFormVisitorInput {
  formId: string;
  uniqueVisitor: boolean;
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isOther: boolean;
  isTestMode: boolean;
}

// 
export interface CreateThemeInput {
  name: string;
  fontFamily: string;
  questions: string;
  answers: string;
  buttons: string;
  background: string;
  logoImage?: string | null;
  backgroundImage?: string | null;
  backgroundBrightness: number | null;
  backgroundScaleX?: number | null;
  backgroundScaleY?: number | null;
  backgroundTranslateX?: number | null;
  backgroundTranslateY?: number | null;
  fullWidth: boolean;
  fullHeight: boolean;
}

// 
export interface UpdateFormInput {
  name?: string | null;
  priceCurrency?: string | null;
  themeKey?: string | null;
  customizedLabels?: string | null;
  redirectOnComplete?: string | null;
  progressBarType?: string | null;
  defaultFormLanguage?: string | null;
  defaultFormTransition?: string | null;
  defaultFormLayout?: string | null;
  hideBranding?: boolean | null;
  embedType?: string | null;
  embedWidth?: string | null;
  embedWidthUnit?: string | null;
  embedHeight?: string | null;
  embedHeightUnit?: string | null;
  embedButtonText?: string | null;
  embedButtonColor?: string | null;
  embedButtonFontSize?: string | null;
  embedButtonBorderRadius?: string | null;
  embedButtonAsText?: boolean | null;
  embedLaunchPopupAutomatically?: boolean | null;
  embedHideFooter?: boolean | null;
  embedCloseOnComplete?: boolean | null;
  closeFormToNewResponses?: boolean | null;
  closeFormFromDateEnable?: boolean | null;
  closeFormFromDate?: any | null;
  responseLimitEnable?: boolean | null;
  closeFormCustomEnable?: boolean | null;
  responseLimit?: number | null;
  closeFormTitle?: string | null;
  closeFormDescription?: string | null;
  closeFormImage?: string | null;
  selfNotificationEnable?: boolean | null;
  selfNotificationSendTo?: string[] | null;
  selfNotificationReplyTo?: string | null;
  selfNotificationSubject?: string | null;
  selfNotificationMessage?: string | null;
  respondentNotificationEnable?: boolean | null;
  respondentNotificationSendTo?: string | null;
  respondentNotificationReplyTo?: string | null;
  respondentNotificationSubject?: string | null;
  respondentNotificationMessage?: string | null;
  allowEditResponse?: boolean | null;
  enableTrackingResponderIp?: boolean | null;
  allowMultipleSubmissionPerUser?: boolean | null;
  protectedPassword?: string | null;
  connectedApp?: ConnectedAppInput | null;
}

// 
export interface ConnectedAppInput {
  name?: string | null;
  payload?: string | null;
  connected?: boolean | null;
}

// 
export interface ChooseUserPlanInput {
  plan: string;
  paymentMethod: AccessKeyPaymentMethod;
  expirationDate: number;
  responsesNeed: string;
  stripeToken?: string | null;
  discountCode?: string | null;
  CODAddress?: string | null;
  CODPhoneNumber?: string | null;
  CODNotes?: string | null;
}

// 
export interface ChooseTeamPlanInput {
  teamId: string;
  plan: string;
  paymentMethod: AccessKeyPaymentMethod;
  expirationDate: number;
  responsesNeed: string | null;
  teamUser: string | null;
  stripeToken?: string | null;
  discountCode?: string | null;
  CODAddress?: string | null;
  CODPhoneNumber?: string | null;
  CODNotes?: string | null;
}

// 
export interface TeamInput {
  name: string;
  domain: string;
  logo?: string | null;
  members?: TeamPermissionInput[] | null;
}

// 
export interface TeamPermissionInput {
  teamId?: string | null;
  memberId: string;
  canViewCreateTab: boolean;
  canViewShareTab: boolean;
  canViewConnectTab: boolean;
  canViewResultsTab: boolean;
  canEditCreateTab: boolean;
  canEditShareTab: boolean;
  canEditConnectTab: boolean;
  canEditResultsTab: boolean;
  canPublishForm: boolean;
}

// 
export interface UpgradePlanInput {
  plan: string;
  billedBy: string;
  discountCode?: string | null;
  amount: number;
}

// 
export interface WorkspaceInput {
  name?: string | null;
  teamId?: string | null;
  memberIds?: string[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================