import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { isMobile } from 'utils/helperFunctions';
import { Link } from 'react-router-dom';
// import { AppContext } from '@formtory/shared/contexts/AppContext';
// import { useContext } from 'react';
import AuthenticationStep from 'containers/Authentication/AuthenticationStep';
import { useContext } from 'react';
import { AppContext } from '@formtory/shared/contexts/AppContext';

// import Cover from '../../assets/images/authentication-cover.svg';

interface Props {
  title: string;
  description?: string;
  cover?: string;
  left?: number;
  right?: number;
  children: React.ReactElement;
  step?: number;
}

export const StyledIntro = styled.div`
// position: fixed;
  height: 100vh;
  background: linear-gradient(0deg, #16388a, #16388a), #042282;
  color: #fff;
  padding: 64px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  img {
    width: 80%;
  }
  h1{
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  .ant-steps-item-title {
    color: #fff!important;
    max-width: 768px;
    font-size: 16px;
    line-height: 20px;
    padding-left: 10px;
    white-space: pre-line;
    // margin-bottom: 16px;
    font-weight: 600;
  }
  .ant-steps-item-description {
    color: #fff!important;
    max-width: 768px;
    font-size: 14px;
    line-height: 18px;
    padding-left: 10px;
    white-space: pre-line;
    margin-bottom: 16px;
    font-weight: 400;
  }
  ul {
    max-width: 768px;
    li {
      font-size: 16px;
      line-height: 24px;
      padding-left: 10px;
      white-space: pre-line;
      margin-bottom: 16px;
      font-weight: 600;
    }
  }
  .svgWrapper-2-4f9 {
    display: inline-block;
    transition: color 0.3s;
    position: absolute;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .candy-18ETa {
    bottom: -5px;
    left: 0;
  }
  .sonic-1w6RA {
    right: 0;
    top: 0;
  }
  .style__Logo {
    color: inherit;
    transition: opacity 0.2s ease 0s;
    position: absolute;
    left: 0;
    top: 0;
    height: 60px;
    padding: 16px;
    .wordmark {
      font-size: 18px;
      font-weight: 600;
    }
  }
`;

export const StyledAuthenticationContainer = styled.div`
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  background: #fff;
  @media (min-width: 320px) {
    padding: 8px;
  }

  @media (min-width: 768px) {
    padding: 16px;
  }
  img {
    margin-bottom: 12px;
  }
  h2 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 0;
  }
  h4 {
    font-weight: 300;
    margin-bottom: 16px;
  }
  form {
    margin-top: 20px;
    width: 100%;
  }
  // .wrapper {
  //   display: flex;
  //   justify-content: center;
  // }
  .terms {
    padding-right: 78px;
    margin-top: 32px;
    color: ${(props) => props.theme.colors.grey};
  }
  .email-group {
    .ant-form-item-children {
      display: flex;
      align-items: center;
      input.ant-input {
        margin-right: 16px;
        font-size: 22px;
        border-width: 3px !important;
        outline: none;
        padding-left: 16px;
        height: 64px;
        box-shadow: none;
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }
  input.ant-input {
    font-size: 22px;
    border-width: 3px !important;
    outline: none;
    height: 64px;
    padding-left: 16px;
    box-shadow: none;
    color: ${(props) => props.theme.colors.primary};
  }
  button {
    padding: 0;
    min-width: 64px;
    height: 64px;
    i {
      font-size: 22px;
    }
  }
  .ant-radio-wrapper {
    zoom: 1.5;
  }
  .ant-input-group-addon:first-child {
    border-width: 3px !important;
    font-size: 22px;
    color: ${(props) => props.theme.colors.primary};
  }
  .ant-input-group-addon:last-child {
    border-width: 3px !important;
    font-size: 22px;
    color: ${(props) => props.theme.colors.primary};
  }
  .ant-alert {
    margin-bottom: 16px;
  }
  .ant-alert-warning {
    background-color: ${(props) => props.theme.colors.orange};
    border-color: ${(props) => props.theme.colors.orange};
    color: white;
    height: 64px;
    .ant-alert-message {
      color: white;
    }
  }
  .ant-alert.ant-alert-no-icon {
    padding: 20px 15px;
  }
  .ant-alert-close-icon {
    top: 20px;
  }
  p.label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 8px;
  }
  .styles_react-code-input-container__tpiKG {
    margin-right: 16px;
    // width: 100% !important;
    input {
      border-width: 2px !important;
      font-family: inherit !important;
      font-size: 30px;
      color: ${(props) => props.theme.colors.primary};
    }
    input:focus {
      outline: none;
      border: 2px solid ${(props) => props.theme.colors.primary};
      caret-color: ${(props) => props.theme.colors.primary};
    }
  }
  
`;

export const StyledContextAuth = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  border: 3px solid !important;
  outline: none;
  // height: 64px;
  box-shadow: none;
  color: #643ced;
  // width: 40vw;
  padding: 4px 16px;
  border-radius: 20px;
  margin-bottom: 16px;
`;

const StyledLogo = styled(Link)`
  color: #643ced;
  transition: opacity 0.2s ease 0s;
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  padding: 16px;
  .wordmark {
    font-size: 18px;
    font-weight: 600;
    }
`;

const StyledBrandHeader = styled.div`
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButtonHelp = styled.button`
  border: 2px solid #eee;
  cursor: pointer;
  padding: 0;
  width: auto;
  height: 40px;
  position: fixed;
  background: #fff;
  border-radius: 6px;
  
  padding: 8px;
  @media (min-width: 320px) {
    left: 10px;
    bottom: 10px;
    margin: 10px auto;
  }

  @media (min-width: 768px) {
    left: 20px;
    bottom: 20px;
    margin: 15px auto;
  }
  i {
    font-size: 18px !important;
    margin-right: 4px !important;
  }
  .badge {
    width: 6px;
    height: 6px;
    background-color: ${(props) => props.theme.colors.red};
    position: absolute;
    top: 3px;
    right: 3px;
    z-index: 1;
    border-radius: 50%;
  }

`;

const Brand = React.memo(() => (
  <StyledLogo to={'/'} className="style__Logo">
    <span className="wordmark">Formtory</span>
  </StyledLogo>
));

const Authentication = (props: Props) => {
  const context = useContext(AppContext);
  const {
    intl: { formatMessage },
  } = context;
  return (
    <div>
      <Row>
        {/* {!isMobile() &&
          <Col md={{ span: 0 }} xs={{ span: 0 }}>
            <StyledIntro style={props.step === 2 ? { position: 'fixed' } : null}>
              <Brand />
              {!props.step && (
                <>
                  <h1>{props.title}</h1>
                  <ul>
                    <li>{formatMessage({ id: 'GatherFeedbackAndData' })}</li>
                    <li>{formatMessage({ id: 'PutItToWork' })}</li>
                    <li>{formatMessage({ id: 'PowerfulFlexibleSecure' })}</li>
                  </ul>
                </>
              )}
              {props.step && <AuthenticationStep current={props.step} />}
              <div className="svgWrapper-2-4f9 candy-18ETa">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={90}
                  height={66}
                  viewBox="0 0 90 66"
                  fill="none"
                  className="injected-svg"
                  data-src="https://static.crello.com/images/modals/auth/candy.svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 5.05V66h90c-2.634-10.05-6.528-20.025-11.74-29.672C57.54-2.017 30.526-5.282 0 5.049z"
                    fill="#EA4D33"
                  />
                </svg>
              </div>
              <div className="svgWrapper-2-4f9 sonic-1w6RA">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={111}
                  height={66}
                  viewBox="0 0 111 66"
                  fill="none"
                  className="injected-svg"
                  data-src="https://static.crello.com/images/modals/auth/sonic.svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 0c5.87 7.923 13.502 15.094 21.018 22.155 1.992 1.872 3.976 3.735 5.916 5.603 3.222 3.1 6.438 6.454 9.687 9.841C51.478 53.09 67.026 69.3 86.94 65.417c10.986-2.142 18.336-9.585 24.06-19.187V0H0z"
                    fill="#2153CC"
                  />
                </svg>
              </div>
            </StyledIntro>
          </Col>
        } */}
        <Col md={!isMobile() ? { span: 24 } : { span: 24 }} xs={{ span: 24 }}>
          <StyledBrandHeader>
            <Brand />
            {props.step && <AuthenticationStep current={props.step} />}
          </StyledBrandHeader>
          {/* <a href="https://admin.formtory.com/" className="style__Logo">
                <span className="wordmark">Formtory</span>
              </a> */}
          {/* <img src={props.cover || Cover} alt="" /> */}
          {props.children}
          <StyledButtonHelp
            className="gist-open-chat"
          >
            <span>{formatMessage({ id: 'needHelp' })}</span>
          </StyledButtonHelp>
        </Col>

      </Row>
    </div >
  );
};

export default Authentication;
