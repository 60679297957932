import React, { useState, useEffect, useCallback } from 'react';
import { DatePicker } from 'antd';
import styled from 'styled-components';
import Title from './Elements/Title';
import Description from './Elements/Description';
import { FormItemBaseWrappedChildProps } from './FormItemBase';
import { StyledQuestionContainer, StyledErrorMessage, StyledQuestionContainerContent } from './Styled';
import { getSetting } from './Utils';
import QuestionLayout from './QuestionLayout';
import { FormItemSettingsLayout } from '../../graphql/types';
import Image from './Elements/Image';
import Video from './Elements/Video';
import Index from './Elements/Index';
import ButtonGroup from './Elements/ButtonGroup';
import Submit from './Submit';
import moment from 'moment';
// import debounce from 'lodash/debounce';

const StyledDatePicker = styled(DatePicker)`
  background: transparent;
  &:focus,
  &:hover {
    .ant-calendar-picker-input:not(.ant-input-disabled) {
      border-color: ${(props) => props.theme.form.questions};
    }
    i.anticon {
      display: none;
    }
  }
  .ant-calendar-picker-input {
    background: transparent;
    width: 100%;
    min-width: 500px;
    @media (max-width: 480px) {
      min-width: 80vw;
    }
    font-size: 22px;
    font-family: ${(props) => props.theme.form.fontFamily};
    color: ${(props) => props.theme.form.answers};
    border: none;
    border-bottom: 2px solid;
    border-color: ${(props) => props.theme.form.answers}66;
    box-shadow: 0 0 0 0;
    &:focus,
    &:hover {
      border-color: ${(props) => props.theme.form.answers};
      box-shadow: 0 0 0 0;
    }
    ::placeholder {
      font-size: 22px;
      font-weight: unset;
      font-family: ${(props) => props.theme.form.fontFamily};
      color: ${(props) => props.theme.form.answers}66;
      opacity: 0.7;
    }
  }
  i.anticon {
    display: none;
  }
`;

const Date = (props: FormItemBaseWrappedChildProps) => {
  const { formItem, index, customizedLabels, previewing, prices, scores, priceCurrency, isNextSubmit, onSubmit, submitting, formLayout } = props;
  const { settings } = formItem;
  const [result, setResult] = useState(props.results[formItem._id] ? moment(props.results[formItem._id]) : null);
  const [hasError, setHasError] = useState(props.submitted && settings.required && !result);
  useEffect(() => {
    if (props.submitted && settings.required && !result) return setHasError(true);
  });

  const onSaveResult = (value) => {
    if (formLayout === 'classic' ? !value : !result && settings.required) return null;
    setTimeout(() => {
      if (formLayout === 'classic' ? !value : !result && settings.required) return null;
      return props.onResult(formItem._id, formLayout === 'classic' ? value : result);
    }, 200);
  };

  return (
    <QuestionLayout
      videoUrl={settings.videoUrl}
      image={settings.image}
      hideImage
      layout={settings.layout}
      imageBrightness={settings.imageBrightness}
      formLayout={formLayout}
    >
      <StyledQuestionContainer style={props.device === 'mobile' ? { justifyContent: 'center' } : {}}>
        <Index renderIndex={index} required={settings.required} />
        <StyledQuestionContainerContent style={props.device === 'mobile' ? { minWidth: '90%' } : {}}>
          <Title
            value={formItem.title}
            prices={prices}
            priceCurrency={priceCurrency}
            scores={scores}
            results={props.results}
            containerProps={{
              style: { textAlign: 'left', marginBottom: settings.descriptionEnabled && formItem.description ? 0 : 22 },
            }}
          />
          {settings.descriptionEnabled && formItem.description && (
            <Description
              prices={prices}
              scores={scores}
              priceCurrency={priceCurrency}
              results={props.results}
              value={formItem.description}
            />
          )}
          {settings.videoUrl && <Video value={settings.videoUrl} />}
          {!settings.videoUrl &&
            settings.image &&
            (!settings.layout || settings.layout === FormItemSettingsLayout.NORMAL) && (
              <Image
                value={settings.image}
                containerProps={{
                  className: 'image--normal',
                  style: { filter: `brightness(${(settings.imageBrightness || 100) / 100})` },
                }}
              />
            )}
          <StyledDatePicker
            format={['DD/MM/YYYY', 'DD/MM/YY']}
            defaultValue={result}
            onChange={(value) => {
              setResult(value);
              setHasError(false);
              formLayout === 'classic' && onSaveResult(value)
            }}
            placeholder={getSetting(customizedLabels, 'INSTRUCTION_FOR_DATE_INPUT', 'Please enter your response')}
          />
          <div className="error">
            {hasError && (
              <StyledErrorMessage>
                {getSetting(customizedLabels, 'ERROR_IF_AN_ANSWER_IS_REQUIRED', 'Oops, Please enter your answer!')}
              </StyledErrorMessage>
            )}
          </div>
          {formLayout !== 'classic' &&
            <div className="buttons">
              {props.active && result && !isNextSubmit && (
                <ButtonGroup
                  previewing={previewing}
                  onClick={onSaveResult}
                  label={getSetting(customizedLabels, 'CONFIRM_ANSWER_BUTTON_TEXT', 'OK') || 'OK'}
                />
              )}
              {isNextSubmit && <Submit customizedLabels={customizedLabels} onSubmit={onSubmit} submitting={submitting} activeItemId={isNextSubmit && 'submit'} />}
            </div>
          }
        </StyledQuestionContainerContent>
      </StyledQuestionContainer>
    </QuestionLayout>
  );
};

export default Date;
