import * as React from 'react';
import { graphql, ChildMutateProps } from 'react-apollo';
import { Input, Button, Row, Col, Popover, Empty } from 'antd';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import { UPDATE_WORKSPACE, GET_WORKSPACES } from '@formtory/shared/graphql/workspace';
import {
  UpdateWorkspaceMutation,
  UpdateWorkspaceMutationVariables,
  GetWorkspaces,
  Me_me_teams,
} from '@formtory/shared/graphql/types';

interface AddWorkspaceProps {
  workspace: any;
  selectedTeam: Me_me_teams;
}

const StyledContentTitle = styled.h1`
  font-size: 22px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
  font-weight: 400;
  margin-top: 24px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const StyledForm = styled.form`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  width: auto;
`;

const StyledTeamMembers = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledAddTeamMembers = styled.ul`
  margin: 0;
  padding: 0;
  li {
    min-width: 240px;
    display: flex;
    align-items: center;
    padding: 14px 0 8px 0;
    list-style: none;
    &:hover {
      background: #f8f8f8;
      cursor: pointer;
    }
    h4 {
      margin-left: 8px;
      margin-top: 0;
      margin-bottom: 8px;
    }
  }
`;

const StyledInput = styled(Input)`
  background: transparent;
  border: none;
  font-size: 22px;
  @media (max-width: 768px) {
    font-size: 18px;
    width: 50vw;
  }
  width: 20vw;
`;

const StyledButton = styled<any>(Button)`
  margin-left: 10px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

type ChildProps = ChildMutateProps<AddWorkspaceProps, UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;

class AddWorkspace extends React.PureComponent<ChildProps> {
  state = {
    name: '',
    openEdit: false,
  };

  onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    return this.props.mutate({
      variables: {
        id: this.props.workspace._id,
        data: { name: this.state.name, teamId: this.props.selectedTeam?._id },
      },
      update: (store, { data: { updateWorkspace } }) => {
        if (this.props.selectedTeam) {
          const previous: GetWorkspaces = store.readQuery({
            query: GET_WORKSPACES,
            variables: { teamId: this.props.selectedTeam._id },
          });
          const index = previous.workspaces.findIndex((workspace) => this.props.workspace._id === workspace._id);
          previous.workspaces.splice(index, 1, updateWorkspace);
          store.writeQuery({
            query: GET_WORKSPACES,
            variables: { teamId: this.props.selectedTeam._id },
            data: previous,
          });
          this.setState({ openEdit: false, name: '' });
        } else {
          const previous: GetWorkspaces = store.readQuery({ query: GET_WORKSPACES });
          const index = previous.workspaces.findIndex((workspace) => this.props.workspace._id === workspace._id);
          previous.workspaces.splice(index, 1, updateWorkspace);
          store.writeQuery({ query: GET_WORKSPACES, data: previous });
          this.setState({ openEdit: false, name: '' });
        }
      },
    });
  };

  onRemoveMember = (memberId: string) => {
    const memberIds = this.props.workspace.members.filter((item) => item._id !== memberId).map((item) => item._id);
    return this.props.mutate({
      variables: {
        id: this.props.workspace._id,
        data: { name: this.props.workspace.name, teamId: this.props.selectedTeam._id, memberIds },
      },
      update: (store, { data: { updateWorkspace } }) => {
        const previous: GetWorkspaces = store.readQuery({
          query: GET_WORKSPACES,
          variables: { teamId: this.props.selectedTeam._id },
        });
        const index = previous.workspaces.findIndex((workspace) => this.props.workspace._id === workspace._id);
        previous.workspaces.splice(index, 1, updateWorkspace);
        store.writeQuery({ query: GET_WORKSPACES, variables: { teamId: this.props.selectedTeam._id }, data: previous });
      },
    });
  };

  onAddMember = (memberId: string) => {
    const memberIds = [...this.props.workspace.members.map((item) => item._id), memberId];
    return this.props.mutate({
      variables: {
        id: this.props.workspace._id,
        data: { name: this.props.workspace.name, teamId: this.props.selectedTeam._id, memberIds },
      },
      update: (store, { data: { updateWorkspace } }) => {
        const previous: GetWorkspaces = store.readQuery({
          query: GET_WORKSPACES,
          variables: { teamId: this.props.selectedTeam._id },
        });
        const index = previous.workspaces.findIndex((workspace) => this.props.workspace._id === workspace._id);
        previous.workspaces.splice(index, 1, updateWorkspace);
        store.writeQuery({ query: GET_WORKSPACES, variables: { teamId: this.props.selectedTeam._id }, data: previous });
      },
    });
  };

  onChangeWorkspaceName = (e) => {
    this.setState({ name: e.target.value });
  };

  onOpenEdit = () => {
    this.setState({ openEdit: !this.state.openEdit, name: this.props.workspace.name });
  };

  handleGetWorkspaceMembers = () => {
    const members = [];
    this.props.workspace?.members?.forEach((member) => {
      if (this.props.selectedTeam?.memberIds.includes(member._id) || this.props.selectedTeam?.ownerId === member._id) {
        members.push(member);
      }
    });
    return members;
  };

  handleTeamMembersAbleToAddToWorkspace = () => {
    return this.props.selectedTeam?.members.filter(
      (member) => !this.props.workspace.members.find((item) => item._id === member._id),
    );
  };

  render() {
    const { workspace } = this.props;
    const { openEdit, name } = this.state;
    const workspaceMembers = this.handleGetWorkspaceMembers();
    const teamsMembers = this.handleTeamMembersAbleToAddToWorkspace();
    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            {!openEdit && <StyledContentTitle onClick={this.onOpenEdit}>{workspace.name}</StyledContentTitle>}
            {openEdit && (
              <StyledForm onSubmit={this.onSubmit}>
                <StyledInput autoFocus={true} defaultValue={name} onChange={this.onChangeWorkspaceName} />
                <StyledButton type={'primary'} htmlType={'submit'}>
                  Save
                </StyledButton>
                <StyledButton onClick={this.onOpenEdit}>Cancel</StyledButton>
              </StyledForm>
            )}
          </Col>
          {this.props.selectedTeam && (
            <StyledTeamMembers>
              {/* <Col span={12} style={{ textAlign: 'right' }}> */}

              {workspaceMembers.map((member) => (
                <Popover
                  key={member._id}
                  placement={'bottom'}
                  title={`Remove ${member.firstName} ${member.lastName}`}
                  content={
                    <Button block type={'danger'} onClick={() => this.onRemoveMember(member._id)}>
                      Remove
                    </Button>
                  }
                  trigger="click"
                >
                  <Avatar
                    className="avatar"
                    round={true}
                    src={member.avatar}
                    size={'32'}
                    style={{ marginRight: -8, cursor: 'pointer' }}
                    name={`${member.firstName} ${member.lastName}`}
                  />
                </Popover>
              ))}
              <Popover
                placement={'bottom'}
                title={`Add member`}
                content={
                  <StyledAddTeamMembers>
                    {teamsMembers.length === 0 && (
                      <li style={{ textAlign: 'center' }}>
                        <Empty style={{ width: '100%' }} />
                      </li>
                    )}
                    {teamsMembers.map((member) => (
                      <li onClick={() => this.onAddMember(member._id)} key={member._id}>
                        <Avatar
                          className="avatar"
                          round={true}
                          src={member.avatar}
                          size={'40'}
                          style={{ marginTop: -6, marginLeft: 8 }}
                          name={`${member.firstName} ${member.lastName}`}
                        />
                        <h4>{`${member.firstName} ${member.lastName}`}</h4>
                      </li>
                    ))}
                  </StyledAddTeamMembers>
                }
                trigger="click"
              >
                <Button
                  style={{
                    width: 32,
                    height: 32,
                    backgroundColor: 'rgb(227, 227, 227)',
                    color: '#48484B',
                    border: '1px solid transparent',
                  }}
                  shape={'circle'}
                  icon={'plus'}
                  size={'small'}
                  type={'primary'}
                />
              </Popover>

              {/* </Col> */}
            </StyledTeamMembers>
          )}
          {/* </Col> */}
        </Row>
      </React.Fragment>
    );
  }
}

const withUpdateWorkspace = graphql<
  AddWorkspaceProps,
  UpdateWorkspaceMutation,
  UpdateWorkspaceMutationVariables,
  ChildProps
>(UPDATE_WORKSPACE);

export default withUpdateWorkspace(AddWorkspace);
