import React from 'react';
import BaseComponentWithContext from '@formtory/shared/components/BaseComponentWithContext';
import { Icon, Select, Col, Button } from 'antd';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';
import { ThemeProvider } from 'styled-components';
import { GetFormVariables, SubmitResponseMutation, GetForm } from '@formtory/shared/graphql/types';
import { GET_FORM, SUBMIT_RESPONSE_MUTATION, TRACKING_FORM_VISITOR } from '@formtory/shared/graphql/form';
import { ChildDataProps, graphql, Mutation } from 'react-apollo';
import GoogleFontLoader from 'react-google-font-loader';
import * as AppUtils from '@formtory/shared/apps/Utils';
import * as FacebookPixel from '@formtory/shared/apps/FacebookPixel';
import { IntegrationApps } from '@formtory/shared/data/default';
import { StyledIntro } from '@formtory/app/src/components/Layouts/Authentication';
import styled from 'styled-components';
// import { getCountryFlag } from '@formtory/app/src/utils/helperFunctions';
import NotFoundImage from '../../../app/src/assets/images/Sleeping-Face.webp';
// import { motion } from "framer-motion"
import { LazyLoadComponent, trackWindowScroll, LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const StyledSider = styled<any>(Sider)`
  height: 100%;
  background: rgb(241, 236, 227);
  z-index: 99;
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: ${isMobile() ? '10px' : '40px'}
  }
  .text {
    flex-shrink: 0;
    min-width: 0px;
    padding: ${isMobile() ? '0' : '24px 0px 40px'};
    .back {
      position: ${isMobile() ? 'block' : 'absolute'};
      margin-bottom: 20px;
      top: 40px;
      left: 40px;
      cursor: pointer;
      color: rgb(38, 38, 39);
    }
    .title {
      display: block;
      font-size: 24px;
      line-height: 32px;
      // font-weight: 300;
      margin-bottom: 16px;
      color: rgb(38, 38, 39);

    }
    .form-stats {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      color: rgb(38, 38, 39);
      .ant-col {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        min-width: 0px;
        background-color: transparent;
        height: fit-content;
        border-radius: 8px;
        box-shadow: rgb(187 187 187) 0px 0px 0px 1px inset;
        padding: 16px;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          letter-spacing: 0.1em;
        }
        .stats-title {
          display: block;
          font-size: 12px;
          line-height: 18px;
          color: rgb(19, 19, 19);
          font-weight: 600;
        }
        .stats-number {
          display: block;
          font-size: 24px;
          line-height: 32px;
          color: rgb(38, 38, 39);
          font-weight: 300;
        }
      }
    }
    .button-group {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .button-span {
        width: 100%;
        display: flex;
        justify-content: ${isMobile() ? 'space-between' : 'center'};
        align-items: center;
      }
      Button {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        background-color: rgb(38, 38, 39);
        color: rgb(255, 255, 255);
        position: relative;
        outline: none;
        font-family: inherit;
        border: 0px;
        transition: all 0.4s ease 0s;
        cursor: pointer;
        white-space: nowrap;
        text-decoration: none;
        border-radius: 4px;
        padding: 10px 18px;
        font-size: 20px;
        line-height: 28px;
        width: 100%;
        margin-bottom: 8px;

        &:hover {
        background-color: rgb(71, 71, 71);
        transition: all 0.2s ease 0s;
        }
      }
      span {
        cursor: pointer;
      }
    }
  }
`;


import {
  StyledLayout,
  StyledLayoutContent,
  StyledReloadButton,
  StyledDeviceButton,
  StyledNotFoundContainer,
  StyledCloseButton,
  StyledLocales,
  StyledLogo,
  // StyledBranding,
} from './Styled';
import Items from './Items';
import FormClosed from './FormClosed';
import FormProtectedPassword from './FormProtectedPassword';
// import { AppContext } from '../../contexts/AppContext';
// import { Brand } from './BottomWidget';
import { InitialTransition } from './Elements/InitialTransition';
import Sider from 'antd/lib/layout/Sider';
import { isMobile } from '@formtory/app/src/utils/helperFunctions';

interface OriginComponentProps {
  formId: string;
  focusedToFormItemId?: string;
  previewing?: boolean;
  hideFooter?: boolean;
  fromPopup?: boolean;
  onClose?: any;
  fromSDK?: boolean;
  onSubmitted?: any;
  isTestMode?: boolean;
  scrollPosition?: any;
  fromTemplate?: boolean;
  onClickBack?: any;
  onDuplicate?: any;
  onSkipTemplate?: any;
}

type ChildProps = ChildDataProps<OriginComponentProps, GetForm, GetFormVariables>;
const withSingleFormQuery = graphql<OriginComponentProps, GetForm, GetFormVariables, ChildProps>(GET_FORM, {
  options: (props) => {
    const variables: GetFormVariables = {
      formId: props.formId,
    };
    if (!props.isTestMode) {
      variables.live = true;
    }
    return {
      variables,
    };
  },
});

const isFormClosed = (form: GetForm['form']) => {
  if (form.closeFormToNewResponses) return true;
  if (form.closeFormFromDateEnable && new Date().getTime() > new Date(form.closeFormFromDate).getTime()) return true;
  return form.responseLimitEnable && form.totalResponses >= form.responseLimit;
};

const FormFont = React.memo(
  ({ form }: { form: GetForm['form'] }) => {
    return (
      <GoogleFontLoader
        fonts={[
          {
            font: form.theme.fontFamily,
            weights: [300, 400, 500, 600, 700],
          },
        ]}
        subsets={['vietnamese']}
      />
    );
  },
  (prevProps, nextProps) => prevProps.form?.theme?.fontFamily === nextProps.form?.theme?.fontFamily,
);

export const flagemojiToPNG = (countryCode) => {
  console.log('countryCode: ', countryCode)
  countryCode = countryCode?.toLowerCase();
  return (<img src={`https://flagcdn.com/24x18/${countryCode}.png`} alt='flag' />)
}
class DisplaySingleForm extends BaseComponentWithContext<ChildProps, {}> {
  state = {
    assetsLoaded: false,
    device: 'desktop',
    editingFormResponse: (this.props.data.form?.allowEditResponse && !this.props.data.form?.allowMultipleSubmissionPerUser) ? JSON.parse(localStorage.getItem(`editingFormResponse_${this.props.data.form?._id}`)) : null,
    locale: new URLSearchParams(window.location.search).get('lang') || this.props.data.form?.defaultFormLanguage,
    // locale: this.props.data.form?.defaultFormLanguage || 'vi-VN',
    unlocked: !!localStorage.getItem(`Unlocked_Form_${this.props.formId}`),
  };

  componentDidMount() {
    setTimeout(
      () => {
        this.setState({ assetsLoaded: true });
        (this.props.data.form?.allowEditResponse && !this.props.data.form?.allowMultipleSubmissionPerUser) && this.setState({ editingFormResponse: JSON.parse(localStorage.getItem(`editingFormResponse_${this.props.data.form?._id}`)) });
      },
      this.props.isTestMode ? 4000 : 4000,
    );
  }

  componentDidUpdate(prevProps: Readonly<ChildProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (!prevProps.data.form && this.props.data.form) {
      if (AppUtils.isAppConnected(this.props.data.form.connectedApps, IntegrationApps.FACEBOOK_PIXEL)) {
        const app: any = AppUtils.getApp(this.props.data.form.connectedApps, IntegrationApps.FACEBOOK_PIXEL);
        if (app && app.payload.pixelId) {
          FacebookPixel.Helpers.initClient(app.payload.pixelId);
        }
      }
      if (AppUtils.isAppConnected(this.props.data.form.connectedApps, IntegrationApps.GOOGLE_ANALYTICS)) {
        const app: any = AppUtils.getApp(this.props.data.form.connectedApps, IntegrationApps.GOOGLE_ANALYTICS);
        if (app && app.payload.code) {
          ReactGA.initialize(app.payload.code);
          ReactGA.pageview(window.location.pathname + window.location.search);
        }
      }
    }
  }

  onReloadPreview = () => {
    this.setState({ assetsLoaded: false });
    setTimeout(
      () => {
        this.setState({ assetsLoaded: true });
      },
      this.props.isTestMode ? 4000 : 4000,
    );
  };

  onDevicePreview = (device) => {
    this.setState({ device: device });
  };

  onSubmitted = (formResponse: SubmitResponseMutation['submitResponse']) => {
    localStorage.setItem(`editingFormResponse_${formResponse.formId}`, JSON.stringify(formResponse));
    if (this.props.data.form.allowEditResponse) {
      // if (!this.props.data.form.allowMultipleSubmissionPerUser) {
      //   localStorage.setItem(`editingFormResponse_${formResponse.formId}`, JSON.stringify(formResponse));
      // }
      this.setState({ editingFormResponse: formResponse });
    }
  };

  onUnlockForm = () => {
    this.setState({ unlocked: true });
  };

  handleChangeLocale = (locale) => {
    if (locale === this.state.locale) return;
    this.setState({ locale });
  };

  render() {
    const {
      data: { loading, error, form },
      scrollPosition
    } = this.props;
    const { assetsLoaded, unlocked, editingFormResponse } = this.state;
    const {
      limitResponse,
      intl: { formatMessage }
    } = this.context;
    const limitedResponseInThisMonth = false;
    const formLayoutInlineStyles: any = { visibility: 'visible' };
    if (assetsLoaded) {
      formLayoutInlineStyles.visibility = 'visible';
      // formLayoutInlineStyles.borderRadius = '20px';
    }
    // if (form?.theme?.backgroundImage) {
    //   formLayoutInlineStyles.backgroundImage = `url(${form.theme.backgroundImage})`;
    // }
    // this.props.previewing ? formLayoutInlineStyles.minHeight = 'calc(100vh - 70px)' : formLayoutInlineStyles.minHeight = '100vh';
    this.props.previewing ? formLayoutInlineStyles.height = '100%' : formLayoutInlineStyles.minHeight = '100vh';
    const defaultFormLanguage = form?.defaultFormLanguage || 'vi-VN';
    const defaultCountryCode = defaultFormLanguage.split('-')[1];
    const defaultLanguageCode = defaultFormLanguage.split('-')[0];
    return (
      <LazyLoadComponent>
        {this.props.previewing && (
          <>
            <StyledReloadButton>
              <Icon type={'sync'} onClick={this.onReloadPreview} />
            </StyledReloadButton>
            <StyledDeviceButton>
              <Icon type={'desktop'} onClick={() => this.onDevicePreview('desktop')} className={this.state.device === 'desktop' && 'active'} />
              <Icon type={'mobile'} onClick={() => this.onDevicePreview('mobile')} className={this.state.device === 'mobile' && 'active'} />
            </StyledDeviceButton>
          </>
        )}
        <div
          style={
            this.props.previewing
              ? this.state.device === 'mobile'
                ? { position: 'relative', width: '95%', height: '85%', maxWidth: '480px', overflow: 'hidden', top: '50%', left: '50%', transform: 'translateY(-50%) translateX(-50%)', borderRadius: '8px', boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px', animation: '1s cubic-bezier(0.22, 1, 0.36, 1) 0s 1 normal none running expand' }
                : { position: 'relative', width: '95%', height: '85%', overflow: 'hidden', top: '50%', left: '50%', transform: 'translateY(-50%) translateX(-50%)', borderRadius: '8px', boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px', animation: '1s cubic-bezier(0.22, 1, 0.36, 1) 0s 1 normal none running expand' }
              : this.props.fromTemplate
                ? { position: 'fixed', top: '0px', bottom: '0px', left: '0px', width: '100%', height: '100%', overflow: 'hidden', zIndex: '999999' }
                : { position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }
          }>
          {/* {loading ||
            (!assetsLoaded && (
              <motion.div
                className="screen-loading-percent"
                key="screen-loading-percent"
                initial={{ background: form?.theme?.background, opacity: 1 }}
                animate={{ background: `${form?.theme?.background}10` }}
                // exit={{ y: '-100vh', opacity: 0 }}
                transition={{ duration: this.props.isTestMode ? 1.5 : 2 }}
              >
                <motion.div id="circle3" />
                <StyledBranding>
                  <Brand />
                </StyledBranding>
              </motion.div>
            ))} */}
          {!assetsLoaded && <InitialTransition form={form} />}
          {error && <p>Something went wrong!</p>}
          {!loading && !error && form && (
            <ThemeProvider theme={(settings) => ({ ...settings, form: { ...form.theme } })}>
              <StyledLayout id={form._id} style={formLayoutInlineStyles}>
                {form.theme.backgroundImage && !this.props.previewing && <LazyLoadImage effect="blur" alt={form.theme.name} src={form.theme.backgroundImage} scrollPosition={scrollPosition} style={{ filter: `brightness(${(form.theme.backgroundBrightness || 100) / 100})`, }} />}
                {form.theme.backgroundImage && this.props.previewing && <LazyLoadImage effect="blur" alt={form.theme.name} src={form.theme.backgroundImage} scrollPosition={scrollPosition} width='auto' height='100%' style={{ filter: `brightness(${(form.theme.backgroundBrightness || 100) / 100})`, }} />}
                <StyledLayoutContent>
                  {form.theme.logoImage && (
                    <StyledLogo>
                      {!this.props.previewing && <LazyLoadImage effect="blur" alt={form.theme.name} src={form.theme.logoImage} scrollPosition={scrollPosition} style={{ maxWidth: 96, maxHeight: 40 }} />}
                      {this.props.previewing && <LazyLoadImage effect="blur" alt={form.theme.name} src={form.theme.logoImage} scrollPosition={scrollPosition} width='auto' height='100%' style={{ maxWidth: 96, maxHeight: 40 }} />}
                    </StyledLogo>
                  )}
                  {form.locales.length > 0 && (
                    <StyledLocales style={(this.props.fromTemplate && isMobile()) ? { top: 93 } : {}}>
                      <Select
                        defaultValue={defaultCountryCode}
                        dropdownStyle={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                        style={{ width: `100%` }}
                        onChange={(value) => this.handleChangeLocale(value)}
                      >
                        <Select.Option value={defaultCountryCode}>
                          {flagemojiToPNG(defaultCountryCode)} {defaultCountryCode}
                        </Select.Option>
                        {form.locales.map((locale) => (
                          <Select.Option value={locale}>
                            {flagemojiToPNG(locale.split('-')[1])} {locale.split('-')[1]}
                          </Select.Option>
                        ))}
                      </Select>
                    </StyledLocales>
                  )}
                  {this.props.fromPopup && (
                    <StyledCloseButton onClick={this.props.onClose}>
                      <Icon type={'close'} />
                    </StyledCloseButton>
                  )}
                  {!this.props.previewing && limitResponse && (
                    <FormClosed form={form} />
                  )}
                  {!this.props.previewing && (isFormClosed(form) || limitedResponseInThisMonth) && (
                    <FormClosed form={form} />
                  )}
                  {!this.props.previewing && form.hasProtectedPassword && !unlocked && (
                    <FormProtectedPassword onUnlockForm={this.onUnlockForm} form={form} />
                  )}
                  {!this.props.previewing && !this.props.fromSDK &&
                    <Helmet
                      title={form.name}
                      htmlAttributes={{ lang: defaultLanguageCode }}
                      meta={[
                        {
                          name: "theme-color",
                          content: form?.theme?.background || "#643ced"
                        },
                        {
                          property: "og:url",
                          content: window.location.href
                        },
                        {
                          property: "og:type",
                          content: 'website'
                        },
                        {
                          property: "og:title",
                          content: form?.name || "Formtory: Bring every form to life"
                        },
                        {
                          property: "og:description",
                          content: "Form that feels more like a journey than a Form"
                        },
                        {
                          property: "og:image",
                          content: form?.theme?.backgroundImage || 'https://formtory.com/footer.webp'
                        },
                        // {
                        //   property: "fb:app_id",
                        //   content: someFbAppId
                        // }
                      ]}
                    />
                  }
                  {(this.props.previewing || !limitResponse) && !limitedResponseInThisMonth && (this.props.previewing || !isFormClosed(form)) && (
                    <>
                      {this.props.fromTemplate &&
                        <>
                          <Col md={isMobile() ? 24 : 4} sm={24} xs={24} style={isMobile() ? { height: 'fit-content' } : { height: '100%' }}>
                            <StyledSider
                              width={'100%'}
                              theme={'light'}
                              className={isMobile() ? 'mobile sider' : 'sider'}
                            >
                              {/* <StyledIntro style={{ justifyContent: 'center', padding: '40px' }}> */}

                              <div className='text'>

                                {!isMobile() && (
                                  <>
                                    <span className="back" onClick={() => this.props.onClickBack()}>
                                      <Icon type={'arrow-left'} /> {formatMessage({ id: 'backToTemplates' })}
                                    </span>
                                    <span className='title'>{form.name}</span>
                                    {/* <span className='description'>{formatMessage({ id: 'useTemplatesToGetToKnowForm' })}</span> */}
                                    <div className="form-stats">
                                      <Col md={12}>
                                        <span className="stats-title">{formatMessage({ id: 'questions' })}</span>
                                        <span className="stats-number">{form.orders.length}</span>
                                      </Col>
                                      <Col md={12} style={{ marginLeft: '8px' }}>
                                        <span className="stats-title">{formatMessage({ id: 'timeToAnswer' })}</span>
                                        <span className="stats-number">1-3<small>min</small></span>
                                      </Col>
                                    </div>
                                  </>
                                )}
                                <div className="button-group">
                                  <Button onClick={() => this.props.onDuplicate(form)}>{formatMessage({ id: 'useTemplate' })}</Button>
                                  <div className="button-span">
                                    {isMobile() && (
                                      <span style={{ textDecoration: 'underline', color: 'rgb(38, 38, 39)' }} onClick={() => this.props.onClickBack()}>
                                        <Icon type={'arrow-left'} /> {formatMessage({ id: 'backToTemplates' })}
                                      </span>
                                    )}
                                    <span style={{ textDecoration: 'underline', color: 'rgb(38, 38, 39)' }} onClick={() => this.props.onSkipTemplate()}>{formatMessage({ id: 'orStartBlank' })}</span>
                                  </div>
                                </div>
                              </div>
                              {/* </Col> */}
                              {/* </StyledIntro> */}
                            </StyledSider>
                          </Col>

                          <Col md={isMobile() ? 24 : 20} sm={24} xs={24} style={{ height: '100%' }}>
                            <PerfectScrollbar className="main-scrollbar">
                              <Mutation mutation={TRACKING_FORM_VISITOR}>
                                {(trackingFormVisitor) => (
                                  <Mutation mutation={SUBMIT_RESPONSE_MUTATION}>
                                    {(submitResponse) => (
                                      <Items
                                        form={form}
                                        editingFormResponse={editingFormResponse}
                                        hideFooter={this.props.hideFooter}
                                        onSubmitted={this.onSubmitted}
                                        locale={this.state.locale}
                                        focusedToFormItemId={this.props.focusedToFormItemId}
                                        previewing={this.props.previewing}
                                        onTrackingFormVisitor={trackingFormVisitor}
                                        onSubmitResponse={submitResponse}
                                        isTestMode={this.props.isTestMode}
                                        formId={form._id}
                                        device={this.state.device}
                                      />
                                    )}
                                  </Mutation>
                                )}
                              </Mutation>
                            </PerfectScrollbar>
                          </Col>
                        </>
                      }
                      {!this.props.fromTemplate && (
                        <PerfectScrollbar className="main-scrollbar">
                          <Mutation mutation={TRACKING_FORM_VISITOR}>
                            {(trackingFormVisitor) => (
                              <Mutation mutation={SUBMIT_RESPONSE_MUTATION}>
                                {(submitResponse) => (
                                  <Items
                                    form={form}
                                    editingFormResponse={editingFormResponse}
                                    hideFooter={this.props.hideFooter}
                                    onSubmitted={this.onSubmitted}
                                    locale={this.state.locale}
                                    focusedToFormItemId={this.props.focusedToFormItemId}
                                    previewing={this.props.previewing}
                                    onTrackingFormVisitor={trackingFormVisitor}
                                    onSubmitResponse={submitResponse}
                                    isTestMode={this.props.isTestMode}
                                    formId={form._id}
                                    device={this.state.device}
                                  />
                                )}
                              </Mutation>
                            )}
                          </Mutation>
                        </PerfectScrollbar>
                      )}
                    </>
                  )}
                  <FormFont form={form} />
                </StyledLayoutContent>
              </StyledLayout>
            </ThemeProvider>
          )}
          {!loading && !error && !form && (
            <StyledNotFoundContainer>
              <img src={NotFoundImage} alt="Not Found" width="200" />
              <br />
              <h2>Form not found!</h2>
            </StyledNotFoundContainer>
          )}
        </div >
      </LazyLoadComponent>
    );
  }
}

export default Object.assign(withSingleFormQuery(trackWindowScroll(DisplaySingleForm)), { contextType: undefined });
