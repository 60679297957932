export const ACCESS_TOKEN_STORAGE_KEY = 'FORMTORY_ACCESS_TOKEN';
const LOCALE_KEY = 'FORMTORY_LOCALE_KEY';

export const saveUserAccessToken = (token: string) => {
  return localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
};

export const getUserAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
};

export const getLocale = () => {
  return localStorage.getItem(LOCALE_KEY) || 'en';
};

export const cleanStorage = () => {
  localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  localStorage.removeItem('selectedTeamId');
};
