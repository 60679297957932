import { motion } from "framer-motion";
import React from "react";

const blackBox = {
  initial: {
    height: "100%",
    bottom: 0,
  },
  animate: {
    height: 0,
    transition: {
      when: "afterChildren",
      duration: 1.5 * 1,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const textContainer = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 0,
    transition: {
      duration: 0.25 * 1,
      when: "afterChildren",
    },
  },
};

const svgContainer = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 1,
      when: "afterChildren",
    },
  },
};

const text = {
  initial: {
    y: 40,
  },
  animate: {
    y: 100,
    transition: {
      duration: 1.5 * 1,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

export const InitialTransition = ({ form }) => {
  return (
    <>
      {form && <motion.div
        style={{ position: 'absolute', zIndex: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', background: form?.theme?.background, fontFamily: form?.theme?.fontFamily }}
        initial="initial"
        animate="animate"
        variants={blackBox}
        onAnimationStart={() => { document.getElementById('FormBody') && (document.getElementById('FormBody').style.overflow = 'hidden') }}
        onAnimationComplete={() => { document.getElementById('FormBody') && (document.getElementById('FormBody').style.overflow = 'auto') }}
      >
        {form.theme.logoImage
          ?
          <img src={form.theme.logoImage} style={{ position: 'relative', bottom: '40px', maxWidth: 96, maxHeight: 40 }}></img>
          :
          <motion.svg variants={svgContainer} xmlns="http://www.w3.org/2000/svg" width="30" height="36" viewBox="0 0 15 18" fill="none" style={{ position: 'relative', bottom: '40px' }}>
            <path d="M2.88958 2.15704L8.92051 1.04573C12.2375 0.435297 14.1407 -0.000171185 14.1562 -0.000171185V3.1666C14.0557 3.22921 11.9978 3.87095 8.76587 4.88834C5.53391 5.90573 2.84319 6.75095 2.78906 6.7666L2.88958 2.15704Z" fill={form?.theme?.questions} />
            <path d="M3.19482 12.6918C3.49637 12.6449 4.4242 12.4883 5.25152 12.3475C6.07884 12.2066 6.80564 12.0892 6.85977 12.0814L6.82111 15.1492C6.66647 15.2823 2.51441 18.0136 2.49121 17.9901L2.65358 12.7857L3.19482 12.6918Z" fill={form?.theme?.questions} />
            <path d="M11.3949 5.33545L1.25977 8.94131V12.0928L11.0908 9.60092L11.3949 5.33545Z" fill={form?.theme?.questions} />
          </motion.svg>
        }

        <motion.svg variants={textContainer} style={{ position: 'absolute', zIndex: 50, display: 'flex' }}>
          <pattern
            id="pattern"
            patternUnits="userSpaceOnUse"
            width={750}
            height={800}
            style={{ color: form?.theme?.questions }}
          >
            <rect className="w-full h-full fill-current" style={{ width: '100%', height: '100%', fill: 'currentColor' }} />
            <motion.rect
              variants={text}
              style={{ width: '100%', height: '100%', color: form?.theme?.buttons, fill: form?.theme?.buttons }}
            />
          </pattern>
          <text
            textAnchor="middle"
            x="48%"
            y="50%"
            style={{ fill: "url(#pattern)", fontSize: `0.6rem`, lineHeight: `0.75rem` }}
          >
            Made with
    </text>
          <text
            textAnchor="middle"
            x="50%"
            y="62%"
            style={{ fill: "url(#pattern)", fontSize: `1.4rem`, lineHeight: `1.9rem`, fontWeight: 'bold' }}
          >
            Formtory
    </text>
        </motion.svg>
      </motion.div>}
    </>
  );
};