export default {
  addEventListener(event: string, handler: (...params: any) => void) {
    document.addEventListener(event, handler);
  },

  removeEventListener(event: string, handler: (...params: any) => void) {
    document.removeEventListener(event, handler);
  },

  dispatchEvent(eventName: string, payload: any) {
    const event = new CustomEvent(eventName, { detail: payload });
    document.dispatchEvent(event);
  },
};
