export async function asyncForEach(array: Array<any>, callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

/* tslint:disable */
export function getYoutubeVideoId(url) {
  var r,
    rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  r = url.match(rx);
  if (!r || typeof r === 'undefined') return false;
  return r[1];
}
/* tslint:enable */
