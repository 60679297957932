import * as React from 'react';
import { useApolloClient } from 'react-apollo-hooks';
import { Card, Icon, Empty, Layout, Button, Row, Skeleton, Switch, Col } from 'antd';
import { GET_TEMPLATE_FORMS } from '@formtory/shared/graphql/form';
import { GetTemplateForms } from '@formtory/shared/graphql/types';
import { useDuplicateFormMutation } from './Hooks';
import styled from 'styled-components';
import { isMobile } from 'utils/helperFunctions';
import { AppContext } from '@formtory/shared/contexts/AppContext';
import FormPreview from '@formtory/shared/components/DisplaySingleForm/Main';
import { LazyLoadImage, LazyLoadComponent, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const { } = Layout;

const StyledTemplate = styled.div<{ isHomepage }>`
  // height: ${p => (p.isHomepage ? '20vh' : '100vh')};
  // width: 100vw;
  // position: absolute;
  // top: 0;
  // z-index: 100;
  background: #fff;
  border-radius: 20px;
  @media (max-width: 480px) {
    margin-top: 12px;
  }
`;

const StyledMenuToggleButton = styled<any>(Button)`
  position: fixed;
  top: 0px;
  left: 0;
  border: 0;
  padding: 5px 15px !important;
  box-shadow: 0 0 10px #eee;
`;

const StyledLayout = styled(Layout) <{ isHomepage }>`
  // margin-top: 70px;
  // min-height: 96vh;
  // height: calc(100vh - 70px);
  // height: ${p => (p.isHomepage ? '20vh' : '100vh')};
  // background: ${p => (p.isHomepage ? 'transparent' : '')};
  background: transparent;
  
  &.mobile {
    .ant-layout-content {
      margin-top: 20px;
    }
  }
`;

const StyledSider = styled<any>(Row)`
  background: rgb(241, 236, 227);
  border-radius: 20px 20px 0 0;
  
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .text {
    flex-shrink: 0;
    min-width: 0px;
    padding: 24px 0px 40px;
    .title {
      display: block;
      font-size: 24px;
      line-height: 32px;
      font-weight: 300;
      margin-bottom: 16px;
    }
    .description {
      
    }
  }
`;

const StyledSiderDiv = styled.div`
  justify-content: flex-start;
  padding: 16px;
  background: unset;
  color: rgb(38, 38, 39);
  @media (max-width: 480px) {
    padding: 16px 0px 0px 0px;
  }
  h1 {
    font-size: 40px;
    padding: 20px;
    text-align: center;
    color: #643ced;
    @media (max-width: 480px) {
      font-size: 24px;
      padding: 0 12px;
      // margin-bottom: 0;
    }
  }
`;


const StyledContent = styled(Row)`
  overflow: hidden;
  .btn-template-action {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    font-family: inherit;
    transition: all 0.4s ease 0s;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    height: 36px;
    padding: ${!isMobile() ? '0 20px' : '0 10px'};
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    border-radius: 6px;
  }
`;

const StyledDiv = styled.div`
display: flex;
height: auto;
width: 100%;
margin: 0 auto;
max-width: -webkit-fit-content;
max-width: -moz-fit-content;
max-width: fit-content;
overflow-x: auto;
overflow-y: hidden;
position: relative;
scrollbar-width: none;
`;

// const StyledCategoryRow = styled(Col)`
//   margin-bottom: 48px;
//   .loading-templates {
//     display: flex;
//     flex-direction: column;
//     .ant-skeleton-header{
//       padding: 0;
//       .ant-skeleton-avatar {
//         width: 100%;
//         // height: auto;
//         padding-top: 90%;
//         border-radius: 8px;
//       }
//     }
//     .ant-skeleton-title {
//       border-radius: 6px;
//       margin: 10px auto;
//       width: 80%;
//     }
//   }
// `;

// const StyledCategoryTitle = styled.span`
//   // display: block;
//   font-size: 12px;
//   line-height: 18px;
//   font-weight: 600;
//   letter-spacing: 0.1em;
//   text-transform: uppercase;
// `;

const StyledListCategory = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 480px) {
    // height: 104px;
    margin: 0 auto;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    scrollbar-width: none;
  }
  
  div {
    cursor: pointer;
    // min-height: 48px;
    // box-shadow: rgb(0 0 0 / 7%) 0px -1px inset;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    padding: 0 12px;
    // width: 72px;
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
  .category-active {
    font-size: 16px
    font-weight: 700;
    position: relative;
    color: #643ced;
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
  .category-active:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 16px solid transparent;
    border-bottom-color: #fff;
    border-top: 0;
    margin-left: -16px;
    margin-bottom: -20px;
    @media (max-width: 480px) {
      bottom: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-bottom-color: #fff;
      border-top: 0;
      margin-left: -8px;
      margin-bottom: 0px;
    }
  }
`;

const StyledCard = styled(Card)`
  animation: 0.5s ease-in-out 0s 1 normal none running jBcSpD;
  transition: all 0.3s ease-in 0s;
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  cursor: pointer;
  margin: 0 8px;
  // max-width: 33%;
  // box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  .ant-card-cover img {
    border-radius: 8px 8px 0 0;
  }
  .ant-card-actions {
    border-radius: 0 0 8px 8px;
  }
  .ant-card-meta-title {
    display: block;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: rgb(19, 19, 19);
    text-align: center;
    @media (max-width: 480px) {
      font-size: 12px;
      line-height: 12px;
    }
  }
  .ant-card-actions > li {
    margin: 8px 0;
    span {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const StyledBilledTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin: 16px 0;
  // button {
  //   margin: 0 5px;
  //   font-size: 15px;
  //   color: rgb(187, 187, 187);
  //   &:hover,
  //   &:active,
  //   &:focus {
  //     border: 2px solid #643ced;
  //     background: #643ced 19;
  //     color: #643ced;
  //   }
  // }
  // button.active {
  //   border: 2px solid #643ced;
  //   // background: #643ced19;
  //   color: #643ced;
  // }
  // .saved-text {
  //   margin-left: 200px;
  //   margin-top: 5px;
  //   color: #643ced;
  // }
  .switch-subscription-time {
    background: #643ced;
  }
  .span-subscription-time {
    color: rgb(187, 187, 187);
    font-weight: 600;
    padding: 0 10px;
  }
  .active {
    color: rgb(19, 19, 19);
  }
`;

export default trackWindowScroll(({ workspaceId, onSkipTemplate, onCancel, scrollPosition, isHomepage }) => {
  const [templates, setTemplates] = React.useState<GetTemplateForms['templateForms']>([]);
  const [category, setCategory] = React.useState(-1);
  const [openSideMenu, setOpenSideMenu] = React.useState(false);
  const [openTemplate, setOpenTemplate] = React.useState(false);
  const [templateId, setTemplateId] = React.useState(null);
  const [loadingTemplates, setLoadingTemplates] = React.useState(true);
  const [templateLocale, setTemplateLocale] = React.useState(localStorage.getItem('locale') || 'vi');
  const client = useApolloClient();
  const { intl: { formatMessage } } = React.useContext(AppContext);
  // const locale = localStorage.getItem('locale') || 'en';

  const listCategory = [
    // { id: 'ALL', name: 'All' },
    { id: 0, code: 'APPLICATION', name: 'Application', name_vi: 'Ứng tuyển' },
    { id: 1, code: 'BOOKING', name: 'Booking', name_vi: 'Đặt chỗ' },
    { id: 2, code: 'FEEDBACK', name: 'Feedback', name_vi: 'Phản hồi' },
    { id: 3, code: 'GIVE AWAY', name: 'Give Away', name_vi: 'Phát thưởng' },
    { id: 4, code: 'LEAD CAPTURE', name: 'Lead Capture', name_vi: 'Thu thập khách hàng' },
    { id: 5, code: 'ORDER FORMS', name: 'Order Forms', name_vi: 'Đặt hàng' },
    { id: 6, code: 'POLL', name: 'Poll', name_vi: 'Bình chọn' },
    { id: 7, code: 'QUIZ', name: 'Quiz', name_vi: 'Đố vui' },
    { id: 8, code: 'REGISTRATION', name: 'Registration', name_vi: 'Đăng ký' },
    { id: 9, code: 'REPORT', name: 'Report', name_vi: 'Báo cáo' },
    { id: 10, code: 'RESEARCH', name: 'Research', name_vi: 'Khảo sát' },
    // { code: 'HR', name: 'HR' },
    // { code: 'EVENT', name: 'Event' },
    // { code: 'OTHER', name: formatMessage({ id: 'othersTemplate' }) },
  ]

  React.useEffect(() => {
    handleGetTemplates().then(r => r);
  }, []);

  React.useEffect(() => {
    onLoadingTemplates()
  })

  const handleGetTemplates = async () => {
    const { data } = await client.query<GetTemplateForms>({ query: GET_TEMPLATE_FORMS });
    setTemplates(data?.templateForms || []);
  };

  const filteredTemplates =
    category === -1 ? templates : templates.filter(template => template.templateInfo.language?.includes(templateLocale)).filter(template => template.templateInfo.categories.includes(listCategory[category].code));

  // const filteredAllTemplates = (category) => {
  //   return templates.filter(template => template.templateInfo.language?.includes(templateLocale)).filter(template => template.templateInfo.categories.includes(category))
  // }
  const duplicateFormMutation = useDuplicateFormMutation(workspaceId);

  const onDuplicate = async form => {
    await duplicateFormMutation({
      variables: { formId: form._id, workspaceId: workspaceId }
    });
    window.location.href = '/';
  };

  const onToggleSideMenu = () => {
    setOpenSideMenu(!openSideMenu);
  };

  const onLoadingTemplates = () => {
    setTimeout(() => {
      setLoadingTemplates(false)
    }, 1500);
  };

  const onClickTemplate = (id) => {
    setOpenTemplate(true);
    setTemplateId(id)
  }

  const onClickBack = () => {
    setOpenTemplate(false);
  }

  return (
    <StyledTemplate isHomepage={isHomepage}>
      {!openTemplate &&
        <StyledLayout isHomepage={isHomepage}>
          {isMobile() && (
            <StyledMenuToggleButton onClick={onToggleSideMenu} style={openSideMenu ? { left: 320 } : {}}>
              <Icon type="menu" />
            </StyledMenuToggleButton>
          )}
          <StyledSider>
            <StyledSiderDiv style={{}}>
              <h1>Bạn sẽ "Biểu Mẫu" gì hôm nay?</h1>
              <StyledListCategory>
                <div className={category === -1 ? 'category-active' : ''} onClick={() => setCategory(-1)}>
                  {formatMessage({ id: 'allNormal' })}
                </div>
                {templateLocale === 'en' &&
                  listCategory.sort((a, b) => a.name.localeCompare(b.name)).map((cate) => (
                    <div className={category === cate.id ? 'category-active' : ''} onClick={() => setCategory(cate.id)}>
                      {cate.name}
                    </div>
                  ))}
                {templateLocale === 'vi' &&
                  listCategory.sort((a, b) => a.name_vi.localeCompare(b.name_vi)).map((cate) => (
                    <div className={category === cate.id ? 'category-active' : ''} onClick={() => setCategory(cate.id)}>
                      {cate.name_vi}
                    </div>
                  ))
                }
                <StyledBilledTypeContainer>
                  {/* <Button disabled={templateLocale === 'en'} onClick={() => setTemplateLocale('en')} style={{ borderRadius: '6px 0 0 6px' }}>
                    EN
                  </Button>
                  <Button disabled={templateLocale === 'vi'} onClick={() => setTemplateLocale('vi')} style={{ borderRadius: '0 6px 6px 0' }}>
                    VI
                  </Button> */}
                  <div>
                    <span className={templateLocale === 'en' ? 'span-subscription-time active' : 'span-subscription-time'}>EN</span>
                    <Switch className="switch-subscription-time" checked={templateLocale === 'vi'} onChange={(checked) => checked ? setTemplateLocale('vi') : setTemplateLocale('en')} />
                    <span className={templateLocale === 'vi' ? 'span-subscription-time active' : 'span-subscription-time'}>VI</span>
                  </div>
                </StyledBilledTypeContainer>
              </StyledListCategory>
            </StyledSiderDiv>
          </StyledSider>

          <Layout style={{ padding: isMobile() ? '16px 8px' : '16px 16px', backgroundColor: 'transparent' }}>
            <StyledContent>

              <Row>
                {category === -1 && (
                  <StyledDiv>
                    {templates.filter(template => template.templateInfo.language?.includes(templateLocale)).map(template => (
                      <LazyLoadComponent>
                        {/* <div> */}
                        {loadingTemplates && (
                          <Skeleton className='loading-templates' paragraph={false} title={true} avatar={{ size: 'large', shape: 'square' }} active />
                        )}
                        <Col xxl={!isMobile() ? 5 : 9} xl={!isMobile() ? 5 : 9} lg={!isMobile() ? 7 : 9} md={!isMobile() ? 9 : 9} sm={9} xs={9} key={template._id} style={{ padding: 0 }}>
                          <StyledCard
                            size="small"
                            cover={<LazyLoadImage effect="blur" alt={template.templateInfo.name} onClick={() => onClickTemplate(template._id)} src={template.templateInfo.backgroundImage} scrollPosition={scrollPosition} style={{ width: '100%' }} />}
                            style={loadingTemplates ? { visibility: 'hidden', position: 'absolute' } : { visibility: 'visible' }}
                          >
                            <span onClick={() => onClickTemplate(template._id)}><Card.Meta style={{ padding: '8px 0' }} title={template.templateInfo.name} /></span>
                          </StyledCard>
                        </Col>
                        {/* </div> */}
                        {/* <Col xxl={!isMobile() ? 3 : 8} xl={!isMobile() ? 3 : 8} lg={!isMobile() ? 6 : 8} md={!isMobile() ? 8 : 8} sm={8} xs={8} key={template._id} style={{ padding: 12 }}>
                          {loadingTemplates && (
                            <Skeleton className='loading-templates' paragraph={false} title={true} avatar={{ size: 'large', shape: 'square' }} active />
                          )}
                          <StyledCard
                            size="small"
                            cover={<LazyLoadImage effect="blur" alt={template.templateInfo.name} onClick={() => onClickTemplate(template._id)} src={template.templateInfo.backgroundImage} scrollPosition={scrollPosition} style={{ width: '100%' }} />}
                            style={loadingTemplates ? { visibility: 'hidden', position: 'absolute' } : { visibility: 'visible' }}
                          >
                            <span onClick={() => onClickTemplate(template._id)}><Card.Meta style={{ padding: '8px 0' }} title={template.templateInfo.name} /></span>
                          </StyledCard>
                        </Col> */}
                      </LazyLoadComponent>
                    ))}
                  </StyledDiv>
                )}
                {category !== -1 && (
                  <StyledDiv>
                    {/* <StyledCategoryTitle>{templateLocale === 'en' ? listCategory[category].name : templateLocale === 'vi' ? listCategory[category].name_vi : listCategory[category].name}</StyledCategoryTitle> */}
                    {/* <StyledCategoryTitle>{templateLocale === 'en' ? listCategory[category].name : templateLocale === 'vi' ? '' : listCategory[category].name}</StyledCategoryTitle>
                    <Divider style={{ margin: '12px 0' }} /> */}
                    {filteredTemplates.map(template => (
                      <LazyLoadComponent>
                        {loadingTemplates && (
                          <Skeleton className='loading-templates' paragraph={false} title={true} avatar={{ size: 'large', shape: 'square' }} active />
                        )}
                        <Col xxl={!isMobile() ? 5 : 9} xl={!isMobile() ? 5 : 9} lg={!isMobile() ? 7 : 9} md={!isMobile() ? 9 : 9} sm={9} xs={9} key={template._id} style={{ padding: 0 }}>
                          <StyledCard
                            size="small"
                            cover={<img alt={template.templateInfo.name} onClick={() => onClickTemplate(template._id)} src={template.templateInfo.backgroundImage} />}
                          // actions={[
                          //   <div>
                          //     <span onClick={() => onDuplicate(template)}>
                          //       {formatMessage({ id: 'useTemplate' })}
                          //     </span>
                          //   </div>
                          // ]}
                          >
                            <span onClick={() => onClickTemplate(template._id)}><Card.Meta style={{ padding: '8px 0' }} title={template.templateInfo.name} /></span>
                          </StyledCard>
                        </Col>
                      </LazyLoadComponent>
                    ))}
                  </StyledDiv>
                )}
                {/* </Row> */}
                {filteredTemplates.length === 0 && <Empty description={'No template'} />}
              </Row>
            </StyledContent>
          </Layout >
        </StyledLayout >
      }
      {openTemplate && <FormPreview fromTemplate onClickBack={onClickBack} onDuplicate={onDuplicate} onSkipTemplate={() => onSkipTemplate()} previewing={false}
        isTestMode={true} focusedToFormItemId={null} formId={templateId} />}
    </StyledTemplate>
  );
});
