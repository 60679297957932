import * as React from 'react';
import { useApolloClient } from 'react-apollo-hooks';
import { Card, Col, Divider, Icon, Empty, Layout, Button, Row, Skeleton, Switch } from 'antd';
import { GET_TEMPLATE_FORMS } from '@formtory/shared/graphql/form';
import { GetTemplateForms } from '@formtory/shared/graphql/types';
import { useDuplicateFormMutation } from './Hooks';
import styled from 'styled-components';
import { isMobile } from 'utils/helperFunctions';
import { StyledIntro } from 'components/Layouts/Authentication';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AppContext } from '@formtory/shared/contexts/AppContext';
import FormPreview from '@formtory/shared/components/DisplaySingleForm/Main';
import { LazyLoadImage, LazyLoadComponent, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const { Content, Sider } = Layout;

const StyledTemplate = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  z-index: 100;
  background: #fff;
  
`;

const StyledMenuToggleButton = styled<any>(Button)`
  position: fixed;
  top: 0px;
  left: 0;
  border: 0;
  padding: 5px 15px !important;
  box-shadow: 0 0 10px #eee;
`;

const StyledLayout = styled(Layout)`
  // margin-top: 70px;
  // min-height: 96vh;
  height: calc(100vh - 70px);
  height: 100vh;
  
  &.mobile {
    .ant-layout-content {
      margin-top: 20px;
    }
  }
`;

const StyledSider = styled<any>(Sider)`
  background: rgb(241, 236, 227);
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .text {
    flex-shrink: 0;
    min-width: 0px;
    padding: 24px 0px 40px;
    .title {
      display: block;
      font-size: 24px;
      line-height: 32px;
      font-weight: 300;
      margin-bottom: 16px;
    }
    .description {
      
    }
  }
  
`;

const StyledContent = styled(Content)`
  overflow: hidden;
  .btn-template-action {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    font-family: inherit;
    transition: all 0.4s ease 0s;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    height: 36px;
    padding: ${!isMobile() ? '0 20px' : '0 10px'};
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    border-radius: 6px;
  }
`;

const StyledCategoryRow = styled(Row)`
  margin-bottom: 48px;
  .loading-templates {
    display: flex;
    flex-direction: column;
    .ant-skeleton-header{
      padding: 0;
      .ant-skeleton-avatar {
        width: 100%;
        // height: auto;
        padding-top: 90%;
        border-radius: 8px;
      }
    }
    .ant-skeleton-title {
      border-radius: 6px;
      margin: 10px auto;
      width: 80%;
    }
  }
`;

const StyledCategoryTitle = styled.span`
  display: block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

const StyledListCategory = styled.div`
  width: 100%;
  div {
    cursor: pointer;
    min-height: 48px;
    box-shadow: rgb(0 0 0 / 7%) 0px -1px inset;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .active {
    font-size: 16px
    font-weight: 700;
  }
`;

const StyledCard = styled(Card)`
  animation: 0.5s ease-in-out 0s 1 normal none running jBcSpD;
  transition: all 0.3s ease-in 0s;
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  .ant-card-cover img {
    border-radius: 8px 8px 0 0;
  }
  .ant-card-actions {
    border-radius: 0 0 8px 8px;
  }
  .ant-card-meta-title {
    display: block;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: rgb(19, 19, 19);
    text-align: center;
  }
  .ant-card-actions > li {
    margin: 8px 0;
    span {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const StyledBilledTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  // button {
  //   margin: 0 5px;
  //   font-size: 15px;
  //   color: rgb(187, 187, 187);
  //   &:hover,
  //   &:active,
  //   &:focus {
  //     border: 2px solid #643ced;
  //     background: #643ced 19;
  //     color: #643ced;
  //   }
  // }
  // button.active {
  //   border: 2px solid #643ced;
  //   // background: #643ced19;
  //   color: #643ced;
  // }
  // .saved-text {
  //   margin-left: 200px;
  //   margin-top: 5px;
  //   color: #643ced;
  // }
  .switch-subscription-time {
    background: #643ced;
  }
  .span-subscription-time {
    color: rgb(187, 187, 187);
    font-weight: 600;
    padding: 0 10px;
  }
  .active {
    color: rgb(19, 19, 19);
  }
`;

export default trackWindowScroll(({ workspaceId, onSkipTemplate, onCancel, scrollPosition }) => {
  const [templates, setTemplates] = React.useState<GetTemplateForms['templateForms']>([]);
  const [category, setCategory] = React.useState(-1);
  const [openSideMenu, setOpenSideMenu] = React.useState(false);
  const [openTemplate, setOpenTemplate] = React.useState(false);
  const [templateId, setTemplateId] = React.useState(null);
  const [loadingTemplates, setLoadingTemplates] = React.useState(true);
  const [templateLocale, setTemplateLocale] = React.useState(localStorage.getItem('locale') || 'vi')
  const client = useApolloClient();
  const { intl: { formatMessage } } = React.useContext(AppContext);
  // const locale = localStorage.getItem('locale') || 'en';

  const listCategory = [
    // { id: 'ALL', name: 'All' },
    { id: 0, code: 'APPLICATION', name: 'Application', name_vi: 'Ứng tuyển' },
    { id: 1, code: 'BOOKING', name: 'Booking', name_vi: 'Đặt chỗ' },
    { id: 2, code: 'FEEDBACK', name: 'Feedback', name_vi: 'Phản hồi' },
    { id: 3, code: 'GIVE AWAY', name: 'Give Away', name_vi: 'Phát thưởng' },
    { id: 4, code: 'LEAD CAPTURE', name: 'Lead Capture', name_vi: 'Thu thập khách hàng' },
    { id: 5, code: 'ORDER FORMS', name: 'Order Forms', name_vi: 'Đặt hàng' },
    { id: 6, code: 'POLL', name: 'Poll', name_vi: 'Bình chọn' },
    { id: 7, code: 'QUIZ', name: 'Quiz', name_vi: 'Đố vui' },
    { id: 8, code: 'REGISTRATION', name: 'Registration', name_vi: 'Đăng ký' },
    { id: 9, code: 'REPORT', name: 'Report', name_vi: 'Báo cáo' },
    { id: 10, code: 'RESEARCH', name: 'Research', name_vi: 'Khảo sát' },
    // { code: 'HR', name: 'HR' },
    // { code: 'EVENT', name: 'Event' },
    // { code: 'OTHER', name: formatMessage({ id: 'othersTemplate' }) },
  ]

  React.useEffect(() => {
    handleGetTemplates().then(r => r);
  }, []);

  React.useEffect(() => {
    onLoadingTemplates()
  })

  const handleGetTemplates = async () => {
    const { data } = await client.query<GetTemplateForms>({ query: GET_TEMPLATE_FORMS });
    setTemplates(data?.templateForms || []);
  };

  const filteredTemplates =
    category === -1 ? templates : templates.filter(template => template.templateInfo.language?.includes(templateLocale)).filter(template => template.templateInfo.categories.includes(listCategory[category].code));

  const filteredAllTemplates = (category) => {
    return templates.filter(template => template.templateInfo.language?.includes(templateLocale)).filter(template => template.templateInfo.categories.includes(category))
  }
  const duplicateFormMutation = useDuplicateFormMutation(workspaceId);

  const onDuplicate = async form => {
    await duplicateFormMutation({
      variables: { formId: form._id, workspaceId: workspaceId }
    });
    window.location.href = '/';
  };

  const onToggleSideMenu = () => {
    setOpenSideMenu(!openSideMenu);
  };

  const onLoadingTemplates = () => {
    setTimeout(() => {
      setLoadingTemplates(false)
    }, 1500);
  };

  const onClickTemplate = (id) => {
    setOpenTemplate(true);
    setTemplateId(id)
  }

  const onClickBack = () => {
    setOpenTemplate(false);
  }

  return (
    <StyledTemplate>
      {!openTemplate &&
        <StyledLayout>
          {isMobile() && (
            <StyledMenuToggleButton onClick={onToggleSideMenu} style={openSideMenu ? { left: 320 } : {}}>
              <Icon type="menu" />
            </StyledMenuToggleButton>
          )}
          <StyledSider
            width={isMobile() && !openSideMenu ? 0 : 320}
            theme={'light'}
            className={isMobile() ? 'mobile sider' : 'sider'}
          >
            <StyledIntro style={{ justifyContent: 'flex-start', padding: '40px', background: 'unset', color: 'rgb(38, 38, 39)' }}>
              {/* <Col md={{ span: 6 }} xs={{ span: 0 }}> */}
              {/* <Divider orientation={'left'}>Templates</Divider> */}
              <div className='text'>
                <span className='title'>{formatMessage({ id: 'templateGallery' })}</span>
                <span className='description'>{formatMessage({ id: 'useTemplatesToGetToKnowForm' })}</span>
              </div>
              <PerfectScrollbar style={{ width: '100%', maxHeight: '50vh' }}>
                <StyledListCategory>
                  <div className={category === -1 ? 'active' : ''} onClick={() => setCategory(-1)}>
                    {formatMessage({ id: 'allNormal' })}
                  </div>
                  {templateLocale === 'en' &&
                    listCategory.sort((a, b) => a.name.localeCompare(b.name)).map((cate) => (
                      <div className={category === cate.id ? 'active' : ''} onClick={() => setCategory(cate.id)}>
                        {cate.name}
                      </div>
                    ))}
                  {templateLocale === 'vi' &&
                    listCategory.sort((a, b) => a.name_vi.localeCompare(b.name_vi)).map((cate) => (
                      <div className={category === cate.id ? 'active' : ''} onClick={() => setCategory(cate.id)}>
                        {cate.name_vi}
                      </div>
                    ))
                  }
                </StyledListCategory>
              </PerfectScrollbar>
              {/* </Col> */}
              {/* <div className="svgWrapper-2-4f9 candy-18ETa">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={90}
                  height={66}
                  viewBox="0 0 90 66"
                  fill="none"
                  className="injected-svg"
                  data-src="https://static.crello.com/images/modals/auth/candy.svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 5.05V66h90c-2.634-10.05-6.528-20.025-11.74-29.672C57.54-2.017 30.526-5.282 0 5.049z"
                    fill="#EA4D33"
                  />
                </svg>
              </div>
              <div className="svgWrapper-2-4f9 sonic-1w6RA">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={111}
                  height={66}
                  viewBox="0 0 111 66"
                  fill="none"
                  className="injected-svg"
                  data-src="https://static.crello.com/images/modals/auth/sonic.svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 0c5.87 7.923 13.502 15.094 21.018 22.155 1.992 1.872 3.976 3.735 5.916 5.603 3.222 3.1 6.438 6.454 9.687 9.841C51.478 53.09 67.026 69.3 86.94 65.417c10.986-2.142 18.336-9.585 24.06-19.187V0H0z"
                    fill="#2153CC"
                  />
                </svg>
              </div> */}
            </StyledIntro>
          </StyledSider>
          <Layout style={{ padding: isMobile() ? '44px 16px' : '64px 64px 0', backgroundColor: '#FFF' }}>
            <StyledContent>
              {/* <Row gutter={16}> */}
              <Row style={{ padding: '0 0 8px 0', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '90%' }}>
                  <Button className='btn-template-action' type={'primary'} onClick={() => onSkipTemplate()}>
                    {formatMessage({ id: 'startBlank' })}
                  </Button>
                  <Button className='btn-template-action' style={{ marginLeft: '8px' }} onClick={() => onCancel()}>
                    {formatMessage({ id: 'cancel' })}
                  </Button>
                </div>
                <StyledBilledTypeContainer>
                  {/* <Button disabled={templateLocale === 'en'} onClick={() => setTemplateLocale('en')} style={{ borderRadius: '6px 0 0 6px' }}>
                    EN
                  </Button>
                  <Button disabled={templateLocale === 'vi'} onClick={() => setTemplateLocale('vi')} style={{ borderRadius: '0 6px 6px 0' }}>
                    VI
                  </Button> */}
                  <div>
                    <span className={templateLocale === 'en' ? 'span-subscription-time active' : 'span-subscription-time'}>EN</span>
                    <Switch className="switch-subscription-time" checked={templateLocale === 'vi'} onChange={(checked) => checked ? setTemplateLocale('vi') : setTemplateLocale('en')} />
                    <span className={templateLocale === 'vi' ? 'span-subscription-time active' : 'span-subscription-time'}>VI</span>
                  </div>
                </StyledBilledTypeContainer>
              </Row>
              {/* <Divider /> */}
              <PerfectScrollbar style={{ height: "calc(100vh - 124px)" }}>

                <Row style={{ marginTop: 40 }}>
                  {category === -1 && (
                    listCategory.map(cate => (
                      <StyledCategoryRow>
                        <StyledCategoryTitle>{templateLocale === 'en' ? cate.name : templateLocale === 'vi' ? cate.name_vi : cate.name}</StyledCategoryTitle>
                        <Divider style={{ margin: '12px 0' }} />
                        <div>
                          {filteredAllTemplates(cate.code).map(template => (
                            <LazyLoadComponent>
                              <Col xxl={!isMobile() ? 4 : 12} xl={!isMobile() ? 4 : 12} lg={!isMobile() ? 6 : 12} md={!isMobile() ? 8 : 12} sm={12} xs={12} key={template._id} style={{ padding: 12 }}>
                                {loadingTemplates && (
                                  <Skeleton className='loading-templates' paragraph={false} title={true} avatar={{ size: 'large', shape: 'square' }} active />
                                )}
                                <StyledCard
                                  size="small"
                                  cover={<LazyLoadImage effect="blur" alt={template.templateInfo.name} onClick={() => onClickTemplate(template._id)} src={template.templateInfo.backgroundImage} scrollPosition={scrollPosition} style={{ width: '100%' }} />}
                                  style={loadingTemplates ? { visibility: 'hidden', position: 'absolute' } : { visibility: 'visible' }}
                                // actions={[
                                //   <div>
                                //     <span onClick={() => onDuplicate(template)}>
                                //       {formatMessage({ id: 'useTemplate' })}
                                //     </span>
                                //   </div>
                                // ]}
                                >
                                  <span onClick={() => onClickTemplate(template._id)}><Card.Meta style={{ padding: '8px 0' }} title={template.templateInfo.name} /></span>
                                </StyledCard>
                              </Col>
                            </LazyLoadComponent>
                          ))}
                        </div>
                      </StyledCategoryRow>
                    ))
                  )}
                  {category !== -1 && (
                    <>
                      {/* <StyledCategoryTitle>{templateLocale === 'en' ? listCategory[category].name : templateLocale === 'vi' ? listCategory[category].name_vi : listCategory[category].name}</StyledCategoryTitle> */}
                      <StyledCategoryTitle>{templateLocale === 'en' ? listCategory[category].name : templateLocale === 'vi' ? '' : listCategory[category].name}</StyledCategoryTitle>
                      <Divider style={{ margin: '12px 0' }} />
                      {filteredTemplates.map(template => (
                        <Col xxl={!isMobile() ? 4 : 12} xl={!isMobile() ? 4 : 12} lg={!isMobile() ? 6 : 12} md={!isMobile() ? 8 : 12} sm={12} xs={12} key={template._id} style={{ padding: 12 }}>
                          <StyledCard
                            size="small"
                            cover={<img alt={template.templateInfo.name} onClick={() => onClickTemplate(template._id)} src={template.templateInfo.backgroundImage} />}
                          // actions={[
                          //   <div>
                          //     <span onClick={() => onDuplicate(template)}>
                          //       {formatMessage({ id: 'useTemplate' })}
                          //     </span>
                          //   </div>
                          // ]}
                          >
                            <span onClick={() => onClickTemplate(template._id)}><Card.Meta style={{ padding: '8px 0' }} title={template.templateInfo.name} /></span>
                          </StyledCard>
                        </Col>
                      ))}
                    </>
                  )}
                  {/* </Row> */}
                  {filteredTemplates.length === 0 && <Empty description={'No template'} />}
                </Row>
              </PerfectScrollbar>

            </StyledContent>
          </Layout >
        </StyledLayout >
      }
      {openTemplate && <FormPreview fromTemplate onClickBack={onClickBack} onDuplicate={onDuplicate} onSkipTemplate={() => onSkipTemplate()} previewing={false}
        isTestMode={true} focusedToFormItemId={null} formId={templateId} />}
    </StyledTemplate >
  );
});
