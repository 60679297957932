import React, { useState, useEffect } from 'react';
import { Rate, Icon } from 'antd';
import styled from 'styled-components';
import Title from './Elements/Title';
import Description from './Elements/Description';
import { FormItemBaseWrappedChildProps } from './FormItemBase';
import { StyledQuestionContainer, StyledErrorMessage, StyledQuestionContainerContent } from './Styled';
import { getSetting } from './Utils';
import QuestionLayout from './QuestionLayout';
import { FormItemSettingsLayout } from '../../graphql/types';
import Image from './Elements/Image';
import Video from './Elements/Video';
import Index from './Elements/Index';
import { isMobile } from '@formtory/app/src/utils/helperFunctions';
import Submit from './Submit';

const StyledRatingContainer = styled.div`
  text-align: left;
  h2 {
    color: ${(props) => props.theme.form.answers};
  }
  .ant-rate-star-first {
    position: absolute !important;
  }
  .ant-rate-star-full {
    i {
      color: ${(props) => props.theme.form.answers};
    }
  }
  .ant-rate-star-first,
  .ant-rate-star-second {
    color: ${(props) => props.theme.form.answers}4C;
  }
  .ant-rate-star:not(:last-child) {
    margin-right: ${isMobile() ? '4px' : '6px'}
  }
`;

const Rating = (props: FormItemBaseWrappedChildProps) => {
  const { formItem, index, customizedLabels, prices, scores, priceCurrency, isNextSubmit, onSubmit, submitting, formLayout } = props;
  const { settings } = formItem;
  const [result, setResult] = useState(props.results[formItem._id] || []);
  const items = Array.from(Array(settings.ratingSteps || 10).keys());
  const [hasError, setHasError] = useState(props.submitted && settings.required && !result);

  useEffect(() => {
    if (props.submitted && settings.required && !result) return setHasError(true);
  });

  const onSaveResult = (value) => {
    setResult(value);
    props.onResult(formItem._id, value);
  };

  return (
    <QuestionLayout
      videoUrl={settings.videoUrl}
      image={settings.image}
      hideImage={true}
      layout={settings.layout}
      imageBrightness={settings.imageBrightness}
      formLayout={formLayout}
    >
      <StyledQuestionContainer style={props.device === 'mobile' ? { justifyContent: 'center' } : {}}>
        <Index renderIndex={index} required={settings.required} />
        <StyledQuestionContainerContent style={props.device === 'mobile' ? { minWidth: '90%' } : {}}>
          <Title
            value={formItem.title}
            results={props.results}
            prices={prices}
            priceCurrency={priceCurrency}
            scores={scores}
            containerProps={{
              style: { textAlign: 'left', marginBottom: settings.descriptionEnabled && formItem.description ? 0 : 22 },
            }}

          />
          {settings.descriptionEnabled && formItem.description && (
            <Description
              prices={prices}
              scores={scores}
              priceCurrency={priceCurrency}
              results={props.results}
              value={formItem.description}
            />
          )}
          {settings.videoUrl && <Video value={settings.videoUrl} />}
          {!settings.videoUrl &&
            settings.image &&
            (!settings.layout || settings.layout === FormItemSettingsLayout.NORMAL) && (
              <Image
                value={settings.image}
                containerProps={{
                  className: 'image--normal',
                  style: { filter: `brightness(${(settings.imageBrightness || 100) / 100})` },
                }}
              />
            )}
          <StyledRatingContainer>
            <Rate
              onChange={(star) => onSaveResult(star)}
              value={parseInt(result, 10)}
              style={
                !isMobile()
                  // ? { fontSize: `min(calc(30vw / ${items.length}), 4vw)` }
                  ? props.device === 'mobile'
                    ? { fontSize: `min(calc((400px - 6px * ${items.length}) / ${items.length}), calc((400px - 6px * 8) / 8))` }
                    : { fontSize: `min(calc((700px - 6px * ${items.length}) / ${items.length}), calc((700px - 6px * 8) / 8))` }
                  : { fontSize: `min(calc(70vw / ${items.length}), 12vw)` }
              }
              count={items.length}
              // tooltips={[...Array(items.length).keys()].map((x) => x + 1).map(String)}
              character={<Icon type={settings.ratingIconName || 'star'} theme={'outlined'} />}
            />
            {result && <h2>Your Response: {result}</h2>}
          </StyledRatingContainer>
          <div className="error">
            {hasError && (
              <StyledErrorMessage>
                {getSetting(customizedLabels, 'ERROR_IF_AN_ANSWER_IS_REQUIRED', 'Oops, Please enter your answer!')}
              </StyledErrorMessage>
            )}
          </div>
          {/* <div className="buttons" /> */}
          {formLayout !== 'classic' &&
            <div className="buttons">
              {isNextSubmit && <Submit customizedLabels={customizedLabels} onSubmit={onSubmit} submitting={submitting} activeItemId={isNextSubmit && 'submit'} />}
            </div>
          }
        </StyledQuestionContainerContent>
      </StyledQuestionContainer>
    </QuestionLayout>
  );
};

export default Rating;
