import * as React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import UrlPattern from 'url-pattern';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withApollo, WithApolloClient } from 'react-apollo';
import { RouterHistoryService } from '@formtory/shared/core';
import { ME } from '@formtory/shared/graphql/authentication';
import { AppContextInterface, AppContext } from '@formtory/shared/contexts/AppContext';

import Header from '../../components/Layouts/Header';
import renderRoutes from '../../routes';
import { isHideHeader, isHideLoading } from '../../utils/pager';
import { hasNoPlan, hasPendingPlan, hasNoTeamPlan, hasPendingTeamPlan } from '../../utils/plan';
import TeamFormModal from '../../components/Team/TeamFormModal';
import { StyledBranding } from '@formtory/shared/components/DisplaySingleForm/Styled';
import { Brand } from '@formtory/shared/components/DisplaySingleForm/BottomWidget';

function getTitleTemplate(totalMessagesUnread: number = 0, totalNotificationUnread: number = 0) {
  const count = totalMessagesUnread + totalNotificationUnread;
  return `${count > 0 ? `(${count}) ` : ''}%s | Formtory`;
}

class App extends React.PureComponent<RouteComponentProps & WithApolloClient<any>, { loaded: boolean }> {
  context: AppContextInterface;

  async componentDidMount() {
    const singleDisplayFormPattern = new UrlPattern('/f(/:id)');
    if (singleDisplayFormPattern.match(window.location.pathname)) {
      this.context.onUpdateContext({ authLoaded: true });
      return null;
    }
    const { data } = await this.props.client.query({
      query: ME,
    });
    if (!data || !data.me) {
      this.context.onUpdateContext({ authLoaded: true });
      return this.onRedirectToLogin();
    }
    // const loginPagePattern = new UrlPattern('/login');
    // if (data && data.me && loginPagePattern.match(window.location.pathname)) {
    //   location.href = '/'
    // }
    this.context.onUpdateContext({ authLoaded: true, auth: data.me, authenticated: true });
    this.context.onCheckLimitResponse(this.context);

    if (data.me.teams.length === 0 || !localStorage.getItem('selectedTeamId')) {
      if (hasNoPlan(data.me)) RouterHistoryService.push('/plan/personal');
      if (hasPendingPlan(data.me)) RouterHistoryService.push('/confirms/plan/personal');
    } else {
      const selectedTeamId = localStorage.getItem('selectedTeamId');
      const selectedTeam = data.me.teams.find(item => item._id === selectedTeamId);
      if (data.me._id === selectedTeam?.ownerId) {
        // if (hasNoTeamPlan(data.me)) RouterHistoryService.push('/plan/team');
        if (hasNoTeamPlan(data.me)) RouterHistoryService.push('/plan/personal');
        if (hasPendingTeamPlan(data.me)) RouterHistoryService.push('/confirms/plan/team');
      } else {
        if (hasNoTeamPlan(data.me) || hasPendingTeamPlan(data.me)) {
          localStorage.removeItem('selectedTeamId');
          location.href = '/';
        }
      }
    }
    return null;
  }

  onRedirectToLogin = () => {
    const loginPagePattern = new UrlPattern('/login');
    if (loginPagePattern.match(window.location.pathname)) return null;
    return (window.location.href = `/login?redirectUri=${encodeURIComponent(window.location.pathname)}`);
  };

  componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  onUpgrade = () => {
    this.context.onUpdateContext({ displayUpgradeOverlay: false });
    setTimeout(() => {
      return RouterHistoryService.push('/plan/personal');
    }, 500);
  };

  onTeamCreated = (isCreated: boolean) => {
    this.context.onUpdateContext({ openCreateNewTeam: false, openTeamSettings: false });
    if (isCreated) {
      setTimeout(() => {
        return RouterHistoryService.push('/plan/personal');
      }, 500);
    }
  };

  // onGist = () => {
  //   (function(d,h,w){
  //     var gist=w.gist=w.gist||[];
  //     gist.methods=['trackPageView','identify','track','setAppId'];
  //     gist.factory=function(t){return function(){
  //       var e=Array.prototype.slice.call(arguments);e.unshift(t);gist.push(e);return gist;
  //     }};
  //     for(var i=0;i<gist.methods.length;i++){
  //       var c=gist.methods[i];gist[c]=gist.factory(c)
  //     }
  //     s=d.createElement('script'),
  //     s.src="https://widget.getgist.com",
  //     s.async=!0,
  //     e=d.getElementsByTagName(h)[0],
  //     e.appendChild(s),
  //     s.addEventListener('load',function(e){},!1),
  //     gist.setAppId("m2v4rbwt"),
  //     gist.trackPageView()
  //   })(document,'head',window);
  // }

  render() {
    const title = `Formtory | Create Amazing Form`;
    const { authLoaded } = this.context;
    if (!isHideLoading()) {
      if (!authLoaded) {
        return (
          <div className="screen-loading">
            <div id="circle3" />
            <StyledBranding>
              <Brand />
            </StyledBranding>
          </div>
        );
      }
    }
    return (
      <HelmetProvider>
        <div style={{ position: 'relative' }}>
          <Helmet titleTemplate={getTitleTemplate(0, 0)} defaultTitle={title} meta={[]}>
            <link rel="icon" type="image/png" href="https://admin.formtory.com/favicon.png" />
          </Helmet>
          {!isHideHeader() && <Header />}
          {renderRoutes()}
          <TeamFormModal
            onSubmitted={this.onTeamCreated}
            initialValues={this.context.openTeamSettings ? this.context.selectedTeam : null}
            visible={this.context.openCreateNewTeam || this.context.openTeamSettings}
            onClose={() => this.context.onUpdateContext({ openCreateNewTeam: false, openTeamSettings: false })}
          />
        </div>
      </HelmetProvider>
    );
  }
}

App.contextType = AppContext;
export default Object.assign(withRouter(withApollo(App)), { contextType: undefined });
