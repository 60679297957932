import { Auth } from '../auth';
require('es6-promise').polyfill();

class GraphQL {

  private endPoint: string;

  constructor() {
    this.endPoint = process.env.GRAPHQL_ENDPOINT_URL;
  }

  static buildHeader() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: Auth.service.getToken(),
    };
  }

  request(query: string) {
    return fetch(`${this.endPoint}${query}`, {
      method: 'POST',
      headers: GraphQL.buildHeader(),
    }).then((response: Response) => {
      return response.json();
    });
  }
}

export default new GraphQL();
