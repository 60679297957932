import gql from 'graphql-tag';
import { TEAM_BASE_FIELDS } from './fragments';

export const CREATE_TEAM = gql`
  mutation CreateTeamMutation($data: TeamInput!) {
    createTeam(data: $data) {
      ...TeamBaseFields
    }
  }
  ${TEAM_BASE_FIELDS}
`;

export const UPDATE_TEAM = gql`
  mutation UpdateTeamMutation($teamId: ID!, $data: TeamInput!) {
    updateTeam(teamId: $teamId, data: $data) {
      ...TeamBaseFields
    }
  }
  ${TEAM_BASE_FIELDS}
`;
