import React, { useState, useEffect } from 'react';
import { Upload, Icon } from 'antd';
import styled from 'styled-components';
import { useMutation } from 'react-apollo-hooks';
import { SingleFileUpload, SingleFileUploadVariables } from '@formtory/shared/graphql/types';
import { SINGLE_FILE_UPLOAD } from '@formtory/shared/graphql/file';
import Title from './Elements/Title';
import ButtonGroup from './Elements/ButtonGroup';
import Description from './Elements/Description';
import { FormItemBaseWrappedChildProps } from './FormItemBase';
import { StyledQuestionContainer, StyledQuestionContainerContent } from './Styled';
import { getSetting } from './Utils';
import Index from './Elements/Index';
import QuestionLayout from './QuestionLayout';
import Submit from './Submit';
import { PLANS } from '../../data/pricing';
import { AppContext } from '../../contexts/AppContext';

export const useSingleFileUploadMutation = () => {
  return useMutation<SingleFileUpload, SingleFileUploadVariables>(SINGLE_FILE_UPLOAD);
};

const StyledFileUpload = styled(Upload.Dragger)`
  .ant-upload {
    border-radius: 6px;
    border-color: ${(props) => props.theme.form.answers};
    background-color: ${(props) => props.theme.form.answers}19;
    border-width: 1px;
    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.form.answers};
    }
    i {
      color: ${(props) => props.theme.form.answers};
    }
    .ant-upload-text {
      font-size: 18px !important;
      color: ${(props) => props.theme.form.answers} !important;
    }
    .ant-upload-hint {
      font-size: 14px !important;
      color: ${(props) => props.theme.form.answers} !important;
    }
  }
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: ${(props) => props.theme.form.answers};
  }
  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: ${(props) => props.theme.form.answers} !important;
  }
  .ant-upload.ant-upload-drag {
    height: auto;
  }
`;

const StyledUploadedItem = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${(props) => props.theme.form.answers}19;
  border: 1px solid ${(props) => props.theme.form.answers};
  margin: 15px 10px 10px 0;
  border-radius: 3px;
  p {
    color: ${(props) => props.theme.form.answers};
    padding: 0 10px;
    margin: 0;
  }
  i {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 18px;
    color: ${(props) => props.theme.form.answers};
  }
`;

const files = [];

const FileUpload = (props: FormItemBaseWrappedChildProps) => {
  const { formItem, index, customizedLabels, previewing, prices, scores, priceCurrency, isNextSubmit, onSubmit, submitting, formLayout } = props;
  const { settings } = formItem;
  const context = React.useContext(AppContext);
  const { onCheckCurrentPlan } = context;
  const [result, setResult] = useState(props.results[formItem._id] || []);
  const [uploading, setUploading] = useState(false);
  const [sizeTooBig, setSizeTooBig] = useState(false);
  const [hasRequiredError, setHasRequiredError] = useState(props.submitted && settings.required && !result);

  useEffect(() => {
    if (props.submitted && settings.required && !result) return setHasRequiredError(true);
  });

  const singleUpload = useSingleFileUploadMutation();

  const uploadProps: any = {
    name: 'file',
    multiple: true,
    beforeUpload: async (file) => {
      if ((onCheckCurrentPlan(context) === PLANS.FREE || onCheckCurrentPlan(context) === PLANS.BASIC) && file.size > 2 * 1024 * 1024) return setSizeTooBig(true);
      if ((onCheckCurrentPlan(context) === PLANS.PRO) && file.size > 5 * 1024 * 1024) return setSizeTooBig(true);
      if ((onCheckCurrentPlan(context) === PLANS.BUSINESS) && file.size > 10 * 1024 * 1024) return setSizeTooBig(true);
      setSizeTooBig(false);
      setUploading(true);
      setHasRequiredError(false);
      const response = await singleUpload({ variables: { file } });
      if (!response.data.singleUpload || !response.data.singleUpload.uri) return null;
      files.push({ fileName: file.name, uri: response.data.singleUpload.uri });
      setResult([...files]);
      setUploading(false);
      // formLayout === 'classic' && onSaveResult()
      return false;
    },
  };

  const onSaveResult = () => {
    if (files.length === 0 && settings.required) return null;
    setTimeout(() => {
      return props.onResult(formItem._id, files);
    }, 200);
  };

  const onDeleteFile = (index) => {
    files.splice(index, 1);
    setResult([...files]);
  };

  return (
    <QuestionLayout
      videoUrl={settings.videoUrl}
      image={settings.image}
      hideImage={true}
      layout={settings.layout}
      imageBrightness={settings.imageBrightness}
      formLayout={formLayout}
    >
      <StyledQuestionContainer style={props.device === 'mobile' ? { justifyContent: 'center' } : {}}>
        <Index renderIndex={index} required={settings.required} />
        <StyledQuestionContainerContent style={props.device === 'mobile' ? { minWidth: '90%' } : {}}>
          <Title
            prices={prices}
            priceCurrency={priceCurrency}
            scores={scores}
            value={formItem.title}
            results={props.results}
            containerProps={{
              style: { textAlign: 'left', marginBottom: settings.descriptionEnabled && formItem.description ? 0 : 22 },
            }}

          />
          {settings.descriptionEnabled && formItem.description && (
            <Description
              prices={prices}
              scores={scores}
              priceCurrency={priceCurrency}
              results={props.results}
              value={formItem.description}
            />
          )}
          <StyledFileUpload {...uploadProps} fileList={null}>
            <p className="ant-upload-drag-icon">
              <Icon type={uploading ? 'loading' : 'paper-clip'} />
            </p>
            <p className="ant-upload-text">
              {uploading
                ? getSetting(customizedLabels, 'FILE_UPLOAD_YOUR_FILE_STILL_UPLOADING', 'Uploading...')
                : getSetting(customizedLabels, 'FILE_UPLOAD_BUTTON_TEXT', 'Choose file')}
            </p>
            {sizeTooBig && (
              <p className="ant-upload-hint">
                {getSetting(customizedLabels, 'FILE_UPLOAD_ERROR_FILE_TO_BIG', 'Size too big. Max is 2MB!')}
              </p>
            )}
            {hasRequiredError && (
              <p className="ant-upload-hint">
                {getSetting(customizedLabels, 'ERROR_IF_AN_ANSWER_IS_REQUIRED', 'Size too big. Max is 2MB!')}
              </p>
            )}
          </StyledFileUpload>
          <div style={{ display: 'flex' }}>
            {result.map((file, index) => (
              <StyledUploadedItem key={file.uri}>
                <p>{file.fileName}</p>
                <a onClick={() => onDeleteFile(index)}>
                  <Icon type={'delete'} />
                </a>
              </StyledUploadedItem>
            ))}
          </div>
          {/* {formLayout !== 'classic' && */}
          <div className="buttons">
            {props.active && result && !isNextSubmit && (
              <ButtonGroup
                previewing={previewing}
                onClick={onSaveResult}
                skipEnter={false}
                label={getSetting(customizedLabels, 'CONFIRM_ANSWER_BUTTON_TEXT', 'OK') || 'OK'}
              />
            )}
            {formLayout !== 'classic' && isNextSubmit && <Submit customizedLabels={customizedLabels} onSubmit={onSubmit} submitting={submitting} activeItemId={isNextSubmit && 'submit'} />}
          </div>
          {/* } */}
        </StyledQuestionContainerContent>
      </StyledQuestionContainer>
    </QuestionLayout>
  );
};

export default FileUpload;
