import * as React from 'react';
import Avatar from 'react-avatar';
import { Dropdown, Menu, Icon, Divider, Button } from 'antd';
import { RouterHistoryService } from '@formtory/shared/core';
import BaseComponentWithContext from '@formtory/shared/components/BaseComponentWithContext';
import { isMobile } from '../../utils/helperFunctions';
import {
  StyledContent,
  // StyledCurrentLanguageButton,
  // StyledCurrentPlanButton,
  StyledHeaderMember,
} from './StyledHeader';
import HeaderNotifications from './HeaderNotifications';
import { cleanStorage } from '../../utils/storage';
import moment from 'moment';
import styled from 'styled-components';

export const StyledAvatarInfo = styled.div`
  display: flex;
  align-items: center;
  .avatar {
    span {
      font-size: 14px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    padding: 8px;
    h4 {
      margin: 0;
      color: ${(props: any) => (props.theme.colors.primary)};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100px;
      }
    span {
      font-size: 12px;
      line-height: 15px;
      color: ${(props: any) => (props.theme.colors.blackGrey)};
    }
  }
  i {
    color: ${(props: any) => (props.theme.colors.primary)};
  }
`;

const StyledMenu = styled<any>(Menu)`
  background: #fff;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2),0 2px 4px 0 rgba(0, 0, 0, 0.1),inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const menu = (handleChangeLanguage: (locale: string) => void) => (
  <Menu onClick={(param) => handleChangeLanguage(param.key)}>
    <Menu.Item key="en">English</Menu.Item>
    <Menu.Item key="vi">Tiếng Việt</Menu.Item>
  </Menu>
);


export default class HeaderMember extends BaseComponentWithContext<{}> {
  state = {
    visible: false,
  };
  handleChangeLanguage = (locale: string) => {
    localStorage.setItem('locale', locale);
    window.location.reload(true);
  };

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  }

  handleLogout() {
    cleanStorage();
    location.href = '/login';
  }

  authMenu = (onLogout: () => void) => {
    const { auth, selectedTeam, onCheckCurrentPlan, intl: { formatMessage } } = this.context;
    const plan = onCheckCurrentPlan(this.context)
    const personalPlan = auth?.accessKey?.plan && auth?.accessKey?.active ? auth?.accessKey?.plan : 'FREE'
    return (
      <StyledMenu>
        <Menu.Item>
          <StyledAvatarInfo>
            <Avatar
              className="avatar"
              round={true}
              src={auth.avatar}
              size={'38'}
              name={`${auth.firstName} ${auth.lastName}`}
            />
            <div className='info'>
              <h4 style={{ color: '#262627' }}>{`@${auth.username}`}</h4>
              <span>{auth.email}</span>
            </div>
          </StyledAvatarInfo>
        </Menu.Item>
        <Divider style={{ margin: '12px 0' }}></Divider>
        {!selectedTeam && (
          <Menu.Item
            key=""
            onClick={() => RouterHistoryService.push('/plan/personal')}
          >
            {/* {formatMessage({ id: 'personal' })} */}<h4 style={{ marginBottom: "0" }}>{personalPlan}</h4>
            <h4 style={{ margin: '0 0 0 8px' }}>{personalPlan === 'FREE' ? '' : <span>({moment(auth?.accessKey?.expirationDate).fromNow(true)})</span>}</h4>
          </Menu.Item>
        )}
        {selectedTeam &&
          <Menu.Item
            key=""
            onClick={() => selectedTeam && selectedTeam.ownerId === auth._id && RouterHistoryService.push('/plan/personal')}
          >
            {/* {formatMessage({ id: 'team' })} */}
            {selectedTeam.name}
            <h4 style={{ margin: '0 0 0 8px' }}>{<span>{plan} ({selectedTeam ? moment(selectedTeam?.accessKey?.expirationDate).fromNow(true) : moment(auth?.accessKey?.expirationDate).fromNow(true)})</span>}</h4>
          </Menu.Item>
        }
        {selectedTeam && selectedTeam.ownerId === auth._id && (
          <Menu.Item onClick={() => this.context.onUpdateContext({ openTeamSettings: true })}>{formatMessage({ id: 'settings' })}</Menu.Item>
        )}
        <Divider style={{ margin: '12px 0' }}></Divider>
        <Menu.Item>
          {formatMessage({ id: 'language' })}
          <Dropdown overlay={menu(this.handleChangeLanguage)} trigger={['click']}>
            <Button style={{ padding: '0 10px', border: 'none', boxShadow: 'none', height: '100%', lineHeight: 1 }}>{localStorage.locale === 'vi' ? 'Tiếng Việt' : localStorage.locale === 'en' ? 'English' : 'Tiếng Việt'}</Button>
          </Dropdown>
        </Menu.Item>
        <Divider style={{ margin: '12px 0' }}></Divider>
        <Menu.Item key="en" style={{ color: 'rgb(175, 4, 4)' }} onClick={(param) => onLogout()}>{formatMessage({ id: 'logout' })}</Menu.Item>
      </StyledMenu>
    )
  };

  render() {
    const { auth } = this.context;
    // const plan = selectedTeam ? (selectedTeam?.accessKey?.active ? selectedTeam?.accessKey?.plan : 'FREE') : (auth?.accessKey?.active ? auth?.accessKey?.plan : 'FREE');
    // const plan = onCheckCurrentPlan(this.context)
    return (
      <StyledHeaderMember>
        {auth && (
          <StyledContent>
            {/* {!isMobile() && (
              <Dropdown overlay={menu(this.handleChangeLanguage)}>
                <StyledCurrentLanguageButton>{localStorage.locale === 'en' ? 'en' : 'vi'}</StyledCurrentLanguageButton>
              </Dropdown>
            )} */}
            <HeaderNotifications />

            <Dropdown
              overlay={this.authMenu(this.handleLogout)}
              placement={'bottomRight'}
              trigger={['click']}
              onVisibleChange={this.handleVisibleChange}
              visible={this.state.visible}
            >
              {/* <div>
                  <Avatar
                    className="avatar"
                    round={true}
                    src={selectedTeam ? selectedTeam.logo : auth.avatar}
                    size={'40'}
                    name={selectedTeam ? selectedTeam.name : `${auth.firstName} ${auth.lastName}`}
                  />
                </div> */}
              <StyledAvatarInfo>
                <Avatar
                  className="avatar"
                  round={true}
                  src={auth.avatar}
                  size={'38'}
                  name={`${auth.firstName} ${auth.lastName}`}
                />
                {!isMobile() && (
                  <div className='info'>
                    <h4>{`${auth.firstName} ${auth.lastName}`}</h4>
                    {/* <span onClick={() => RouterHistoryService.push(selectedTeam ? '/plan/team' : '/plan/personal')}>{plan}</span> */}
                    {/* <span>{auth.email}</span> */}
                  </div>
                )}
                <div><Icon type="caret-down" /></div>
              </StyledAvatarInfo>
            </Dropdown>
            {/* {!isMobile() && (
              <StyledCurrentPlanButton
                onClick={() => RouterHistoryService.push(selectedTeam ? '/plan/team' : '/plan/personal')}
              >
                <span style={{ fontSize: '10px' }}>{plan}</span>
              </StyledCurrentPlanButton>
            )} */}


          </StyledContent>
        )}
      </StyledHeaderMember>
    );
  }
}
