import {
  GetWorkspaceFormItems_formItems,
  GetWorkspaceFormItems_formItems_logicJumps_conditions,
} from '@formtory/shared/graphql/types';
import { conditions as allConditions } from '@formtory/shared/data/default';
import { FormTypes } from '@formtory/shared/models/form';

export const checkCondition = (
  condition: GetWorkspaceFormItems_formItems_logicJumps_conditions,
  result: any,
  isFileUpload = false,
) => {
  result === 0
    ? result = JSON.stringify(result)
    : result = JSON.stringify(result || '');

  switch (condition.condition) {
    case allConditions['IS'].value:
      if (isFileUpload) return result.length > 0;
      return result.includes(condition.value);
    case allConditions['IS_NOT'].value:
      if (isFileUpload) return result.length === 0;
      return !result.includes(condition.value);
    case allConditions['IS_EQUAL_TO'].value:
      return `${condition.value}`.toLowerCase() === `${result}`.toLowerCase();
    case allConditions['IS_NOT_EQUAL_TO'].value:
      return JSON.stringify(condition.value.toLowerCase()) !== result.toLowerCase();
    case allConditions['BEGINS_WITH'].value:
      return !!result.startsWith(condition.value);
    case allConditions['ENDS_WITH'].value:
      return !!result.endsWith(condition.value);
    case allConditions['CONTAINS'].value:
      return result.includes(condition.value);
    case allConditions['DOES_NOT_CONTAINS'].value:
      return !result.includes(condition.value);
    case allConditions['IS_GREATER_THAN'].value:
      return parseInt(result, 10) > parseInt(condition.value, 10);
    case allConditions['IS_LOWER_THAN'].value:
      return parseInt(result, 10) < parseInt(condition.value, 10);
    default:
      return false;
  }
};

export const checkLogicJumpConditions = (
  conditions: Array<GetWorkspaceFormItems_formItems_logicJumps_conditions>,
  results: string,
  isFileUpload: boolean,
) => {
  if (conditions.length === 0) return true;
  const andConditions = conditions.filter((con) => con.and);
  let totalAndMatchConditions = 0;
  andConditions.forEach((condition) => {
    if (checkCondition(condition, results[condition.formItemId], isFileUpload)) totalAndMatchConditions += 1;
  });
  if (andConditions.length !== 0 && andConditions.length === totalAndMatchConditions) return true;
  const orConditions = conditions.filter((con) => con.or);
  let totalOrMatchConditions = 0;
  orConditions.forEach((condition) => {
    if (checkCondition(condition, results[condition.formItemId], isFileUpload)) totalOrMatchConditions += 1;
  });
  return totalOrMatchConditions > 0;
};

export const getJumpTo = (formItem: GetWorkspaceFormItems_formItems, results: any) => {
  let jumpTo = null;
  const isFileUpload = formItem.formType === FormTypes[FormTypes.FILE_UPLOAD];
  formItem.logicJumps.forEach((logicJump) => {
    if (jumpTo) return;
    const isMatched = checkLogicJumpConditions(logicJump.conditions, results, isFileUpload);
    if (isMatched) jumpTo = logicJump.jumpTo;
  });
  if (!jumpTo) {
    if (formItem.allCaseJumpTo) return formItem.allCaseJumpTo;
  }
  return jumpTo;
};

export const getNextFormItems = (
  currentFormItem: GetWorkspaceFormItems_formItems,
  currentFormItemIndex: number,
  formItems: Array<GetWorkspaceFormItems_formItems>,
) => {
  if (!currentFormItem) return;
  if (currentFormItem.logicJumps.length > 0) return [];
  if (currentFormItem.allCaseJumpTo) {
    return formItems.filter((item) => item._id === currentFormItem.allCaseJumpTo);
  }
  const logicFormItem = formItems
    .slice(currentFormItemIndex, formItems.length)
    .find((item) => !!(item.logicJumps.length > 0 || item.allCaseJumpTo));
  if (logicFormItem) {
    const logicFormItemIndex = formItems.findIndex((item) => item._id === logicFormItem._id);
    if (logicFormItem.logicJumps.length > 0) {
      return formItems.slice(currentFormItemIndex + 1, logicFormItemIndex + 1);
    }
    if (logicFormItem.allCaseJumpTo === formItems[logicFormItemIndex + 1]._id) {
      return formItems.slice(currentFormItemIndex + 1, logicFormItemIndex + 1);
    }
    if (logicFormItem.allCaseJumpTo) {
      return formItems
        .slice(currentFormItemIndex + 1, logicFormItemIndex + 1)
        .concat(formItems.filter((item) => item._id === currentFormItem.allCaseJumpTo));
    }
  }
  return formItems.slice(currentFormItemIndex + 1, formItems.length);
};
