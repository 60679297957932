import Fetcher from './services/fetch';
import GraphQL from './services/graphQL';
import GraphQLTypes from './services/graphQL/types';
import { Auth } from './services/auth';
import FacebookService from './services/auth/facebook.service';
import Mixpanel from './services/analytic/mix-panel';
import GoogleAnalytic from './services/analytic/google-analytic';
import Socket from './services/socket';
import NotificationCenter from './services/notification';
import RouterHistoryService from './services/router/history';
import EventHub from './events/hub';

const AuthService = Auth.service;

export {
  Fetcher,
  GraphQL,
  FacebookService,
  Mixpanel,
  NotificationCenter,
  EventHub,
  RouterHistoryService,
  Socket,
  GoogleAnalytic,
  GraphQLTypes,
  AuthService,
};
