import { GET_FORM, GET_WORKSPACE_FORM_ITEMS, PUBLISH_FORM } from '@formtory/shared/graphql/form';
import {
  GetForm, GetFormVariables, GetWorkspaceFormItems,
  GetWorkspaceFormItemsVariables, PublishForm,
  PublishFormVariables
} from '@formtory/shared/graphql/types';
import { Card, Dropdown, Icon, Menu } from 'antd';
import * as React from 'react';
// import { isMobile } from '../../utils/helperFunctions';
import { useApolloClient } from 'react-apollo-hooks';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from '../../../../shared/contexts/AppContext';
import { FormattedMessage } from 'react-intl';
import { LazyLoadComponent, trackWindowScroll, LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// interface Props {
//   bg: any;
// }

const CardActions = (item, onDelete, onDuplicate) => (
  <Menu>
    <Menu.Item key="0">
      <Link to={`/form/${item._id}/create`}><FormattedMessage id="edit" /></Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to={`/preview/form/${item?.hash}`} target={'_blank'}>
        <FormattedMessage id="preview" />
      </Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to={`/form/${item._id}/results`}><FormattedMessage id="results" /></Link>
    </Menu.Item>
    <Menu.Item onClick={onDuplicate}>
      <FormattedMessage id="duplicate" />
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item onClick={onDelete} style={{ color: 'rgb(175, 4, 4)' }}>
      <FormattedMessage id="delete" />
    </Menu.Item>
  </Menu>
);

const FormMainType = trackWindowScroll(({ form, onDelete, onDuplicate, scrollPosition }) => {
  const context = React.useContext(AppContext);
  const {
    intl: { formatMessage },
  } = context;
  const client = useApolloClient();
  React.useEffect(() => {
    if (form.publishedAt && !form.closeFormToNewResponses && context.limitResponse) {
      handlePublishForm()
    }
  }, [form, context.limitResponse]);

  const handlePublishForm = async () => {
    const getFormResponse = await client.query<GetForm, GetFormVariables>({
      query: GET_FORM,
      variables: { formId: form._id },
      fetchPolicy: 'network-only',
    });
    const getFormItemResponse = await client.query<GetWorkspaceFormItems, GetWorkspaceFormItemsVariables>({
      query: GET_WORKSPACE_FORM_ITEMS,
      variables: { formId: form._id },
      fetchPolicy: 'network-only',
    });
    const data = {
      form: context.limitResponse ? { ...getFormResponse.data.form, closeFormToNewResponses: true } : getFormResponse.data.form,
      formItems: getFormItemResponse.data.formItems,
    };
    await client.mutate<PublishForm, PublishFormVariables>({
      mutation: PUBLISH_FORM,
      variables: { id: form._id, data: JSON.stringify(data) },
    });
  };
  return (
    <LazyLoadComponent>
      <StyledContainer>
        <Link to={`/form/${form._id}/create`}>
          {context.limitResponse && form.publishedAt && <div className='form-tag limit'>{formatMessage({ id: 'limit' })}</div>}
          {(form.closeFormToNewResponses && !((form.closeFormFromDateEnable && new Date().getTime() > new Date(form.closeFormFromDate).getTime()) || (form.responseLimitEnable && form.totalResponses >= form.responseLimit))) && !context.limitResponse && <div className='form-tag closed'>{formatMessage({ id: 'closed' })}</div>}
          {((form.closeFormFromDateEnable && new Date().getTime() > new Date(form.closeFormFromDate).getTime()) || (form.responseLimitEnable && form.totalResponses >= form.responseLimit)) && !context.limitResponse && <div className='form-tag done'>{formatMessage({ id: 'done' })}</div>}
          {(form.publishedAt && !form.closeFormToNewResponses && !(form.closeFormFromDateEnable && new Date().getTime() > new Date(form.closeFormFromDate).getTime()) && !(form.responseLimitEnable && form.totalResponses >= form.responseLimit)) && !context.limitResponse && <div className='form-tag live'>{formatMessage({ id: 'live' })}</div>}


          <StyledFormName>
            {/* <div style={form.theme.answers !== '#ffffff' ? { color: `${form.theme.answers}` } : { color: '#000' }}> */}
            {form.theme.backgroundImage && <LazyLoadImage effect="blur" alt={form.theme.name} src={form.theme.backgroundImage} scrollPosition={scrollPosition} />}
            <div style={{ color: `${form.theme.answers}` }}>
              {form.name}
            </div>
          </StyledFormName>
        </Link>

        <StyledFooter>
          <div style={!form.totalResponses ? { background: 'transparent' } : {}}>
            {form.publishedAt ? (
              <>
                {form.totalResponses || 0}
                <span> {formatMessage({ id: 'responsesShort' })}</span>
              </>
            ) : (
                <span className='unpublished'>{formatMessage({ id: 'unpublished' })}</span>
              )}
          </div>
          <Dropdown overlay={CardActions(form, onDelete, onDuplicate)} trigger={['click']} placement="bottomRight">
            <StyledIconWrapper>
              <Icon type={'ellipsis'} />
            </StyledIconWrapper>
          </Dropdown>
        </StyledFooter>
      </StyledContainer >
    </LazyLoadComponent>
  );
});

const StyledContainer = styled(Card)`
  border: none;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  animation: 0.3s ease-in-out 0s 1 normal none running lbWRkT;
  transition: all 0.2s ease-in 0s;
  .ant-card-body {
    padding: 0;
    height: 200px;
    border: none;
  }
  a {
    color: ${(props) => props.theme.colors.blackGrey};
  }
  .form-tag{
    position: absolute;
    top: 8px;
    right: 4px;
    -webkit-box-align: center;
    align-items: center;
    display: inline-flex;
    font-weight: 400;
    padding: 0px 6px;
    font-size: 12px;
    line-height: 18px;
    border-radius: 16px;
    height: 18px;
    margin: 0px 8px;
    z-index: 2;
  }
  .live {
    background-color: ${(props) => props.theme.colors.blue}19;
    color: ${(props) => props.theme.colors.blue};
  }
  .done {
    background-color: rgb(245, 249, 248);
    color: rgb(2, 80, 65);
  }
  .closed {
    background-color: ${(props) => props.theme.colors.red}19;
    color: ${(props) => props.theme.colors.red};
  }
  .limit {
    background-color: ${(props) => props.theme.colors.orange}19;
    color: ${(props) => props.theme.colors.orange};
  }

`;

const StyledFormName = styled.div`
  height: 150px;
  display: flex;
  // padding: 0 15px;
  border-radius: 15px 15px 0 0;
  align-items: center;
  justify-content: center;
  background-position: center center;
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  .lazy-load-image-background{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 15px 15px 0 0;
    img {
      min-height: 150px;
      min-width: 100%;
      width: auto;
      height: 150px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  div {
    text-align: center;
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-height: 50%;
    padding: 0 15px;
    font-size: 14px;
    line-height: 18px;
    color: rgb(115, 115, 115);
    font-weight: 600;
    // text-transform: uppercase;
    letter-spacing: 0.1em;
  }
`;

const StyledFooter = styled.div`
  height: 50px;
  border-top: 1px solid #eee;
  background: #fff;
  border-radius: 0 0 15px 15px;
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: space-between;
  div {
    height: 30px;
    font-size: 12px;
    padding: 5px 10px;
    background: #f8f8f8;
    border-radius: 6px;
  }
  .unpublished {
    color: ${(props) => props.theme.colors.grey}50;
  }
  @media (max-width: 768px) {
    padding: 6px;
    div {
      font-size: 10px;
      padding: 8px;
    }
  }
`;

const StyledIconWrapper = styled.span`
  padding: 5px;
  height: 30px;
  background-color: #fff;
  cursor: pointer;
    border-radius: 6px;
  &:hover {
    background: ${(props) => props.theme.backgrounds.greyLight};
  }
  i {
    font-size: 22px;
  }
  @media (max-width: 768px) {
    i {
      font-size: 14px;
    }
  }
`;

export default FormMainType;
