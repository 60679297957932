import { Auth } from '../auth';
require('es6-promise').polyfill();

class Fetch {

  protected headers: HeadersInit;
  protected loading: boolean = false;
  protected endPoint: string;

  constructor() {
    this.endPoint = process.env.SERVER_ENDPOINT_URL;
  }

  buildHeaders(): HeadersInit {
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: Auth.service.getToken(),
    };
    return this.headers;
  }

  static processResponse(response: Response) {
    return (response.status >= 200 && response.status < 300);
  }

  get(url: string) {
    return fetch(`${this.endPoint}${url}`, {
      method: 'GET',
      headers: this.buildHeaders(),
    }).then((response: Response) => {
      Fetch.processResponse(response);
      return response.json();
    });
  }

  post(url: string, data: any = {}) {
    return fetch(`${this.endPoint}${url}`, {
      method: 'POST',
      headers: this.buildHeaders(),
      body: JSON.stringify(data),
    }).then((response: Response) => {
      Fetch.processResponse(response);
      return response.json();
    });
  }

  put(url: string, data: any = {}) {
    return fetch(`${this.endPoint}${url}`, {
      method: 'PUT',
      headers: this.buildHeaders(),
      body: JSON.stringify(data),
    }).then((response: Response) => {
      Fetch.processResponse(response);
      return response.json();
    });
  }

  delete(url: string, body?: any) {
    return fetch(`${this.endPoint}${url}`, {
      method: 'DELETE',
      headers: this.buildHeaders(),
      body: body ? JSON.stringify(body) : null,
    }).then((response: Response) => {
      Fetch.processResponse(response);
      return response.json();
    });
  }

  uploadSingle(data: any) {
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    return fetch(`${this.endPoint}core/common/upload/single`, {
      method: 'POST',
      body: formData,
    }).then((response: Response) => {
      Fetch.processResponse(response);
      return response.json();
    });
  }
}

export default new Fetch();
