import React from 'react';
import Title from './Elements/Title';
import { FormItemBaseWrappedChildProps } from './FormItemBase';
import { StyledQuestionContainer, StyledQuestionContainerContent } from './Styled';
import ButtonGroup from '@formtory/shared/components/DisplaySingleForm/Elements/ButtonGroup';
import { getSetting } from '@formtory/shared/components/DisplaySingleForm/Utils';
import QuestionLayout from './QuestionLayout';
import Index from './Elements/Index';

const QuestionGroup = (props: FormItemBaseWrappedChildProps) => {
  const { formItem, index, prices, customizedLabels, scores, priceCurrency, previewing, formLayout } = props;
  const { settings } = formItem;

  return (
    <QuestionLayout
      videoUrl={settings.videoUrl}
      image={settings.image}
      layout={settings.layout}
      imageBrightness={settings.imageBrightness}
      formLayout={formLayout}
    >
      <StyledQuestionContainer style={props.device === 'mobile' ? { justifyContent: 'center' } : {}}>
        <Index renderIndex={index} required={settings.required} />
        <StyledQuestionContainerContent style={props.device === 'mobile' ? { minWidth: '90%' } : {}}>
          <Title
            prices={prices}
            priceCurrency={priceCurrency}
            scores={scores}
            value={formItem.title}
            results={props.results}
            containerProps={{
              style: { textAlign: 'left', marginBottom: settings.descriptionEnabled && formItem.description ? 0 : 22 },
            }}

          />
          {formLayout !== 'classic' &&
            <div className="buttons">
              {props.active && (
                <ButtonGroup
                  previewing={previewing}
                  onClick={() => props.onResult(formItem._id, 'YES')}
                  label={getSetting(customizedLabels, 'CONFIRM_ANSWER_BUTTON_TEXT', 'OK') || 'OK'}
                />
              )}
            </div>
          }
        </StyledQuestionContainerContent>
      </StyledQuestionContainer>
    </QuestionLayout>
  );
};

export default QuestionGroup;
