import { CalculationCondition, GetWorkspaceFormItems_formItems } from '@formtory/shared/graphql/types';

export const calculate = (formItems: Array<GetWorkspaceFormItems_formItems>, results: any) => {
  let prices = 0;
  let scores = 0;
  formItems.forEach((formItem) => {
    if (formItem.calculations.length === 0) return;
    if (!results[formItem._id]) return;
    formItem.calculations.forEach((calculation) => {
      const result = JSON.stringify(results[formItem._id]);
      if (calculation.condition === CalculationCondition.ALWAYS) {
        const adjustNumber = parseInt(result.replace(/"/g, ''), 10);
        if (calculation.addTo === 'PRICE') {
          switch (calculation.addType) {
            case '+':
              prices += adjustNumber;
              break;
            case '-':
              prices -= adjustNumber;
              break;
            case '*':
              prices *= adjustNumber;
              break;
            case '/':
              prices /= adjustNumber;
              break;
            default:
              break;
          }
          return calculation;
        }
        switch (calculation.addType) {
          case '+':
            scores += adjustNumber;
            break;
          case '-':
            scores -= adjustNumber;
            break;
          case '*':
            scores *= adjustNumber;
            break;
          case '/':
            scores /= adjustNumber;
            break;
          default:
            break;
        }
        return calculation;
      }
      if (result.includes(calculation.value)) {
        if (calculation.addTo === 'PRICE') {
          switch (calculation.addType) {
            case '+':
              prices += calculation.addValue;
              break;
            case '-':
              prices -= calculation.addValue;
              break;
            case '*':
              prices *= calculation.addValue;
              break;
            case '/':
              prices /= calculation.addValue;
              break;
            default:
              break;
          }
          return calculation;
        }
        switch (calculation.addType) {
          case '+':
            scores += calculation.addValue;
            break;
          case '-':
            scores -= calculation.addValue;
            break;
          case '*':
            scores *= calculation.addValue;
            break;
          case '/':
            scores /= calculation.addValue;
            break;
          default:
            break;
        }
        return calculation;
      }
      return calculation;
    });
  });
  return { prices, scores };
};
