import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import orderBy from 'lodash/orderBy';
import EventHub from '@formtory/shared/core/events/hub';
import Title from './Elements/Title';
import Description from './Elements/Description';
import { FormItemBaseWrappedChildProps } from './FormItemBase';
import { StyledQuestionContainer, StyledErrorMessage, StyledQuestionContainerContent } from './Styled';
import { getSetting } from './Utils';
import QuestionLayout from './QuestionLayout';
import { FormItemSettingsLayout } from '../../graphql/types';
import Image from './Elements/Image';
import Video from './Elements/Video';
import Index from './Elements/Index';
import Submit from './Submit';

const StyledSelect = styled(Select)`
  background: transparent;
  text-align: left;
  min-width: 500px;
  @media (max-width: 480px) {
    min-width: 80vw;
  }
  width: 100%;
  font-size: 22px;
  padding: 0;
  color: ${(props) => props.theme.form.answers};
  border: none;
  border-bottom: 2px solid;
  border-color: ${(props) => props.theme.form.answers}66;
  box-shadow: 0 0 0 0;
  &:focus,
  &:hover {
    border-color: ${(props) => props.theme.form.answers}!important;
    box-shadow: 0 0 0 0;
  }
  &:active {
    border-color: ${(props) => props.theme.form.answers}!important;
  }
  ::placeholder {
    font-size: 22px;
    font-weight: unset;
    font-family: ${(props) => props.theme.form.fontFamily};
    color: ${(props) => props.theme.form.answers}66;
    opacity: 0.7;
  }
  .ant-select-selection {
    background: transparent;
    border: none;
    &:hover,
    &:active,
    &:focus {
      background: transparent;
      border: none;
      box-shadow: 0 0 0 0;
    }
  }
  .ant-select-selection__placeholder {
    height: 100%;
    font-size: 22px;
    font-weight: unset;
    font-family: ${(props) => props.theme.form.fontFamily};
    color: ${(props) => props.theme.form.answers}66;
  }
  .ant-select-arrow {
    color: ${(props) => props.theme.form.answers};
    font-size: 22px;
    top: 20px;
  }
  .ant-select-selection-selected-value > div {
    padding: 0;
    border: none;
  }
`;

const StyledOption = styled.div`
  font-size: 22px;
  padding: 12px;
  color: ${(props) => props.theme.form.answers};
  border: 2px solid;
  border-color: ${(props) => props.theme.form.answers}66;
  box-shadow: 0 0 0 0;
  border-radius: 8px;
  &:focus,
  &:hover {
    border-color: ${(props) => props.theme.form.answers};
    box-shadow: 0 0 0 0;
  }
`;

const Dropdown = (props: FormItemBaseWrappedChildProps) => {
  const { formItem, index, customizedLabels, previewing, prices, scores, priceCurrency, isNextSubmit, onSubmit, submitting, formLayout, theme, locale } = props;
  const { settings } = formItem;
  const [result, setResult] = useState(props.results[formItem._id] || []);
  const [resultsIndex, setResultsIndex] = useState(props.resultsIndex[formItem._id] || []);
  const [hasError, setHasError] = useState(props.submitted && settings.required && !result);
  const [choices, setChoices] = useState(settings.alphabeticalOrder ? orderBy(settings.choices) : settings.choices);

  const resetChoices = (e) => {
    if (!previewing) return;
    const settings = e.detail.settings;
    setChoices(settings.alphabeticalOrder ? orderBy(settings.choices) : settings.choices);
  };

  useEffect(() => {
    EventHub.addEventListener(`SAVED_SETTING:${formItem._id}`, resetChoices);
    return () => {
      EventHub.removeEventListener(`SAVED_SETTING:${formItem._id}`, resetChoices);
    };
  }, []);

  useEffect(() => {
    setChoices(settings.alphabeticalOrder ? orderBy(settings.choices) : settings.choices);
  }, [locale]);

  useEffect(() => {
    setChoices(settings.alphabeticalOrder ? orderBy(settings.choices) : settings.choices);
  }, [formItem._id]);

  useEffect(() => {
    if (props.submitted && settings.required && !result) return setHasError(true);
  });

  const onSaveResult = (value) => {
    const choiceIndex = settings.choices.findIndex((c) => c === value)
    setResult(value);
    setResultsIndex(choiceIndex);
    props.onResult(formItem._id, value, choiceIndex);
  };

  return (
    <QuestionLayout
      videoUrl={settings.videoUrl}
      image={settings.image}
      layout={settings.layout}
      hideImage
      imageBrightness={settings.imageBrightness}
      formLayout={formLayout}
      device={props.device}
    >
      <StyledQuestionContainer style={props.device === 'mobile' ? { justifyContent: 'center' } : {}}>
        <Index renderIndex={index} required={settings.required} />
        <StyledQuestionContainerContent style={props.device === 'mobile' ? { minWidth: '90%' } : {}}>
          <Title
            value={formItem.title}
            prices={prices}
            priceCurrency={priceCurrency}
            scores={scores}
            results={props.results}
            containerProps={{
              style: { textAlign: 'left', marginBottom: settings.descriptionEnabled && formItem.description ? 0 : 22 },
            }}
          />
          {settings.descriptionEnabled && formItem.description && (
            <Description
              prices={prices}
              scores={scores}
              priceCurrency={priceCurrency}
              results={props.results}
              value={formItem.description}
            />
          )}
          {settings.videoUrl && <Video value={settings.videoUrl} />}
          {!settings.videoUrl &&
            settings.image &&
            (!settings.layout || settings.layout === FormItemSettingsLayout.NORMAL) && (
              <Image
                value={settings.image}
                containerProps={{
                  className: 'image--normal',
                  style: { filter: `brightness(${(settings.imageBrightness || 100) / 100})` },
                }}
              />
            )}
          <StyledSelect
            defaultValue={result}
            dropdownStyle={formLayout !== 'classic' ? { backgroundColor: 'transparent', boxShadow: 'none' } : { backgroundColor: theme.buttons, borderRadius: '6px' }}
            placeholder={getSetting(
              customizedLabels,
              'INSTRUCTION_FOR_DROPDOWN_QUESTION',
              'Please enter your response',
            )}
            onChange={(value) => onSaveResult(value)}
            style={props.device !== 'mobile' ? {} : { minWidth: '90%' }}
          >
            {choices.map((choice, index) => (
              <Select.Option
                key={choice}
                value={choice}
                style={{
                  padding: 0,
                  margin: 4,
                  borderRadius: '8px'
                }}
              >
                <StyledOption>
                  {choice}
                </StyledOption>
              </Select.Option>
            ))}
            {settings.otherOptionEnabled && (
              <Select.Option value={getSetting(customizedLabels, 'LABEL_FOR_OTHER_ANSWER_OPTION', 'Other')}>
                {getSetting(customizedLabels, 'LABEL_FOR_OTHER_ANSWER_OPTION', 'Other')}
              </Select.Option>
            )}
          </StyledSelect>
          {hasError && (
            <div className="error">
              <StyledErrorMessage>
                {getSetting(
                  customizedLabels,
                  'ERROR_IF_AN_ANSWER_REQUIRES_A_SELECTION',
                  'Oops, Please enter your answer!',
                )}
              </StyledErrorMessage>
            </div>
          )}
          {formLayout !== 'classic' &&
            <div className="buttons">
              {/* {props.active && result && (
              <ButtonGroup
                previewing={previewing}
                onClick={onSaveResult}
                label={getSetting(customizedLabels, 'CONFIRM_ANSWER_BUTTON_TEXT', 'OK') || 'OK'}
              />
            )} */}
              {isNextSubmit && <Submit customizedLabels={customizedLabels} onSubmit={onSubmit} submitting={submitting} activeItemId={isNextSubmit && 'submit'} />}
            </div>
          }
        </StyledQuestionContainerContent>
      </StyledQuestionContainer>
    </QuestionLayout>
  );
};

export default Dropdown;