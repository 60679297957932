import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

const StyledInput = styled(Input)`
  background: transparent;
  font-size: 22px;
  width: 100%;
  padding: 0;
  color: ${(props) => props.theme.form.answers};
  border: none;
  border-bottom: 2px solid;
  border-color: ${(props) => props.theme.form.answers}66;
  box-shadow: 0 0 0 0;
  &:focus,
  &:hover {
    border-color: ${(props) => props.theme.form.answers};
    box-shadow: 0 0 0 0;
  }
  ::placeholder {
    font-size: 22px;
    font-weight: unset;
    font-family: ${(props) => props.theme.form.fontFamily};
    color: ${(props) => props.theme.form.answers}66;
    opacity: 0.7;
  }
`;

const Title = ({ value, onChange = null, placeholder = null, containerProps = {} }) => (
  <StyledInput onChange={onChange} {...containerProps} defaultValue={value} placeholder={placeholder} autoComplete="off" />
);

export default Title;
