const theme = {
  colors: {
    primary: '#643ced',
    blackGrey: 'rgb(38, 38, 39)',
    red: '#eb3b5a',
    blue: '#45aaf2',
    purple: '#a55eea',
    orange: '#fd9644',
    yellow: '#f7b731',
    green: '#26de81',
    grey: '#777777',
    blueGreen: '#2bcbba',
    lightGrey: '#eeeeee',
  },
  fontSizes: {
    base: '14px',
    medium: '16px',
  },
  backgrounds: {
    greyLight: 'rgb(250, 250, 250)',
    greyLightMoreGrey: '#f1f1f1',
  },
  mobile: {
    fontFamily: {
      light: 'CircularLight',
      normal: 'CircularBook',
      medium: 'CircularMedium',
      bold: 'CircularBold',
      black: 'CircularBlack',
    },
  },
  form: {
    name: 'Default theme',
    fontFamily: 'Work Sans',
    questions: '#000000',
    answers: '#643ced',
    buttons: '#643ced',
    background: '#FFFFFF',
    backgroundImage: null,
  },
};

export const createTheme = (platform: 'ios' | 'android' | 'macos' | 'windows' | 'web') => {
  if (platform === 'ios') {
    return {
      ...theme,
      ...{},
    };
  }
  if (platform === 'android') {
    return {
      ...theme,
      ...{
        mobile: {
          fontFamily: {
            light: 'sans-serif-light',
            normal: 'sans-serif',
            medium: 'sans-serif-black',
            bold: 'sans-serif-black',
            black: 'sans-serif-medium',
          },
        },
      },
    };
  }
  return theme;
};

export default theme;
