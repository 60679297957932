import React, { useState, useEffect } from 'react';
import { Button, Row, Icon } from 'antd';
import styled from 'styled-components';
import Title from './Elements/Title';
import Description from './Elements/Description';
import { FormItemBaseWrappedChildProps } from './FormItemBase';
import { StyledErrorMessage, StyledQuestionContainer, StyledQuestionContainerContent } from './Styled';
import { getSetting } from './Utils';
import QuestionLayout from './QuestionLayout';
import { FormItemSettingsLayout } from '../../graphql/types';
import Image from './Elements/Image';
import Video from './Elements/Video';
import Index from './Elements/Index';
import Submit from './Submit';

const StyledItem = styled<any>(Button)`
  max-width: 70px;
  max-height: 70px;
  @media (max-width: 480px) {
    max-width: 50px;
    max-height: 50px;
  }
  border-radius: 6px;
  margin-right: 6px;
  padding: 0;
  margin-top: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  border: 2px solid;
  border-color: ${(props) => props.color || props.theme.form.answers};
  background-color: ${(props) => props.color || props.theme.form.answers}19};
  color: ${(props) => props.theme.form.answers};
  &:hover,
  &:focus {
    border-color: ${(props) => props.color || props.theme.form.answers};
    background-color: ${(props) => props.theme.form.answers};
    color: ${(props) => props.theme.form.background};
  }
  &.active {
    background-color: ${(props) => props.theme.form.answers};
    border-color: ${(props) => props.theme.form.answers};
    color: ${(props) => props.theme.form.background};
  }
`;

const StyledLabels = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  i {
    font-size: 10px;
  }
  span {
    color: ${(props) => props.theme.form.answers};
  }
`;

function createOpinionScaleItems(formItem: FormItemBaseWrappedChildProps['formItem']) {
  const { settings } = formItem;
  if (settings.isNPS) {
    return Array.from(Array(11).keys()).map((key) => key);
  }
  const defaultArray = Array.from(Array(1000).keys());
  const startIndex = defaultArray.findIndex((value) => (settings.opinionScaleFrom || 0) === value);
  const endIndex = defaultArray.findIndex((value) => (settings.opinionScaleTo || 10) === value);
  return defaultArray.slice(startIndex, endIndex + 1).map((key) => key);
}

const OpinionScale = (props: FormItemBaseWrappedChildProps) => {
  const { formItem, index, customizedLabels, prices, scores, priceCurrency, isNextSubmit, onSubmit, submitting, formLayout } = props;
  const { settings } = formItem;
  const [result, setResult] = useState(props.results[formItem._id] || []);
  const items = createOpinionScaleItems(props.formItem);
  const [hasError, setHasError] = useState(props.submitted && settings.required && !result);

  useEffect(() => {
    if (props.submitted && settings.required && !result) return setHasError(true);
  });

  const onSaveResult = (value) => {
    setResult(value);
    props.onResult(formItem._id, value);
  };

  const getOptionClassName = (result, item) => {
    return result === item ? 'active' : '';
  };

  const getOptionColor = (index) => {
    if (formItem.settings.npsSegmentedOptionsEnabled) {
      if (index < items.length - 4) {
        return formItem.settings.npsSegmentedOptions?.detractors || '#fc9b9d';
      }
      if (index < items.length - 2) {
        return formItem.settings.npsSegmentedOptions?.passives || '#ffdb78';
      }
      if (index < items.length) {
        return formItem.settings.npsSegmentedOptions?.promoters || '#88cb6b';
      }
    }
    return null;
  };

  return (
    <QuestionLayout
      videoUrl={settings.videoUrl}
      image={settings.image}
      hideImage={true}
      layout={settings.layout}
      imageBrightness={settings.imageBrightness}
      formLayout={formLayout}
    >
      <StyledQuestionContainer style={props.device === 'mobile' ? { justifyContent: 'center' } : {}}>
        <Index renderIndex={index} required={settings.required} />
        <StyledQuestionContainerContent style={props.device === 'mobile' ? { minWidth: '90%' } : {}}>
          <Title
            prices={prices}
            priceCurrency={priceCurrency}
            scores={scores}
            value={formItem.title}
            results={props.results}
            containerProps={{
              style: { textAlign: 'left', marginBottom: settings.descriptionEnabled && formItem.description ? 0 : 22 },
            }}

          />
          {settings.descriptionEnabled && formItem.description && (
            <Description
              prices={prices}
              scores={scores}
              priceCurrency={priceCurrency}
              results={props.results}
              value={formItem.description}
            />
          )}
          {settings.videoUrl && <Video value={settings.videoUrl} />}
          {!settings.videoUrl &&
            settings.image &&
            (!settings.layout || settings.layout === FormItemSettingsLayout.NORMAL) && (
              <Image
                value={settings.image}
                containerProps={{
                  className: 'image--normal',
                  style: { filter: `brightness(${(settings.imageBrightness || 100) / 100})` },
                }}
              />
            )}
          {settings.opinionScaleLabelEnabled && (
            <Row>
              <StyledLabels>
                {settings.opinionScaleLabelLeft && <span>{items[0]} <Icon type={'arrow-right'} /> {settings.opinionScaleLabelLeft}</span>}
                {settings.opinionScaleLabelCenter && <span>{items[Math.floor(items.length / 2)]} <Icon type={'arrow-right'} /> {settings.opinionScaleLabelCenter}</span>}
                {settings.opinionScaleLabelRight && <span>{items[items.length - 1]} <Icon type={'arrow-right'} /> {settings.opinionScaleLabelRight}</span>}
              </StyledLabels>
            </Row>
          )}
          <div className={'button-group'} style={props.device !== 'mobile' ? {} : { width: 'fit-content' }}>
            {items.map((item, index) => (
              <StyledItem
                key={item}
                className={getOptionClassName(result, item)}
                // value="large"
                // shape={'circle'}
                color={getOptionColor(index)}
                onClick={() => onSaveResult(item)}
                style={{ width: `calc((710px - 6px * ${items.length}) / ${items.length})`, height: `calc((710px - 6px * ${items.length}) / ${items.length})` }}
              >
                {item}
              </StyledItem>
            ))}

          </div>
          <div className="error">
            {hasError && (
              <StyledErrorMessage>
                {getSetting(customizedLabels, 'ERROR_IF_AN_ANSWER_IS_REQUIRED', 'Oops, Please enter your answer!')}
              </StyledErrorMessage>
            )}
          </div>
          {formLayout !== 'classic' &&
            <div className="buttons">
              {isNextSubmit && <Submit customizedLabels={customizedLabels} onSubmit={onSubmit} submitting={submitting} activeItemId={isNextSubmit && 'submit'} />}
            </div>
          }
        </StyledQuestionContainerContent>
      </StyledQuestionContainer>
    </QuestionLayout>
  );
};

export default OpinionScale;
