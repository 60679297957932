import * as React from 'react';
import { graphql, ChildMutateProps } from 'react-apollo';
import { Input } from 'antd';
import { ADD_WORKSPACE, GET_WORKSPACES } from '@formtory/shared/graphql/workspace';
import { AddWorkspaceMutation, AddWorkspaceMutationVariables, Me_me_teams } from '@formtory/shared/graphql/types';

interface AddWorkspaceProps {
  onAddedWorkspace: any;
  name?: string;
  selectedTeam: Me_me_teams;
  formatMessage?: any;
}

type ChildProps = ChildMutateProps<AddWorkspaceProps, AddWorkspaceMutation, AddWorkspaceMutationVariables>;

class AddWorkspace extends React.PureComponent<ChildProps> {
  state = {
    name: '',
  };

  onWorkspaceNameChange = (e: any) => {
    this.setState({ name: e.target.value });
  };

  onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    return this.props.mutate({
      variables: { data: { name: this.state.name, teamId: this.props.selectedTeam?._id } },
      update: (store, { data: { createWorkspace } }: any) => {
        const previous: any = this.props.selectedTeam
          ? store.readQuery({ query: GET_WORKSPACES, variables: { teamId: this.props.selectedTeam._id } })
          : store.readQuery({ query: GET_WORKSPACES });
        const data = {
          workspaces: [...previous.workspaces, createWorkspace],
        };
        if (this.props.selectedTeam) {
          store.writeQuery({ query: GET_WORKSPACES, variables: { teamId: this.props.selectedTeam._id }, data });
        } else {
          store.writeQuery({ query: GET_WORKSPACES, data });
        }
        this.props.onAddedWorkspace();
      },
    });
  };

  render() {
    const { name } = this.state;
    return (
      <form onSubmit={this.onSubmit}>
        <Input value={name} onChange={this.onWorkspaceNameChange} type="text" placeholder={this.props.formatMessage({ id: 'addWorkspaceName' })} />
      </form>
    );
  }
}

const withAddWorkspace = graphql<AddWorkspaceProps, AddWorkspaceMutation, AddWorkspaceMutationVariables, ChildProps>(
  ADD_WORKSPACE,
);

export default withAddWorkspace(AddWorkspace);
