import * as React from 'react';
import { Me_me_teams, TeamBaseFields } from '@formtory/shared/graphql/types';
import TeamForm from './TeamForm';
import { StyledModal } from 'components/Workspaces/AddNewForm';
import { AppContext } from '@formtory/shared/contexts/AppContext';

interface BaseProps {
  visible: boolean;
  onClose: () => void;
  initialValues?: Me_me_teams;
  onSubmitted?: (isCreated: boolean, team: TeamBaseFields) => void;
}

export default (props: BaseProps) => {
  const { intl: { formatMessage } } = React.useContext(AppContext);
  return (
    <StyledModal
      closable={true}
      footer={null}
      wrapClassName="theme-modal"
      onCancel={props.onClose}
      visible={props.visible}
      title={props.initialValues ? `${formatMessage({ id: 'settings' })}` : formatMessage({ id: 'createNewTeam' })}
      centered
    >
      {props.visible && <TeamForm initialValues={props.initialValues} onSubmitted={props.onSubmitted} />}
    </StyledModal>
  );
};
