// @ts-ignore
import { browserHistory } from 'react-router';
import ReactGA from 'react-ga';
import { Auth } from '../auth';

class GoogleAnalytic {

  private ReactGA: any;

  constructor() {
    this.ReactGA = null;
  }

  init() {
    if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
      const auth = Auth.service.getAuth();
      this.ReactGA = ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID, {
        debug: (window.location.hash === '#debug-ga'),
        titleCase: false,
        gaOptions: {
          userId: (auth) ? auth._id : '',
        },
      });

      ReactGA.set({ page: window.location.pathname + window.location.search });
      ReactGA.pageview(window.location.pathname + window.location.search);

      browserHistory.listen(() => {
        ReactGA.set({ page: window.location.pathname + window.location.search });
        ReactGA.pageview(window.location.pathname + window.location.search);
      });
    }
  }

  trackEvent(category: any, action: any) {
    this.ReactGA.event({
      category,
      action,
    });
  }
}

export default new GoogleAnalytic();
