import ServiceType from './service';
const bookingType = `
  _id
  name
  content
  image
  dateBooked
  address
  cancelReason
  totalAmount
  accepted
  appliedDiscountCode
  appliedDiscountPercent
  acceptedAt
  paid
  cancelled
  cancelledAt
  additionCosts {
    _id
    price
    content
    createdAt
    createdBy
    accepted
  }
  done
  markAsDoneAt
  creator {
    _id
    username
    fullName
    nickName
    avatar
  }
  services {
    serviceId
    quantity
    modifiedAt
    price
    note
  }
  partner {
    _id
    name
    slug
    logo
    ownerId
    owner {
      _id
      fullName
      avatar
      username
    }
    services {
      ${ServiceType}
    }
  }
  partnerSource
  refunded
  createdAt
  createdBy
  updatedAt
`;

export const bookingSimpleType = `
  _id
  name
  content
  image
  dateBooked
  address
  accepted
  acceptedAt
  totalAmount
  paid
  creator {
    username
    fullName
    nickName
    avatar
  }
  partner {
    _id
    name
    slug
    logo
    ownerId
  }
  cancelled
  cancelledAt
  done
  markAsDoneAt
  refunded
  createdAt
  createdBy
  updatedAt
`;

export default bookingType;
