import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

const StyledInput = styled(Input)`
  background: transparent;
  font-size: 22px;
  width: 100%;
  padding: 0;
  color: ${(props) => props.theme.form.answers};
  border: none;
  border-bottom: 2px solid;
  border-color: ${(props) => props.theme.form.answers}66;
  box-shadow: 0 0 0 0;
  &:focus,
  &:hover {
    border-color: ${(props) => props.theme.form.answers};
    box-shadow: 0 0 0 0;
  }
  ::placeholder {
    font-size: 22px;
    font-weight: unset;
    font-family: ${(props) => props.theme.form.fontFamily};
    color: ${(props) => props.theme.form.answers}66;
    opacity: 0.7;
  }
  &:not:hover {
    border: none;
    box-shadow: 0 0 0 0;
  }
  .ant-input-number-input {
    ::placeholder {
      font-size: 22px;
      font-weight: 600;
      font-family: ${(props) => props.theme.form.fontFamily};
      color: ${(props) => props.theme.form.answers};
      opacity: 0.7;
    }
  }
  &.ant-input-number-focused {
    border: none;
  }
`;

const Title = ({ value, placeholder = null, containerProps = {} }) => (
  <StyledInput
    onWheelCapture={(e: any) => {
      e.target.blur();
    }}
    type={'number'}
    onKeyDown={e => (e.keyCode === 69 || e.keyCode === 65 || e.keyCode === 229) && e.preventDefault()}
    {...containerProps}
    defaultValue={value}
    placeholder={placeholder}
  />
);

export default Title;
