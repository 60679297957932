import Cookie from 'js-cookie';
import { Base64 } from 'js-base64';
import { TOKEN_COOKIE_KEY, USER_PAYLOAD_COOKIE_KEY } from '../../constants/auth.constants';
import { Auth as AuthInterface } from '../../../models/auth';
import { User } from '../../../models/user';

class AuthService {

  protected auth: AuthInterface = null;
  protected token: string = null;
  protected language: string = null;
  protected endPoint: string = null;

  constructor() {
    this.endPoint = process.env.SERVER_ENDPOINT_URL;
  }

  cleanUserAttributes(user: User) {
    this.auth = {
      _id: user._id,
      name: user.name,
      fullName: user.fullName,
      email: user.email,
      avatar: user.avatar,
      username: user.username,
      localeKey: user.localeKey,
      isSuperAdmin: user.isSuperAdmin,
    };
    return this.auth;
  }

  saveToken(token: string) {
    Cookie.set(TOKEN_COOKIE_KEY, token, { expires: 29 });
    this.token = token;
  }

  async refreshToken(): Promise<{ hasExpired: boolean, user: AuthInterface, notLoggedIn?: boolean, token?: string }> {
    const token = this.getToken();
    if (!token) return { hasExpired: true, user: null, notLoggedIn: true, token: null };
    return fetch(`${this.endPoint}${`auth/refresh-authenticate-token/${token}`}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: null,
    }).then((response) => {
      return response.json();
    }).then((response) => {
      if (!response.success && response.hasExpired) return { hasExpired: true, user: null, token: null };
      const user = this.cleanUserAttributes(response.user);
      if (response.success && response.user) {
        return { hasExpired: false, user, token };
      }
      if (response.success && response.newToken && response.user) {
        this.saveToken(response.newToken);
        return { hasExpired: false, user, token: response.newToken };
      }
      return { hasExpired: true, user: null, notLoggedIn: true };
    });
  }

  logout() {
    Cookie.remove(TOKEN_COOKIE_KEY);
    Cookie.remove(USER_PAYLOAD_COOKIE_KEY);
    this.auth = null;
    this.token = null;
    return this.auth;
  }

  getAuth() {
    if (!this.auth) {
      const hash = Cookie.get(USER_PAYLOAD_COOKIE_KEY);
      if (hash) {
        this.auth = JSON.parse(Base64.decode(hash));
        return this.auth;
      }
    }
    return this.auth;
  }

  setAuth(auth: AuthInterface | User) {
    this.auth = auth;
  }

  setToken(token: string) {
    this.token = token;
  }

  getToken() {
    if (!this.token) {
      const token = Cookie.get(TOKEN_COOKIE_KEY);
      if (token) this.token = token;
    }
    return this.token;
  }
}

export const Auth = {
  service: new AuthService(),
};

export default AuthService;
