import React from 'react';
import ButtonGroup from './Elements/ButtonGroup';
import { StyledSubmitContainer, StyledText } from './Styled';
import { getSetting } from './Utils';

const Submit = ({ submitting, onSubmit, customizedLabels, activeItemId }) => {
  return (
    <StyledSubmitContainer>
      <ButtonGroup
        containerProps={{ style: { marginTop: 28 } }}
        // skipEnter={true}
        onClick={onSubmit}
        label={submitting ? '' : getSetting(customizedLabels, 'BUTTON_TO_SEND_FORM', 'Submit')}
        buttonProps={{ loading: submitting, disabled: submitting || activeItemId !== 'submit' }}

      />
      <StyledText style={{ marginTop: 10, marginBottom: 0 }}>Never submit passwords!</StyledText>
    </StyledSubmitContainer>
  );
};

export default Submit;
