import { Auth } from '../auth';

declare global {
  interface Window { mixpanel: any; }
}

class MixPanel {

  mixpanel: any;

  constructor() {
    if (typeof window === 'undefined') return;
    this.mixpanel = window.mixpanel;
  }

  trackEvent(action: string, data: object = {}) {
    if (typeof this.mixpanel !== 'undefined') {
      const trackingData: any = {};
      const auth = Auth.service.getAuth();
      if (auth) {
        if (auth.isSuperAdmin) return; // Skip tracking for app
        trackingData.auth = { _id: auth._id };
      }
      this.mixpanel.track(action, { ...trackingData, ...{ payload: data } });
    }
  }

  openGiftBoxOnHomepage() {
    this.trackEvent('OPEN_HOMEPAGE_GIFT_BOX_MODAL');
  }

  exploreEventTools() {
    this.trackEvent('CLICK_EXPLORE_EVENT_TOOLS');
  }

  playHowToBookingVideo() {
    this.trackEvent('CLICK_PLAY_HOW_TO_BOOKING_VIDEO');
  }

  forceCloseHomepageGiftBox() {
    this.trackEvent('FORCE_CLOSE_HOME_GIFT_BOX');
  }

  autoCloseHomepageGiftBox() {
    this.trackEvent('AUTO_CLOSE_HOME_GIFT_BOX');
  }

  tryEventTools() {
    this.trackEvent('CLICK_TRY_EVENT_TOOL_ON_HOMEPAGE');
  }

  viewMoreArtistsOnHomepage(page: number) {
    this.trackEvent('CLICK_VIEW_MORE_ARTISTS_ON_HOMEPAGE', { page });
  }

  viewMoreSuppliersOnHomepage(page: number) {
    this.trackEvent('CLICK_VIEW_MORE_SUPPLIERS_ON_HOMEPAGE', { page });
  }

  clickSignInOnHeader() {
    this.trackEvent('CLICK_SIGN_IN_ON_HEADER');
  }

  loggedIn() {
    this.trackEvent('USER_LOGGED_IN');
  }
}

export default new MixPanel();
