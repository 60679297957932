import * as React from 'react';
import styled from 'styled-components';
import { Select, message } from 'antd';
import { AppContext } from '@formtory/shared/contexts/AppContext';

const { Option, OptGroup } = Select;

const StyledSelect = styled(Select)`
  padding: 12px 16px;
  border-right: 1px solid #e8e8e8;
  min-width: 160px;
  width: 100%;
  .ant-select-selection--single {
    height: 32px;
    background: rgb(240, 240, 240);
    border: none;
    // border-radius: 40px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    // color: rgb(115, 115, 115);
    position: relative;
    outline: none;
    font-family: inherit;
    border: 0px;
    transition: all 0.4s ease 0s;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    border-radius: 4px;
    padding: 6px 12px;
    min-height: 32px;
    width: 100%;
  }
  .ant-select-selection--single .ant-select-selection__rendered {
    // margin-left: 16px;
    font-size: 14px;
    line-height: 20px;
  }
  .ant-select-selection-selected-value {
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
    font-weight: 600;
    // text-transform: uppercase;
    letter-spacing: 0.1em;
  }
`;

const StyledCreateTeamText = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

// const StyledTeamSettingsText = styled.span`
//   color: ${(props) => props.theme.colors.red};
// `;

export default () => {
  const context = React.useContext(AppContext);
  const {
    intl: { formatMessage },
  } = context;

  const handleSelectTeam = (value) => {
    if (value === 'PERSONAL') {
      localStorage.removeItem('selectedTeamId');
      location.href = '/';
      return;
    }
    if (value === 'CREATE') {
      context.onUpdateContext({ openCreateNewTeam: true });
      return;
    }
    if (value === 'SETTINGS') {
      context.onUpdateContext({ openTeamSettings: true });
      return;
    }
    const team = context.auth.teams.find((item) => item._id === value);
    if (!team?.accessKey?.active && team.ownerId !== context.auth._id) {
      message.warning('Your team is not active yet! Please talk to your team owner.');
      return;
    }
    localStorage.setItem('selectedTeamId', value);
    location.href = '/';
  };

  if (!context.auth) return null;

  return (
    <StyledSelect
      value={context.selectedTeam?._id || 'PERSONAL'}
      dropdownClassName={'TeamSwitcherDropdownMenu'}
      onChange={handleSelectTeam}
    >
      {/* <OptGroup label={formatMessage({ id: 'teamActions' })}>
        {context.selectedTeam && context.selectedTeam.ownerId === context.auth._id && (
          <Option value="SETTINGS">
            <StyledTeamSettingsText>{`${context.selectedTeam?.name} ${formatMessage({ id: 'settings' })}`}</StyledTeamSettingsText>
          </Option>
        )}
        <Option value="CREATE">
          <StyledCreateTeamText>{formatMessage({ id: 'createNewTeam' })}</StyledCreateTeamText>
        </Option>
      </OptGroup> */}
      <OptGroup label={formatMessage({ id: 'personal' })}>
        <Option value="PERSONAL">
          {context.auth.firstName} {context.auth.lastName}
        </Option>
      </OptGroup>
      <OptGroup label={formatMessage({ id: 'teams' })}>
        {/* {context.selectedTeam && context.selectedTeam.ownerId === context.auth._id && (
          <Option value="SETTINGS">
            <StyledTeamSettingsText>{`${formatMessage({ id: 'settings' })}`}</StyledTeamSettingsText>
          </Option>
        )} */}
        <Option value="CREATE">
          <StyledCreateTeamText>{formatMessage({ id: 'createNewTeam' })}</StyledCreateTeamText>
        </Option>
        {context.auth?.teams?.map((team) => (
          <Option value={team._id} key={team._id}>
            {team.name}
          </Option>
        ))}
      </OptGroup>
    </StyledSelect>
  );
};
