import { ContentState, EditorState } from 'draft-js';
// import { useEffect } from 'react';
// import { useState } from 'react';
import Editor from 'draft-js-plugins-editor';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { convertDraftRawToString } from '../Utils';
import { isMobile } from '@formtory/app/src/utils/helperFunctions';
// import createMarkdownPlugin from 'draft-js-markdown-plugin';
const StyledTitle = styled.h1`
  text-align: center;
  font-size: ${isMobile ? '20px' : '24px'};
  font-weight: unset;
  margin-bottom: 0;
  color: ${(props) => props.theme.form.questions};
  cursor: default;
  .DraftEditor-root {
    display: inline-block;
  }
  .center {
    .public-DraftStyleDefault-ltr {
      text-align: center;
    }
  }
  b {
    margin-left: 5px;
  }
  i {
    font-style: normal;
    margin-left: -3px;
    margin-right: 5px;
    font-size: 20px;
    color: ${(props) => props.theme.form.questions}CC;
  }
`;

// const StyledTitleSticky = styled.h1`
//   position: -webkit-sticky;
//   position: sticky;
//   top: 0;
//   z-index: 3;
//   background: ${(props) => props.theme.form.background}90;
//   text-align: center;
//   font-size: 24px;
//   font-weight: unset;
//   margin-bottom: 0;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   color: ${(props) => props.theme.form.questions};
//   .DraftEditor-root {
//     display: inline-block;
//   }
//   b {
//     margin-left: 5px;
//   }
//   i {
//     font-style: normal;
//     margin-left: -3px;
//     margin-right: 5px;
//     font-size: 20px;
//     color: ${(props) => props.theme.form.questions}CC;
//   }
// `;

const Title = ({ value, results = {}, prices, scores, priceCurrency, center = false, containerProps = {} }) => {
  // const [sticky, setSticky] = useState(false);
  // useEffect(() => {
  //   var offsetTop = window.pageYOffset + document.getElementById('title').getBoundingClientRect().top;
  //   if (offsetTop <= 70) {
  //     setSticky(true);
  //   }
  // });
  // console.log('results: ', results)
  const [editorState, setEditorState] = useState(value === 'Hidden fields' ? null : EditorState.createWithContent(ContentState.createFromText(convertDraftRawToString(value, results, prices, scores, priceCurrency))));
  useEffect(() => {
    (value !== 'Hidden fields') && setEditorState(EditorState.createWithContent(ContentState.createFromText(convertDraftRawToString(value, results, prices, scores, priceCurrency))))
  }, [value, Object.values(results).length, prices, scores, priceCurrency])
  // console.log('prices: ', prices)
  // console.log('scores: ', scores)
  // console.log('value: ', value)
  return (
    <>
      {/* {center === true ? <StyledTitle {...containerProps} id="title"> : <StyledTitle {...containerProps} id="title">} */}
      <StyledTitle {...containerProps} id="title">
        <div className={center ? 'center' : ''}>
          {value === 'Hidden fields' ? value : <Editor readOnly={true} editorState={editorState} />}
          {/* {required && <b>*</b>} */}
        </div>
      </StyledTitle>
      {/* {sticky ? (
        <StyledTitleSticky {...containerProps} id="title">
          {value === 'Hidden fields' ? value : convertDraftRawToString(value, results, prices, scores, priceCurrency)}
          {required && <b>*</b>}
        </StyledTitleSticky>
      ) : (
        <StyledTitle {...containerProps} id="title">
          {value === 'Hidden fields' ? value : convertDraftRawToString(value, results, prices, scores, priceCurrency)}
          {required && <b>*</b>}
        </StyledTitle>
      )} */}
    </>
  );
};

export default Title;
