import React, { useContext } from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import { AppContext } from '../contexts/AppContext';
import { PLANS } from '../data/pricing';

interface BaseProps {
  image: string;
  title: string;
  appKey: string;
  description: string;
  imagePlaceholderBackground: string;
  connected?: boolean;
  comingSoon?: boolean;
  onConnect?: any;
  onDisconnect?: any;
  canEdit?: boolean;
}

const StyledContainer = styled.div`
  box-shadow: 0 0 10px #eee;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .image {
    width: 15%;
    // position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 16px;
    @media (max-width: 768px) {
      padding: 0;
    }
    img {
      max-width: 70%;
      max-height: 70%;
      margin: auto;
      @media (max-width: 768px) {
        max-width: 50%;
        max-height: 50%;
      }
    }
  }
  .info {
    background: #fff;
    padding: 20px;
    width: 70%;
    @media (min-width: 320px) {
      width: 65%;
    }
    h3 {
      font-size: 16px;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      color: #777;
      margin-bottom: 0;
    }
  }
  .button {
    width: 15%;
    background: #fff;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    justify-content: center;
    @media (min-width: 320px) {
      width: 20%;
    }
    button {
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      background-color: rgb(38, 38, 39);
      color: rgb(255, 255, 255);
      position: relative;
      outline: none;
      font-family: inherit;
      border: 0px;
      transition: all 0.4s ease 0s;
      cursor: pointer;
      white-space: nowrap;
      text-decoration: none;
      border-radius: 4px;
      padding: 0px 12px;
      min-height: 32px;
      font-size: 14px;
      line-height: 20px;
      border: none;
      box-shadow: 0 0 10px #eee;
      height: auto;
    }
  }
`;

const BasedApp = React.memo((props: BaseProps) => {
  const context = useContext(AppContext);
  return (
    <StyledContainer className={'integration'}>
      <div className="image">
        <img src={props.image} alt="" />
      </div>
      <div className="info">
        <h3>{props.title}</h3>
        <p>{props.description}</p>
      </div>
      <div className="button">
        {(context.onCheckCurrentPlan(context) === PLANS.FREE || context.onCheckCurrentPlan(context) === PLANS.BASIC) && <Button onClick={props.onConnect}>Upgrade</Button>}
        {context.onCheckCurrentPlan(context) !== PLANS.FREE && context.onCheckCurrentPlan(context) !== PLANS.BASIC && !props.comingSoon && !props.connected && <Button disabled={!props.canEdit} onClick={props.onConnect}>Connect</Button>}
        {context.onCheckCurrentPlan(context) !== PLANS.FREE && context.onCheckCurrentPlan(context) !== PLANS.BASIC && !props.comingSoon && props.connected && (
          <Button type={'primary'} onClick={props.onDisconnect}>
            Disconnect
          </Button>
        )}
        {context.onCheckCurrentPlan(context) !== PLANS.FREE && context.onCheckCurrentPlan(context) !== PLANS.BASIC && props.comingSoon && <Button>Enterprise</Button>}
      </div>
    </StyledContainer>
  );
});

export default BasedApp;
