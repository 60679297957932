import gql from 'graphql-tag';
import {
  FORM_BASE_FIELDS,
  USER_BASE_FIELDS,
  WORKSPACE_BASE_FIELDS,
  FORM_ITEM_BASE_FIELDS,
  FORM_ITEM_SETTING_BASE_FIELDS,
  TEAM_BASE_FIELDS,
} from './fragments';

export const GET_WORKSPACE_FORMS = gql`
  query GetWorkspaceForms($workspaceId: ID!) {
    forms(workspaceId: $workspaceId) {
      ...FormBaseFields
      creator {
        ...UserBaseFields
      }
      workspace {
        ...WorkspaceBaseFields
      }
    }
  }
  ${FORM_BASE_FIELDS}
  ${USER_BASE_FIELDS}
  ${WORKSPACE_BASE_FIELDS}
`;

export const GET_TEMPLATE_FORMS = gql`
  query GetTemplateForms {
    templateForms {
      ...FormBaseFields
      isTemplate
      templateInfo {
        name
        categories
        backgroundImage
        language
      }
    }
  }
  ${FORM_BASE_FIELDS}
`;

export const GET_WORKSPACE_FORM = gql`
  query GetWorkspaceForm($formId: ID!) {
    form(formId: $formId) {
      ...FormBaseFields
      creator {
        ...UserBaseFields
      }
      workspace {
        ...WorkspaceBaseFields
      }
      protectedPassword
    }
  }
  ${FORM_BASE_FIELDS}
  ${USER_BASE_FIELDS}
  ${WORKSPACE_BASE_FIELDS}
`;

export const GET_FORM = gql`
  query GetForm($formId: ID!, $live: Boolean) {
    form(formId: $formId, live: $live) {
      ...FormBaseFields
      creator {
        ...UserBaseFields
      }
      workspace {
        ...WorkspaceBaseFields
        team {
          ...TeamBaseFields
        }
      }
      formItemTranslations {
        _id
        title
        description
        choices
        pictureChoices {
          image
          label
        }
        locale
        formId
        formItemId
      }
    }
  }
  ${FORM_BASE_FIELDS}
  ${TEAM_BASE_FIELDS}
  ${USER_BASE_FIELDS}
  ${WORKSPACE_BASE_FIELDS}
`;

export const PUBLISH_FORM = gql`
  mutation PublishForm($id: ID!, $data: String!) {
    publishForm(id: $id, data: $data) {
      ...FormBaseFields
    }
  }
  ${FORM_BASE_FIELDS}
`;

export const SAVE_FORM_ITEM = gql`
  mutation SaveFormItemMutation($data: FormItemInput!) {
    saveFormItem(data: $data) {
      ...FormItemBaseFields
    }
  }
  ${FORM_ITEM_BASE_FIELDS}
`;

export const DELETE_WORKSPACE_FORM = gql`
  mutation DeleteWorkspaceForm($formId: ID!) {
    deleteForm(id: $formId) {
      _id
    }
  }
`;

export const DUPLICATE_FORM = gql`
  mutation duplicateForm($formId: ID!, $workspaceId: ID!) {
    duplicateForm(id: $formId, workspaceId: $workspaceId) {
      _id
    }
  }
`;

export const SAVE_FORM_ITEM_SETTINGS = gql`
  mutation SaveFormItemSettingsMutation($data: FormItemSettingsInput!) {
    saveFormItemSettings(data: $data) {
      ...FormItemSettingBaseFields
    }
  }
  ${FORM_ITEM_SETTING_BASE_FIELDS}
`;

export const GET_WORKSPACE_FORM_ITEMS = gql`
  query GetWorkspaceFormItems($formId: ID!, $live: Boolean) {
    formItems(formId: $formId, live: $live) {
      ...FormItemBaseFields
    }
  }
  ${FORM_ITEM_BASE_FIELDS}
`;

export const CREATE_NEW_FORM = gql`
  mutation CreateWorkspaceFormMutation($workspaceId: ID!, $name: String!) {
    createForm(data: { name: $name, workspaceId: $workspaceId }) {
      ...FormBaseFields
      creator {
        ...UserBaseFields
      }
      workspace {
        ...WorkspaceBaseFields
      }
    }
  }
  ${USER_BASE_FIELDS}
  ${WORKSPACE_BASE_FIELDS}
  ${FORM_BASE_FIELDS}
`;

export const SUBMIT_RESPONSE_MUTATION = gql`
  mutation SubmitResponseMutation($data: SubmitResponseInput!) {
    submitResponse(data: $data) {
      _id
      formId
      isDesktop
      isMobile
      isTablet
      isOther
      completedTimes
      createdAt
      deletedAt
      prices
      scores
    }
  }
`;

export const TRACKING_FORM_VISITOR = gql`
  mutation TrackingFormVisitorMutation($data: trackingFormVisitorInput!) {
    trackingFormVisitor(data: $data) {
      _id
    }
  }
`;

export const GET_FORM_THEMES = gql`
  query GetFormThemes {
    themes {
      _id
      answers
      logoImage
      backgroundImage
      backgroundBrightness
      backgroundScaleX
      backgroundScaleY
      backgroundTranslateX
      backgroundTranslateY
      fullWidth
      fullHeight
      background
      buttons
      fontFamily
      questions
      name
    }
  }
`;

export const CREATE_FORM_THEME = gql`
  mutation CreateFormThemeMutation($data: CreateThemeInput!) {
    createFormTheme(data: $data) {
      _id
      answers
      logoImage
      backgroundImage
      backgroundBrightness
      backgroundScaleX
      backgroundScaleY
      backgroundTranslateX
      backgroundTranslateY
      fullWidth
      fullHeight
      background
      buttons
      fontFamily
      questions
      name
    }
  }
`;

export const CREATE_FORM_TRANSLATIONS = gql`
  mutation CreateFormTranslationsMutation($id: ID!, $locale: String!, $usingGoogleTranslate: Boolean) {
    createFormTranslation(id: $id, locale: $locale, usingGoogleTranslate: $usingGoogleTranslate) {
      _id
      locales
      labelTranslations
    }
  }
`;

export const UPDATE_FORM_ITEM_TRANSLATIONS = gql`
  mutation UpdateFormItemTranslationMutation($id: ID, $formItemId: ID!, $locale: String!, $title: String, $description: String, $choices: [String], $pictureChoices: [PictureChoiceSettingInput]) {
    updateFormItemTranslation(id: $id, formItemId: $formItemId, locale: $locale, title: $title, description: $description, choices: $choices, pictureChoices: $pictureChoices)
  }
`;

export const UPDATE_FORM_ITEM_LABEL_TRANSLATIONS = gql`
  mutation UpdateFormItemLabelTranslationMutation($formId: ID!, $locale: String!, $value: String!) {
    updateFormLabelTranslation(formId: $formId, locale: $locale, value: $value) {
      _id
      labelTranslations
    }
  }
`;

export const DELETE_FORM_TRANSLATION = gql`
  mutation DeleteFormTranslationMutation($id: ID!, $locale: String!) {
    deleteFormTranslation(id: $id, locale: $locale) {
      _id
      locales
      labelTranslations
    }
  }
`;

export const REGENERATE_FORM_TRANSLATION = gql`
  mutation RegenerateFormTranslationMutation($id: ID!, $locale: String!) {
    createFormTranslation(id: $id, locale: $locale, usingGoogleTranslate: true, regenerate: true) {
      _id
      locales
      labelTranslations
    }
  }
`;

export const GET_FORM_ITEM_TRANSLATIONS = gql`
  query GetFormItemTranslations($formId: ID!, $locale: String!) {
    getFormItemTranslations(formId: $formId, locale: $locale) {
      _id
      title
      description
      choices
      pictureChoices {
        image
        label
      }
      locale
      formId
      formItemId
    }
  }
`;

export const UPDATE_FORM_THEME = gql`
  mutation UpdateFormThemeMutation($id: ID!, $data: CreateThemeInput!) {
    updateFormTheme(id: $id, data: $data) {
      _id
      answers
      logoImage
      backgroundImage
      backgroundBrightness
      backgroundScaleX
      backgroundScaleY
      backgroundTranslateX
      backgroundTranslateY
      fullWidth
      fullHeight
      background
      buttons
      fontFamily
      questions
      name
    }
  }
`;

export const DELETE_THEME = gql`
  mutation DeleteThemeMutation($id: ID!, $formId: ID!) {
    deleteFormTheme(id: $id, formId: $formId) {
      _id
      themeKey
      theme {
        _id
        answers
        logoImage
        backgroundImage
        backgroundBrightness
        backgroundScaleX
        backgroundScaleY
        backgroundTranslateX
        backgroundTranslateY
        fullWidth
        fullHeight
        background
        buttons
        fontFamily
        questions
        name
      }
    }
  }
`;

export const UPDATE_FORM_MUTATION = gql`
  mutation UpdateFormMutation($id: ID!, $data: UpdateFormInput!) {
    updateForm(id: $id, data: $data) {
      ...FormBaseFields
    }
  }
  ${FORM_BASE_FIELDS}
`;

export const DUPLICATE_FORM_ITEM_MUTATION = gql`
  mutation DuplicateFormItemMutation($formItemId: ID!) {
    duplicateFormItem(formItemId: $formItemId) {
      _id
      order
    }
  }
`;

export const DELETE_FORM_ITEM_MUTATION = gql`
  mutation DeleteFormItemMutation($id: ID!) {
    deleteFormItem(id: $id) {
      _id
    }
  }
`;

export const FORM_STATS = gql`
  query FormStats($id: ID!, $isDesktop: Boolean!, $isMobile: Boolean!, $isTablet: Boolean!, $isOther: Boolean!) {
    formStats(id: $id, isDesktop: $isDesktop, isMobile: $isMobile, isTablet: $isTablet, isOther: $isOther) {
      averageResponseCompleteTime
      completionRates
      totalResponses
      totalUniqueVisits
      totalVisits
    }
  }
`;

export const FORM_RESULTS_BY_FORM_ITEM_ID = gql`
  query FormResultsByFormItemId($formItemId: ID!, $limit: Int!, $skip: Int!, $live: Boolean) {
    formResponseResultsByFormItemId(formItemId: $formItemId, limit: $limit, skip: $skip, live: $live) {
      count
      edges {
        result
        createdAt
      }
    }
  }
`;

export const FORM_RESPONSES = gql`
  query FormResponseByFormId(
    $formId: ID!
    $startDate: DateTime
    $endDate: DateTime
    $limit: Int!
    $skip: Int!
    $formItemId: ID
    $searchValue: String,
    $live: Boolean
  ) {
    formResponsesByFormId(
      formId: $formId
      searchValue: $searchValue
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      skip: $skip
      live: $live
    ) {
      count
      edges {
        _id
        completedTimes
        prices
        scores
        createdAt
        resultByFormItem(formItemId: $formItemId) {
          result
        }
        results {
          _id
          formItemId
          createdAt
          result
        }
      }
    }
  }
`;

export const DELETE_RESPONSE_MUTATION = gql`
  mutation DeleteResponseMutation($id: ID!, $formItemId: ID!) {
    deleteFormResponse(id: $id) {
      _id
      completedTimes
      prices
      scores
      createdAt
      resultByFormItem(formItemId: $formItemId) {
        result
      }
      results {
        _id
        formItemId
        createdAt
        result
      }
    }
  }
`;

export const UNLOCK_FORM = gql`
  mutation UnlockFormMutation($formId: ID!, $password: String!) {
    unlockForm(formId: $formId, password: $password)
  }
`;

export const GET_FORM_ITEM_NPS_REPORTS = gql`
  query FormItemNPSReports($formItemId: ID!, $startDate: String, $endDate: String, $live: Boolean) {
    formItemNPSReports(formItemId: $formItemId, startDate: $startDate, endDate: $endDate, live: $live) {
      formItemId
      score
      detractors
      passives
      promoters
      stats {
        day
        month
        year
        detractors
        passives
        promoters
        score
      }
    }
  }
`;
