/* eslint-disable */
declare var FB: any;

export function imageBase64ToFile(base64: any, fileName: string) {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    //eslint-disable-line
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
}

export function shareFacebook(url: string, callback: any) {
  FB.ui(
    {
      method: 'share',
      href: url || window.location.href,
    },
    response => {
      if (callback) {
        if (response && !response.error_code) return callback(true);
        callback(false);
      }
    },
  );
}

export function scrollToTargetAdjusted(id: string, adjust: number) {
  const element = document.getElementById(id);
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition - adjust;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
}

export function convertSecondToHHMM(second: number) {
  const date = new Date(null);
  date.setSeconds(second);
  return date.toISOString().substr(11, 8);
}

export function readFileFromInputEvent(event: any, callback: any) {
  const reader = new FileReader();
  const file = event.target.files[0];
  if (!file) return;
  const input: any = document.getElementById(event.target.id);
  input.value = '';
  reader.onload = (img: any) => {
    callback(img.target.result);
  };
  reader.readAsDataURL(file);
}

export const customSuggestionsFilter = (searchValue, suggestions) => {
  const size = list => (list.constructor.name === 'List' ? list.size : list.length);

  const get = (obj, attr) => (obj.get ? obj.get(attr) : obj[attr]);

  const value = searchValue.toLowerCase();
  const filteredSuggestions = suggestions.filter(
    suggestion =>
      !value ||
      get(suggestion, 'name')
        .toLowerCase()
        .indexOf(value) > -1,
  );
  const length = size(filteredSuggestions) < 10 ? size(filteredSuggestions) : 10;
  return filteredSuggestions.slice(0, length);
};

export function findHashTags(searchText: string) {
  const regexp = /\B\#\w\w+\b/g; //eslint-disable-line
  let results = searchText.match(regexp);
  if (results) {
    results = results.map(tag => tag.replace('#', ''));
    return results;
  }
  return [];
}

export function imageCDNProcessor(imageUrl: string) {
  // if (!imageUrl) return imageUrl;
  // if (
  //   imageUrl.indexOf('https://s3-ap-southeast-1.amazonaws.com/show-kite-prod/') > -1 &&
  //   process.env.NODE_ENV === 'production'
  // ) {
  //   return imageUrl.replace('https://s3-ap-southeast-1.amazonaws.com/show-kite-prod/', process.env.CLOUD_FRONT_DOMAIN);
  // }
  // if (
  //   imageUrl.indexOf('https://show-kite-prod.s3-ap-southeast-1.amazonaws.com/') > -1 &&
  //   process.env.NODE_ENV === 'production'
  // ) {
  //   return imageUrl.replace('https://show-kite-prod.s3-ap-southeast-1.amazonaws.com/', process.env.CLOUD_FRONT_DOMAIN);
  // }
  return imageUrl;
}

export function getImageThumb(entity: any, thumbKey: string, type: string = 'small', fallbackField: string = 'logo') {
  if (!entity[thumbKey] || typeof entity[thumbKey] === 'undefined') return entity[fallbackField];
  if (!entity[thumbKey][type]) return entity[fallbackField];
  return entity[thumbKey][type];
}

/* eslint:disable */
export function getYoutubeVideoId(url) {
  var r,
    rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  r = url.match(rx);
  if (!r || typeof r === 'undefined') return false;
  return r[1];
}
/* eslint:enable */

export function soundCloudEmbedUrlBuilder(url: string) {
  return `https://w.soundcloud.com/player/?url=${url}`;
}

/* eslint:disable */
export function getParameterByName(name: string, url: string) {
  const str = !url ? window.location.href : url;
  name = name.replace(/[\[\]]/g, '\\$&'); //eslint-disable-line
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), //eslint-disable-line
    results = regex.exec(str);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' ')); //eslint-disable-line
}
/* eslint:enable */

export function isImageUrl(url: string) {
  return url.match(/\.(jpeg|JPEC|jpg|JPG|gif|GIF|png|PNG|HEIC)$/) !== null;
}

export function isUrl(url: string) {
  if (isImageUrl(url)) return false;
  if (getYoutubeVideoId(url)) return false;
  const pattern = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  return pattern.test(url);
}

export function copyTextToClipboard(text: string) {
  const textArea: any = document.createElement('textarea');
  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = 0;
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'Copied!' : 'Copy failed!';
    console.log(msg);
  } catch (err) {
    console.log('Oops, unable to copy');
  }
  document.body.removeChild(textArea);
}

export function isMobile() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  return false;
}

export default {
  isMobile,
  imageCDNProcessor,
};

export function getCountryFlag(cc: string) {
  // Mild sanity check.
  if (cc.length !== 2)
    return cc;

  // Convert char to Regional Indicator Symbol Letter
  function risl(chr) {
    return String.fromCodePoint(0x1F1E6 - 65 + chr.toUpperCase().charCodeAt(0));
  }

  // Create RISL sequence from country code.
  return risl(cc[0]) + risl(cc[1]);
}
