import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 10px;
    font-weight: 300;
    margin-bottom: 0;
    color: ${(props) => props.theme.form.questions};
  }
`;

const StyledButton = styled<any>(Button)`
  background-color: ${(props) => props.theme.form.buttons};
  border-color: ${(props) => props.theme.form.buttons};
  color: ${(props) => props.theme.form.background};
  // font-size: 12px;
  // line-height: 18px;
  // font-weight: 600;
  // text-transform: uppercase;
  border-radius: 8px;
  span {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.1em;
    color: ${(props) => props.theme.form.background};
  }
  &:hover,
  &:focus,
  &:active
  {
    background-color: ${(props) => props.theme.form.buttons}90;
    border-color: ${(props) => props.theme.form.buttons};
    color: ${(props) => props.theme.form.background};
  }
`;

interface ButtonGroupProps {
  containerProps?: any;
  skipEnter?: boolean;
  previewing?: boolean;
  onClick?: any;
  buttonProps?: any;
  label: string;
}

export default class ButtonGroup extends React.PureComponent<ButtonGroupProps> {
  constructor(props: any) {
    super(props);
    this.onKeydown = this.onKeydown.bind(this);
  }

  componentDidMount() {
    if (this.props.skipEnter || this.props.previewing) return null;
    document.addEventListener('keydown', this.onKeydown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeydown);
  }

  onKeydown(event: any) {
    if (this.props.skipEnter) return;
    if (event.keyCode === 13) {
      if (event.shiftKey) {
        event.stopPropagation();
      } else {
        event.preventDefault();
        event.stopPropagation();
        document.getElementById('submit--button').click();
      }
    }
  }

  render() {
    return (
      <StyledButtonContainer {...this.props.containerProps}>
        <StyledButton
          id={'submit--button'}
          onClick={this.props.onClick}
          type={'primary'}
          htmlType={'button'}
          {...this.props.buttonProps}
        >
          {this.props.label}
        </StyledButton>
        <p>
          Press <b>ENTER</b>
        </p>
      </StyledButtonContainer>
    );
  }
}
