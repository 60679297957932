import { defaultLabels } from '@formtory/shared/data/default';
import EditorState from 'draft-js/lib/EditorState';
import convertFromRaw from 'draft-js/lib/convertFromRawToDraftState';

export const getSetting = (customizedLabels, key, defaultValue) => {
  customizedLabels = JSON.parse(customizedLabels ? customizedLabels : JSON.stringify({}));
  if (typeof customizedLabels[key] !== 'undefined') {
    return customizedLabels[key];
  }
  if (typeof defaultLabels[key] !== 'undefined' && defaultLabels[key] && defaultLabels[key].value) {
    return defaultLabels[key].value;
  }
  return defaultValue;
};

export const convertDraftRawToString = (value, results, prices = null, scores = null, priceCurrency = null): string => {
  const rawValue = value ? convertFromRaw(JSON.parse(value)) : null;
  const editorState = rawValue ? EditorState.createWithContent(rawValue) : null;
  if (!editorState) return null;
  let titleAsPlainText = editorState.getCurrentContent().getPlainText();
  const mentions = titleAsPlainText.match(/\[(.*?)\]/g);
  if (mentions && mentions.length > 0) {
    mentions.forEach(mention => {
      if (mention === '[SCORE]' && scores) {
        // calculated score
        if (scores) return (titleAsPlainText = titleAsPlainText.replace(mention, scores));
        return (titleAsPlainText = titleAsPlainText.replace(mention, '___'));
      }
      if (mention === '[PRICE]' && prices) {
        // calculated price
        let convertedPrices;
        if (!priceCurrency || priceCurrency === 'USD') {
          // convertedPrices = `$${prices.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.')}`;
          convertedPrices = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices);
        }
        if (priceCurrency === 'VND') {
          // convertedPrices = `${prices.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.')}`;
          // convertedPrices = convertedPrices.substring(0, convertedPrices.length - 3);
          // convertedPrices = `${convertedPrices} VN`;
          convertedPrices = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(prices);
        }
        if (priceCurrency === 'EUR') {
          convertedPrices = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(prices);
        }
        if (priceCurrency === 'JPY') {
          convertedPrices = new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(prices);
        }
        if (priceCurrency === 'GBP') {
          convertedPrices = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(prices);
        }
        if (priceCurrency === 'CNY') {
          convertedPrices = new Intl.NumberFormat('zh-CN', { style: 'currency', currency: 'CNY' }).format(prices);
        }
        if (convertedPrices) return (titleAsPlainText = titleAsPlainText.replace(mention, convertedPrices));
        return (titleAsPlainText = titleAsPlainText.replace(mention, '___'));
      }
      if (mention.includes('HIDDEN_FIELD:')) {
        const urlParams = new URLSearchParams(window.location.search);
        const fieldName = mention.split(':')[1].replace(']', '');
        const fieldValue = urlParams.get(fieldName.toLowerCase());
        if (fieldValue) return (titleAsPlainText = titleAsPlainText.replace(mention, fieldValue));
        return (titleAsPlainText = titleAsPlainText.replace(mention, '___'));
      }
      // tslint:disable-next-line:no-bitwise
      const resultId = mention.split(':')[1] ? mention.split(':')[1].replace(']', '') : null;
      if (results[resultId]) {
        if (typeof results[resultId] === 'string' || typeof results[resultId] === 'number') {
          return (titleAsPlainText = titleAsPlainText.replace(mention, results[resultId]));
        }
        if (!Array.isArray(results[resultId])) {
          return (titleAsPlainText = titleAsPlainText.replace(mention, results[resultId]));
        }
        if (typeof results[resultId][0] === 'object') {
          // For picture choice
          return (titleAsPlainText = titleAsPlainText.replace(
            mention,
            results[resultId].map((item, index) => item.label || `Choice ${index}`).join(','),
          ));
        }
        return (titleAsPlainText = titleAsPlainText.replace(mention, results[resultId].join(',')));
      }
      return (titleAsPlainText = titleAsPlainText.replace(mention, '___'));
    });
  }
  return titleAsPlainText;
};
