import * as React from 'react';
import {
  GetWorkspaces,
  GetWorkspacesVariables,
  GetWorkspaces_workspaces,
  Me_me_teams,

} from '@formtory/shared/graphql/types';
import { ChildDataProps, graphql } from 'react-apollo';
import { GET_WORKSPACES } from '@formtory/shared/graphql/workspace';
import PerfectScrollbar from 'react-perfect-scrollbar';


interface OriginComponentProps {
  q?: string;
  activeWorkspaceId?: string;
  selectedTeam?: Me_me_teams;
  onSelectWorkspace: (workspace: any) => void;
}

function reducerWorkspaces(workspaces: Array<GetWorkspaces_workspaces>, q: string): Array<GetWorkspaces_workspaces> {
  if (!q) return workspaces;
  return workspaces.filter((workspace) => workspace.name.indexOf(q) > -1);
}

type ChildProps = ChildDataProps<OriginComponentProps, GetWorkspaces, any>;
const withWorkspaceQuery = graphql<OriginComponentProps, GetWorkspaces, GetWorkspacesVariables, ChildProps>(
  GET_WORKSPACES,
  {
    options: (props) => ({
      variables: {
        teamId: props.selectedTeam?._id,
      },
    }),
  },
);
export default withWorkspaceQuery(
  ({ q, onSelectWorkspace, activeWorkspaceId, data: { loading, error, workspaces } }) => {
    if (!loading && !error && workspaces.length > 0) {
      if (!activeWorkspaceId) {
        onSelectWorkspace(workspaces[0]);
      } else {
        const currentWorkspace = workspaces.find((workspace) => workspace._id === activeWorkspaceId);
        onSelectWorkspace(currentWorkspace || workspaces[0]);
      }
    }
    return (
      <PerfectScrollbar>
        {loading && (
          <div className={'ant-menu-item'} style={{ paddingLeft: 24, fontSize: '16px' }}>
            <span className="nav-text">Loading...</span>
          </div>
        )}
        {!loading && error && (
          <div className={'ant-menu-item'} style={{ paddingLeft: 24, fontSize: '16px' }}>
            <span className="nav-text">Something wrong!</span>
          </div>
        )}
        {!loading &&
          !error &&
          reducerWorkspaces(workspaces, q).map((workspace) => (
            <div
              key={workspace._id}
              className={workspace._id === activeWorkspaceId ? 'ant-menu-item workspace workspace-active' : 'ant-menu-item workspace'}
              style={{ paddingLeft: 24, fontSize: '12px', lineHeight: '18px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', fontWeight: 600, textTransform: 'uppercase', letterSpacing: '0.1em' }}
              onClick={() => onSelectWorkspace(workspace)}
            >
              <span className="nav-text">{workspace.name}</span>
            </div>
          ))}
      </PerfectScrollbar>
    );
  },
);