import { FormTypes } from '@formtory/shared/models/form';
import { AlphabetCharacters } from '@formtory/shared/data/default';

export const buildIndexes = (orderedItems: any, skipDot?: boolean) => {
  let currentIndex = 1;
  const indexes = {};
  orderedItems = orderedItems.filter(
    (item) =>
      ![FormTypes[FormTypes.THANK_YOU], FormTypes[FormTypes.WELCOME], FormTypes[FormTypes.HIDDEN_FIELDS]].includes(
        item.formType,
      ),
  );
  orderedItems.forEach((item) => {
    if (item.groupId) {
      const parent = orderedItems.find((doc) => doc._id === item.groupId);
      if (parent) {
        const parentIndex = indexes[parent._id];
        if (parentIndex) {
          const children = orderedItems.filter((doc) => doc.groupId === parent._id);
          const index = children.findIndex((doc) => doc._id === item._id);
          return (indexes[item._id] = `${parentIndex.replace(' ', '')}${AlphabetCharacters[index].toLowerCase()} `);
        }
      }
    }
    // if ([FormTypes[FormTypes.STATEMENT]].includes(item.formType)) {
    //   return (indexes[item._id] = `${currentIndex}${skipDot ? '' : '. '}`);
    // }
    indexes[item._id] = `${currentIndex}${skipDot ? '' : '. '}`;
    return (currentIndex += 1);
  });
  return indexes;
};
