import { ContentState, EditorState } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { convertDraftRawToString } from '../Utils';
import { isMobile } from '@formtory/app/src/utils/helperFunctions';

const StyledDescription = styled.h2`
  text-align: left;
  font-size: ${isMobile ? '16px' : '20px'};
  font-weight: 300;
  color: ${props => props.theme.form.questions};
  cursor: default;
  &.text--center {
    text-align: center !important;
  }
`;

const Description = ({ value, prices, scores, priceCurrency, results = {}, containerProps = {} }) => {
  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText(convertDraftRawToString(value, results, prices, scores, priceCurrency))));
  useEffect(() => {
    setEditorState(EditorState.createWithContent(ContentState.createFromText(convertDraftRawToString(value, results, prices, scores, priceCurrency))))
  }, [value, results, prices, scores, priceCurrency])
  return (
    <StyledDescription {...containerProps}>
      <Editor readOnly={true} editorState={editorState} />
    </StyledDescription>
  );
};

export default Description;
