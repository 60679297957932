import React from 'react';
import styled from 'styled-components';
import { Icon, Input, Button, Form, message } from 'antd';
import { useApolloClient } from 'react-apollo-hooks';
import { GetForm, UnlockFormMutation, UnlockFormMutationVariables } from '@formtory/shared/graphql/types';
import { UNLOCK_FORM } from '@formtory/shared/graphql/form';

interface BaseProps {
  form: GetForm['form'];
  onUnlockForm: () => void;
}

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.95);
  .locked {
    width: 480px;
    height: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
      color: #222;
      font-weight: 400;
    }
  }
  .lock-icon {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 3px solid ${props => props.theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      color: ${props => props.theme.colors.primary};
      font-size: 36px;
    }
    margin-bottom: 16px;
  }
`;

const FormProtectedPassword = React.memo((props: BaseProps) => {
  const [password, setPassword] = React.useState('');
  const [unlocking, setUnlocking] = React.useState(false);
  const client = useApolloClient();

  const handleUnlock = async () => {
    setUnlocking(true);
    const { data } = await client.mutate<UnlockFormMutation, UnlockFormMutationVariables>({
      mutation: UNLOCK_FORM,
      variables: { formId: props.form._id, password },
    });
    setUnlocking(false);
    if (data.unlockForm) {
      localStorage.setItem(`Unlocked_Form_${props.form.hash}`, 'true');
      props.onUnlockForm();
      return;
    }
    message.error('Password invalid! Please try again.');
  };

  return (
    <StyledContainer>
      <div className="locked">
        <div className={'lock-icon'}>
          <Icon type={'lock'} />
        </div>
        <h2>{'Enter password to continue'}</h2>
        <Form.Item style={{ width: '100%' }}>
          <Input.Password
            value={password}
            onChange={e => setPassword(e.target.value)}
            autoFocus
            placeholder="Enter your password..."
          />
        </Form.Item>
        <Button loading={unlocking} disabled={unlocking} type={'primary'} onClick={handleUnlock}>
          Unlock
        </Button>
      </div>
    </StyledContainer>
  );
});

export default FormProtectedPassword;
