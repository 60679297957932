import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Modal from 'antd/lib/modal';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import { IntegrationApps } from '@formtory/shared/data/default';
import { GetWorkspaceForm, UpdateFormMutationVariables } from '@formtory/shared/graphql/types';
import BasedAppDisplay from '@formtory/shared/apps/BasedAppDisplay';
import { AppContext } from '@formtory/shared/contexts/AppContext';
import { PLANS } from '@formtory/shared/data/pricing';
import Brand from './images/facebook-pixel.png';
import * as Utils from './Utils';

interface BaseProps {
  onSave: (data: UpdateFormMutationVariables['data']) => void | any;
  form: GetWorkspaceForm['form'];
  canEdit?: boolean;
}

const StyledContainer = styled.div``;

const StyledButton = styled<any>(Button)`
  margin-top: 10px;
  margin-right: 10px;
`;

export const Helpers = {
  initClient(id: string) {
    // tslint:disable-next-line:only-arrow-functions
    const n: any = (window['fbq'] = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    });

    if (!window['_fbq']) window['_fbq'] = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    const script = document.createElement('script');
    script.onload = () => {
      window['fbq']('init', id);
      window['fbq']('trackCustom', 'Formtory_FormView');
    };
    script.src = 'https://connect.facebook.net/en_US/fbevents.js';
    document.head.appendChild(script);
  },
  track(eventName: string, payload: any) {
    if (!window['fbq'] || typeof window['fbq'] === 'undefined') return;
    window['fbq']('trackCustom', eventName, payload);
  },
};

const FacebookPixel = React.memo((props: BaseProps) => {
  const context = useContext(AppContext);
  const [openSetting, setOpenSetting] = useState(false);
  const [pixelId, setPixelId] = useState<string>('');
  const [submitting, setSubmitting] = useState(false);
  const { intl: { formatMessage } } = React.useContext(AppContext);

  const onConnect = () => {
    // if (!context.onCheckAuthPlan(PLANS.PRO)) return null;
    if (context.onCheckCurrentPlan(context) === PLANS.FREE || context.onCheckCurrentPlan(context) === PLANS.BASIC) return null;
    setOpenSetting(true);
  };

  const onSubmit = async () => {
    setSubmitting(true);
    await props.onSave({
      connectedApp: {
        name: IntegrationApps.FACEBOOK_PIXEL,
        payload: JSON.stringify({ pixelId }),
        connected: true,
      },
    });
    setTimeout(() => {
      setSubmitting(false);
      setOpenSetting(false);
    }, 1000);
  };

  const onDisconnect = async () => {
    await props.onSave({
      connectedApp: {
        name: IntegrationApps.FACEBOOK_PIXEL,
        payload: JSON.stringify({ pixelId }),
        connected: false,
      },
    });
    setTimeout(() => {
      setSubmitting(false);
      setOpenSetting(false);
    }, 1000);
  };

  return (
    <StyledContainer>
      <BasedAppDisplay
        appKey={IntegrationApps.FACEBOOK_PIXEL}
        imagePlaceholderBackground={'#ecf0f1'}
        image={Brand}
        connected={Utils.isAppConnected(props.form.connectedApps, IntegrationApps.FACEBOOK_PIXEL)}
        onDisconnect={onDisconnect}
        onConnect={onConnect}
        title={formatMessage({ id: 'facebookPixelTitle' })}
        description={formatMessage({ id: 'facebookPixelDescription' })}
        canEdit={props.canEdit}
      // comingSoon
      />
      <Modal
        title={formatMessage({ id: 'addYourFacebookPixel' })}
        closable={true}
        onCancel={() => setOpenSetting(false)}
        footer={null}
        visible={openSetting}
      >
        <p>
          {formatMessage({ id: 'copyAndPasteYourPixel' })}{' '}
          <a href="https://www.facebook.com/business/help/952192354843755" target={'_blank'}>
            {formatMessage({ id: 'howDoIFindPixel' })}
          </a>
        </p>
        <Input value={pixelId} placeholder={'Eg: 123456789101234567'} onChange={e => setPixelId(e.target.value)} />
        <StyledButton htmlType={'button'}>{formatMessage({ id: 'cancel' })}</StyledButton>
        <StyledButton
          type={'primary'}
          disabled={submitting}
          loading={submitting}
          htmlType={'button'}
          onClick={onSubmit}
        >
          {formatMessage({ id: 'save' })}
        </StyledButton>
      </Modal>
    </StyledContainer>
  );
});

export default FacebookPixel;
