import React, { useState, useEffect, useCallback } from 'react';
import Title from './Elements/Title';
import InputNumber from './Elements/InputNumber';
import CountryPhoneInput from './Elements/CountryPhoneInput';
import Description from './Elements/Description';
import ButtonGroup from './Elements/ButtonGroup';
import { FormItemBaseWrappedChildProps } from './FormItemBase';
import { StyledQuestionContainer, StyledErrorMessage, StyledQuestionContainerContent } from './Styled';
import { getSetting } from './Utils';
import QuestionLayout from './QuestionLayout';
import { FormItemSettingsLayout } from '../../graphql/types';
import Image from './Elements/Image';
import Video from './Elements/Video';
import Index from './Elements/Index';
import Submit from './Submit';
import debounce from 'lodash/debounce';

const Numeric = (props: FormItemBaseWrappedChildProps) => {
  const { formItem, index, customizedLabels, previewing, prices, scores, priceCurrency, isNextSubmit, onSubmit, submitting, formLayout } = props;
  const { settings } = formItem;

  const [result, setResult] = useState(props.results[formItem._id] || null);
  const [hasError, setHasError] = useState(props.submitted && settings.required && !result);

  useEffect(() => {
    if (props.submitted && settings.required && !result) return setHasError(true);
  });

  const onSaveResult = (value) => {
    if (formLayout === 'classic' ? !value : !result && settings.required) return null;
    setTimeout(() => {
      if (formLayout === 'classic' ? !value : !result && settings.required) return null;
      return props.onResult(formItem._id, formLayout === 'classic' ? value : result);
    }, 200);
  };

  const onSaveResultDebounce = useCallback(debounce(onSaveResult, 1000), []);

  const onChangeInput = (e) => {
    if (settings.isTelephone) {
      setResult(e);
      if (e) setHasError(false);
      formLayout === 'classic' && onSaveResultDebounce(e)
    } else {
      setResult(e.target.value);
      if (e.target.value) setHasError(false);
      formLayout === 'classic' && onSaveResultDebounce(e.target.value)
    }
  }
  return (
    <QuestionLayout
      videoUrl={settings.videoUrl}
      image={settings.image}
      hideImage={true}
      layout={settings.layout}
      imageBrightness={settings.imageBrightness}
      formLayout={formLayout}
    >
      <StyledQuestionContainer style={props.device === 'mobile' ? { justifyContent: 'center' } : {}}>
        <Index renderIndex={index} required={settings.required} />
        <StyledQuestionContainerContent style={props.device === 'mobile' ? { minWidth: '90%' } : {}}>
          <Title
            prices={prices}
            priceCurrency={priceCurrency}
            scores={scores}
            value={formItem.title}
            results={props.results}
            containerProps={{
              style: { textAlign: 'left', marginBottom: settings.descriptionEnabled && formItem.description ? 0 : 22 },
            }}

          />
          {settings.descriptionEnabled && formItem.description && (
            <Description
              prices={prices}
              scores={scores}
              priceCurrency={priceCurrency}
              results={props.results}
              value={formItem.description}
            />
          )}
          {settings.videoUrl && <Video value={settings.videoUrl} />}
          {!settings.videoUrl &&
            settings.image &&
            (!settings.layout || settings.layout === FormItemSettingsLayout.NORMAL) && (
              <Image
                value={settings.image}
                containerProps={{
                  className: 'image--normal',
                  style: { filter: `brightness(${(settings.imageBrightness || 100) / 100})` },
                }}
              />
            )}
          {settings.isTelephone
            ?
            <CountryPhoneInput
              value={result}
              placeholder={getSetting(customizedLabels, 'HINT_FOR_ADDING_TEXT', 'Please enter your response')}
              onChange={onChangeInput}
              containerProps={{
                defaultValue: result,
                id: `input-${formItem._id}`,
                onChange: onChangeInput
              }}
            />
            :
            <InputNumber
              value={result}
              placeholder={getSetting(customizedLabels, 'HINT_FOR_ADDING_TEXT', 'Please enter your response')}
              containerProps={{
                defaultValue: result,
                id: `input-${formItem._id}`,
                onChange: onChangeInput
              }}
            />
          }


          <div className="error">
            {hasError && (
              <StyledErrorMessage>
                {getSetting(customizedLabels, 'ERROR_IF_AN_ANSWER_IS_REQUIRED', 'Oops, Please enter your answer!')}
              </StyledErrorMessage>
            )}
          </div>
          {formLayout !== 'classic' &&
            <div className="buttons">
              {props.active && !isNextSubmit && (
                <ButtonGroup
                  previewing={previewing}
                  onClick={onSaveResult}
                  label={getSetting(customizedLabels, 'CONFIRM_ANSWER_BUTTON_TEXT', 'OK') || 'OK'}
                />
              )}
              {isNextSubmit && <Submit customizedLabels={customizedLabels} onSubmit={onSubmit} submitting={submitting} activeItemId={isNextSubmit && 'submit'} />}
            </div>
          }
        </StyledQuestionContainerContent>
      </StyledQuestionContainer>
    </QuestionLayout>
  );
};

export default Numeric;
