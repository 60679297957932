import React from 'react';
import styled from 'styled-components';
import { isMobile } from '@formtory/app/src/utils/helperFunctions';

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  &.image--normal {
    max-width: 80%;
    max-height: 50vh;
    margin-bottom: 22px;
    ${isMobile() && 'max-height: 40vh'};
    @media (max-width: 768px) {
      max-height: 40vh;
    }
    // margin-top: 12px;
    // margin-bottom: 16px;
    border-radius: 6px;
  }
  &.image--aside {
    max-width: 80%;
    ${isMobile() && 'max-height: 40vh'};
    @media (max-width: 768px) {
      max-height: 40vh;
    }
    border-radius: 12px;
  }
`;

const Image = ({ value, containerProps = {} }) => <StyledImage {...containerProps} src={value} />;

export default Image;
