import React, { useState, useEffect, useCallback } from 'react';
import Title from './Elements/Title';
import Input from './Elements/Input';
import Description from './Elements/Description';
import ButtonGroup from './Elements/ButtonGroup';
import { FormItemBaseWrappedChildProps } from './FormItemBase';
import { StyledQuestionContainer, StyledErrorMessage, StyledQuestionContainerContent } from './Styled';
import { getSetting } from './Utils';
import QuestionLayout from './QuestionLayout';
import { FormItemSettingsLayout } from '../../graphql/types';
import Image from './Elements/Image';
import Video from './Elements/Video';
import Index from './Elements/Index';
import Submit from './Submit';
import debounce from 'lodash/debounce';

const UnKnow = (props: FormItemBaseWrappedChildProps) => {
  const { formItem, index, customizedLabels, previewing, prices, scores, priceCurrency, isNextSubmit, onSubmit, submitting, formLayout } = props;
  const { settings } = formItem;
  const [result, setResult] = useState(null);
  const [hasError, setHasError] = useState(props.submitted && settings.required && !result);

  useEffect(() => {
    if (props.submitted && settings.required && !result) return setHasError(true);
  });

  const onSaveResult = (value) => {
    if (formLayout === 'classic' ? !value : !result && settings.required) return setHasError(true);
    setTimeout(() => {
      return props.onResult(formItem._id, formLayout === 'classic' ? value : result);
    }, 200);
  };

  const onSaveResultDebounce = useCallback(debounce(onSaveResult, 1000), []);

  const onChangeInput = (e) => {
    if (
      e.target.value &&
      settings.maxCharactersEnabled &&
      settings.maxCharactersValue &&
      settings.maxCharactersValue > 0 &&
      settings.maxCharactersValue === e.target.value.length
    ) {
      return null;
    }
    setResult(e.target.value);
    if (e.target.value) setHasError(false);
    formLayout === 'classic' && onSaveResultDebounce(e.target.value)
  }

  return (
    <QuestionLayout
      videoUrl={settings.videoUrl}
      image={settings.image}
      layout={settings.layout}
      hideImage
      imageBrightness={settings.imageBrightness}
      formLayout={formLayout}
      device={props.device}
    >
      <StyledQuestionContainer style={props.device === 'mobile' ? { justifyContent: 'center' } : {}}>
        <Index renderIndex={index} required={settings.required} />
        <StyledQuestionContainerContent style={props.device === 'mobile' ? { minWidth: '90%' } : {}}>
          <Title
            value={formItem.title}
            prices={prices}
            priceCurrency={priceCurrency}
            scores={scores}
            results={props.results}
            containerProps={{
              style: { textAlign: 'left', marginBottom: settings.descriptionEnabled && formItem.description ? 0 : 22 },
            }}

          />
          {settings.descriptionEnabled && formItem.description && (
            <Description
              prices={prices}
              scores={scores}
              priceCurrency={priceCurrency}
              results={props.results}
              value={formItem.description}
            />
          )}
          {settings.videoUrl && <Video value={settings.videoUrl} />}
          {!settings.videoUrl &&
            settings.image &&
            (!settings.layout || settings.layout === FormItemSettingsLayout.NORMAL) && (
              <Image
                value={settings.image}
                containerProps={{
                  className: 'image--normal',
                  style: { filter: `brightness(${(settings.imageBrightness || 100) / 100})` },
                }}
              />
            )}
          <Input
            onChange={onChangeInput}
            value={result}
            placeholder={getSetting(customizedLabels, 'HINT_FOR_ADDING_TEXT', 'Please enter your response')}
            containerProps={{
              value: result,
              defaultValue: result,
            }}
          />
          <div className="error">
            {hasError && (
              <StyledErrorMessage>
                {getSetting(customizedLabels, 'ERROR_IF_AN_ANSWER_IS_REQUIRED', 'Oops, Please enter your answer!')}
              </StyledErrorMessage>
            )}
          </div>
          {formLayout !== 'classic' &&
            <div className="buttons">
              {props.active && result && !isNextSubmit && (
                <ButtonGroup
                  previewing={previewing}
                  onClick={onSaveResult}
                  label={getSetting(customizedLabels, 'CONFIRM_ANSWER_BUTTON_TEXT', 'OK') || 'OK'}
                />
              )}
              {isNextSubmit && <Submit customizedLabels={customizedLabels} onSubmit={onSubmit} submitting={submitting} activeItemId={isNextSubmit && 'submit'} />}
            </div>
          }
        </StyledQuestionContainerContent>
      </StyledQuestionContainer>
    </QuestionLayout>
  );
};

export default UnKnow;
