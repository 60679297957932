import * as React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import AppProvider from '@formtory/shared/contexts/AppContext';
import { ApolloProvider } from 'react-apollo';
import MainTheme from '@formtory/shared/themes/main';
import { ThemeProvider } from 'styled-components';
import { RouterHistoryService as history } from '@formtory/shared/core';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import App from './containers/App/App';
import LanguageProvider from './containers/LanguageProvider';
import * as serviceWorker from './serviceWorker';
import { translationMessages } from './i18n';
import './vendor';
import GlobalApollo from './apollo';

const renderApp = (messages: any) => {
  render(
    <ApolloProvider client={GlobalApollo.GlobalApolloClient}>
      <ApolloHooksProvider client={GlobalApollo.GlobalApolloClient}>
        <ThemeProvider theme={MainTheme}>
          <LanguageProvider messages={messages}>
            <AppProvider>
              <Router history={history}>
                <App />
              </Router>
            </AppProvider>
          </LanguageProvider>
        </ThemeProvider>
      </ApolloHooksProvider>
    </ApolloProvider>,
    document.getElementById('root'),
  );
};

renderApp(translationMessages);

serviceWorker.unregister();
