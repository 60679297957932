import React, { useEffect, useState, useRef } from 'react';
import { List, Icon, Input, Button } from 'antd';
import styled from 'styled-components';
import sampleSize from 'lodash/sampleSize';
import EventHub from '@formtory/shared/core/events/hub';
import Title from './Elements/Title';
import Description from './Elements/Description';
import ButtonGroup from './Elements/ButtonGroup';
import { FormItemBaseWrappedChildProps } from './FormItemBase';
import { AlphabetCharacters } from '@formtory/shared/data/default';
import {
  StyledQuestionContainerNoMedia,
  StyledErrorMessage,
  StyledQuestionContainerContent,
  StyledAlphabetCharacters,
  StyledOptionContent,
} from './Styled';
import { getSetting } from './Utils';
import QuestionLayout from './QuestionLayout';
import Video from './Elements/Video';
import { FormItemSettingsLayout } from '../../graphql/types';
import Image from './Elements/Image';
import Index from './Elements/Index';
import { isMobile } from '@formtory/app/src/utils/helperFunctions';
import Submit from './Submit';
import { AppContext } from '../../contexts/AppContext';
import { useSingleFileUploadMutation } from './FileUpload';

const StyledAddBackgroundImageButton = styled.label`
  cursor: pointer;
  border: none;
`;

const StyledList = styled<any>(List)`
  text-align: left;
  .ant-row {
    display: inline-flex;
    list-style: none;
    padding: 0px;
    flex-flow: row wrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    width: 100%;
  }
  .ant-col {
    margin-bottom: 16px;
  }
`;

const StyledOption = styled.div`
  background: ${(props) => props.theme.form.answers}19;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid ${(props) => props.theme.form.answers};
  color: ${(props) => props.theme.form.answers};
  img {
    max-width: 100%;
    max-height: 100%;
  }
  div.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 15px;
    div {
      width: 100%;
      // height: calc(100% - (48px));
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .choice {
      color: ${(props) => props.theme.form.answers};
      font-size: 18px;
      margin: 10px 0 5px 0;
      display: block;
      word-wrap: break-word;
      // overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      // max-height: 48px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  &:hover,
  &:focus {
    opacity: 1;
    background: ${(props) => props.theme.form.answers}55;
    border: 1px solid ${(props) => props.theme.form.answers};
    color: ${(props) => props.theme.form.answers};
  }
  &.active {
    border: 1px solid ${(props) => props.theme.form.answers};
    background: ${(props) => props.theme.form.answers}55;
    color: ${(props) => props.theme.form.answers};
    p {
      color: ${(props) => props.theme.form.answers};
    }
  }
  .checked-container {
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    border-top: 40px solid ${(props) => props.theme.form.answers};
    position: absolute;
    right: -27px;
    top: -7px;
    transform: rotate(225deg);
    transition: 0.3s;
    i {
      color: ${(props) => props.theme.form.background};
      font-size: 18px;
      position: absolute;
      left: -11px;
      bottom: 14px;
      transform: rotate(130deg);
    }
  }
`;

const ChoiceItem = ({ choice, selected, onSelectChoice, index }) => (
  <StyledOption onClick={() => onSelectChoice(choice)} className={selected ? 'active' : ''}>
    <div className={'wrapper'}>
      <div>
        <img src={choice.image} alt="" />
      </div>
      <div className={'choice'}>
        <StyledAlphabetCharacters className={selected ? 'active' : ''}>
          {AlphabetCharacters[index]}
        </StyledAlphabetCharacters>
        <StyledOptionContent>{choice.label}</StyledOptionContent>
      </div>
    </div>
    {selected && (
      <div className="checked-container">
        <Icon type={'check'} />
      </div>
    )}
  </StyledOption>
);

const OtherChoiceItem = ({ choice, selected, onSelectChoice = null, onChangeOtherImage = null, onChangeOtherInput = null, index }) => {
  const otherInputFocus = useRef(null);
  const value = choice.image
  const singleUpload = useSingleFileUploadMutation();
  const [uploading, setUploading] = useState(false);
  // const [openUnsplash, setOpenUnsplash] = useState(false);
  const [otherInputOpen, setOtherInputOpen] = useState(false);
  const context = React.useContext(AppContext);
  const { intl: { formatMessage } } = context;
  const onChangeImage = async (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    setUploading(true);
    const response = await singleUpload({ variables: { file: e.target.files[0] } });
    setUploading(false);
    if (!response.data.singleUpload || !response.data.singleUpload.uri) return null;
    onChangeOtherImage(response.data.singleUpload.uri);
  };
  return (
    <StyledOption onClick={() => onSelectChoice(choice)} className={selected ? 'active' : ''}>
      <div className={'wrapper'}>
        {value && <div>
          <img src={value} alt="" />
        </div>}
        {selected && (
          <div>
            <StyledAddBackgroundImageButton htmlFor={'other-image'}>
              {uploading ? formatMessage({ id: 'uploading' }) : formatMessage({ id: 'upload' })}
            </StyledAddBackgroundImageButton>
          </div>
        )}
        <input
          type="file"
          onChange={onChangeImage}
          style={{ display: 'none' }}
          id={'other-image'}
          accept="image/x-png,image/gif,image/jpeg"
        />
        <div className={'choice'} onClick={() => {
          if (!otherInputOpen) {
            otherInputFocus.current?.focus({ cursor: 'start' });
            setOtherInputOpen(true);
          }
        }}>
          <StyledAlphabetCharacters className={selected ? 'active' : ''}>
            {AlphabetCharacters[index]}
          </StyledAlphabetCharacters>
          <StyledOptionContent>
            {otherInputOpen
              ?
              <>
                <Input
                  ref={otherInputFocus}
                  onChange={(e) => onChangeOtherInput(e.target.value)}
                  value={choice.label}
                  style={{ width: 'calc(100% - 40px)', height: '28px', background: 'transparent', fontSize: '20px', color: '#643ced', border: 'none', boxShadow: 'none', padding: 0 }}
                  placeholder={'Please enter your response'}
                />
                <Button
                  onClick={() => {
                    setOtherInputOpen(false)
                    onSelectChoice(choice.label)
                  }}
                  style={{ width: '28px', height: '28px', padding: 0, borderRadius: '2px' }}
                >
                  <Icon type={'check'} />
                </Button>
              </>
              :
              <>
                {choice.label}
                {selected && !otherInputOpen && (
                  <StyledAddBackgroundImageButton
                    onClick={() => {
                      if (!otherInputOpen) {
                        otherInputFocus.current?.focus({ cursor: 'start' });
                        setOtherInputOpen(true);
                      }
                    }}
                  >
                    <Icon type={'edit'} style={{ color: '#000' }} />
                  </StyledAddBackgroundImageButton>
                )}
              </>
            }
          </StyledOptionContent>
        </div>
      </div>
      {selected && (
        <div className="checked-container">
          <Icon type={'check'} />
        </div>
      )}
    </StyledOption>
  );
}

// const LogoImageSelector = ({ value, onChange }) => {
//   const singleUpload = useSingleFileUploadMutation();
//   const [uploading, setUploading] = useState(false);
//   // const [openUnsplash, setOpenUnsplash] = useState(false);
//   const context = React.useContext(AppContext);
//   const { intl: { formatMessage } } = context;


//   return (
//     <div style={{ position: 'relative', display: 'flex' }}>
//       {value && <StyledBackgroundImage src={value} />}
//       {!value && (
//         <div>
//           <StyledAddBackgroundImageButton htmlFor={'theme-logo-image'}>
//             {uploading ? formatMessage({ id: 'uploading' }) : formatMessage({ id: 'upload' })}
//           </StyledAddBackgroundImageButton>
//           {/* <StyledAddBackgroundImageButton onClick={() => setOpenUnsplash(true)}>{formatMessage({ id: 'library' })}</StyledAddBackgroundImageButton> */}
//         </div>
//       )}
//       {value && (
//         <div>
//           <StyledAddBackgroundImageButton onClick={() => onChange(null)}>{formatMessage({ id: 'remove' })}</StyledAddBackgroundImageButton>
//         </div>
//       )}
//       <input
//         type="file"
//         onChange={onChangeImage}
//         style={{ display: 'none' }}
//         id={'theme-logo-image'}
//         accept="image/x-png,image/gif,image/jpeg"
//       />
//       {/* {openUnsplash && (
//         <UnsplashPhotoSearcherModal
//           onSelected={(data: UnsplashFileUpload['unsplashUpload']) => onChange(data.uri)}
//           visible={openUnsplash}
//           onClose={() => setOpenUnsplash(false)}
//         />
//       )} */}
//     </div>
//   );
// };

const PictureChoice = (props: FormItemBaseWrappedChildProps) => {
  const { formItem, index, customizedLabels, previewing, prices, scores, priceCurrency, isNextSubmit, onSubmit, submitting, formLayout, locale } = props;
  const { settings } = formItem;
  const containerWidth = document.getElementById(`ChoiceOptionContainers-${formItem._id}`)?.clientWidth || 1024;
  const [results, setResults] = useState(props.results[formItem._id] || []);
  const [resultsIndex, setResultsIndex] = useState(props.resultsIndex[formItem._id] || []);
  const [otherImage, setOtherImage] = useState('https://via.placeholder.com/728x728.png?text=Other');
  const [otherInput, setOtherInput] = useState('Other');
  const [hasError, setHasError] = useState(false);
  const [choices, setChoices] = useState(
    settings.randomizeEnabled
      ? sampleSize(settings.pictureChoices, settings.pictureChoices.length)
      : settings.pictureChoices,
  );

  const resetChoices = (e) => {
    if (!previewing) return;
    const settings = e.detail.settings;
    setChoices(
      settings.randomizeEnabled
        ? sampleSize(settings.pictureChoices, settings.pictureChoices.length)
        : settings.pictureChoices,
    );
  };

  useEffect(() => {
    EventHub.addEventListener(`SAVED_SETTING:${formItem._id}`, resetChoices);
    return () => {
      EventHub.removeEventListener(`SAVED_SETTING:${formItem._id}`, resetChoices);
    };
  }, []);

  useEffect(() => {
    setChoices(
      settings.randomizeEnabled
        ? sampleSize(settings.pictureChoices, settings.pictureChoices.length)
        : settings.pictureChoices,
    );
  }, [locale]);

  useEffect(() => {
    setChoices(
      settings.randomizeEnabled
        ? sampleSize(settings.pictureChoices, settings.pictureChoices.length)
        : settings.pictureChoices,
    );
  }, [formItem._id]);

  useEffect(() => {
    if (props.submitted && settings.required && results.length === 0) return setHasError(true);
  });

  const onSelectChoice = (choice) => {
    const choiceIndex = settings.pictureChoices.findIndex((c) => c === choice)
    if (choiceIndex === -1) {
      if (!settings.multipleSelectionEnabled) {
        setResults([choice]);
        return setResultsIndex([choiceIndex]);
      }
    }
    if (!settings.multipleSelectionEnabled) {
      setResults([choice]);
      setResultsIndex([choiceIndex]);
      return setTimeout(() => {
        onSaveResults([choice], [choiceIndex]);
      }, 200);
    }
    const index = results.findIndex((result) => result.image === choice.image);
    if (index > -1) {
      results.splice(index, 1);
      resultsIndex.splice(index, 1);
      if (formLayout === 'classic') {
        setResults([...results])
        setResultsIndex([...resultsIndex])
        return setTimeout(() => {
          onSaveResults(results, resultsIndex);
        }, 200);
      }
      setResults([...results]);
      return setResultsIndex([...resultsIndex]);
    }
    if (formLayout === 'classic') {
      setResults([...results, ...[choice]])
      setResultsIndex([...resultsIndex, ...[choiceIndex]])
      return setTimeout(() => {
        onSaveResults([...results, ...[choice]], [...resultsIndex, ...[choiceIndex]]);
      }, 200);
    }
    setResults([...results, ...[choice]]);
    return setResultsIndex([...resultsIndex, ...[choiceIndex]]);
  };

  const onSaveResults = (results, resultsIndex) => {
    if (results.length === 0) return null;
    return props.onResult(formItem._id, results, resultsIndex);
  };
  return (
    <QuestionLayout
      videoUrl={settings.videoUrl}
      image={settings.image}
      layout={settings.layout}
      hideImage
      imageBrightness={settings.imageBrightness}
      formLayout={formLayout}
      device={props.device}
    >
      <StyledQuestionContainerNoMedia>
        <Index renderIndex={index} required={settings.required} />
        <StyledQuestionContainerContent style={props.device === 'mobile' ? { minWidth: '90%' } : {}}>
          <Title
            value={formItem.title}
            prices={prices}
            priceCurrency={priceCurrency}
            scores={scores}
            results={props.results}
            containerProps={{
              style: { textAlign: 'left', marginBottom: settings.descriptionEnabled && formItem.description ? 0 : 22 },
            }}

          />
          {settings.descriptionEnabled && formItem.description && (
            <Description
              prices={prices}
              scores={scores}
              priceCurrency={priceCurrency}
              results={props.results}
              value={formItem.description}
            />
          )}
          {settings.videoUrl && <Video value={settings.videoUrl} />}
          {!settings.videoUrl &&
            settings.image &&
            (!settings.layout || settings.layout === FormItemSettingsLayout.NORMAL) && (
              <Image
                value={settings.image}
                containerProps={{
                  className: 'image--normal',
                  style: { filter: `brightness(${(settings.imageBrightness || 100) / 100})` },
                }}
              />
            )}
          <div id={`ChoiceOptionContainers-${formItem._id}`} style={{ width: '100%' }}>
            <StyledList
              grid={{ column: isMobile() ? 2 : settings.pictureChoiceSuperSize ? 2 : 4, gutter: 10 }}
              dataSource={choices}
              renderItem={(item, index) => {
                if (index === choices.length - 1 && settings.otherOptionEnabled) {
                  return (
                    <React.Fragment>
                      <List.Item style={{ width: '100%', height: '100%' }}>
                        <ChoiceItem
                          selected={!!results.find((result) => result.image === item.image)}
                          onSelectChoice={onSelectChoice}
                          index={index}
                          choice={item}
                        />
                      </List.Item>
                      <List.Item style={{ width: '100%', height: '100%' }}>
                        <OtherChoiceItem
                          selected={
                            !!results.find(
                              (result) => result.image === otherImage,
                            )
                          }
                          onSelectChoice={onSelectChoice}
                          onChangeOtherImage={setOtherImage}
                          onChangeOtherInput={setOtherInput}
                          index={choices.length}
                          choice={{
                            image: otherImage,
                            label: otherInput,
                          }}
                        />
                      </List.Item>
                    </React.Fragment>
                  );
                }
                return (
                  <List.Item style={{ width: '100%', height: '100%' }}>
                    <ChoiceItem
                      selected={!!results.find((result) => result.image === item.image)}
                      onSelectChoice={onSelectChoice}
                      index={index}
                      choice={item}
                    />
                  </List.Item>
                );
              }}
            />
          </div>
          <div className="error">
            {hasError && (
              <StyledErrorMessage>
                {getSetting(customizedLabels, 'ERROR_IF_AN_ANSWER_REQUIRES_A_SELECTION', 'Oops, Please make a selection')}
              </StyledErrorMessage>
            )}
          </div>
          {formLayout !== 'classic' &&
            <div className="buttons">
              {props.active && !settings.multipleSelectionEnabled && results.length > 0 && !isNextSubmit && !!resultsIndex.includes(-1) && (
                <ButtonGroup
                  previewing={previewing}
                  onClick={() => onSaveResults(results, resultsIndex)}
                  label={settings.buttonText || 'OK'}
                />
              )}
              {props.active && settings.multipleSelectionEnabled && results.length > 0 && !isNextSubmit && (
                <ButtonGroup
                  previewing={previewing}
                  onClick={() => onSaveResults(results, resultsIndex)}
                  label={settings.buttonText || 'OK'}
                />
              )}
              {isNextSubmit && <Submit customizedLabels={customizedLabels} onSubmit={onSubmit} submitting={submitting} activeItemId={isNextSubmit && 'submit'} />}
            </div>
          }
        </StyledQuestionContainerContent>
      </StyledQuestionContainerNoMedia>
    </QuestionLayout>
  );
};

export default PictureChoice;
