const ServiceType = `
  _id
  calculatedBy
  description
  icon
  title
  mobileIcon
  type
  photos
  nonFixed
  popular
  customPriceTitle
  disableForBooking
  directlyContact
  source
  order
  price
  refId
`;

export default ServiceType;
